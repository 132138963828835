import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../../api/Router';

import { scrollToPosition } from '../../../../api/utils';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../../domain/_shared';
import * as services from '../../../../domain/services';
import { Survey, AnswersData, Question } from '../../../../domain/models';
import { extractInitialFrom, updateSingleAnswer, missingRequiredAnswers, isOwnTask, isAnswerable, isAnswered } from '../../../../components/Task/synchronous-helpers';

import { P50x } from '../../../../components/P50x';
import { BaseAppLayout } from '../../../../layouts/shared/BaseAppLayout';
import { ButtonBack } from '../../../../components/Buttons/ButtonBack';
import { FeedbackBox } from '../../../../components/Task/FeedbackBox';
import { SurveyFeedback } from '../../../../components/Task/SurveyFeedback';
import { CountdownTrigger } from '../../../../components/Task/CountdownTrigger';
import { CardSection, CardSectionLoading, CardViewType } from '../../../../components/Cards/CardSection';
import { ClickableContentArea } from '../../../../components/ClickableContentArea';
import { QuestionsList } from '../../../../components/Forms/QuestionsList';
import { SubmitForm } from '../../../../components/Buttons/SubmitForm';
import { CancelForm } from '../../../../components/Buttons/CancelForm';

export interface SurveyPageRouteParameters {
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export interface SurveyPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

type State = {
    apiError?: any | undefined;
    survey?: Survey;
    answers?: AnswersData;
    missingAnswers?: Question["id"][];
    operation?: string;
    lock?: boolean;
    submitted?: boolean;
    newStatus?: string;
    showFeedback?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadSurvey(survey?: Survey): State;
    SetAnswers(answers: AnswersData): State;
    CheckAnswers(missingAnswers: Question["id"][]): State;
    RemoveMissingAnswer(missingAnswers: Question["id"][], questionId: number): State;
    InactivateForm(): State;
    SendForm(operation: string): State;
    UnlockSend(): State;
    PostStatus200(newStatus: string): State;
    ToggleFeedback(showFeedback: boolean): State;
    ReactivateForm(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadSurvey: survey => ({ ...state, survey }),
    SetAnswers: answers => ({ ...state, answers }),
    CheckAnswers: missingAnswers => ({ ...state, missingAnswers }),
    RemoveMissingAnswer: (missingAnswers, questionId) => ({ ...state, missingAnswers: missingAnswers.filter((i) => i !== questionId) }),
    InactivateForm: () => ({ ...state, submitted: true }),
    SendForm: operation => ({ ...state, operation, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    PostStatus200: newStatus => ({ ...state, lock: false, submitted: true, newStatus }),
    ToggleFeedback: showFeedback => ({ ...state, showFeedback }),
    ReactivateForm: () => ({ ...state, submitted: false })
})

export const SurveyPage: React.FunctionComponent<SurveyPageProps> = ({
    auth,
    onLogout,
    groupId,
    courseId,
    lessonId,
    taskId
}) => {
    const { t } = useTranslation();
    const [{
        apiError,
        survey,
        answers,
        missingAnswers,
        operation,
        lock,
        submitted,
        newStatus,
        showFeedback
    }, actions] = wrapperReducer(useReducer(reducer, {
        lock: false,
        submitted: false,
        showFeedback: false,
    }));

    const goToRoot = () => Router.push('/');
    const goToLessons = () => Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`);

    useLoadSurvey(auth, taskId, actions);
    
    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        
        <div>         

            {(survey)
                ? (
                    <>                      

                        <div className="card bg-white px-4 pt-3">
                            <CardSection
                                title={survey.name}
                                showGridListToggle={false}
                                initialCardViewType={CardViewType.List}
                            >
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />

                                    {survey.coachBody && (
                                        <>
                                            <ClickableContentArea
                                                rawHTML={survey.coachBody || ''}
                                                onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))}
                                            />
                                            <hr className="mt-0 mb-4" />
                                        </>
                                    )}

                                    <QuestionsList
                                        active={isAnswerable(survey, 'coach') && !lock && !submitted}
                                        visible={isOwnTask(survey, 'coach') || !survey.isResponsePrivate}
                                        answered={false}
                                        questions={survey.questions}
                                        currentValues={answers}
                                        missingAnswers={missingAnswers}
                                        onChange={(type, id, val) => {
                                            actions.send(a => a.SetAnswers(updateSingleAnswer(type, id, val, answers)));
                                            (!!missingAnswers) && actions.send(a => a.RemoveMissingAnswer(missingAnswers, id));
                                        }}
                                    />

                                    <hr />

                                    <div className="col-12 text-right">
                                        {(isAnswerable(survey, 'coach'))
                                            ? (<>
                                                <SubmitForm
                                                    active={!lock && !submitted}
                                                    secondaryColor={true}
                                                    label={t('TaskPage.saveBtn')}
                                                    loading={!!lock && (operation === 'save')}
                                                    onSubmit={() => {
                                                        actions.send(a => a.CheckAnswers([]));
                                                        actions.send(a => a.SendForm('save'));
                                                    }}
                                                />
                                                <SubmitForm
                                                    active={!lock && !submitted}
                                                    label={t('TaskPage.submitBtn')}
                                                    loading={!!lock && (operation === 'submit')}
                                                    onSubmit={() => {
                                                        actions.send(a => a.CheckAnswers(missingRequiredAnswers(survey, answers)));
                                                        actions.send(a => a.SendForm('submit'));
                                                    }}
                                                />
                                                <CancelForm
                                                    active={!lock && !submitted}
                                                    label={t('TaskPage.cancelBtn')}
                                                    onCancel={() => { actions.send(a => a.SetAnswers({})); goToLessons(); }}
                                                />
                                            </>)
                                            : (<ButtonBack text={t('TaskPage.goBack')} btnDefault={true} onClick={goToLessons} />)
                                        }
                                    </div>

                                </div>
                            </CardSection>
                        </div>
                    </>
                )
                : <CardSectionLoading description={true} showGridListToggle={true} initialCardViewType={CardViewType.List} />}
        </div>
    );
}

const useLoadSurvey = function (auth: Auth,  surveyId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            actions.send(a => a.LoadSurvey(undefined));
            const survey = await services.getFormContent(auth,  surveyId);
            if (isRequestError(survey)) {
                // TODO: log the error
                // TODO: Diogo - create request error message
                // TODO: use error boundary
                console.log(survey);
            }
            else {
                actions.send(a => a.LoadSurvey(survey));
                actions.send(a => a.SetAnswers(extractInitialFrom(survey)));
                scrollToPosition(0, 0);

                if (survey.status !== 'NotStarted') {
                    actions.send(a => a.InactivateForm());
                    actions.send(a => a.ToggleFeedback(true));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [surveyId]);
}

const useSendSurvey = function (lock: boolean, submitted: boolean, auth: Auth, traineeId: number, surveyId: number, operation: string, answers: AnswersData, missingAnswers: Question["id"][], actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                if (missingAnswers.length === 0) {
                    const status = await services.postCoachSurveyAnswer(auth, traineeId, surveyId, { op: operation, answers });
                    if (isRequestError(status)) {
                        // TODO: log the error
                        // TODO: Diogo - create request error message
                        // TODO: use error boundary
                        console.log(status);

                        // (TODO) unlock resend in any error case?
                        actions.send(a => a.UnlockSend());
                    }
                    else {
                        actions.send(a => a.PostStatus200(status));           // Freezes Submit/Cancel
                        actions.send(a => a.ToggleFeedback(true));
                        scrollToPosition(0, 0);
                    }
                } else {
                    actions.send(a => a.UnlockSend());
                    scrollToPosition(0, 0);
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}
