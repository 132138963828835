import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ProfileCardProps {
    fullName?: string;
    nickName?: string;
    phone?: string;                     // (TODO) number format TBD
    email?: string;
    language?: string;                  // 'en-US', 'pt-BR', ...    
    timezone?: string;                  // 'America/Chicago', 'America/Sao_Paulo', ...
    onEditProfileClick: () => void;
    onChangePasswordClick: () => void;
}

export const ProfileCard: React.FunctionComponent<ProfileCardProps> = ({
    fullName,
    nickName,
    phone,
    email,
    language,
    timezone,
    onEditProfileClick,
    onChangePasswordClick
}) => {
    const { t } = useTranslation();

    return (

        <div className="card">
            <div className="card-header">
                <h1 className="h5"><strong>{t('ProfileCard.myProfile')}</strong></h1>
            </div>

            <div className="card-body">
                <ProfileForm
                    fullNameText={fullName || ''}
                    nickNameText={nickName || ''}
                    phoneText={phone || ''}         // (TODO) change displaying format 
                    emailText={email || ''}
                    languageValue={language || ''}
                    timezoneValue={timezone || ''}
                />
            </div>

            <div className="card-footer text-right">
                <button className="btn btn-primary m-1" type="button" onClick={() => onEditProfileClick()}>
                    {t('ProfileCard.editProfileBtn')}
                </button>
                <button className="btn btn-primary m-1" type="button" onClick={() => onChangePasswordClick()}>
                    {t('ProfileCard.changePasswordBtn')}
                </button>
            </div>
        </div>
    )
}

interface ProfileFormProps {
    fullNameText: string;
    nickNameText: string;
    phoneText: string;
    emailText: string;
    languageValue: string;
    timezoneValue: string;
}

const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({
    fullNameText,
    nickNameText,
    phoneText,
    emailText,
    languageValue,
    timezoneValue
}) => {
    const { t } = useTranslation();

    return (
        <form className="form-horizontal col-md-10 col-lg-8 col-xlg-6">
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.fullName')}</label>
                <div className="col-md-9">
                    <p className="form-control-static">{fullNameText}</p>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.nickName')}</label>
                <div className="col-md-9">
                    <p className="form-control-static">{nickNameText}</p>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.phone')}</label>
                <div className="col-md-9">
                    <p className="form-control-static">{phoneText}</p>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.email')}</label>
                <div className="col-md-9">
                    <p className="form-control-static">{emailText}</p>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.language')}</label>
                <div className="col-md-9">
                    <p className="form-control-static">{languageValue && t(`IANA.${languageValue}`)}</p>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.timezone')}</label>
                <div className="col-md-9">
                    <p className="form-control-static">{timezoneValue && t(`IANA.${timezoneValue}`)}</p>
                </div>
            </div>

            <hr className="mb-4" />
            <div className="form-group row">
                <label className="col-md-3 col-form-label">{t('ProfileCard.password')}</label>
                <div className="col-md-9">
                    <input className="form-control" type="password" value="000000000000" disabled />
                </div>
            </div>

        </form>
    )
}
