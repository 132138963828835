import * as React from 'react';
import { RadioboxI } from '../Forms/RadioboxI';
import { QuestionOption, Question } from '../../domain/models';
import { QuizOptionField } from './QuizOptionField';
import { EditQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditQuestion';
import { EditRadiobox } from '../../pages/Admin/CourseManagementPage/components/tab1/EditRadiobox';

export interface QuizRadioBoxProps {
    onChangeOption: (options: QuestionOption[]) => void;
    onSetAnswer: (options: QuestionOption[]) => void;
    updateQuestion?: (question: Question) => void;
    question?: Question;
    currentOptions?: QuestionOption[];
    onCompleteEdit?: (item: QuestionOption) => void
    order?: number;
    expectedAnswer?: number[];
    onChangeAnswer?: (options: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
}

export const QuizRadioBox: React.FunctionComponent<QuizRadioBoxProps> = ({ onChangeOption,
    onSetAnswer,
    question,
    updateQuestion,
    currentOptions,
    onCompleteEdit,
    onAddOption,
    onDeleteOption,
    order,
    expectedAnswer,
    onChangeAnswer }) => {

    interface QuizOption {
        // index: number;
        option?: string;
        isSelected: boolean;
    }

    const initialOptionsValue: QuizOption[] = [{ option: '', isSelected: false },
    { option: '', isSelected: false },
    { option: '', isSelected: false }
    ]

    const [options, setOptions] = React.useState(initialOptionsValue)
    const [selectedIndex, setSelectedIndex] = React.useState()
    const [isEditMode, setEditMode] = React.useState(false)
    const [validationOn, toggleValidation] = React.useState(false)
    React.useEffect(() => {
        if (question) setEditMode(true)
    }, [])

    const onAddNewOptionHandler = (index: number) => {
        const updatedOptions = [...options]
        updatedOptions.splice(index + 1, 0, { option: '', isSelected: false })
        setOptions([...updatedOptions])
        toggleValidation(false)
    }
    const onRemoveOptionHandler = (index: number) => {
        const updatedOptions = [...options]
        if (updatedOptions.length < 2) {
            toggleValidation(true)
            return
        }
        updatedOptions.splice(index, 1)
        setOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option.option!,
                order: index + 1
            }
        })
        onChangeOption(questionOptions)
    }

    const onChangeHandler = (val: string, index: number) => {
        const updatedOptions = [...options]
        updatedOptions.splice(index, 1, { isSelected: options[index].isSelected, option: val })
        setOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option.option!,
                order: index + 1
            }
        })
        onChangeOption(questionOptions)
        toggleValidation(false)
    }

    const onOptionChangeHandler = (val: string) => {
        let option = options[Number(val)]
        const updatedOptions = [...options]
        updatedOptions.splice(Number(val), 1, { isSelected: !options[Number(val)].isSelected, option: options[Number(val)].option })
        setOptions([...updatedOptions])
        let answer = [{
            id: 0,
            body: option.option!
        }]
        setSelectedIndex(Number(val))
        onSetAnswer(answer);
    }

    return (
        <div>
            <EditQuestion question={question} updateQuestion={updateQuestion} order={order} />
            <>
                {!isEditMode &&
                    options.map((item, index) => (
                        <div className="d-flex">
                            <RadioboxI
                                active={true}
                                id={index.toString()}
                                value={index.toString()}
                                label={''}
                                isSelected={selectedIndex === index }
                                isValid={false}
                                isInvalid={false}
                                onChange={(index) => onOptionChangeHandler(index)}
                            />
                            <QuizOptionField
                                index={index}
                                active={true}
                                placeholder={'Enter an answer choice'}
                                value={item.option}
                                isValid={false}
                                isInvalid={false}
                                onChange={onChangeHandler}
                                onAddOption={onAddNewOptionHandler}
                                onRemoveOption={onRemoveOptionHandler}
                            />
                        </div>
                    ))

                }
                {validationOn &&
                    <div className="invalid-feedback d-flex">You cant delete all options.</div>
                }
                {isEditMode &&
                    <EditRadiobox
                        onChangeAnswer={onChangeAnswer}
                        expectedAnswer={expectedAnswer}
                        currentOptions={currentOptions!}
                        onCompleteEdit={onCompleteEdit!}
                        onAddOption={onAddOption}
                        onDeleteOption={onDeleteOption}
                    />
                }
            </>
        </div>
    );
}