import * as React from 'react';

import { Auth } from '../../domain/_shared';

import { CourseExecutionPage, CourseExecutionPageRouteParameters } from './CourseExecutionPage';
import { TaskPage, TaskPageRouteParameters } from './TaskPage';

export const traineeRoutes = (auth: Auth, handleLogout: () => void): [string[], Function][] => {
    return [
        [['groups', ':groupId', 'trainee', 'courses', ':courseId'],
        (params: CourseExecutionPageRouteParameters) => (
            <CourseExecutionPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
            />
        )],
        [['groups', ':groupId', 'trainee', 'courses', ':courseId', 'lessons', ':lessonId', 'tasks', ':taskId', ':taskType'],
        (params: TaskPageRouteParameters) => (
            <TaskPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
                lessonId={params.lessonId}
                taskId={params.taskId}
                taskType={params.taskType}
            />
        )],
    ]
}