import { __SERVER_API__ } from '.';
import { Auth, get, post } from '../_shared';
import {
    TraineeCourse,
    Lesson,
    Content,
    Meeting,
    EditableForm,
    Quiz,
    Survey,
    AnswersPostRequest
} from '../models';

export async function getTraineeCourse(auth: Auth, courseId: number): Promise<TraineeCourse> {
    return await get(`${__SERVER_API__}/api/trainee/courses/${courseId}`, auth);
}

export async function getTraineeLessonbyId(auth: Auth, lessonId: number): Promise<Lesson> {
    return await get(`${__SERVER_API__}/api/trainee/lessons/${lessonId}`, auth);
}

export async function getTraineeLessons(auth: Auth, courseId: number): Promise<Lesson[]> {
    return await get(`${__SERVER_API__}/api/trainee/courses/${courseId}/lessons`, auth);
}

export async function getTraineeContentTask(auth: Auth, contentId: number): Promise<Content> {
    return await get(`${__SERVER_API__}/api/trainee/content/${contentId}`, auth);
}

export async function getTraineeMeetingTask(auth: Auth, meetingId: number): Promise<Meeting> {
    return await get(`${__SERVER_API__}/api/trainee/meeting/${meetingId}`, auth);
}

export async function getTraineeEditableFormTask(auth: Auth, editableFormId: number): Promise<EditableForm> {
    return await get(`${__SERVER_API__}/api/trainee/form/${editableFormId}`, auth);
}

export async function getTraineeQuizTask(auth: Auth, quizId: number): Promise<Quiz> {
    return await get(`${__SERVER_API__}/api/trainee/form/${quizId}`, auth);
}

export async function getTraineeSurveyTask(auth: Auth, surveyId: number): Promise<Survey> {
    return await get(`${__SERVER_API__}/api/trainee/form/${surveyId}`, auth);
}

// export async function postTraineeContentRead(auth: Auth, contentId: number): Promise<void> { 
//     return new Promise<void>((resolve, reject) => { resolve() });
// } // (TODO) for now the GET /trainee/content/${contentId} is the implicit POST

export async function postTraineeEditableFormAnswer(auth: Auth, editableFormId: number, postData: AnswersPostRequest): Promise<string> {
    return await post<AnswersPostRequest, string>(`${__SERVER_API__}/api/trainee/form/${editableFormId}`, auth, postData);
}

export async function postTraineeQuizAnswer(auth: Auth, quizId: number, postData: AnswersPostRequest): Promise<string> {
    return await post<AnswersPostRequest, string>(`${__SERVER_API__}/api/trainee/form/${quizId}`, auth, postData);
}

export async function postTraineeSurveyAnswer(auth: Auth, surveyId: number, postData: AnswersPostRequest): Promise<string> {
    return await post<AnswersPostRequest, string>(`${__SERVER_API__}/api/trainee/form/${surveyId}`, auth, postData);
}

