import * as React from 'react';
import { SurveyOptionsField } from '../../../../../components/Survey/SurveyOptionField';
import { QuestionOption, Question } from '../../../../../domain/models';
import _ from 'lodash';

export interface EditSubQuestionProps {
    currentOptions: QuestionOption[];
    onCompleteEdit: (item: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
}

export const EditSubQuestion: React.FunctionComponent<EditSubQuestionProps> = ({
    currentOptions,
    onCompleteEdit,
    onAddOption,
    onDeleteOption
}) => {

    const [editedOptionValue, setEditedSubQuestionValue] = React.useState()
    const [editedOptionIndex, setEditedSubQuestionIndex] = React.useState()
    const [optionsList, setOptionsList] = React.useState(currentOptions)
    const [validationOn, toggleValidation] = React.useState(false)
    const [showSpinner, setShowSpinner] = React.useState()

    React.useEffect(() => {
        setOptionsList(currentOptions)
        setShowSpinner(undefined)
    }, [currentOptions])

    const editOptionHandler = (val: string, index: number) => {
        setEditedSubQuestionValue(val)
        setEditedSubQuestionIndex(index)
    }

    const onAddNewOptionHandler = (index: number) => {
        if (optionsList.length === currentOptions.length) {
            const updatedOptions = [...optionsList]
            updatedOptions.splice(index + 1, 0, { id: 0, body: '' })
            setOptionsList([...updatedOptions])
            toggleValidation(false)
        }
    }

    const onRemoveOptionHandler = (index: number) => {
        let item = optionsList[index]
        if (optionsList.length < 2) {
            toggleValidation(true)
            return
        }
        if (item.id !== 0) onDeleteOption!(item.id)
        else {
            const updatedOptions = [...optionsList]
            updatedOptions.splice(index, 1)
            setOptionsList([...updatedOptions])
        }
    }

    const editCompleteHandler = (item: QuestionOption, index: number) => () => {
      if(!_.isEmpty(editedOptionValue) && !_.isUndefined(editedOptionValue) && !_.isNull(editedOptionValue)) {
      setShowSpinner(editedOptionIndex)
        let updatedOption: QuestionOption = {
            id: item.id,
            body: editedOptionValue,
            order: index + 1,
            expectedAnswer: item.expectedAnswer
        }
        if (editedOptionValue)
            item.id === 0 ? onAddOption!(updatedOption) : onCompleteEdit(updatedOption)
      }
        setEditedSubQuestionValue(undefined)
        setEditedSubQuestionIndex(undefined)
    }

    return (
        <>
            {optionsList!.map((item, index) => (
                <SurveyOptionsField
                    index={index}
                    active={true}
                    placeholder={'Enter an answer choice'}
                    value={index === editedOptionIndex  ?  (editedOptionValue || '') : item.body}
                    isValid={false}
                    isInvalid={false}
                    onChange={editOptionHandler}
                    onAddOption={onAddNewOptionHandler}
                    onRemoveOption={onRemoveOptionHandler}
                    isEditing={index === editedOptionIndex}
                    showSpinner={index === showSpinner}
                    onCompleteEdit={editCompleteHandler(item, index)}
                />
            ))
            }
            {validationOn &&
                <div className="invalid-feedback d-flex">You cant delete more.You need atleast 1 subquestion.</div>
            }
        </>
    )

}