import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Member } from '../../../../../domain/models';

import { UserContext } from '../../../../../domain/contexts/UserContext';

export interface MembersListProps {
  member: Member,
  index?: number,
  isSelected?: boolean,
  onSelect?: (memberId: number) => void,
  onUnSelect?: (memberId: number) => void,
  onClickAppoveButton: (id: number) => void,
  onClickSetAdminButton: (id: number) => void,
  onClickSetNonAdminButton: (id: number) => void
}

export const MembersList: React.FunctionComponent<MembersListProps> = ({ member, isSelected, onClickAppoveButton, onClickSetAdminButton, onClickSetNonAdminButton }) => {
  const {state: { presets }} = useContext(UserContext);
  const { t } = useTranslation();
  const [rowStyle, updateRowStyle] = useState({})
  const approveButtonStyle = member.membershipStatus === 'Member' ? { display: 'block' } : {}
  const groupAdminButtonStyle = member.isAdmin === true ? { display: 'inline-block' } : {}

  const handleApprove = (id: number) => () => {
    onClickAppoveButton(id)
  }

  const handleSetAdmin = (id: number) => () => {
    onClickSetAdminButton(id)
  }
  
  const handleSetNonAdmin = (id: number) => () => {
    onClickSetNonAdminButton(id)
  }

  useEffect(() => {
    isSelected ? updateRowStyle({ backgroundColor: '#f5fdff' }) : updateRowStyle({})
  }, [isSelected])

  return (
    <>
      <div className="list-table-col" style={rowStyle}>
        <span className="">
          <strong>{member.name}</strong>
        </span>
      </div>

      <div className="list-table-col" style={rowStyle}>
        <span className="small">
          {member.email}
        </span>
      </div>

      <div className="list-table-col" style={rowStyle}>
        <strong className="small text-muted label-hidden">{t('MemberListItem.EnrollDate')}</strong>
        <span className="small">
          {
            (_.isUndefined(member.joinDate) || (_.isNull(member.joinDate))) ? t('miscelaneous.N/A') : t('dates.tiny', { valueObj: { type: 'withTZdatetime', data: member.joinDate } })
          }
        </span>
      </div>

      <div className="list-table-col">
        {((member.isAdmin === true)) &&
          <div className="group-btns">
            <span className="badge badge-pill badge-outline-info" style={groupAdminButtonStyle}>{t('MemberListItem.groupAdminRole')}</span>
            <span className="button-show-hide">
              {(presets ? member.id !== presets.uuid : false) && <button className="btn btn-light btn-sm m-1" onClick={handleSetNonAdmin(member.id)} style={approveButtonStyle}>{t('MemberListItem.setNonAdminBtn')}</button>}
            </span>
          </div>
        }
        {((member.isAdmin === false) && (member.membershipStatus === 'Member')) &&
          <div className="group-btns">
            <span className="badge badge-pill badge-outline-success" style={groupAdminButtonStyle}>{t('MemberListItem.approvedStatus')}</span>
            <span className="button-show-hide">
              {(presets ? member.id !== presets.uuid : false) && <button className="btn btn-light btn-sm m-1" onClick={handleSetAdmin(member.id)} style={approveButtonStyle}>{t('MemberListItem.setAdminBtn')}</button>}
            </span>
          </div>
        }
        {((member.isAdmin === false) && (member.membershipStatus === 'Pending')) &&
          <div className="group-btns">
            <span className="badge badge-pill badge-outline-danger" style={groupAdminButtonStyle}>{t('MemberListItem.pendingStatus')}</span>
            <span className="button-show-hide">
              {(presets ? member.id !== presets.uuid : false) && <button className="btn btn-light btn-sm m-1" onClick={handleApprove(member.id)} style={approveButtonStyle}>{t('MemberListItem.approveBtn')}</button>}
            </span>
          </div>
        }
      </div>  
    </>
  )
}
