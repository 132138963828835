import * as React from 'react';

export interface AlertProps {
    text: string,
    styleName: string,
}

export const Alert: React.FunctionComponent<AlertProps> = ({ text, styleName }) => {
    return (
        <div className={styleName} role="alert">
            {text}
        </div>
    )
}