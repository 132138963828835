import * as React from 'react';
import { AddButton } from './AddButton';

export interface LessonProps {
    onClick: () => void;
}

export const AddLesson: React.FunctionComponent<LessonProps> = ({ onClick, children }) => {
    return (
        <div className="card">

            <div className="card-header card-height">
                {
                    children
                }
                {!children &&
                    <AddButton active={true} label={'Lesson'} onAdd={onClick}></AddButton>
                }
            </div>

        </div>

    );
}