import _ from 'lodash';

import { Auth } from '../../../../../domain/_shared';
import * as services from '../../../../../domain/services';
import { Coach, Participant } from '../../../../../domain/models';

import { RequestList } from './DesignateCoachPopup';

export async function designateCoachHandler(auth: Auth, courseId: number, coaches: Coach[], selectedParticipants: number[], coachId: number, handleRequests: (failedRequests: number[]) => void) {
    try {
        let dispatchedRequest = 0
        let failedRequests: number[] = []
        let designateCoachRequestList: RequestList[] = []

        for (let i = 0; i < selectedParticipants.length; i++) {
            const request = services.assignCoach(auth, courseId, selectedParticipants[i], coachId)
            designateCoachRequestList.push({ promise: request, traineeID: selectedParticipants[i] })
        }
        designateCoachRequestList.forEach((request) => {
            request.promise
                .then(() => {
                    dispatchedRequest = dispatchedRequest + 1;
                    if (coaches && dispatchedRequest >= selectedParticipants.length) {
                        handleRequests(failedRequests)
                    }
                })
                .catch((error) => {
                    dispatchedRequest = dispatchedRequest + 1;
                    failedRequests.push(request.traineeID)
                    if (coaches && dispatchedRequest >= selectedParticipants.length) {
                        handleRequests(failedRequests)
                    }
                })
        })
    }
    catch (e) {
        return;
    }

}

export const coachUnAssignHandler = async (auth: Auth, courseId: number, selectedParticipants: number[], handleRequests: (failedRequests: number[]) => void) => {

    let dispatchedRequest = 0
    let failedRequests: number[] = []
    let coachUnAssignRequestList: RequestList[] = []
    try {
        for (let i = 0; i < selectedParticipants.length; i++) {
            const request = services.assignCoach(auth, courseId, selectedParticipants[i], null)
            coachUnAssignRequestList.push({ promise: request, traineeID: selectedParticipants[i] })
        }

        coachUnAssignRequestList.forEach((request) => {
            request.promise
                .then(() => {
                    dispatchedRequest = dispatchedRequest + 1;
                    if (dispatchedRequest >= selectedParticipants.length) {
                        handleRequests(failedRequests)
                    }
                })
                .catch((error) => {
                    dispatchedRequest = dispatchedRequest + 1;
                    failedRequests.push(request.traineeID)
                    if (dispatchedRequest >= selectedParticipants.length) {
                        handleRequests(failedRequests)
                    }
                })
        })

    }
    catch (e) {
        return;
    }

}

export const approveCourseMembers = async (auth: Auth, courseId: number, selectedParticipants: Participant[], handleRequests: (failedRequests: number[]) => void, id?: number) => {

    let dispatchedRequest = 0
    let failedRequests: number[] = []
    let approveRequestList: RequestList[] = []
    let selectedUsers = []
    id ? selectedUsers.push({ 'id': id }) : selectedUsers = _.filter(selectedParticipants, function (participant) {
        return participant.enrollmentStatus.toLowerCase() === 'pending';
    })
    try {
        for (let i = 0; i < selectedUsers.length; i++) {
            const request = services.approveUser(auth, courseId, selectedUsers[i].id)
            approveRequestList.push({ promise: request, traineeID: selectedUsers[i].id })
        }
        approveRequestList.forEach((request) => {
            request.promise
                .then(() => {
                    dispatchedRequest = dispatchedRequest + 1;
                    if (dispatchedRequest >= selectedUsers.length) {
                        handleRequests(failedRequests)
                    }
                })
                .catch((error) => {
                    dispatchedRequest = dispatchedRequest + 1;
                    failedRequests.push(request.traineeID)
                    if (dispatchedRequest >= selectedUsers.length) {
                        handleRequests(failedRequests)
                    }
                })
        })
    } catch (error) {
        return;
    }

}
