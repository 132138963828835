import * as React from 'react';

export interface ButtonEditProps {
    active: boolean,
    label: string,
    onEdit: () => void,
}

export const ButtonEdit: React.FunctionComponent<ButtonEditProps> = ({ active, label, onEdit }) => {
    return (
        <button
            disabled={!active}
            onClick={onEdit}
            className="btn btn-primary"
            type="submit"
        >
            <i className="icon fa fa-pencil"></i>
            {' '}{label}
        </button>
    )
}