import * as React from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classnames from 'classnames';

import { Participant } from '../../../../../domain/models';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';
import * as AdminActions from './actions';

import { PercentageBar } from '../../../../../components/ProgressBar/PercentageBar';
import { DesignateCoachPopup } from './DesignateCoachPopup';
import { UnassignCoachPopup } from './UnassignCoachPopup';
import { DesignateConfirmPopup } from './DesignateConfirmPopup';

export interface ParticipantListTableProps {
    rowWidth: number,
    participant: Participant,
    index?: number,
    isSelected?: boolean,
    onSelect?: (participantId: number) => void,
    onUnSelect?: (participantId: number) => void,
    onClickAppoveButton: (id: number) => void,
    onClickDesignate: () => void,
    onOpenDesignate: (coachID: number) => void,
    status:string;
}

export const ParticipantListTable: React.FunctionComponent<ParticipantListTableProps> = ({ rowWidth, participant, onSelect, onUnSelect, isSelected,onClickAppoveButton,status }) => {
    const { t } = useTranslation();

    const { state: {
        auth,
        courseId,
        coaches,
        reloadParticipants,
        reloadCoaches },
        actions } = useContext(ParticipantsMgmtContext)

    const [openInfo, toggleInfo] = useState(false);
    const [openLastActivities, toggleLastActivities] = useState(false);
    const [rowStyle, updateRowStyle] = useState({})
    const [isDesignatePopupVisible, toggleDesignatePopUp] = useState(false)
    const [isDesignateConfirmPopupVisible, toggleDesignateConfirmPopUp] = useState(false)
    const [isUnAssignPopupVisible, toggleUnAssignPopUp] = useState(false)
    const [selectedCoachID, updateSelectedCoach] = useState(0)
    const approveButtonStyle = participant.enrollmentStatus === 'Pending' ? {} : { display: 'none' }
    const designateUnsignButtonStyle = participant.enrollmentStatus !== 'Pending' ? {} : { display: 'none' }
    const [showhide, hoverShowhide] = useState({})
    const tableRowInfoRef = useRef(null);
    const [rowHeight, resizeRowHeight] = useState(0 as number);

    useEffect(() => {       
        resizeRowHeight(tableRowInfoRef.current ? (tableRowInfoRef.current! as HTMLDivElement).offsetHeight - 2 : 0);

        const resizeHandler = () => {
            if (tableRowInfoRef && tableRowInfoRef.current) {
                resizeRowHeight((tableRowInfoRef.current! as HTMLDivElement).offsetHeight - 2);
            }
        }
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [tableRowInfoRef.current]);

    useEffect(() => {
        if(openInfo) resizeRowHeight(tableRowInfoRef.current ? (tableRowInfoRef.current! as HTMLDivElement).offsetHeight - 2 : 0);
        else resizeRowHeight(0);
    }, [openInfo, openLastActivities]);

    const handleCheckBox = (value: any) => {
        if (value.target.checked) {
            updateRowStyle({ backgroundColor: '#f5fdff' })
            hoverShowhide({ display: 'none' })
            if (onSelect) onSelect(participant.id)
        } else {
            updateRowStyle({})
            hoverShowhide({})
            if (onUnSelect) onUnSelect(participant.id)
        }
    }

    const handleApprove = (id: number) => () => {
        onClickAppoveButton(id)
    }

    const handleRequests = (failedRequests: number[]) => {
        if (failedRequests.length > 0) {
            actions.send(a => a.Set50xError('error'))
        } else {
            actions.send(a => a.reloadParticipantList(!reloadParticipants))
            actions.send(a => a.reloadCoachList(!reloadCoaches))
            toggleDesignatePopUp(false)
            toggleDesignateConfirmPopUp(false)
            toggleUnAssignPopUp(false)
        }
    }

    const onclickDesignateHandler = (coachID: number) => {
        toggleDesignatePopUp(false)
        toggleDesignateConfirmPopUp(true)
        updateSelectedCoach(coachID)
    }

    const onclickConfirmPopup = () => {
        AdminActions.designateCoachHandler(auth, courseId, coaches!, [participant.id], selectedCoachID, handleRequests)

    }

    const coachUnAssignHandler = () => {
        AdminActions.coachUnAssignHandler(auth, courseId, [participant.id], handleRequests)
    }

    useEffect(() => {
        isSelected ? updateRowStyle({ backgroundColor: '#f5fdff' }) : updateRowStyle({})
        isSelected ? hoverShowhide({ display: 'none' }) : hoverShowhide({})
    }, [isSelected])

    return (
      <>
        <div className='list-table-row'>

          <div className="list-table-col" style={rowStyle}>
          {status!=='Draft' &&
            <div className="form-check checkbox">
              <input className="form-check-input" type="checkbox" id={participant.name} value="1" checked={isSelected} onClick={handleCheckBox} onChange={() => { }} />
              <label className="form-check-label" htmlFor={participant.name}></label>
            </div>
          }
          </div>

          <div className="list-table-col" style={rowStyle}>
            <div className="">
              <strong>{participant.name}</strong>
            </div>
          </div>

          <div className="list-table-col" style={rowStyle}>
            <strong className="small text-muted label-hidden"> {t('CourseManagementParticipantsPage.Coach')}</strong>
            <span className="small">
                {participant.coachName &&
                    participant.coachName
                }
                {!participant.coachName && participant.progress >= 1 &&
                    t('miscelaneous.N/A')
                }
                {!participant.coachName && participant.progress < 1 &&
                    t('CourseManagementParticipantsPage.PendingLbl')
                }
            </span>
          </div>

          <div className="list-table-col" style={rowStyle}>
            <div className="small list-table-status">
                {(participant.enrollmentStatus !== 'Pending') &&
                    <div>
                        {((participant.progress < 1) && (participant.progress > 0)) &&
                            <div className="text-info mb-2">{t('CourseManagementParticipantsPage.InProgressLbl')}</div>
                        }
                        {(participant.progress === 1) &&
                            <div className="text-success mb-2">{t('CourseManagementParticipantsPage.CompletedLbl')}</div>
                        }
                        {(participant.progress === 0) &&
                            <div className="font-italic mb-2">{t('CourseManagementParticipantsPage.NotStartedLbl')}</div>
                        }
                    </div>
                }
                {(participant.enrollmentStatus === 'Pending') &&
                    <div className="text-success mb-2">{t('CourseManagementParticipantsPage.PendingLbl')}</div>
                }
                <PercentageBar height={5} width={50} percentage={participant.progress * 100} />
            </div>
          </div>

          <div className="list-table-col" style={rowStyle}>
            <strong className="small text-muted label-hidden">{t('CourseManagementParticipantsPage.EnrollDate')}</strong>
            <span className="small">
                {
                    (_.isUndefined(participant.enrollmentDate) || (_.isNull(participant.enrollmentDate))) ? t('miscelaneous.N/A') : t('dates.tiny', { valueObj: { type: 'withTZdatetime', data: participant.enrollmentDate } })
                }
            </span>
          </div>
          
          <div className="list-table-col list-table-col-btns" style={rowStyle}>
            <button className="btn btn-light btn-sm m-1" onClick={handleApprove(participant.id)} style={approveButtonStyle}>{t('ListTablePage.Approve')}</button>
            {status!=='Draft' &&
            <div style={designateUnsignButtonStyle}>
              <div style={showhide}>
                <button onClick={() => toggleDesignatePopUp(true)} className="btn btn-light btn-sm m-1">{t('ListTablePage.DesignateCoachBtn')}</button>
                <DesignateCoachPopup onClose={() => { toggleDesignatePopUp(false) }} onClickDesignate={(coachID) => { onclickDesignateHandler(coachID) }} isVisible={isDesignatePopupVisible} coachList={coaches} coachName={participant.coachName} />
              </div>
              <div style={showhide}>
                <button onClick={() => { toggleUnAssignPopUp(true) }} className="btn btn-light btn-sm m-1">{t('ListTablePage.UnsignCoachBtn')}</button>
                <UnassignCoachPopup isVisible={isUnAssignPopupVisible} onClose={() => { toggleUnAssignPopUp(false) }} onClickUnAssign={coachUnAssignHandler} />
                <DesignateConfirmPopup onClose={() => { toggleDesignateConfirmPopUp(false) }} isVisible={isDesignateConfirmPopupVisible} onClickDesignate={onclickConfirmPopup} coachList={coaches} />
              </div>
            </div>
            }
          </div>

          <div className="list-table-col" style={rowStyle}>
            <div className="list-table-action">
              <button className="btn" style={{ fontSize: '25px' }} onClick={() => toggleInfo(!openInfo)}>
                <i className={classnames('fa', { 'fa-angle-up': openInfo, 'fa-angle-down': !openInfo })}></i>
              </button>
            </div>
          </div> 

      </div>

      <div className="list-table-row" style={{ height: rowHeight+'px'}} >
        <div className="list-table-col" style={{ position: 'relative', overflow: 'visible' }}>
          
          <div ref={tableRowInfoRef} className={classnames('list-table-info', { 'show': openInfo })} style={{ width: (rowWidth+1)+'px'}}>
            <div className="list-table-info-content" style={rowStyle}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.TraineeEmail')}</strong> <a href={`mailto:${participant.email}`}><i className="fa fa-envelope"></i></a> {participant.email}</small> </div>
                  <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.CoachEmail')}</strong> <a href={`mailto:${participant.coachEmail}`}><i className="fa fa-envelope"></i></a> {participant.coachEmail} </small> </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.TraineeLastEntry')}</strong> {(_.isUndefined(participant.traineeLastAccessDate) || (_.isNull(participant.traineeLastAccessDate))) ? t('miscelaneous.N/A') : t('dates.long', { valueObj: { type: 'withTZdatetime', data: participant.traineeLastAccessDate } })} </small> </div>
                  <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.CoachLastEntry')}</strong> {(_.isUndefined(participant.coachLastAccessDate) || (_.isNull(participant.coachLastAccessDate))) ? t('miscelaneous.N/A') : t('dates.long', { valueObj: { type: 'withTZdatetime', data: participant.coachLastAccessDate } })} </small> </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2"> <small> <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => toggleLastActivities(!openLastActivities)}>{t('CourseManagementParticipantsPage.LastActivity')}<span className={classnames('fa', { 'fa-angle-up': openLastActivities, 'fa-angle-down': !openLastActivities })}></span></span></small> </div>
                  <div className={classnames('lastActivities', { 'show': openLastActivities })}>
                      <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.Lesson')}</strong> {participant.lastLessonName}</small> </div>
                      <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.Task')}</strong> {participant.lastTaskName}</small> </div>
                      <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.Status')}</strong> <FormatTaskStatus status={participant.lastTaskStatus} /></small> </div>
                      <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.By')}</strong> <FormatAssignedTo role={participant.lastTaskAssignedTo} /></small> </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>

        </div>
      </div>
      </>
    )
}

const FormatTaskStatus: React.FunctionComponent<{
    status?: string;
}> = ({ status }) => {
    const { t, i18n } = useTranslation();

    if (status && i18n.exists && i18n.exists(`fromAPI.taskStatuses.${status}`)) {
        return <>{t(`fromAPI.taskStatuses.${status}`)}</>
    }

    return <></>
}

const FormatAssignedTo: React.FunctionComponent<{
    role?: string;
}> = ({ role }) => {
    const { t } = useTranslation();

    if (role) {
        switch (role.toLowerCase()) {
            case 'trainee':
                return (<span className={'mb-0'}>{' '}<strong>{t(`fromAPI.userRoles.Trainee`)}</strong></span>);
            case 'coach':
                return (<span className={'mb-0 text-info'}>{' '}<strong>{t(`fromAPI.userRoles.Coach`)}</strong></span>);
            case 'both':
                return (<span className={'mb-0'}>{' '}<strong>{t(`fromAPI.userRoles.Both`)}</strong></span>);
            default:
                return (<>(INVALID)</>);
        }
    }

    return <></>
}