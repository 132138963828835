import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../../../components/Cards/Card';
import { CardViewType } from '../../../../../components/Cards/CardSection';

export interface MemberCourseStatusCardProps {
    cardViewType?: CardViewType;
    name: string;
    totalTrainees?: number;
    totalCoaches?: number;
    onClick: () => void
}

export const MemberCourseStatusCard: React.FunctionComponent<MemberCourseStatusCardProps> = ({
    cardViewType,
    name,
    totalTrainees,
    totalCoaches,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <Card
            cardViewType={cardViewType}
            header={() => (
                <h2 className="card-title h6">
                    <a href="javascript:void(0)" onClick={() => onClick()} style={{ cursor: 'hand' }}>{name}</a>
                </h2>
            )}
            body={() => (
                <>
                    {(totalTrainees !== undefined) && (<div>
                        <small><strong className="text-muted">{t('MemberCourseStatusCard.totalTrainees')}:</strong> {totalTrainees}</small>
                    </div>)}
                    {(totalCoaches !== undefined) && (<div className="mt-1 mt-md-2">
                        <small><strong className="text-muted">{t('MemberCourseStatusCard.totalCoaches')}:</strong> {totalCoaches}</small>
                    </div>)}
                </>
            )}
            footer={() => (
                <button className="btn btn-primary" type="button" onClick={() => onClick()}>{t('MemberCourseStatusCard.viewCourseBtn')}</button>
            )}
        />
    );
}
