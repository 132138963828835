import * as React from 'react';
import { CheckboxI } from '../Forms/CheckboxI';
import { QuestionOption, Question } from '../../domain/models';
import { EditQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditQuestion';
import { EditOption } from '../../pages/Admin/CourseManagementPage/components/tab1/EditOption';
import { EditSingleLineTextOption } from './EditSingleLineTextOption';

export interface QuizSingleLineFieldProps {
    updateQuestion?: (question: Question) => void;
    question?: Question;
    currentOptions?: string;
    onCompleteEdit?: (item: Question) => void
    order?: number;
}

export const QuizSingleLineField: React.FunctionComponent<QuizSingleLineFieldProps> = ({ 
    question,
    updateQuestion,
    currentOptions,
    onCompleteEdit,
    order }) => {
      
    const initialOptionsValue: string[] = ['', '', '']
    const [options, setOptions] = React.useState(initialOptionsValue)
    const [isEditMode, setEditMode] = React.useState(false)
    React.useEffect(() => {
        if (question) setEditMode(true)
    }, []) 

  
    return (
        <div>
            <EditQuestion question={question} updateQuestion={updateQuestion} order={order} />
            <>
               
                {isEditMode &&
                    <EditSingleLineTextOption question={question} currentOptions={currentOptions!} onCompleteEdit={onCompleteEdit!} />
                }
            </>

        </div>
    );
}