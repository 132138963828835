import * as React from 'react';
import { useState } from 'react';
import { QuestionOption } from '../../domain/models';
import { SurveyOptionPreview } from './SurveyOptionPreview';

export interface SurveyDropdownIProps {
    options: QuestionOption[];
    
}
export const SurveyDropdownI: React.FunctionComponent<SurveyDropdownIProps> = ({ options }) => {
    
    interface SurveyDropDownOption  {
         label: string;
         value:string;
     }
    const [dropDownOptions, setDropDownOptions] = React.useState()
   

    React.useEffect(() => {
        let previewOptions = options.map((option, index) => {
            return {
                label: option.body,
                value: '',
            }
          })
        setDropDownOptions(previewOptions)
    }, [options])

    return (
        <>
          
            {options.map((item, index) => (
              <SurveyOptionPreview
                index={index}
                active={true}
                placeholder={''}
                value={item.body}
                isValid={false}
                isInvalid={false}
                onChange={() => { }}
                onAddOption={() => { }}
                onRemoveOption={() => { }}
              />
            ))}
        </>
    );
}

