import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { __SERVER_REPO__ } from '../../../domain/_defaults';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { UserGroup } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { CardSection, CardViewType } from '../../../components/Cards/CardSection';
import { AdminGroupCard } from './components/main/AdminGroupCard';
import { NonAdminGroupCard } from './components/main/NonAdminGroupCard';

export interface MyGroupsPageRouteParameters {
}

export interface MyGroupsPageProps {
    auth: Auth;
    onLogout: () => void;
}

type State = {
    apiError?: any | undefined;
    groups?: UserGroup[];
}

type Action = {
    Set50xError(apiError: any): State;
    LoadGroupsList(groups: UserGroup[]): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadGroupsList: groups => ({ ...state, groups }),
})

export const MyGroupsPage: React.FunctionComponent<MyGroupsPageProps> = ({ auth, onLogout }) => {
    const { t } = useTranslation();
    const [{ apiError, groups }, actions] = wrapperReducer(useReducer(reducer, {}));

    const goToRoot = () => Router.push('/');
    
    useLoadUserGroups(auth, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!groups) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myGroups'), active: true }
            ]}
            onLogout={onLogout}
        >
            <CardSection
                title={t('MyGroupsPage.title')}
                showGridListToggle={(groups.length > 0) ? true : undefined}
                initialCardViewType={CardViewType.Grid}
            >
                {(groups.length > 0)
                    ? groups.map((group, i) => (
                        group.isAdmin
                            ? <AdminGroupCard
                                key={i}
                                name={group.name}
                                description={group.description}
                                userCount={group.userCount}
                                creationDate={group.creationDate}
                                onClick={() => Router.push(`/groups/${group.id}/admin/0`)}
                            />
                            : <NonAdminGroupCard
                                key={i}
                                name={group.name}
                                description={group.description}
                                userCount={group.userCount}
                                creationDate={group.creationDate}
                                onClick={() => Router.push(`/groups/${group.id}`)}
                            />)
                    )
                    : (
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-md-7">
                                    <div className="clearfix">
                                        <div className="text-center mb-2"><img src={`${__SERVER_REPO__}/system/icon-groups.svg`} alt="Error Image" style={{ width: '100%', maxWidth: '230px' }} /></div>
                                        <p className="h4 mt-0 text-center">{t('MyGroupsPage.noGroups')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </CardSection>
        </BaseAppLayout>
    );
}

function useLoadUserGroups(auth: Auth, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const userGroups = await services.getUserGroups(auth);
            if (isRequestError(userGroups)) {
                console.log(userGroups);
            } else {
                actions.send(a => a.LoadGroupsList(userGroups));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
