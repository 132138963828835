import * as React from 'react';
import { Lesson, Task } from '../../../domain/models';
import { LessonCollapsePanel } from './LessonCollapsePanel';
export interface TraineeLessonPanelProps {
    lessons: Lesson[];
    courseName: string;
    courseIcon: string | undefined;
    selectedTaskId: number;
    onTaskClick: (task: Task, lessonId: number) => void;
}
export const TaskRightSidePanel: React.FunctionComponent<TraineeLessonPanelProps> = ({ lessons, courseName, courseIcon, onTaskClick, selectedTaskId }) => {

    return (
        <div className="col-md-4 col-lg-3 task-execution-sidebar">
            <div className="lessons-sidebar">
                <div className="lessons-sidebar-header">
                    <div className="lessons-sidebar-header-img">
                        <img
                            src={courseIcon}
                            alt="preview"
                        />
                    </div>
                    <h2 className="lessons-sidebar-header-title">
                        {courseName}
                    </h2>
                </div>

                <div className="lessons-sidebar-content">
                    {lessons && lessons.map(function (item, index: number) {
                        return <LessonCollapsePanel 
                            key={index} 
                            lesson={item} 
                            index={index} 
                            selectedTaskId={selectedTaskId}
                            onTaskClick={onTaskClick}
                          />
                    })}
                </div>
            </div>
        </div>
    );
}

