import * as React from 'react';
import * as services from '../../../../../domain/services';
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { Question } from '../../../../../domain/models';
import { RichTextEditor } from '../../../../../components/RichTextEditor/RichTextEditor';
import { richEditorModuleOptionsBasic } from '../../../../../api/utils';

export interface EditQuestionProps {   
    question?: Question;
    updateQuestion?: (question: Question) => void
    order?:number;
}

export const EditQuestion: React.FunctionComponent<EditQuestionProps> = ({ 
 
  question,
  updateQuestion,
  order
   }) => {
   const { state: { auth } } = React.useContext(AuthContext);
  const [editorValue, setEditorValue] = React.useState(question && question.body)   

  const updateQuestionHandler = () => {
    let updatedQuestion: Question = {
      id: question!.id,
      name: question!.name,
      type: question!.type,
      isRequired: question!.isRequired,
      weight: question!.weight,
      order: order!+1,
      body: editorValue!,
      minValue:question!.type.toLowerCase()==="linearscale"?question!.minValue:undefined,
      maxValue:question!.type.toLowerCase()==="linearscale"?question!.maxValue:undefined,
      minValueLabel:question!.type.toLowerCase()==="linearscale"?question!.minValueLabel:'',
      maxValueLabel:question!.type.toLowerCase()==="linearscale"?question!.maxValueLabel:'',
    }
    updateQuestion!(updatedQuestion)
  }

  const imageUpload = (image: File, completion: (imgURL: string) => void) => {
    services.saveImage(auth!, image)
        .then(response => {
            completion(response)
        })
        .catch((error) => {
           // actions.send(a => a.Set50xError(error))
        })
}

    return (
      <div className="edit-question-text">
        {question&&
        <div className="edit-question-container" onBlur={updateQuestionHandler} onFocus={() => {}}>
          <RichTextEditor
            theme={'snow'}
            placeholder={'Enter your question'}
            value={editorValue || ''}
            options={richEditorModuleOptionsBasic}
            onChange={(val) => { setEditorValue(val) }}
            onUploadImage={imageUpload}
          />
          </div>
        }     
        
      </div>
    );
}