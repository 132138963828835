import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { AdminCourse } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { CourseEditHeader } from './components/main/CourseEditHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { DraftLessonsList } from './components/tab1/DraftLessonsList';
import { DraftCourseInfo } from './components/tab0/DraftCourseInfo';

export interface CourseEditionPageRouteParameters {
    groupId: number;
    courseId: number;
}

export interface CourseEditionPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
}

type State = {
    apiError?: any | undefined;
    course?: AdminCourse;
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: AdminCourse): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const CourseEditionPage: React.FunctionComponent<CourseEditionPageProps> = ({ auth, onLogout, groupId, courseId }) => {
    const { t } = useTranslation();
    const [{
        apiError,
        course,
        tabIndex
    }, actions] = wrapperReducer(useReducer(reducer, {
        tabIndex: 2
    }));

    useLoadCourse(auth, courseId, actions);

    if (apiError) return <P50x onGoBack={() => Router.push('/')} />
    if (!course) return <LoadingPage />

    return (
        <BaseAppLayout
           
            onLogout={onLogout}
        >
                <DraftCourseInfo groupId={groupId} courseId={courseId} auth={auth}/>
        </BaseAppLayout>
    );
}

const useLoadCourse = function (auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getAdminCourse(auth, courseId);
            if (isRequestError(course)) {
            }
            else {
                actions.send(a => a.LoadCourse(course));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
