import * as React from 'react';

export interface RadioboxIProps {
    active: boolean;
    id: string;
    value: string;
    label: string;
    isSelected: boolean;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (selectedValue: string) => void;
}

export const RadioboxI: React.FunctionComponent<RadioboxIProps> = ({ active, id, value, label, isSelected, isValid, isInvalid, onChange }) => {
    return (
        <div className={"form-radio survey-form-t-margin" + (isValid ? ' is-valid' : '') + (isInvalid && !isSelected ? ' is-invalid' : '')}>
            <input
                className="form-radio-input"
                type="radio"
                disabled={!active}
                id={id}
                value={value}
                checked={isSelected}
                onChange={e => { if (!isSelected) onChange(e.target.value) }}
            />
            <label className="form-radio-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
}