
import * as React from 'react';
import { Lesson } from '../../../../domain/models';

export interface PaginationProps {
    lessons: Lesson[];
    lesson: Lesson;
    onNavigateToNextLessonsClick: (nextlessonId: number, nexttaskId: number, nexttaskType: string) => void
    onNavigateToPrevLessonsClick: (prevlessonId: number, prevtaskId: number, prevtaskType: string) => void
}
export const Pagination: React.FunctionComponent<PaginationProps> = ({
    lessons,
    lesson,
    onNavigateToNextLessonsClick,
    onNavigateToPrevLessonsClick
}) => {

    let currentIndex = lessons.findIndex(lessons => lessons.name === lesson.name)
    let index = currentIndex + 1
    let previousLesson = lessons[currentIndex - 1];
    let nextLesson = lessons[currentIndex + 1];

    return (
        <div className="task-execution-pagination mt-5 p-3">
            <ul className="task-pagination">
                <li className="task-pagination-item">
                    {previousLesson &&
                        <button
                            className="btn btn-light task-pagination-prev"
                           onClick={() => onNavigateToPrevLessonsClick(previousLesson.id, previousLesson.tasks[0].id, previousLesson.tasks[0].type)}
                        >
                            <i className="task-pagination-icon fa fa-angle-left"></i>
                            <span className="task-pagination-title">

                                <span> Lesson {index - 1}- {previousLesson.name}</span>
                            </span>
                            <span className="task-pagination-label"> Prev</span>
                        </button>
                    }
                </li>
                <li className="task-pagination-item">
                    {nextLesson && 
                        <button
                            className="btn btn-light task-pagination-next"
                            onClick={() => onNavigateToNextLessonsClick(nextLesson.id, nextLesson.tasks[0].id, nextLesson.tasks[0].type)}
                        >
                            <span className="task-pagination-title">
                                Lesson {index + 1} - {nextLesson.name}
                            </span>
                            <span className="task-pagination-label">Next </span>
                            <i className="task-pagination-icon fa fa-angle-right"></i>
                        </button>
                    }
                </li>
            </ul>
        </div>
    );
}