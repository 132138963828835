import * as React from 'react';
import { useContext, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { UserProfile } from '../../../domain/models';

import { GlobalContext } from '../../../domain/contexts/GlobalContext';
import { UserContext } from '../../../domain/contexts/UserContext';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { EditProfileCard } from './EditProfileCard';

export interface EditProfilePageRouteParameters {
}

export interface EditProfilePageProps {
    auth: Auth;
    onLogout: () => void;
}

type State = {
    apiError?: any | undefined;
    profile?: UserProfile;
    newProfile?: UserProfile;
    lock?: boolean;
    errorMessage?: string;
    submitted?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCurrentProfile(profile: UserProfile): State;
    SendProfileUpdate(newProfile: UserProfile): State;
    UnlockSend(): State;
    SetErrorMessage(errorMessage: string): State;
    ClearErrorMessage(): State;
    PostStatus204(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCurrentProfile: profile => ({ ...state, profile }),
    SendProfileUpdate: newProfile => ({ ...state, newProfile, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    SetErrorMessage: errorMessage => ({ ...state, errorMessage }),
    ClearErrorMessage: () => ({ ...state, errorMessage: undefined }),
    PostStatus204: () => ({ ...state, lock: false, submitted: true }),
})

export const EditProfilePage: React.FunctionComponent<EditProfilePageProps> = ({ auth, onLogout }) => {
    const { actions: globalActions } = useContext(GlobalContext);
    const { actions: userActions } = useContext(UserContext);
    const { t } = useTranslation();
    const [{
        apiError,
        profile,
        newProfile,
        lock,
        errorMessage,
        submitted
    }, actions] = wrapperReducer(useReducer(reducer, {
        newProfile: {},
        lock: false,
        errorMessage: undefined,
        submitted: false
    }));

    const goToRoot = () => Router.push('/');
    const goToProfile = () => Router.push('/profile');

    useLoadProfile(auth, actions);
    useSendProfileUpdate(lock || false, submitted || false, auth, newProfile || {}, actions, t);

    useEffect(() => {
        if (submitted) {
            globalActions.send(a => a.SetLanguageAndTimezone(newProfile!.locale!, newProfile!.zoneinfo!));
            userActions.send(a => a.OverwriteNickname(newProfile!.preferred_username!));
            // (TODO) update presets aside from language and timezone
            goToProfile();
        }
    }, [submitted]);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!profile) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myProfile'), active: true }
            ]}
            onLogout={onLogout}
        >

            <EditProfileCard
                feedbackError={errorMessage}
                disabled={lock}
                isSending={lock}
                currentFullName={profile.name}
                currentNickName={profile.preferred_username}
                currentPhone={profile.phone_number}
                currentEmail={profile.email}
                currentLanguage={profile.locale}
                currentTimezone={profile.zoneinfo}
                onCancel={goToProfile}
                onSave={(nP) => actions.send(a => a.SendProfileUpdate(nP))}
            />

        </BaseAppLayout>
    );
}

const useLoadProfile = function (auth: Auth, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const profile = await services.getUserProfile(auth);
            if (isRequestError(profile)) {
                console.log(profile);
            } else {
                actions.send(a => a.LoadCurrentProfile(profile));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

const useSendProfileUpdate = function (
    lock: boolean,
    submitted: boolean,
    auth: Auth,
    newProfile: UserProfile,
    actions: ActionDispatcher<Action, State>,
    t: i18next.TFunction
) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                actions.send(a => a.ClearErrorMessage());

                const status = await services.updateUserProfile(auth, newProfile);
                if (isRequestError(status)) {
                    const err: string | undefined = status.message || status.error_description;
                    const end = err ? err.indexOf('.') : 0;
                    actions.send(a => a.SetErrorMessage(err ? t(`EditProfilePage.${err.substring(0, end === -1 ? undefined : end)}`) : t('EditProfilePage.errorGeneric')));
                    actions.send(a => a.UnlockSend());
                }
                else {
                    actions.send(a => a.PostStatus204());
                }
            }
        }
        catch (e) {
            console.error(e);
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}
