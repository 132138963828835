import * as React from "react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";

import * as Router from "../../../api/Router";

import {
  ActionMatcher,
  ActionDispatcher,
  wrapperReducer,
  useAsyncEffect
} from "../../../api/react-helper";
import { Auth, isRequestError } from "../../../domain/_shared";
import * as services from "../../../domain/services";
import {
  CoachCourse,
  Trainee,
  Lesson,
  TraineeResult
} from "../../../domain/models";

import { P50x } from "../../../components/P50x";
import { LoadingPage } from "../../../components/LoadingPage";
import { BaseAppLayout } from "../../../layouts/shared/BaseAppLayout";
import { ButtonBack } from "../../../components/Buttons/ButtonBack";
import { ScrollDownList } from "../../../components/Lists/ScrollDownList";
import { CoachLessonPanel } from "./components/main/CoachLessonPanel";

export interface CourseMentoringPageRouteParameters {
  groupId: number;
  courseId: number;
  traineeId: number;
}

export interface CourseMentoringPageProps {
  auth: Auth;
  onLogout: () => void;
  groupId: number;
  courseId: number;
  traineeId: number;
}

type State = {
  apiError?: any | undefined;
  course?: CoachCourse;
  trainees?: TraineeResult;
  lessons?: Lesson[];
};

type Action = {
  Set50xError(apiError: any): State;
  LoadCourse(course: CoachCourse): State;
  LoadTrainees(trainees: TraineeResult): State;
  LoadLessonsList(lessons: Lesson[]): State;
};

const reducer = (state: State, action: ActionMatcher<Action, State>) =>
  action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadTrainees: trainees => ({ ...state, trainees }),
    LoadLessonsList: lessons => ({ ...state, lessons })
  });

let viewCounter: number = 0;
export const CourseMentoringPage: React.FunctionComponent<CourseMentoringPageProps> = ({
  auth,
  onLogout,
  groupId,
  courseId,
  traineeId
}) => {
  viewCounter++;
  const { t } = useTranslation();
  const [{ apiError, course, trainees, lessons }, actions] = wrapperReducer(
    useReducer(reducer, {})
  );

  const goToRoot = () => Router.push("/");

  useLoadCourse(auth, courseId, actions);
  useLoadTrainees(auth, courseId, actions);
  useLoadLessons(auth, courseId, traineeId, actions);

  function reloadLessons(
    lessons: Lesson[],
    reloadIndexes: number
  ): JSX.Element {
    return (
      <>
        {lessons.map((lesson, i) => (
          <CoachLessonPanel
            key={`${reloadIndexes}${i}`} // OBS.: --force component reload (because of statuses)
            index={i + 1}
            lesson={lesson}
            onTaskClick={task =>
              Router.push(
                `/groups/${groupId}/coach/courses/${courseId}/lessons/${
                  lesson.id
                }/tasks/${task.id}/${task.type.toLowerCase()}/${trainee &&
                  trainee.id}`
              )
            }
          />
        ))}
      </>
    );
  }

  if (apiError) return <P50x onGoBack={goToRoot} />;
  if (!course || !trainees || !lessons) return <LoadingPage />;

  let trainee: Trainee | undefined | null = null;

  if (trainees) {
    trainee = trainees.results.find(t => t.id == traineeId);
  }

  const goToCourse = () =>
    Router.push(`/groups/${groupId}/coach/courses/${course ? course.id : 0}`);

  return (
    <BaseAppLayout
      breadcrumbs={[
        { text: t("Breadcrumbs.home"), onClick: goToRoot },
        { text: t("Breadcrumbs.course"), onClick: goToCourse },
        { text: t("Breadcrumbs.lessons"), active: true }
      ]}
      onLogout={onLogout}
    >
      <div>
        <div className="main">
          <div className="container-fluid">
            <div className="header-page hasBtnBack hasInfo hasLessons">
              <ButtonBack
                text={t("CourseMentoringPage.goBack")}
                onClick={goToCourse}
              />
              <h1 className="h4 header-page-title title-color">
                {course && course.name}
              </h1>
              {trainee && (
                <span className="header-page-info d-none d-md-block">
                  <small>
                    <i className="icon icon-user text-muted"></i>
                  </small>{" "}
                  {trainee.name}
                </span>
              )}
            </div>

            <div className="row">
              <div className="col-md-4 mb-4 mb-md-0 list-users">
                <ScrollDownList
                  title={t("CourseMentoringPage.listTitle")}
                  linkItems={(trainees.results || []).map(trainee => ({
                    active: true,
                    id: trainee.id,
                    label: trainee.name,
                    highlighted: false
                  }))}
                  activeLink={trainee ? trainee.id : 0}
                  onClick={id =>
                    Router.push(
                      `/groups/${groupId}/coach/courses/${courseId}/trainee/${id}`
                    )
                  }
                  info={trainee ? trainee.name : ""}
                />
              </div>

              <div className="col-md-8">
                {course && lessons && trainee && (
                  <div className="main">
                    <div className="container-fluid">
                      {reloadLessons(lessons, viewCounter)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAppLayout>
  );
};

function useLoadCourse(
  auth: Auth,
  courseId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const course = await services.getCoachCourse(auth, courseId);
      if (isRequestError(course)) {
      } else {
        actions.send(a => a.LoadCourse(course));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, []);
}

function useLoadTrainees(
  auth: Auth,
  courseId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const trainees = await services.getCoachTrainees(auth, courseId);
      if (isRequestError(trainees)) {
      } else {
        actions.send(a => a.LoadTrainees(trainees));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, []);
}

function useLoadLessons(
  auth: Auth,
  courseId: number,
  traineeId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const lessons = await services.getCoachLessons(auth, courseId, traineeId);
      if (isRequestError(lessons)) {
      } else {
        actions.send(a => a.LoadLessonsList(lessons));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, [traineeId]);
}
