import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CardLoading } from './Card';
import { LoadingPlaceHolder } from '../LoadingPlaceHolder';

export enum CardViewType {
    Grid,
    List,
}

export const CardViewTypeContext = React.createContext(CardViewType.Grid);

export interface CardSectionProps {
    title: string;
    description?: string;
    showGridListToggle?: boolean;
    topContent?: () => JSX.Element | null;
    initialCardViewType?: CardViewType;
}

export const CardSection: React.FunctionComponent<CardSectionProps> = ({
    title,
    description,
    showGridListToggle,
    children,
    initialCardViewType,
    topContent,
}) => {
    const { t } = useTranslation();
    const [{ showDescription, cardViewType }, toggleDescription] = useState({
        showDescription: false,
        cardViewType: initialCardViewType || CardViewType.Grid
    });

    return (
        <div className="card card-section mb-1">
            <div className="card-header pt-0">
                <h1 className="h5 m-0 float-left title-color">{title}</h1>
                {
                    description
                    && (
                        <button
                            className="btn btn-link float-right"
                            type="button"
                            onClick={() => toggleDescription({ showDescription: !showDescription, cardViewType })}
                        >
                            {!showDescription ? t('CardSection.showDescription') : t('CardSection.hideDescription')}
                        </button>
                    )
                }
            </div>
            {
                showDescription
                && <div
                    className="p-4 bg-light collapse in show"
                    aria-expanded="true"
                    aria-hidden="false"
                    style={{ display: 'block', overflow: 'visible', height: 'auto' }}
                >
                    {description}
                </div>
            }
            <div className="card-body">
                {topContent && topContent()}
                {
                    showGridListToggle
                    && (
                        <button
                            className={classnames('btn bg-transparent text-muted p-0 mb-2 d-md-block d-none')}
                            style={{ boxShadow: 'none' }}
                            type="button"
                            onClick={() => toggleDescription({
                                showDescription,
                                cardViewType: cardViewType === CardViewType.Grid ? CardViewType.List : CardViewType.Grid
                            })}
                        >
                            <span className={
                                classnames({
                                    'fa fa-th': cardViewType === CardViewType.Grid,
                                    'fa fa-list-ul': cardViewType === CardViewType.List,
                                })
                            }></span>
                            <small className="text-muted">
                                {' '}<span>{cardViewType !== CardViewType.Grid ? t('CardSection.showGrid') : t('CardSection.showList')}</span>
                            </small>
                        </button>
                    )
                }
                <div className="row">
                    <CardViewTypeContext.Provider value={cardViewType}>
                        {children}
                    </CardViewTypeContext.Provider>
                </div>
            </div>
        </div>
    );
}

export const CardSectionLoading: React.FunctionComponent<{
    description?: boolean,
    showGridListToggle?: boolean,
    initialCardViewType?: CardViewType
}> = ({ description, showGridListToggle, initialCardViewType }) => {
    const { t } = useTranslation();

    return (
        <div className="card">
            {/* <div className="card-header">
        <LoadingPlaceHolder className="h5 m-0 float-left" />
        {description && <LoadingPlaceHolder className="float-right" style={{ width: 100 }} />}
      </div> */}
            <div className="card-body">
                {/* {showGridListToggle && <LoadingPlaceHolder className="p-0 mb-2" style={{ width: 100 }} />} */}
                <div className="row">
                    <CardViewTypeContext.Provider value={initialCardViewType || CardViewType.Grid}>
                        {/* <CardLoading />
                        <CardLoading />
                        <CardLoading /> */}
                        <div className="app flex-row align-items-center content-loading">
                            <div className="container">
                                <div className="justify-content-center text-center">
                                    <div className="sk-rotating-plane"></div>
                                    <p>{t('CardSectionLoading.loading')}</p>
                                </div>
                            </div>
                        </div>
                    </CardViewTypeContext.Provider>
                </div>
            </div>
        </div>
    );
}