import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Buttons/Button';

export interface QuizFeedbackProps {
    isOwnTask: boolean;
    status?: string;
    activeResume: boolean;
    onResume: () => void;
    scoreCriteria: number;
    score: number;
    activeRetry: boolean;
    onRetry: () => void;
}

export const QuizFeedback: React.FunctionComponent<QuizFeedbackProps> = ({
    isOwnTask,
    status,
    activeResume,
    onResume,
    scoreCriteria,
    score,
    activeRetry,
    onRetry
}) => {
    const { t } = useTranslation();

    switch (status) {
        case 'NotStarted':
            return (
                <></>   // feedback hidden
            );
        case 'Saved':
            return (
                (isOwnTask)
                    ? (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-check text-success"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-success"><strong>{t('QuizFeedback.quizSaved')}</strong></p>
                                <div className="task-summary-btns">
                                    <Button
                                        active={activeResume}
                                        label={t('QuizFeedback.resumeBtn')}
                                        styleName={(activeResume) ? 'btn btn-primary' : 'btn btn-light'}
                                        href={''}
                                        onClick={() => onResume()}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-check text-success"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-success"><strong>{t('QuizFeedback.quizSaved')}</strong></p>
                            </div>
                        </div>
                    )
            );
        case 'WaitingForCorrection':
            return (
                <></>   // (TODO) need more information on this scenario
            );
        case 'Failed':
            return (
                (isOwnTask)
                    ? (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-close text-danger"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-danger"><strong>{t('QuizFeedback.failed')}</strong></p>
                                <p className="card-text text-danger">{t('QuizFeedback.criteria', { scoreCriteria: Math.round(scoreCriteria * 100) })}</p>
                                <p><strong>{t('QuizFeedback.score')}: {Math.round(score * 100)}%</strong></p>
                                <div className="task-summary-btns">
                                    <Button
                                        active={activeRetry}
                                        label={t('QuizFeedback.retryBtn')}
                                        styleName={(activeRetry) ? 'btn btn-primary' : 'btn btn-light'}
                                        href={''}
                                        onClick={() => onRetry()}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-close text-danger"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-danger"><strong>{t('QuizFeedback.failed')}</strong></p>
                                <p><strong>{t('QuizFeedback.score')}: {Math.round(score * 100)}%</strong></p>
                            </div>
                        </div>
                    )
            );
        case 'Passed':
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-check text-success"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-success"><strong>{t('QuizFeedback.passed')}</strong></p>
                        <p><strong>{t('QuizFeedback.score')}: {Math.round(score * 100)}%</strong></p>
                    </div>
                </div>
            );
        default:
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-close text-danger"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-danger"><strong>(unknown status)</strong></p>
                    </div>
                </div>
            );
    }
}
