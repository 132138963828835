export const __TOKEN_DOMAIN__: string = process.env.REACT_APP_TOKEN_DOMAIN || '';

export interface Auth {
    ".expires": string;
    ".issued": string;
    access_token: string;
    expires_in: number;
    userName: string;       // (TODO) consider removing from back-end
    token_type: string;
}

export interface RequestError {
    message?: string;
    error_description?: string;
}

export function isAuthError<T>(reqResult: T | RequestError): reqResult is RequestError {
    return (reqResult as RequestError).message !== undefined;
}

export const isRequestError = isAuthError;

export const processResponse = async function <R>(response: Response): Promise<R> {
    switch (response.status) {
        case 200:
            try {
                const result = await response.json();
                return result as R;                             // isAuthError/RequestError = false
            } catch (e) {
                return {
                    message: e.message,
                    error_description: 'Something went wrong when parsing Response object'
                } as unknown as R;
            }
        case 201:
            try {
                const result = await response.json();
                return result as R;                             // isAuthError/RequestError = false
            } catch (e) {
                return {
                    message: e.message,
                    error_description: 'Something went wrong when parsing Response object'
                } as unknown as R;
            }
        // return new Promise((resolve, reject) => {
        //     resolve({
        //         message: undefined,
        //         error_description: undefined
        //     } as unknown as R)                              // isAuthError/RequestError = false
        // });
        case 204:
            return new Promise((resolve, reject) => {
                resolve({
                    message: undefined,
                    error_description: undefined
                } as unknown as R)                              // isAuthError/RequestError = false
            });
        case 400:
            try {
                const result = await response.json();
                return {
                    message: !!response && response.statusText,
                    error_description: 'Error 400 from API server',
                    ...result   // OBS.: overwrite fields
                } as unknown as R;                              // isAuthError/RequestError = true
            } catch (e) {
                return {
                    message: e.message,
                    error_description: 'Error 400 from API server, without Response object'
                } as unknown as R;                              // isAuthError/RequestError = true
            }
        default:
            return new Promise((resolve, reject) => {
                reject({
                    message: 'Some error ocurred',
                    error_description: `Error ${response.status} from API server`
                } as unknown as R)                              // isAuthError/RequestError = true
            });
    }
}

export const processResponseImage = async function <R>(response: Response): Promise<R> {

    switch (response.status) {

        case 200:
            try {
                const result = await response.text();
                return result as unknown as R;                             // isAuthError/RequestError = false
            } catch (e) {
                return {
                    message: e.message,
                    error_description: 'Something went wrong when parsing Response object'
                } as unknown as R;
            }
        case 201:
            try {
                const result = await response.text();
                return result as unknown as R;
            } catch (e) {
                return {
                    message: undefined,
                    error_description: undefined
                } as unknown as R;                            // isAuthError/RequestError = false
            };
        case 204:
            return new Promise((resolve, reject) => {
                resolve({
                    message: undefined,
                    error_description: undefined
                } as unknown as R)                              // isAuthError/RequestError = false
            });
        case 400:
            try {
                const result = await response.json();
                return {
                    message: !!response && response.statusText,
                    error_description: 'Error 400 from API server',
                    ...result   // OBS.: overwrite fields
                } as unknown as R;                              // isAuthError/RequestError = true
            } catch (e) {
                return {
                    message: e.message,
                    error_description: 'Error 400 from API server, without Response object'
                } as unknown as R;                              // isAuthError/RequestError = true
            }
        case 409:
            try {
                const result = await response.json();
                return result.location as unknown as R;
            } catch (e) {
                return {
                    message: undefined,
                    error_description: undefined
                } as unknown as R;                            // isAuthError/RequestError = false
            };
        default:
            return new Promise((resolve, reject) => {
                reject({
                    message: 'Some error ocurred',
                    error_description: `Error ${response.status} from API server`
                } as unknown as R)                              // isAuthError/RequestError = true
            });
    }
}

export async function get<T>(url: string, auth: Auth): Promise<T> {
    const response = await fetch(url, {
        "credentials": "omit",
        "headers": {
            "authorization": `Bearer ${auth.access_token}`
        },
        "method": "GET"
    });
    return processResponse<T>(response);
}

export async function post<T, R>(url: string, auth: Auth, body: T): Promise<R> {
    const response = await fetch(url, {
        "credentials": "omit",
        "headers": {
            "authorization": `Bearer ${auth.access_token}`,
            "content-type": "application/json"
        },
        "method": "POST",
        "body": JSON.stringify(body as T)
    });

    return processResponse<R>(response);
}

export async function postImage<T, R>(url: string, auth: Auth, body: File): Promise<R> {
    
    let headerData = new Headers()
    headerData.append("Authorization", `Bearer ${auth.access_token}`)

    let options = {
        "headers": headerData,
        "method": "POST",
        "body": body
    }
    const response = await fetch(url, options);
    return processResponseImage<R>(response);
}

export async function update<T, R>(url: string, auth: Auth, body: T): Promise<R> {
    const response = await fetch(url, {
        "credentials": "omit",
        "headers": {
            "authorization": `Bearer ${auth.access_token}`,
            "content-type": "application/json"
        },
        "method": "PUT",
        "body": JSON.stringify(body as T)
    });

    return processResponse<R>(response);
}

export async function updateImage<T, R>(url: string, auth: Auth, body: any): Promise<R> {
    let headerData = new Headers()
    headerData.append("Authorization", `Bearer ${auth.access_token}`)

    let options = {
        "headers": headerData,
        "method": "PUT",
        "body": body
    }
    const response = await fetch(url, options);
    return processResponseImage<R>(response);
}

export async function remove<R>(url: string, auth: Auth): Promise<R> {
    const response = await fetch(url, {
        "credentials": "omit",
        "headers": {
            "authorization": `Bearer ${auth.access_token}`
        },
        "method": "DELETE"
    });

    return processResponse<R>(response);
}

export interface GlobalConfig {
    // (TODO) global configurations except for language and timezone
}

export interface UserPresets {
    uuid: number;               // unique identifier of the user
    nickName: string;
    language: string;           // i18n codes e.g. 'en-US'
    timezone: string;           // e.g. 'America/Sao_Paulo'
    // (TODO) future functionality, user custom configurations (e.g. UI sizing, dashboard enabled/disabled features)
}

/**
 * Anchor tag onClick handler (can also activate programatically without Event)
 * @param validUrl e.g. href from <a> tag
 * @param closeLabel close InAppBrowser (cordova) label, if present
 */
export const handleLink = (validUrl: string, closeLabel?: string): void => {
    window.open(validUrl, '_blank');
}
