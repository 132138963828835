import * as React from 'react';
import { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';

import { P50x } from '../../../components/P50x';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { ChangePasswordCard } from './ChangePasswordCard';

export interface ChangePasswordPageRouteParameters {
}

export interface ChangePasswordPageProps {
    auth: Auth;
    onLogout: () => void;
}

type State = {
    apiError?: any | undefined;
    form?: FormData;
    lock?: boolean;
    errorMessage?: string;
    submitted?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    SendNewPassword(form: FormData): State;
    UnlockSend(): State;
    SetErrorMessage(errorMessage: string): State;
    ClearErrorMessage(): State;
    PostStatus204(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    SendNewPassword: form => ({ ...state, lock: true, form }),
    SetErrorMessage: errorMessage => ({ ...state, errorMessage }),
    ClearErrorMessage: () => ({ ...state, errorMessage: undefined }),
    UnlockSend: () => ({ ...state, lock: false }),
    PostStatus204: () => ({ ...state, lock: false, submitted: true }),
})

export const ChangePasswordPage: React.FunctionComponent<ChangePasswordPageProps> = ({ auth, onLogout }) => {
    const { t } = useTranslation();
    const [{
        apiError,
        form,
        lock,
        errorMessage,
        submitted
    }, actions] = wrapperReducer(useReducer(reducer, {
        lock: false,
        errorMessage: undefined,
        submitted: false
    }));

    const goToRoot = () => Router.push('/');
    const goToProfile = () => Router.push('/profile');

    useSendNewPassword(
        lock || false,
        submitted || false,
        auth,
        {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            ...form
        },
        actions,
        t);

    useEffect(() => { if (submitted) { goToProfile() } }, [submitted]);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myProfile'), onClick: goToProfile },
                { text: t('Breadcrumbs.changePassword'), active: true }
            ]}
            onLogout={onLogout}
        >

            <ChangePasswordCard
                feedbackError={errorMessage}
                disabled={lock}
                isSending={lock}
                onCancel={goToProfile}
                onSubmit={(oldPassword, newPassword, confirmPassword) => actions.send(a => a.SendNewPassword({
                    oldPassword,
                    newPassword,
                    confirmPassword
                }))}
            />

        </BaseAppLayout>
    );
}

const useSendNewPassword = function (
    lock: boolean,
    submitted: boolean,
    auth: Auth,
    form: FormData,
    actions: ActionDispatcher<Action, State>,
    t: i18next.TFunction
) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                actions.send(a => a.ClearErrorMessage());

                const status = await services.postChangePassword(auth, form.oldPassword, form.newPassword, form.confirmPassword);
                if (isRequestError(status)) {
                    const err: string | undefined = status.message || status.error_description;
                    const end = err ? err.indexOf('.') : 0;
                    actions.send(a => a.SetErrorMessage(err ? t(`ChangePasswordPage.${err.substring(0, end === -1 ? undefined : end)}`) : t('ChangePasswordPage.errorGeneric')));
                    actions.send(a => a.UnlockSend());
                }
                else {
                    actions.send(a => a.PostStatus204());
                }
            }
        }
        catch (e) {
            console.error(e);
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}

interface FormData {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}
