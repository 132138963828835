import * as React from 'react';
import * as services from '../../domain/services';
import { ActionMatcher, wrapperReducer } from '../../api/react-helper';
import { AuthContext } from '../../domain/contexts/AuthContext';
import { DropdownI } from '../Forms/DropdownI';
import { Question } from '../../domain/models';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { useTranslation } from 'react-i18next';
import { DateTimeI } from '../Forms/DateTimeI';

export interface QuestionPanelProps {
    errorMessage: string;
    formId: number;
    question: string;
    taskType: string;
    onSave: (formId: number, question: Question) => void;
    onClose: () => void;
    onSelectType: (value: string) => void;
    onChangeValue: (value: string) => void;
}

type State = {
    questionText: string;
    type?: string;
    body?: string;
    isRequired?: boolean;
    apiError?: any | undefined;
}

type Action = {
    SetQuestion(questionText: string): State;
    SetType(type: string): State;
    SetBody(body: string): State;
    SetRequired(isRequired: boolean): State;
    Set50xError(apiError: any): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetQuestion: questionText => ({ ...state, questionText }),
    SetType: type => ({ ...state, type }),
    SetBody: body => ({ ...state, body }),
    SetRequired: isRequired => ({ ...state, isRequired }),
    Set50xError: apiError => ({ ...state, apiError }),
})

export const QuestionPanel: React.FunctionComponent<QuestionPanelProps> = ({
    errorMessage,
    formId,
    question,
    taskType,
    onSave,
    onClose,
    onSelectType,
    onChangeValue }) => {

    const [{
        questionText,
        type,
        body,
        isRequired,
        apiError
    }, actions] = wrapperReducer(React.useReducer(
        reducer,
        { questionText: '' }

    ));
    const { t } = useTranslation();
    const { state: { auth } } = React.useContext(AuthContext);
    const [questionType, setQuestionType] = React.useState('3')

    const changeQuestionType = (value: string) => {
        setQuestionType(value);
        onSelectType(value)
    } 

    const modulesOptions = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            ['link', 'blockquote', 'image', 'video'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ align: 'center' }, { align: 'right' }, { align: 'justify' }, { align: '' }],
            [{ 'header': [1, 2, false] }],
        ]
        ,
        clipboard: {
            matchVisual: false,
        },
        ImageResize: {
            displaySize: true
          }
    }

    const imageUpload = (image: File, completion: (imgURL: string) => void) => {
        services.saveImage(auth!, image)
            .then(response => {
                completion(response)
            })
            .catch((error) => {
                actions.send(a => a.Set50xError(error))
            })
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        if (isNaN(Number(target.value))) {
            return
        }
        onChangeValue(target.value)
    }

    return (
        <form className="form-horizontal">
            <div className="row">
                <div className="form-group col-md-6">
                    {(taskType.toLowerCase() === t('miscelaneous.survey') || taskType.toLowerCase() === 'editableform' || taskType.toLowerCase() === t('miscelaneous.quiz')) &&
                        <label>Select the task type</label>
                    }
                  
                    {(taskType.toLowerCase() === t('miscelaneous.survey') || taskType.toLowerCase() === 'editableform') &&
                        <DropdownI
                            active={true}
                            options={[
                                { value: '3', label: t('QuestionPanel.single_Line_Text') },
                                { value: '4', label: t('QuestionPanel.multi_Line_Text') },
                                { value: '1', label: t('QuestionPanel.dropDown') },
                                { value: '2', label: t('QuestionPanel.checkBox') },
                                { value: '0', label: t('QuestionPanel.radio_Button') },
                                { value: '7', label: t('QuestionPanel.date_Time') },
                                { value: '5', label: t('QuestionPanel.date') },
                                { value: '6', label: t('QuestionPanel.time') },
                                { value: '8', label: t('QuestionPanel.linear_Scale') },
                                { value: '9', label: t('QuestionPanel.multiple_choice_grid') },
                                { value: '10', label: t('QuestionPanel.checkbox_grid') }
                            ]}
                            selected={questionType}
                            isValid={false}
                            isInvalid={false}
                            invalidMessage={undefined}
                            onChange={changeQuestionType}
                        />

                    }
                    {taskType.toLowerCase() === t('miscelaneous.quiz') &&
                        <DropdownI
                            active={true}
                            options={[
                                { value: '3', label: t('QuestionPanel.single_Line_Text') },
                                { value: '0', label: t('QuestionPanel.radio_Button') },
                                { value: '2', label: t('QuestionPanel.checkBox') },
                                { value: '1', label: t('QuestionPanel.dropDown') },
                                { value: '9', label: t('QuestionPanel.multiple_choice_grid') },
                                { value: '10', label: t('QuestionPanel.checkbox_grid') },
                            ]}
                            selected={questionType}
                            isValid={false}
                            isInvalid={false}
                            invalidMessage={undefined}
                            onChange={changeQuestionType}
                        />

                    }
                </div>
            </div>
            {taskType.toLowerCase() !== "meeting" &&
                <div className="row">
                    <div className="form-group col-md-12">
                        <div style={{ border: "1px solid #e4eaef", padding: "0", backgroundColor: '#fff' }}>
                            <div className="content-editor">
                                <RichTextEditor
                                    theme={'snow'}
                                    placeholder={t('QuestionPanel.enter_your_question')}
                                    value={question || ''}
                                    options={modulesOptions}
                                    onChange={(val) => { onChangeValue(val.replace(/&quot;/g, '')) }}
                                    onUploadImage={imageUpload}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {taskType.toLowerCase() !== "meeting" &&
                errorMessage &&
                <div style={{ paddingLeft: "15px" }} className={errorMessage ? 'display-block  invalid-feedback' : 'invalid-feedback'}>{errorMessage}</div>

            }
            
        </form >
    );
}