
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';

interface RichEditorImageUploaderProps {
  isVisible: boolean,
  onClose: () => void,
  uploadedFiles: (files: File) => void
}

export const RichEditorImageUploader: React.FunctionComponent<RichEditorImageUploaderProps> = ({ isVisible, onClose, uploadedFiles }) => {

  const onDrop = useCallback(async (acceptedFiles) => {
    const binaryString = acceptedFiles[0]
    uploadedFiles(binaryString)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: 'image/jpeg, image/png,image/gif' })
  const { t } = useTranslation();
  return (
    <span>
      <Modal
        open={isVisible}
        onClose={onClose}
        classNames={{
          overlay: "modal-overlay",
        }}
        showCloseIcon={false}
        center>
        <div>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })} className="btn btn-dashes">
              <input {...getInputProps()} className="dropZone-area" />
              <p>{t('RichEditorImageUploader.DragDrop')}</p>
            </div>
          </section>
        </div>
      </Modal>
    </span>
  )}
