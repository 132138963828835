import * as React from 'react';
import { useContext, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as Router from '../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../api/react-helper';
import { validEmail as validInput } from '../../domain/_defaults';
import { isRequestError } from '../../domain/_shared';
import * as services from '../../domain/services';

import { GlobalContext } from '../../domain/contexts/GlobalContext';

import { P50x } from '../../components/P50x';
import { Forgot } from './Forgot';

export interface ForgotPasswordPageRouteParameters {
}

export interface ForgotPasswordPageProps {
    onLogout: () => void;
}

type State = {
    apiError?: any | undefined;
    input?: InputType;
    lock?: boolean;
    errorMessage?: string;
    submitted?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    SendForgot(email: string): State;
    UnlockSend(): State;
    SetErrorMessage(error: string): State;
    ClearErrorMessage(): State;
    PostStatus204(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    SendForgot: email => ({ ...state, input: { email }, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    SetErrorMessage: error => ({ ...state, errorMessage: error }),
    ClearErrorMessage: () => ({ ...state, errorMessage: undefined }),
    PostStatus204: () => ({ ...state, lock: false, submitted: true }),
})

export const ForgotPasswordPage: React.FunctionComponent<ForgotPasswordPageProps> = ({ onLogout }) => {
    const { actions: globalActions } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [{
        apiError,
        input,
        lock,
        errorMessage,
        submitted
    }, actions] = wrapperReducer(useReducer(reducer, {
        input: {},
        lock: false,
        errorMessage: undefined,
        submitted: false
    }));

    const goToRoot = () => Router.push('/');

    useHandleForgot(lock || false, submitted || false, input || {}, actions, t);

    useEffect(() => {
        if (submitted) {
            setTimeout(() => onLogout(), 1000);
        }
    }, [submitted]);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <Forgot
            errorMessage={errorMessage}
            disabled={lock || submitted}
            isSending={lock || submitted}
            onChangeLanguage={(lng) => globalActions.send(a => a.SetLanguage(lng))}
            onForgotClick={(e) => actions.send(a => a.SendForgot(e))}
            onGoBackClick={goToRoot}
        />
    );
}

const useHandleForgot = function (
    lock: boolean,
    submitted: boolean,
    input: InputType | undefined,
    actions: ActionDispatcher<Action, State>,
    t: i18next.TFunction
) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                actions.send(a => a.ClearErrorMessage());

                if (!input || !input.email) {
                    actions.send(a => a.SetErrorMessage(t('ForgotPasswordPage.errorNoEmail')));
                    actions.send(a => a.UnlockSend());
                } else {
                    if (!validInput(input.email)) {
                        actions.send(a => a.SetErrorMessage(t('ForgotPasswordPage.invalidInput')));
                        actions.send(a => a.UnlockSend());
                    } else {
                        const status = await services.forgotPassword(input.email);
                        if (isRequestError(status)) {
                            const err: string | undefined = status.message || status.error_description;
                            const end = err ? err.indexOf('.') : 0;
                            actions.send(a => a.SetErrorMessage(err ? t(`ForgotPasswordPage.${err.substring(0, end === -1 ? undefined : end)}`) : t('ForgotPasswordPage.errorGeneric')));
                            actions.send(a => a.UnlockSend());
                        }
                        else {
                            actions.send(a => a.PostStatus204());
                        }
                    }
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}

interface InputType {
    email?: string;
}
