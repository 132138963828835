import * as React from 'react';
import { SurveyOptionsField } from './SurveyOptionField';
import { QuestionOption, Question } from '../../domain/models';
import { RadioboxI } from '../Forms/RadioboxI';
import { EditQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditQuestion';
import { EditRadioBoxGridSurvey } from '../../pages/Admin/CourseManagementPage/components/tab1/EditRadioBoxSurvey';

export interface SurveyMultipleChoiceGridProps {
    onChangeRowOption: (rowOptions: QuestionOption[]) => void;
    onChangeColumnOption: (columnOptions: QuestionOption[]) => void;
    question?: Question;
    order?: number;
    currentOptions?: QuestionOption[];
    currentSubQuestions?: QuestionOption[];
    onCompleteOptionEdit?: (item: QuestionOption) => void;
    onCompleteSubQuestionEdit?: (item: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
    onAddSubQuestion?: (item: QuestionOption) => void;
    onDeleteSubQuestion?: (id: number) => void;
    updateQuestion?: (question: Question) => void;
}

export const SurveyMultipleChoice: React.FunctionComponent<SurveyMultipleChoiceGridProps> = ({
    onChangeRowOption,
    onChangeColumnOption,
    question,
    updateQuestion,
    currentOptions,
    currentSubQuestions,
    onCompleteOptionEdit,
    onCompleteSubQuestionEdit,
    onAddOption,
    onDeleteOption,
    onAddSubQuestion,
    onDeleteSubQuestion,
    order
}) => {

    const initialOptionsValue: string[] = ['', '', '']
    const [rowOptions, setRowOptions] = React.useState(initialOptionsValue)
    const [columnOptions, setColumnOptions] = React.useState(initialOptionsValue)
    const [validationRowOn, toggleRowValidation] = React.useState(false)
    const [validationColumnOn, toggleColumnValidation] = React.useState(false)

    const [isEditMode, setEditMode] = React.useState(false)
    React.useEffect(() => {
        if (question) setEditMode(true)
    }, [])

    const onAddNewRowOptionHandler = (index: number) => {
        const updatedOptions = [...rowOptions]
        updatedOptions.splice(index + 1, 0, '')
        setRowOptions([...updatedOptions])
        toggleRowValidation(false)
    }
    const onRemoveRowOptionHandler = (index: number) => {
        const updatedOptions = [...rowOptions]
        if (updatedOptions.length < 2) {
            toggleRowValidation(true)
            return
        }
        updatedOptions.splice(index, 1)
        setRowOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option,
                order: index + 1
            }
        })
        onChangeRowOption(questionOptions)
    }

    const onChangeRowHandler = (val: string, index: number) => {
        const updatedOptions = [...rowOptions]
        updatedOptions.splice(index, 1, val)
        setRowOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option,
                order: index + 1
            }
        })
        onChangeRowOption(questionOptions)
        toggleRowValidation(false)
    }

    // =================================================

    const onAddNewColumnOptionHandler = (index: number) => {
        const updatedOptions = [...columnOptions]
        updatedOptions.splice(index + 1, 0, '')
        setColumnOptions([...updatedOptions])
        toggleColumnValidation(false)
    }
    const onRemoveColumnOptionHandler = (index: number) => {
        const updatedOptions = [...columnOptions]
        if (updatedOptions.length < 2) {
            toggleColumnValidation(true)
            return
        }
        updatedOptions.splice(index, 1)
        setColumnOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option,
                order: index + 1
            }
        })
        onChangeColumnOption(questionOptions)
    }

    const onChangeColumnHandler = (val: string, index: number) => {
        const updatedOptions = [...columnOptions]
        updatedOptions.splice(index, 1, val)
        setColumnOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option,
                order: index + 1
            }
        })
        onChangeColumnOption(questionOptions)
        toggleColumnValidation(false)
    }



    return (
        <div className="col-md-9">
            <EditQuestion question={question} updateQuestion={updateQuestion} order={order} />
            {!isEditMode &&
                <>
                    <div className="mt-3">
                        <p><strong>Rows</strong></p>
                    </div>
                    <div>

                        {
                            rowOptions.map((item, index) => (
                                <div className="d-flex survey-form-t-margin">
                                    <RadioboxI
                                        active={false}
                                        id={'uniqueId'}
                                        value={''}
                                        label={''}
                                        isSelected={false}
                                        isValid={false}
                                        isInvalid={false}
                                        onChange={() => { }}
                                    />
                                    <SurveyOptionsField
                                        index={index}
                                        active={true}
                                        placeholder={'Enter row label'}
                                        value={item}
                                        isValid={false}
                                        isInvalid={false}
                                        onChange={onChangeRowHandler}
                                        onAddOption={onAddNewRowOptionHandler}
                                        onRemoveOption={onRemoveRowOptionHandler}
                                    />
                                </div>
                            ))

                        }
                        {validationRowOn &&
                            <div className="invalid-feedback d-flex">You cant delete all options.</div>
                        }
                    </div>
                    <div className="mt-3">
                        <p className="mb-0"><strong>Columns</strong></p>
                    </div>
                    <div>
                        {
                            columnOptions.map((item, index) => (
                                <div className="d-flex survey-form-t-margin">
                                    <RadioboxI
                                        active={false}
                                        id={'uniqueId'}
                                        value={''}
                                        label={''}
                                        isSelected={false}
                                        isValid={false}
                                        isInvalid={false}
                                        onChange={() => { }}
                                    />
                                    <SurveyOptionsField
                                        index={index}
                                        active={true}
                                        placeholder={'Enter column label'}
                                        value={item}
                                        isValid={false}
                                        isInvalid={false}
                                        onChange={onChangeColumnHandler}
                                        onAddOption={onAddNewColumnOptionHandler}
                                        onRemoveOption={onRemoveColumnOptionHandler}
                                    />
                                </div>
                            ))

                        }
                        {validationColumnOn &&
                            <div className="invalid-feedback d-flex">You cant delete all options.</div>
                        }
                    </div>
                </>
            }
            {isEditMode &&
                <EditRadioBoxGridSurvey
                    question={question}
                    currentOptions={currentOptions}
                    currentSubQuestions={currentSubQuestions}
                    onCompleteOptionEdit={onCompleteOptionEdit}
                    onCompleteSubQuestionEdit={onCompleteSubQuestionEdit}
                    onAddOption={onAddOption}
                    onDeleteOption={onDeleteOption}
                    onAddSubQuestion={onAddSubQuestion}
                    onDeleteSubQuestion={onDeleteSubQuestion}
                />
            }

        </div>
    );
}