import * as React from 'react';
import moment from 'moment';

import { TimeI } from './TimeI';

export interface TimeBlockProps {
    active: boolean;
    answered: boolean;
    questionBody: string;
    expectedAnswer?: string;        // 'YYYY-MM-DDTHH:mm:ss.sss' (ISO) format
    value?: string;                 // 'hh:mm' format
    min?: string;                   // 'hh:mm[:ss]' format
    max?: string;                   // 'hh:mm[:ss]' format
    onChange: (newTime: string) => void;
}

export const TimeBlock: React.FunctionComponent<TimeBlockProps> = ({ active, answered, questionBody, expectedAnswer, value, min, max, onChange }) => {

    const isValid = function (localTime: string | undefined): boolean {
        let valueIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            valueIsExpected = (moment.utc(expectedAnswer).format('HH:mm') === localTime);    // OBS.: ignoring seconds
        }

        return answered && valueIsExpected;
    }

    const isInvalid = function (localTime: string | undefined): boolean {
        let valueIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            valueIsExpected = (moment.utc(expectedAnswer).format('HH:mm') === localTime);    // OBS.: ignoring seconds
        }

        return answered && !valueIsExpected;
    }

    return (
        <>
            <div className="form-group">
                <h3 className="h6 mb-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
                <TimeI
                    active={active}
                    value={value}
                    min={min}
                    max={max}
                    isValid={isValid(value)}
                    isInvalid={isInvalid(value)}
                    onChange={(typedValue: string) => onChange(typedValue)}
                />
            </div>
        </>
    );
}
