import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';


export interface TraineeFilterDropDownProps {
    label: string;
    styleName?: string;
    icon?: string,
    traineeStatusFilter: string[];
    changeFilter: (filter: string[]) => void;
}

export const TraineeFilterDropDown: React.FunctionComponent<TraineeFilterDropDownProps> = ({ label, styleName, icon, traineeStatusFilter, changeFilter }) => {
    const { t } = useTranslation();
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);

    const closeDropdown = () => {
        if (openDropdownMenu) toggleDropdownMenu(false);
    }

    const handleStatusCheckBox = (value: React.MouseEvent<HTMLInputElement>) => {
      const checkbox = value.target as HTMLInputElement
      let updatedStatusList = !traineeStatusFilter ? [] : [...traineeStatusFilter]
        checkbox.checked ? updatedStatusList.push(checkbox.value) : updatedStatusList.splice(updatedStatusList.indexOf(checkbox.value), 1)
        changeFilter(updatedStatusList)
    }


    const isFiltersChecked = (filter: string) => {
       if (!traineeStatusFilter) return false
        return (traineeStatusFilter.includes(filter))
    }


    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(closeDropdown, 500);
    }

    return (

        <div className="btn-group" onBlur={handleOnBlur}>
            <div className="dropdown">
                <button className={`btn dropdown-toggle ${styleName ? styleName : 'btn-light'}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
                    {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
                    <span className="btn-label">{label}</span>
                </button>
                <div className={classnames('dropdown-menu dropdown-menu-left', { 'show': openDropdownMenu })}>
                    <div className="p-2">
                    </div>
                    <div>
                        <li className="dropdown-item">
                            <div className="form-check">

                                <input className="form-check-input" type="checkbox" id="option3" value="notStarted" onClick={handleStatusCheckBox} checked={isFiltersChecked('notStarted')} />
                                <label className="form-check-label font-italic" htmlFor="option3"><span className="small">{t('CourseManagementParticipantsPage.NotStartedLbl')}</span></label>
                            </div>
                        </li>
                        <li className="dropdown-item">
                            <div className="form-check">

                                <input className="form-check-input" type="checkbox" id="option4" value="inProgress" onClick={handleStatusCheckBox} checked={isFiltersChecked('inProgress')} />
                                <label className="form-check-label text-info font-italic" htmlFor="option4"><span className="small">{t('CourseManagementParticipantsPage.InProgressLbl')}</span></label>
                            </div>
                        </li>
                        <li className="dropdown-item">
                            <div className="form-check">

                                <input className="form-check-input" type="checkbox" id="option5" value="finished" onClick={handleStatusCheckBox} checked={isFiltersChecked('finished')} />
                                <label className="form-check-label text-success font-italic" htmlFor="option5"><span className="small">{t('CourseManagementParticipantsPage.CompletedLbl')}</span></label>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>

    )
}
