import * as React from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Participant, Coach } from '../../../../../domain/models';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';
import * as AdminActions from './actions';

import { LoadingPage } from '../../../../../components/LoadingPage';
import { SearchInput } from '../../../../../components/Search/SearchInput';
import { AddMemberDropDown } from './AddParticipantDropDown';
import { ParticipantListTable } from './ParticipantListTable';
import { ParticipantPaginationCustom } from './ParticipantPagination';
import { ButtonDropdown } from './ButtonDropdown';
import { CoachDropdown } from './CoachDropdown';
import { BadgeFilter } from '../../../../../components/Badges/BadgeFilter';
import { DesignateCoachPopup } from './DesignateCoachPopup';
import { UnassignCoachPopup } from './UnassignCoachPopup';
import { FilterDropdown } from './FilterDropdown';
import { ApprovePendingParticipantsPopup } from './ApprovePendingParticipantsPopup';
import { DesignateConfirmPopup } from './DesignateConfirmPopup';
import { ErrorPopUp } from '../../../../../components/Popup/ErrorPopUp';

export interface ParticipantListProps {
    courseId: any;
    groupId: any;
    status: string;
}

export const ParticipantsList: React.FunctionComponent<ParticipantListProps> = ({ groupId, status }) => {
    const { state: {
        auth,
        courseId,
        participantsList,
        coaches,
        selectedCoaches,
        searchText,
        selectedTraineeFilters,
        resultsPerPage,
        selectedParticipants,
        coachStatusFilter,
        reloadParticipants,
        reloadCoaches },
        actions } = useContext(ParticipantsMgmtContext);
    const { t } = useTranslation();
    const [isDesignatePopupVisible, toggleDesignatePopUp] = useState(false)
    const [isDesignateConfirmPopupVisible, toggleDesignateConfirmPopUp] = useState(false)
    const [selectedCoachID, updateSelectedCoach] = useState(0)
    const [isUnAssignPopupVisible, toggleUnAssignPopUp] = useState(false)
    const [popUpErrorMsg, toggleErrorPopUp] = useState('')
    const [isApproveAllPopUpVisible, toggleApproveAllPopUp] = useState(false)
    const tableDivRef = useRef(null);
    const [tableRowWidth, resizeRow] = useState(1600);


    useEffect(() => {
        resizeRow(tableDivRef.current ? (tableDivRef.current! as HTMLDivElement).offsetWidth - 2 : window.innerWidth);

        const resizeHandler = () => {
            if (tableDivRef && tableDivRef.current) {
                resizeRow((tableDivRef.current! as HTMLDivElement).offsetWidth - 2);
            }
        }
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [tableDivRef.current]);

    if (!participantsList || !coaches) return <LoadingPage />
    const participants = participantsList.results as Participant[]
    const traineeFilterLabels: any = { finished: t('CourseManagementParticipantsPage.CompletedLbl'), inProgress: t('CourseManagementParticipantsPage.InProgressLbl'), notStarted: t('CourseManagementParticipantsPage.NotStartedLbl') }

    const searchHandler = (searchText: string) => {
        actions.send(a => a.updateSearchText(searchText))
    }

    const coachBadgeCloseHandler = (coach: Coach) => {
        let updatedCoachList = !selectedCoaches ? [] : [...selectedCoaches]
        updatedCoachList.splice(updatedCoachList.indexOf(coach), 1)
        actions.send(a => a.updateCoachSelection(updatedCoachList))
    }

    const filterBadgeCloseHandler = (filter: string) => {
        let updatedFilterList = !selectedTraineeFilters ? [] : [...selectedTraineeFilters]
        updatedFilterList.splice(updatedFilterList.indexOf(filter), 1)
        actions.send(a => a.updateTraineeFilters(updatedFilterList))
    }

    const PendingBadgeCloseHandler = () => {
        actions.send(a => a.updateCoachFilters(false))
    }

    const isPendingUsersExist = () => {
        let pendingUsers = []
        pendingUsers = _.filter(participants, function (participant) {
            return participant.enrollmentStatus.toLowerCase() === 'pending';
        })
        return pendingUsers.length > 0 ? true : false
    }

    const isCheckedTopCheckBox = () => {
        return selectedParticipants.length > 0 ? true : false
    }

    const onSelectAllParticipants = () => {
        if (selectedParticipants.length) {
            actions.send(a => a.updateParticipantSelection([]))
        } else {
            const selectedParticipantsID = participants.map((participant) => { return participant.id })
            actions.send(a => a.updateParticipantSelection([...selectedParticipantsID]))
        }
    }

    const removeSelectionHandler = (participantId: number) => {
        let updatedSelectionList = selectedParticipants
        updatedSelectionList.splice(updatedSelectionList.indexOf(participantId), 1)
        actions.send(a => a.updateParticipantSelection([...updatedSelectionList]))
    }

    const addSelectionHandler = (participantId: number) => {
        let updatedSelectionList = selectedParticipants
        updatedSelectionList.push(participantId)
        actions.send(a => a.updateParticipantSelection([...updatedSelectionList]))
    }

    const tableChecked = (participantId: number) => {
        return selectedParticipants.includes(participantId)
    }

    const onclickDesignateConfirmHandler = (coachID: number) => {
        toggleDesignatePopUp(false)
        toggleDesignateConfirmPopUp(true)
        updateSelectedCoach(coachID)
    }

    const onclickDesignateHandler = () => {
        toggleDesignatePopUp(false)
        toggleDesignateConfirmPopUp(true)
        AdminActions.designateCoachHandler(auth, courseId, coaches, selectedParticipants, selectedCoachID, handleRequests)
    }

    const handleRequests = (failedRequests: number[]) => {
        toggleDesignatePopUp(false)
        toggleUnAssignPopUp(false)
        toggleApproveAllPopUp(false)

        if (failedRequests.length > 0) {
            const errorMsg = t('CourseManagementParticipantsPage.errorPopupMsg', { failedRequests: failedRequests.length })
            toggleErrorPopUp(errorMsg)
            actions.send(a => a.reloadParticipantList(!reloadParticipants))
            actions.send(a => a.reloadCoachList(!reloadCoaches))

        } else {
            actions.send(a => a.reloadParticipantList(!reloadParticipants))
            toggleDesignatePopUp(false)
            toggleDesignateConfirmPopUp(false)
            actions.send(a => a.reloadCoachList(!reloadCoaches))
        }
    }

    const coachUnAssignHandler = () => {
        AdminActions.coachUnAssignHandler(auth, courseId, selectedParticipants, handleRequests)
    }

    const approveAllHandler = () => {
        AdminActions.approveCourseMembers(auth, courseId, participants, handleRequests)
    }

    const approveSelectedHandler = (id: number) => {
        AdminActions.approveCourseMembers(auth, courseId, participants, handleRequests, id)
    }

    return (
        <div className="list-page">

            <div className="list-page-header">

                <div className="list-page-actions">
                    <div className="list-page-search">
                        <SearchInput
                            active={true}
                            placeholder={t('CourseManagementParticipantsPage.searchInputPlaceholder')}
                            value={searchText}
                            isValid={false}
                            isInvalid={false}
                            onChange={searchHandler}
                            onFocus={() => ''}
                            onBlur={() => ''}
                        />
                    </div>
                    <div className="list-page-controls hasAddBtn">
                        <FilterDropdown label={t('CourseManagementParticipantsPage.filtersDd')} styleName="btn-outline-light bg-white btn-lg" icon="fa fa-filter" />
                        <CoachDropdown label={t('CourseManagementParticipantsPage.coachesDd')} styleName="btn-outline-light bg-white btn-lg" icon="icon icon-people" coachList={coaches} hasSearch={true} hasDeleteBtn={true} />
                        <ButtonDropdown label={t('CourseManagementParticipantsPage.sorterDd')} styleName="btn-outline-light bg-white btn-lg" icon="fa fa-sort-amount-asc" />
                        <AddMemberDropDown label={t('ParticipantListPage.AddBtn')} styleName="btn-primary " icon="fa fa-plus" courseId={courseId} groupId={groupId} status={status} />
                    </div>
                </div>

                <div className="list-page-filters display-flex">
                    <div className="list-page-badges">

                        {selectedCoaches &&
                            selectedCoaches.map((coach, i) => (
                                <BadgeFilter key={i} label={coach.name} coach={coach} onCloseCoachBadge={coachBadgeCloseHandler} />
                            ))}

                        {selectedTraineeFilters &&
                            selectedTraineeFilters.map((filterValue, i) => (
                                <BadgeFilter key={i} value={filterValue} label={traineeFilterLabels[filterValue]} onCloseFilterBadge={filterBadgeCloseHandler} />
                            ))}

                        {coachStatusFilter &&
                            <BadgeFilter label={t('CourseManagementParticipantsPage.PendingLbl')} value={'pending'} onCloseFilterBadge={PendingBadgeCloseHandler} />
                        }

                    </div>
                </div>

            </div>

            <div className="list-page-body">
                <div className="table-group">

                    <div className="table-group-header hasInfo hasCheck">
                    {status !== 'Draft' &&
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="check1" value="1" checked={isCheckedTopCheckBox()} onChange={() => { }} onClick={onSelectAllParticipants} />
                            <label className="form-check-label" htmlFor="check1"></label>
                        </div>
                    }
                        {status !== 'Draft' &&
                            <div className={`${!isCheckedTopCheckBox() ? 'display-none' : ''}`} >

                                <button onClick={() => toggleDesignatePopUp(true)} className="btn btn-light btn-sm m-1">{t('ListTablePage.DesignateCoachBtn')}</button>
                                <DesignateCoachPopup onClickDesignate={onclickDesignateConfirmHandler} isVisible={isDesignatePopupVisible} coachList={coaches} onClose={() => { toggleDesignatePopUp(false) }} />
                                <button onClick={() => { toggleUnAssignPopUp(true) }} className="btn btn-light btn-sm m-1">{t('ListTablePage.UnsignCoachBtn')}</button>
                                <UnassignCoachPopup isVisible={isUnAssignPopupVisible} onClose={() => { toggleUnAssignPopUp(false) }} onClickUnAssign={coachUnAssignHandler} />
                                <DesignateConfirmPopup isVisible={isDesignateConfirmPopupVisible} onClose={() => { toggleDesignateConfirmPopUp(false) }} onClickDesignate={onclickDesignateHandler} coachList={coaches} />

                            </div>
                        }

                        <button className={`${!isPendingUsersExist() ? 'display-none' : 'btn btn-light btn-sm m-1'}`} onClick={() => { toggleApproveAllPopUp(true) }}>{t('ListTablePage.ApproveAll')}</button>
                        <ApprovePendingParticipantsPopup isVisible={isApproveAllPopUpVisible} onClose={() => { toggleApproveAllPopUp(false) }} onApprove={approveAllHandler} message={t('ListTablePage.approveAllMsg')} courseId={courseId} />

                        <div className="table-group-info">
                            {t('miscelaneous.paginationItems', { begin: (participantsList.page * resultsPerPage) + 1, end: (participantsList.page * resultsPerPage) + participantsList.results.length, total: participantsList.resultsCount })}
                        </div>
                    </div>

                    <div className="table-group-body">

                        <div ref={tableDivRef} className="list-table hasCheck hasDropDown fromMd">
                            <div className="list-table-row list-table-header">
                                <div className="list-table-col">
                                </div>
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.TraineeLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.CoachLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.StatusLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.EnrollDateLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                </div>
                                <div className="list-table-col">
                                </div>
                            </div>

                            {participants.map((participant, index) => (
                                <ParticipantListTable key={participant.id} rowWidth={tableRowWidth} onClickDesignate={onclickDesignateHandler} onOpenDesignate={onclickDesignateConfirmHandler} participant={participant} onSelect={addSelectionHandler} onUnSelect={removeSelectionHandler} isSelected={tableChecked(participant.id)} onClickAppoveButton={approveSelectedHandler} status={status}/>
                            ))
                            }

                        </div>

                    </div>



                </div>
            </div>
            <div className="list-page-footer">
                <ParticipantPaginationCustom resultsCount={participantsList.resultsCount} />
            </div>
            <ErrorPopUp isVisible={!_.isEmpty(popUpErrorMsg)} onClose={() => { toggleErrorPopUp('') }} message={popUpErrorMsg} />
        </div>
    )
}