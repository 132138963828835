import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const FormatLessonStatus: React.FunctionComponent<{
    status?: string;
}> = ({ status }) => {
    const { t, i18n } = useTranslation();

    if (status && i18n.exists && i18n.exists(`fromAPI.lessonStatuses.${status}`)) {
        return <>{t(`fromAPI.lessonStatuses.${status}`)}</>
    }

    return <></>
}

export const FormatTaskNotes: React.FunctionComponent<{
    status?: string;
    score?: number;
}> = ({ status, score }) => {
    const { t, i18n } = useTranslation();

    if (status && i18n.exists && i18n.exists(`fromAPI.taskStatuses.${status}`)) {
        switch (status.toLowerCase()) {
            case 'notstarted':
                return (<></>);
            case 'saved':
                return (<></>);
            case 'notscheduled':
                return (<></>);
            case 'finished':
                return (
                    <div className="list-tasks-status">
                        <p className="mb-0 text-success"><i className="list-tasks-status-icon fa fa-check"></i></p>
                    </div>
                );
            case 'waitingforcoachapproval':
                return (
                    <div className="list-tasks-status">
                        <p className="mb-0 text-info">{t('fromAPI.taskStatuses.WaitingForCoachApproval')}</p>
                    </div>
                );
            case 'failed':
                return (
                    <div className="list-tasks-status">
                        <p className="mb-0 text-danger">{(score) ? `${t('LessonPanel.score')}: ${Math.round(score * 100)}% - ` : ' '}{t('fromAPI.taskStatuses.Failed')}<i className="list-tasks-status-icon fa fa-close"></i></p>
                    </div>
                );
            case 'passed':
                return (
                    <div className="list-tasks-status">
                        <p className="mb-0 text-success">{(score) ? `${t('LessonPanel.score')}: ${Math.round(score * 100)}% - ` : ' '}{t('fromAPI.taskStatuses.Passed')}<i className="list-tasks-status-icon fa fa-check"></i></p>
                    </div>
                );
            default:
                return (<>(UNKNOWN STATUS)</>);
        }
    }

    return <></>
}
