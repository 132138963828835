import './index.sass';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';

import { GlobalContextProvider } from './domain/contexts/GlobalContext';
import { App } from './App';

const serviceWorker = require('./serviceWorker');

function initializeReactGA() {
    ReactGA.initialize('UA-141008466-1');
    ReactGA.pageview('/');
}

const startApp = () => {
    initializeReactGA();

    ReactDOM.render(
        <GlobalContextProvider>
            <App />
        </GlobalContextProvider>,
        document.getElementById('root')
    );
    serviceWorker.unregister();
}

startApp();
