import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ParticipantCourseHeaderProps {
    courseName: string;
    groupName?: string;

    description: string;
}

export const ParticipantCourseHeader: React.FunctionComponent<ParticipantCourseHeaderProps> = ({
    courseName,
    groupName,

    description
}) => {
    const { t } = useTranslation();

    return (
        <div className="course-header">
            <div className="row">
                <div className="col-md-8">
                    <h1 className="h4 mt-2 mb-3 mt-md-0 mb-md-0 title-color">{courseName}</h1>
                    <div className="mt-2 mt-md-2">
                        <strong>{t('CourseHeader.group')}:</strong>
                        {' '} {groupName}
                    </div>
                    <div className="card-text text-muted mt-1 mt-md-2 mb-1 d-none d-sm-block">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}
