import * as React from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { SingleLineIcon } from '../../components/Icons';
import * as Sli from '../../components/Icons';

import { GlobalContext } from '../../domain/contexts/GlobalContext';

import { LanguageSelector } from '../../components/i18n/LanguageSelector';

const logo = require('../../assets/img/logo.png');

export interface ResetProps {
    userEmail: string;
    validationInfo?: string;
    successMessage?: string;
    errorMessage?: string;
    disabled?: boolean;
    isSending?: boolean;
    submitted?: boolean;
    onChangeLanguage: (lng: string) => void;
    onResetClick: (password: string, samePassword: string) => void;
    onGoBackClick: () => void;
    onRetryForgotClick: () => void;
}

export const Reset: React.FunctionComponent<ResetProps> = ({
    userEmail,
    validationInfo,
    successMessage,
    errorMessage,
    disabled,
    isSending,
    submitted,
    onChangeLanguage,
    onResetClick,
    onGoBackClick,
    onRetryForgotClick
}) => {
    const { state: { currentLanguage } } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [{ passwordText, samePasswordText }, setState] = useState({ passwordText: '', samePasswordText: '' });

    function handleFormResetClick() {
        onResetClick(passwordText, samePasswordText);
    }

    return (
        <div className="login-page bg-gradient">
            <header className="app-header text-right">
                <div className="container-fluid pt-3 mb-xs-3">
                    <LanguageSelector
                        styleName="bg-primary text-white border-primary"
                        contentPosition="dropdown-menu-right"
                        currentLanguage={currentLanguage}
                        disabled={!!disabled}
                        onFlagClick={onChangeLanguage}
                    />
                </div>
            </header>

            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center py-4">

                        <div className="col-12 text-center mb-3">
                            <img alt="Galilee" className="brand-logo" width="200" src={logo} onClick={onGoBackClick} style={{ cursor: 'pointer' }} />
                        </div>

                        <div className="col-12 col-md-6 col-lg-5">
                            <div className="card-group">
                                <div className="card p-2 p-md-3">
                                    <div className="card-body">

                                        <h1 className="h3">{t('ResetComponent.title')}</h1>
                                        <p className="">{t('ResetComponent.message')}
                                            {' '} <strong>{userEmail}</strong>
                                            <br />
                                            <span className="text-muted small my-2 d-block">{validationInfo}</span>
                                        </p>

                                        {(!submitted)
                                            ? (<ResetForm
                                                passwordText={passwordText}
                                                samePasswordText={samePasswordText}
                                                errorMessage={errorMessage}
                                                disabled={disabled}
                                                isSending={isSending}
                                                onPasswordTextChange={text => setState({ passwordText: text, samePasswordText })}
                                                onSamePasswordTextChange={text => setState({ passwordText, samePasswordText: text })}
                                                onResetClick={handleFormResetClick}
                                                onGoBackClick={onGoBackClick}
                                            />)
                                            : (<ResetResponse
                                                successMessage={successMessage}
                                                errorMessage={errorMessage}
                                                onRetryForgotClick={onRetryForgotClick}
                                                onGoBackClick={onGoBackClick}
                                            />)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

interface ResetFormProps {
    passwordText: string;
    samePasswordText: string;
    errorMessage?: string;
    disabled?: boolean;
    isSending?: boolean;
    onPasswordTextChange: (text: string) => void;
    onSamePasswordTextChange: (text: string) => void;
    onResetClick: () => void;
    onGoBackClick: () => void;
}

const ResetForm: React.FunctionComponent<ResetFormProps> = ({
    passwordText,
    samePasswordText,
    errorMessage,
    disabled,
    isSending,
    onPasswordTextChange,
    onSamePasswordTextChange,
    onResetClick,
    onGoBackClick
}) => {
    const { t } = useTranslation();
    const passwordEl = useRef(null);

    useEffect(() => {
        if (passwordEl && passwordEl.current) {
            (passwordEl.current! as HTMLInputElement).focus();
        }
    }, [errorMessage]);

    function handleKeyPress(e: React.KeyboardEvent) {
        if (e.keyCode === 13) {
            e.preventDefault();    // don't trigger onChange
            e.stopPropagation();
            onResetClick();
        }
    }

    return (
        <form>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <div className="input-group mb-1">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <SingleLineIcon icon={Sli.lock} />
                    </span>
                </div>
                <input
                    ref={passwordEl}
                    className={classnames('form-control', { 'is-invalid': errorMessage })}
                    placeholder={t('ResetComponent.passwordPlaceholder')}
                    type="password"
                    value={passwordText}
                    disabled={disabled}
                    onChange={e => onPasswordTextChange(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>

            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <SingleLineIcon icon={Sli.lock} />
                    </span>
                </div>
                <input
                    className={classnames('form-control', { 'is-invalid': errorMessage })}
                    placeholder={t('ResetComponent.copyPasswordPlaceholder')}
                    type="password"
                    value={samePasswordText}
                    disabled={disabled}
                    onChange={e => onSamePasswordTextChange(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>

            <div className="row">
                <div className="col-12">
                    {isSending
                        ? (
                            <button
                                type="button"
                                className="btn btn-loader btn-primary"
                                disabled={true}
                                style={{ width: '100%' }}
                            >
                                <div className="sk-rotating-plane"></div>
                                <span className="label small">{t('ResetComponent.submitLabel')}</span>
                            </button>
                        )
                        : (
                            <button
                                type="button"
                                className="btn btn-block btn-primary text-center"
                                disabled={disabled}
                                onClick={() => onResetClick()}
                            >
                                {t('ResetComponent.submitLabel')}
                            </button>
                        )
                    }
                </div>
                <div className="col-12 text-center text-sm-left mt-3 mt-md-2 mb-1">
                    <button
                        disabled={disabled}
                        type="button"
                        className="btn btn-link p-0 small text-muted"
                        onClick={onGoBackClick}
                    >
                        <i className="icon fa fa-arrow-left mr-1" />
                        {t('ResetComponent.cancel')}
                    </button>
                </div>
            </div>
        </form>
    );
};

interface ResetResponseProps {
    successMessage?: string;
    errorMessage?: string;
    onRetryForgotClick: () => void;
    onGoBackClick: () => void;
}

const ResetResponse: React.FunctionComponent<ResetResponseProps> = ({
    successMessage,
    errorMessage,
    onRetryForgotClick,
    onGoBackClick
}) => {
    const { t } = useTranslation();

    return (
        <form>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <div className="row">
                {!successMessage && <div className="col-12">
                    <button
                        className="btn btn-block btn-primary text-center"
                        type="button"
                        onClick={() => onRetryForgotClick()}
                    >{t('ResetComponent.resendSubmitLabel')}</button>
                </div>}
                <div className="col-12 text-center text-sm-left mt-3 mt-md-2 mb-1">
                    <button
                        type="button"
                        className="btn btn-link p-0 small text-muted"
                        onClick={onGoBackClick}
                    >
                        <i className="icon fa fa-arrow-left mr-1" />
                        {t('ResetComponent.goBack')}
                    </button>
                </div>
            </div>
        </form>
    );
};
