export const tryRetrieveBrowserLanguage = (): string | undefined => {
    return window.navigator.language;
}

/**
 * Gets a cookie value by a string key, if exists
 * @param key string
 */
export const getCookie = (key: string): string | undefined => {
    const cookiesStr = '; ' + document.cookie;
    const parts = cookiesStr.split(`; ${key}=`);
    if (parts.length >= 2) {
        return parts.pop()!.split(';').shift();
    }

    return undefined;
}

/**
 * Sets a new cookie, if not previous existent
 * @param key string
 * @param value string
 * @param domain e.g. `.domain.com` or `subdomain.domain.com`
 * @param path e.g. `/`
 * @param expires Date.toUTCString() format
 * @returns `true` if new cookie created, `false` otherwise
 */
export const setCookie = (key: string, value: string, domain?: string, path?: string, expires?: string, secure?: boolean): boolean => {
    if (!getCookie(key)) {
        let cookieStr = `${key}=${value}`;
        if (!!expires) cookieStr = cookieStr.concat('; expires=' + expires);
        if (!!path) cookieStr = cookieStr.concat('; path=' + path);
        if (!!secure) cookieStr = cookieStr.concat('; secure');
        if (process.env.NODE_ENV === 'production') {
            if (!!domain) cookieStr = cookieStr.concat('; domain=' + domain);
        }
        document.cookie = cookieStr;

        // CHECK
        if (!!getCookie(key)) return true;
    }

    return false;
}

/**
 * Forcibly remove cookie
 * @param key string
 */
export const deleteCookie = (key: string, domain?: string): void => {
    let cookieStr = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    if (!!domain) cookieStr = cookieStr.concat('; domain=' + domain);
    document.cookie = cookieStr;
}
