import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface CoachCourseHeaderProps {
    courseStatus: string;
    courseType: string;
    courseName: string;
    courseImgUrl?: string;
    groupName?: string;

    traineeCount: number;
    notStartedCount: number;
    inProgressCount: number;
    finishedCount: number;
}

export const CoachCourseHeader: React.FunctionComponent<CoachCourseHeaderProps> = ({
    courseStatus,
    courseType,
    courseName,
    courseImgUrl,
    groupName,

    traineeCount,
    notStartedCount,
    inProgressCount,
    finishedCount,
}) => {
    const { t } = useTranslation();

    return (
        <div className="course-header">
            <div className="row">
                <div className="col-md-8">
                  <div className="header-wrapper">
                    {(courseImgUrl) && <img className="rounded mb-2" src={courseImgUrl} alt="(some-URL)"></img>}
                    <div className="header-content">                      
                      <h1 className="h4 mt-2 mb-3 mt-md-0 mb-md-0 title-color">{courseName}</h1>
                      <div className="mt-2 mt-md-2">
                          <strong>{t('CourseHeader.group')}:</strong>
                          {' '} {groupName}
                      </div>
                      {(courseStatus === 'Draft') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}><span className="badge badge-pill badge-info">{t('CourseHeader.draftLbl')}</span></p>
                      </div>}
                      {(courseStatus === 'Test') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}><span className="badge badge-pill badge-light">{t('CourseHeader.testLbl')}</span></p>
                      </div>}
                    </div> 
                  </div>                    
                </div>

                <div className="col-md-4 mt-2 mt-md-0">
                    <div className="row">
                        <div className="col-6">
                            <div className="mt-0 mt-md-2">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.trainees')}:</span> {traineeCount}</small>
                            </div>
                            <div className="mt-0 mt-md-2">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.notStarted')}:</span> {notStartedCount}</small>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="mt-0 mt-md-2">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.ongoing')}:</span> {inProgressCount}</small>
                            </div>
                            <div className="mt-0 mt-md-2">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.finished')}:</span> {finishedCount}</small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
