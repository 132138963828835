import * as React from 'react';
import { useReducer, useEffect } from 'react';

import * as Router from '../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer } from '../../api/react-helper';
import { tryRetrieveBrowserLanguage } from '../../api/browser';
import { validLanguage, validTimezone, APP_Default_Language, APP_Default_Timezone } from '../_defaults';
import { GlobalConfig } from '../_shared';

import { I18NProvider } from './I18NContext';
import { AuthContextProvider } from './AuthContext';

type State = {
    sessionData?: GlobalConfig;
    currentLanguage?: string;
    currentTimezone?: string;
    currentPostMesssage?: Event;
}

type Action = {
    ReloadLocalStorage: () => State;
    SetLanguage: (lng: string) => State;
    SetTimezone: (tz: string) => State;
    SetLanguageAndTimezone: (lng: string, tz: string) => State;
    SetCurrentPostMessage: (evt: Event) => State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    ReloadLocalStorage: () => {
        if (!state.sessionData          // NOTE: verify memory State comparing to LocalStorage
            || !state.currentLanguage
            || !state.currentTimezone
        ) {
            // NOTE: if one of the properties is undefined, reset everything
            const sD = {};
            const lng = APP_Default_Language;
            const tz = APP_Default_Timezone;

            overrideLocalStorage(sD, lng, tz);
            return { ...state, sessionData: sD, currentLanguage: lng, currentTimezone: tz };
        } else {
            const sD = { ...state.sessionData };    // (TODO) reset defaults if needed
            const lng = state.currentLanguage;
            const tz = state.currentTimezone;

            overrideLocalStorage(sD, lng, tz);
            // NOTE: no need to reload state
        }

        return state;       // WARNING: NOT triggering re-render
    },
    SetLanguage: lng => {
        if (validLanguage(lng)) {
            if (languageIsChanging(state, lng)) {
                console.log('LANGUAGE SET TO: ', lng);

                localStorage.setItem('language', lng);
                return { ...state, currentLanguage: lng };
            } else console.log('LANGUAGE UNCHANGED: ', lng);
        } else console.error('INVALID LANGUAGE');

        return state;       // WARNING: NOT triggering re-render
    },
    SetTimezone: tz => {
        if (validTimezone(tz)) {
            if (timezoneIsChanging(state, tz)) {
                console.log('TIMEZONE SET TO: ', tz);

                localStorage.setItem('timezone', tz);
                return { ...state, currentTimezone: tz };
            } else console.log('TIMEZONE UNCHANGED: ', tz);
        } else console.error('INVALID TIMEZONE');

        return state;       // WARNING: NOT triggering re-render
    },
    SetLanguageAndTimezone: (lng, tz) => {
        if (validLanguage(lng) && validTimezone(tz)) {
            if (languageIsChanging(state, lng) || timezoneIsChanging(state, tz)) {
                console.log(`LANGUAGE/TIMEZONE SET TO: '${lng}' and '${tz}'`);

                localStorage.setItem('language', lng);
                localStorage.setItem('timezone', tz);
                return { ...state, currentLanguage: lng, currentTimezone: tz };
            } else console.log(`LANGUAGE AND TIMEZONE UNCHANGED: '${lng}' and '${tz}'`);
        } else console.error('INVALID LANGUAGE AND/OR TIMEZONE');

        return state;       // WARNING: NOT triggering re-render
    },
    SetCurrentPostMessage: (evt) => {
        if (evt != null && typeof evt === 'object') return { ...state, currentPostMesssage: evt };

        return state;
    }
})

export interface GlobalContextProps { state: State, actions: ActionDispatcher<Action, State> };
export const GlobalContext = React.createContext<GlobalContextProps>({} as { state: State, actions: ActionDispatcher<Action, State> });
export const GlobalContextProvider: React.FunctionComponent = ({ children }) => {
    const [state, actions] = wrapperReducer(useReducer(reducer, {
        sessionData: initializeSessionData(),
        currentLanguage: initializeLanguage(),
        currentTimezone: initializeTimezone(),
        currentPostMesssage: undefined
    }));

    useEffect(() => {
        console.log(`APP INITIALIZED: '${JSON.stringify(state.sessionData)}' - '${state.currentLanguage}' - '${state.currentTimezone}'`);
    }, []); // WARNING: 1x initilization

    return (
        <GlobalContext.Provider value={{ state, actions }}>
            <I18NProvider>
                <AuthContextProvider>
                    {children}
                </AuthContextProvider>
            </I18NProvider>
        </GlobalContext.Provider>
    )
}

const overrideLocalStorage = (sessionData: GlobalConfig, lng: string, tz: string): void => {
    localStorage.clear();
    localStorage.setItem('session_data', JSON.stringify(sessionData));
    localStorage.setItem('language', lng);
    localStorage.setItem('timezone', tz);
}

const initializeSessionData = (): GlobalConfig => {
    const sD: GlobalConfig = tryRetrieveSessionData() || {};    // (TODO) initialize other global configurations
    localStorage.setItem('session_data', JSON.stringify(sD));   // NOTE: overrides whatever exists
    return sD;
}

const initializeLanguage = (): string => {
    const lng: string = detectLanguage() || APP_Default_Language;
    localStorage.setItem('language', lng);  // NOTE: overrides whatever exists
    return lng;
}

const initializeTimezone = (): string => {
    const tz: string = detectTimezone() || APP_Default_Timezone;
    localStorage.setItem('timezone', tz);  // NOTE: overrides whatever exists
    return tz;

}

const detectLanguage = (): string | undefined => {
    // BROWSER
    if (true) {
        //1. queryString
        const queryStringAfterF5 = Router.search()["lang"];
        if (!!validLanguage(queryStringAfterF5)) {
            // console.log(`From query string: ${queryStringAfterF5}`);
            return queryStringAfterF5;
        }

        //2. localStorage
        const lng = tryRetrieveLanguage();
        if (!!validLanguage(lng)) {
            // console.log(`From local storage: ${lng}`);
            return lng;
        }

        //3. navigator
        const navigatorLang = tryRetrieveBrowserLanguage();
        if (!!validLanguage(navigatorLang)) {
            // console.log(`From navigator: ${navigatorLang}`);
            return navigatorLang;
        } else {
            console.error('Navigator language not supported, please contact technical team');
        }

        // (TODO) other detection options
    }

    // MOBILE
    if (false) {
    }

    return undefined;
}

const detectTimezone = (): string | undefined => {
    return tryRetrieveTimezone();
}

const languageIsChanging = (state: State, lng: string): boolean => {
    return state.currentLanguage ? state.currentLanguage !== lng : true
}

const timezoneIsChanging = (state: State, tz: string): boolean => {
    return state.currentTimezone ? state.currentTimezone !== tz : true
}

const tryRetrieveSessionData = (): GlobalConfig | undefined => {
    const storageSessionData = localStorage.getItem('session_data');

    if (!!storageSessionData) {
        try {
            const sD = JSON.parse(storageSessionData);
            if (isGlobalConfigType(sD)) {
                return sD;
            } else {
                console.error('LOCALSTORAGE: \'session_data\' parsed object doesn\'t match, removing key...');
            }
        } catch (e) {
            console.error('LOCALSTORAGE: \'session_data\' is not a parseable object, removing key...');
        }
    }

    localStorage.removeItem('session_data');
    return undefined;
}

// NOTE: SHALLOW TYPE CHECK
function isGlobalConfigType(obj: any): obj is GlobalConfig {
    if (obj !== undefined && obj !== null && typeof (obj) === 'object'
        // NOTE: not checking extra properties if existent
    ) {
        return true;
    }

    return false;
}

const tryRetrieveLanguage = (): string | undefined => {
    if (!localStorage.getItem('language')) {
        return undefined;
    }
    return localStorage.getItem('language')!;
}

const tryRetrieveTimezone = (): string | undefined => {
    if (!localStorage.getItem('timezone')) {
        return undefined;
    }
    return localStorage.getItem('timezone')!;
}
