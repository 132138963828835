import * as React from "react";
import { ButtonBack } from "../../../../components/Buttons/ButtonBack";
import { RichTextEditor } from "../../../../components/RichTextEditor/RichTextEditor";
import {
    ActionMatcher,
    wrapperReducer,
    ActionDispatcher,
    useAsyncEffect
} from "../../../../api/react-helper";
import { useReducer, useState, useContext } from "react";
import { CourseConfigs, UserProfile, Category } from "../../../../domain/models";
import { AuthContext } from "../../../../domain/contexts/AuthContext";
import { Auth, isRequestError } from "../../../../domain/_shared";
import { useTranslation } from "react-i18next";
import * as services from "../../../../domain/services";
import { LoadingPage } from "../../../../components/LoadingPage";
import * as Router from "../../../../api/Router";
import { ImageEditor } from "../../../../components/ImageEditor/ImageEditor";
import { P50x } from "../../../../components/P50x";
import { ImageLoading } from "../../../../components/ImageLoader/ImageLoading";
import Trumbowyg from "react-trumbowyg";
import "react-trumbowyg/dist/trumbowyg.min.css";
import $ from "jquery";
import _ from "lodash";
import { APP_Language_List } from "../../../../domain/_defaults";

export interface NewCourseProps {
    groupId: number;
    tabId: number;
}

type State = {
    courseNameText: string;
    description?: string;
    courseType?: string;
    bodyContent?: string;
    isGroupMembershipRequired?: boolean;
    isEnrollmentApprovalRequired?: boolean;
    apiError?: any | undefined;
    imageFile?: any;
    categoryName: string;
    profile?: UserProfile;
    category: Category[];
    language?: string;
};

type Action = {
    SetCourseName(courseNameText: string): State;
    SetDescription(description: string): State;
    SetCourseType(courseType: string): State;
    SetBody(bodyContent: string): State;
    SetCategory(categoryName: string): State;
    SetGroupMembership(isGroupMembershipRequired: boolean): State;
    SetEnrollmentApproval(isEnrollmentApprovalRequired: boolean): State;
    Set50xError(apiError: any): State;
    SetImage(imageFile: any): State;
    LoadCurrentProfile(profile: UserProfile): State;
    LoadCategories(catagory: Category[]): State;
    SetLanguage(language: string): State;
};

const reducer = (state: State, action: ActionMatcher<Action, State>) =>
    action.match({
        SetCourseName: courseNameText => ({ ...state, courseNameText }),
        SetDescription: description => ({ ...state, description }),
        SetCourseType: courseType => ({ ...state, courseType }),
        SetBody: bodyContent => ({ ...state, bodyContent }),
        SetGroupMembership: isGroupMembershipRequired => ({
            ...state,
            isGroupMembershipRequired
        }),
        SetEnrollmentApproval: isEnrollmentApprovalRequired => ({
            ...state,
            isEnrollmentApprovalRequired
        }),
        Set50xError: apiError => ({ ...state, apiError }),
        SetImage: imageFile => ({ ...state, imageFile }),
        LoadCurrentProfile: profile => ({ ...state, profile }),
        LoadCategories: category => ({ ...state, category }),
        SetCategory: categoryName => ({ ...state, categoryName }),
        SetLanguage: language => ({ ...state, language }),
    });

export const NewCourse: React.FunctionComponent<NewCourseProps> = ({
    groupId
}) => {
    const { t } = useTranslation();
    const [
        {
            courseNameText,
            description,
            courseType,
            bodyContent,
            imageFile,
            isGroupMembershipRequired,
            isEnrollmentApprovalRequired,
            apiError,
            profile,
            category,
            categoryName,
            language,
        },
        actions
    ] = wrapperReducer(
        useReducer(reducer, {
            courseNameText: "",
            courseType: "CoachAssisted",
            isGroupMembershipRequired: false,
            isEnrollmentApprovalRequired: false,
            category: [],
            categoryName: ""
        })
    );

    const [state, setState] = useState({
        courseNameText: courseNameText || "",
        invalidCourseNameMessage: undefined as string | undefined,
        errorMsg: undefined as string | undefined,
        invalidLanguageMessage: undefined as string | undefined,
        invalidCategoryMessage: undefined as string | undefined,
    });
    const { invalidCourseNameMessage,invalidLanguageMessage, invalidCategoryMessage } = state;
    const {
        state: { auth }
    } = useContext(AuthContext);
    const [imageSource, updateImageSource] = useState("");
    const [isVisible, toggleVisibility] = useState(false);
    const [isImageLoading, updateImageUploadingStatus] = useState(false);
    const [imageUrl, updateimageUrl] = useState();
    const [selectedLanguage, setLanguage] = useState("");
    // CATEGORY
    const [selectedCategory, setCategory] = useState("");

    useLoadProfile(auth!, actions);
    useLoadCatagory(auth!, actions)


    const handleCoursenameTextChange = (value: any) => {
        setState({
            ...state,
            courseNameText: value.target.value,
            invalidCourseNameMessage: undefined
        });
    };

    const imageUpload = (image: File, completion: (imgURL: string) => void) => {
        updateImageUploadingStatus(true);
        services
            .saveImage(auth!, image)
            .then(response => {
                updateImageUploadingStatus(false);
                completion(response);
            })
            .catch(error => {
                updateImageUploadingStatus(false);
                actions.send(a => a.Set50xError(error));
            });
    };

    const logoUpload = (image: File, completion: (imgURL: string) => void) => {
        imageUpload(image, (imgURL: string) => {
            updateimageUrl(image);
            completion(imgURL);
        });
    };

    function onCancelClick() {
        Router.push(`/groups/${groupId}/admin/1`);
    }

    const handleFileChange = (event: any) => {
        window.URL = window.URL;
        let url = window.URL.createObjectURL(event.target.files[0]);
        updateImageSource(url);
        toggleVisibility(true);
    };

    const onCloseLogoImageEditor = () => {
        updateImageSource("");
        updateimageUrl(undefined);
        toggleVisibility(false);
    };

    const onLanguageValueChange = (val: string) => {
        setLanguage(val);
    };

    const onCategoryValueChange = (val: string) => {
        setCategory(val);
    };
    function onSaveClick() {
        let body = $("#react-trumbowyg").trumbowyg("html");
        let courseName: string | undefined;
        if (courseNameText.length === 0)
            courseName = t("NewCoursePage.fieldRequired");
        if (courseNameText.length > 0 && categoryName !== "" && language !== "" && categoryName !== "") {
            const newCourse: CourseConfigs = {
                name: courseNameText,
                description: description,
                body: body,
                type: courseType,
                language: selectedLanguage,
                categoryId: parseInt(categoryName),
                isGroupMembershipRequired: isGroupMembershipRequired,
                isEnrollmentApprovalRequired: isEnrollmentApprovalRequired
            };
            createNewCourse(auth!, newCourse);
        } else {
            const LanguageerrorMessage = selectedLanguage
            ? undefined
            : "Please select a language";
            const categoryerrorMessage = selectedCategory
                ? undefined
                : "Please select a Category";
            setState({
                ...state,
                invalidCourseNameMessage: courseName,
                invalidCategoryMessage: categoryerrorMessage,
                invalidLanguageMessage: LanguageerrorMessage
            });
        }
    }

    const createNewCourse = async function (auth: Auth, newCourse: CourseConfigs) {
        services
            .createCourse(auth, groupId, newCourse)
            .then(response => {
                if (imageUrl) {
                    services
                        .updateCourseImage(auth, parseInt(response), imageUrl)
                        .then(() => {
                            Router.push(
                                `/groups/${groupId}/admin/courses/${parseInt(response)}/1`
                            );
                        })
                        .catch(e => {
                            actions.send(a => a.Set50xError(e));
                        });
                }
                Router.push(`/groups/${groupId}/admin/courses/${parseInt(response)}/1`);
            })
            .catch(e => {
                actions.send(a => a.Set50xError(e));
            });
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (apiError) return <P50x onGoBack={() => Router.push("/")} />;
    if (!profile) return <LoadingPage />;



    return (
        <div>
            <div className="main">
                <div className="container-fluid">
                    <div className="header-page hasBtnBack hasInfo">
                        {/* <ButtonBack text="Back To..." onClick={() => history.back()} /> */}
                        <h1 className="h4 header-page-title title-color">
                            {t("NewCoursePage.NewCourse")}
                        </h1>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <form className="form-horizontal">
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="title">
                                        {t("NewCoursePage.Title")}
                                    </label>
                                    <div className="col-md-9">
                                        <input
                                            className={
                                                "form-control " +
                                                (invalidCourseNameMessage ? "is-invalid" : "")
                                            }
                                            id="title"
                                            type="text"
                                            name="title"
                                            placeholder="Title"
                                            value={courseNameText}
                                            onChange={value => {
                                                const val = value.target.value;
                                                actions.send(a => a.SetCourseName(val));
                                            }}
                                            onKeyUp={handleCoursenameTextChange}
                                        />
                                        {invalidCourseNameMessage && (
                                            <div className="invalid-feedback">
                                                {invalidCourseNameMessage}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        className="col-md-3 col-form-label"
                                        htmlFor="description"
                                    >
                                        {t("NewCoursePage.Description")}
                                    </label>
                                    <div className="col-md-9">
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            placeholder="Description (optional)"
                                            onChange={value => {
                                                const val = value.target.value;
                                                actions.send(a => a.SetDescription(val));
                                            }}
                                            value={description || ""}
                                        />
                                    </div>
                                </div>
                                {/* <div className="form-group row ">
                                    <label
                                        className="col-md-3 col-form-label"
                                        htmlFor="profile-language"
                                    >
                                        {t("EditProfileCard.language")}
                                    </label>
                                    <div className="col-md-9 ">
                                        <LanguageSelectElement

                                            selected={selectedLanguage}
                                            invalidLanguageMessage={invalidLanguageMessage}
                                            disabled={false}
                                            onChange={val => {
                                                onLanguageValueChange(val);
                                                setState({
                                                    ...state,
                                                    invalidLanguageMessage: undefined,
                                                });
                                                actions.send(a => a.SetLanguage(val));
                                            }}
                                        />
                                    </div>
                                </div> */}
                                 <div className="form-group row ">
                                    <label
                                        className="col-md-3 col-form-label"
                                        htmlFor="profile-language"
                                    >
                                        {t("EditProfileCard.language")}
                                    </label>
                                    <div className="col-md-9 ">
                                        <LanguageSelectElement

                                            selected={selectedLanguage}
                                            invalidLanguageMessage={invalidLanguageMessage}
                                            disabled={false}
                                            onChange={val => {
                                                onLanguageValueChange(val);
                                                setState({
                                                    ...state,
                                                    invalidLanguageMessage: undefined,
                                                });
                                                actions.send(a => a.SetLanguage(val));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row removeSpace" style={{ marginBottom: "1rem" }} >
                                    <label
                                        className="col-md-3 col-form-label"
                                        htmlFor="catagory"
                                    >
                                        {t("CreateNewCoursePage.Catagory")}
                                    </label>
                                    {/* CATEGORY */}

                                    <div className="col-md-9">
                                        <CategorySelectElement
                                            selected={selectedCategory}
                                            invalidCategoryMessage={invalidCategoryMessage}
                                            category={category}
                                            disabled={false}                                         
                                            onChange={val => {
                                                onCategoryValueChange(val);
                                                setState({
                                                    ...state,
                                                    invalidCategoryMessage: undefined
                                                });
                                                actions.send(a => a.SetCategory(val));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        className="col-md-3 col-form-label"
                                        htmlFor="file-input"
                                    >
                                        {t("NewCoursePage.ImageProfile")}
                                    </label>
                                    <div className="col-md-9 display-toggle">
                                        <div className="imgeditorbtn-width">
                                            <input
                                                type="file"
                                                id="file"
                                                accept="image/*"
                                                onChange={event => {
                                                    handleFileChange(event);
                                                    event.target.value = "";
                                                }}
                                            />
                                            <label htmlFor="file">
                                                {t("NewCoursePage.ChooseImages")}{" "}
                                            </label>
                                        </div>

                                        <ImageEditor
                                            preview={""}
                                            imageSource={imageSource}
                                            cancelButton={true}
                                            visible={isVisible}
                                            onClose={onCloseLogoImageEditor}
                                            onUploadImage={logoUpload}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <Trumbowyg
                                            id="react-trumbowyg"
                                            buttons={[
                                                ["viewHTML"],
                                                ["formatting"],
                                                "btnGrp-semantic",
                                                "btnGrp-justify",
                                                "btnGrp-lists",
                                                ["table"], // I ADDED THIS FOR THE TABLE PLUGIN BUTTON
                                                ["fullscreen"]
                                            ]}
                                            data={bodyContent || ""}
                                            placeholder="Type your text!"
                                            onChange={() => { }}
                                            onPaste={() => { }}
                                            semantic={false}
                                        />
                                        {/* <RichTextEditor theme={'snow'} onUploadImage={imageUpload} value={bodyContent || ''} onChange={(val) => { actions.send(a => a.SetBody(val.replace(/&quot;/g, ''))) }} ></RichTextEditor> */}
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card-footer">
                            <div className="row">
                                <div className="col-6 blockDisplay">
                                    <div className="text-right">
                                        <div className="form-switch form-switch-right">
                                            <i
                                                className="fa fa-question-circle-o icn-gap"
                                                aria-hidden="true"
                                                title="Some explanation about this control using tooltip."
                                            >
                                                {" "}
                                            </i>

                                            <input
                                                className="form-switch-input"
                                                id="requireGroupMembership"
                                                type="checkbox"
                                                onChange={value => {
                                                    const val = value.target.checked;
                                                    actions.send(a => a.SetGroupMembership(val));
                                                }}
                                            />

                                            <label
                                                className="form-switch-label"
                                                htmlFor="requireGroupMembership"
                                            >
                                                <small>
                                                    <strong>
                                                        {t("NewCoursePage.Requiregroupmembership")}
                                                    </strong>
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="form-switch form-switch-right">
                                            <input
                                                className="form-switch-input"
                                                id="requireManualEnrollmentApproval"
                                                type="checkbox"
                                                onChange={value => {
                                                    const val = value.target.checked;
                                                    actions.send(a => a.SetEnrollmentApproval(val));
                                                }}
                                            />
                                            <label
                                                className="form-switch-label"
                                                htmlFor="requireManualEnrollmentApproval"
                                            >
                                                <small>
                                                    <strong>
                                                        {t("NewCoursePage.Requiremanualenrollmentapproval")}
                                                    </strong>
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2"></div>

                                <div className="col-4 blockDisplay-10">
                                    <div className="row">
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">
                                                <strong>{t("NewCoursePage.Typeofcourse")}</strong>
                                            </label>
                                            <div className="col-md-8 col-form-label">
                                                <div className="form-radio">
                                                    <input
                                                        className="form-radio-input"
                                                        id="radio1"
                                                        type="radio"
                                                        value="SelfPaced"
                                                        name="radios"
                                                        onChange={value => {
                                                            const val = value.target.value;
                                                            actions.send(a => a.SetCourseType(val));
                                                        }}
                                                        onClick={() => { }}
                                                    />
                                                    <label className="form-radio-label" htmlFor="radio1">
                                                        <strong>{t("NewCoursePage.SelfPaced")}</strong>
                                                    </label>
                                                </div>
                                                <div className="form-radio">
                                                    <input
                                                        className="form-radio-input"
                                                        id="radio2"
                                                        type="radio"
                                                        value="CoachAssisted"
                                                        name="radios"
                                                        onChange={value => {
                                                            const val = value.target.value;
                                                            actions.send(a => a.SetCourseType(val));
                                                        }}
                                                        onClick={() => { }}
                                                        defaultChecked
                                                    />
                                                    <label className="form-radio-label" htmlFor="radio2">
                                                        <strong>{t("NewCoursePage.Coachassisted")}</strong>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-right">
                        <button
                            className="btn btn-light m-1"
                            type="submit"
                            onClick={() => onCancelClick()}
                        >
                            {t("NewCoursePage.Cancel")}
                        </button>
                        {/* <button className="btn btn-light m-1" type="submit" onClick={() => onSaveClick()}>
                            {t('NewCoursePage.Create')}
                        </button> */}
                        <button
                            className="btn btn-primary m-1"
                            type="submit"
                            onClick={() => onSaveClick()}
                        >
                            {t("NewCoursePage.CreateandAddLessons")}
                        </button>
                    </div>
                </div>
            </div>
            <ImageLoading isVisible={isImageLoading} />
        </div>
    );
};

const useLoadProfile = function (
    auth: Auth,
    actions: ActionDispatcher<Action, State>
) {
    useAsyncEffect(async () => {
        try {
            const profile = await services.getUserProfile(auth);
            if (isRequestError(profile)) {
                console.log(profile);
            } else {
                actions.send(a => a.LoadCurrentProfile(profile));
            }
        } catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
};

const useLoadCatagory = function (
    auth: Auth,
    actions: ActionDispatcher<Action, State>,
    // language?: string,
   ) {
    useAsyncEffect(async () => {
        try {
           
                const category = await services.getCategories(auth);
                if (isRequestError(category)) {
                    console.log(category);
                } else {
                    actions.send(a => a.LoadCategories(category));
                }
            
        } catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
};

interface LanguageSelectElementProps {
    selected?: string;
    invalidLanguageMessage?: string;
    disabled: boolean;
    onChange: (selectedValue: string) => void;
}

const LanguageSelectElement: React.FunctionComponent<LanguageSelectElementProps> = ({
    selected,
    invalidLanguageMessage,
    disabled,
    onChange
}) => {
    const { t } = useTranslation();
    const languages = APP_Language_List;

    return (
        <div >
            <select
                className={
                    "form-control " + (invalidLanguageMessage ? "languageblock" : "")
                }
                disabled={disabled}
                id="profile-language"
                name="profile-language"
                value={selected ? selected : ""}
                onChange={e => onChange(e.target.value)}
            >
                <option value={""} disabled>
                    {t("EditProfileCard.pleaseSelect")}
                </option>
                {languages.map((lng, i) => (
                    <option key={i} value={lng}>
                        {t(`IANA.${lng}`)}
                    </option>
                ))}
            </select>
            {invalidLanguageMessage && (
                <div className="invalid-msg">{invalidLanguageMessage}</div>
            )}
        </div>
    );
};



interface CategorySelectElementProps {
    selected?: string;
    invalidCategoryMessage?: string;
    disabled: boolean;
    category: Category[];
    onChange: (selectedValue: string) => void;
}

const CategorySelectElement: React.FunctionComponent<CategorySelectElementProps> = ({
    selected,
    invalidCategoryMessage,
    disabled,
    onChange,
    category
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <select
                className={
                    "form-control " + (invalidCategoryMessage ? "languageblock" : "")
                }
                disabled={disabled}
                id="profile-language"
                name="profile-language"
                value={selected ? selected : ""}
                onChange={e => onChange(e.target.value)}
            >
                <option value={""} disabled>
                    {t("EditProfileCard.pleaseSelect")}
                </option>
                {category.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </select>
            {invalidCategoryMessage && (
                <div className="invalid-msg">{invalidCategoryMessage}</div>
            )}
        </div>
    );
};




// interface CategorySelectElementProps {
//     selected?: string;
//     invalidCategoryMessage?: string;
//     disabled: boolean;
//     onChange: (selectedValue: string) => void;
// }

// const CategorySelectElement: React.FunctionComponent<CategorySelectElementProps> = ({
//     selected,
//     invalidCategoryMessage,
//     disabled,
//     onChange
// }) => {
//     const { t } = useTranslation();
//     const languages = APP_Language_List;

//     return (
//         <>
//             <select
//                 className={"form-control " + (invalidCategoryMessage ? 'is-invalid' : '')}
//                 disabled={disabled}
//                 id="profile-language"
//                 name="profile-language"
//                 value={selected ? selected : ''}
//                 onChange={e => onChange(e.target.value)}
//             >
//                 <option value={''} disabled>{t('EditProfileCard.pleaseSelect')}</option>
//                 {languages.map((lng, i) =>
//                     <option key={i} value={lng}>{t(`IANA.${lng}`)}</option>
//                 )}
//             </select>
//             {invalidCategoryMessage && <div className="invalid-feedback">{invalidCategoryMessage}</div>}
//         </>
//     )
// }
