import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const Breadcrumb: React.FunctionComponent = ({ children }) => {
    return (
        <ol className="breadcrumb">
            {children}
        </ol>
    );
}

export interface BreadcrumbItemProps {
    active?: boolean;
    href?: string;
    text: string;
    onClick?: () => void;
}

export const BreadcrumbItem: React.FunctionComponent<BreadcrumbItemProps> = ({ active, href, text, onClick }) => {
    const { t } = useTranslation();
    return (
        <li className={classnames('breadcrumb-item', { 'active': active })}>
            {active ?
                <>{text === t('Breadcrumbs.home') && <span className={'icon icon-home'}></span>}<span className="text"> {text}</span></> :
                <a href={href || 'javascript:void(0)'} onClick={onClick}>{text === t('Breadcrumbs.home') && <span className={'icon icon-home'}></span>} <span className="text">{text}</span></a>}
        </li>
    );
}