import * as React from 'react';
import { useState } from 'react';
import { QuestionOption } from '../../domain/models';
import { DropdownI } from '../Forms/DropdownI';
import { QuizOptionPreview } from './QuizOptionPreview';

export interface QuizDropdownIProps {
    options: QuestionOption[];
    expectedOptions: number[];
}
export const QuizDropdownI: React.FunctionComponent<QuizDropdownIProps> = ({ options, expectedOptions }) => {

    interface QuizDropDownOption {
        label: string;
        value: string;
    }
    const [dropDownOptions, setDropDownOptions] = React.useState()
    let  expectedAnswer= options.filter(options => options.id === Number(expectedOptions))

    React.useEffect(() => {
        let previewOptions = options.map((option, index) => {           
                return {
                    label: option.body,
                    value: option.body,
                }           
        })
        setDropDownOptions(previewOptions)
    }, [])

    return (
        <>
             {options.map((item, index) => (
              <QuizOptionPreview
                index={index}
                active={false}
                placeholder={''}
                value={item.body}
                isValid={false}
                isInvalid={false}
                onChange={() => { }}
                onAddOption={() => { }}
                onRemoveOption={() => { }}
                expectedAnswer={expectedAnswer[0].body}
              />
            ))}
        </>
    );
}

