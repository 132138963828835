import * as React from 'react';
import { useState, useEffect } from 'react';

import { ResultsList } from './ResultsList';
import { SearchInput } from './SearchInput';

export interface SearchBlockProps {
    active: boolean;
    searchPlaceholder?: string;
    inputPattern: string;
    searchString?: string;
    throttle_ms: number;
    inactiveItemPlaceholder?: string;
    searchResult?: Array<any>;
    emptyResultsMesssage?: string;
    onChange: (typedValue: string) => void;             // current typed value (not necessarily searching)
    onThrottledChange: (searchValue: string) => void;   // actual search string
    onClickResult: (itemValue: Array<any>) => void;
}

export const SearchBlock: React.FunctionComponent<SearchBlockProps> = ({ active, searchPlaceholder, inputPattern, searchString, throttle_ms, inactiveItemPlaceholder, searchResult, emptyResultsMesssage, onChange, onThrottledChange, onClickResult }) => {
    const [isTyping, setIsTyping] = useState(true);
    const [enableSearch, setEnableSearch] = useState(false);

    let timerID: number;
    const resetThrottle = function (ms: number): void {
        window.clearTimeout(timerID);
        timerID = window.setTimeout(() => { setIsTyping(false) }, ms);
    }

    const handleChange = function (typedValue: string): void {
        setIsTyping(true);
        resetThrottle(throttle_ms);
        onChange(typedValue);   // immediate input value update
    }

    const isInvalidSearch = function (inputPattern: string, value: string | undefined): boolean {
        return value ? new RegExp(inputPattern).test(value) : false;
    }

    useEffect(() => {
        (!isTyping) && (searchString) && onThrottledChange(searchString);   // dispatch delayed search
    }, [isTyping]);

    return (
        <>
            <SearchInput
                active={active}
                placeholder={searchPlaceholder}
                value={searchString}
                isValid={false}         // (TODO) probably NOT NEEDED
                isInvalid={isInvalidSearch(inputPattern, searchString)}
                onChange={(tV) => handleChange(tV)}
                onFocus={() => { setEnableSearch(true); handleChange(searchString || '') }}
            // onBlur={() => setEnableSearch(false)}
            />
            {
                (active) && (enableSearch) && (
                    <ResultsList
                        key={0}
                        active={true}
                        inactiveItemPlaceholder={inactiveItemPlaceholder}
                        searchResult={searchResult}
                        emptyResultsMessage={emptyResultsMesssage}
                        onClick={(val) => onClickResult(val)}
                    />
                )
            }
        </>
    );
}
