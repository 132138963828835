import * as React from 'react';
import { useTranslation } from 'react-i18next';

const brand = require('../assets/img/logo.png');

export interface TermsOfUsePageRouteParameters {
}

export interface TermsOfUsePageProps {
}

export const TermsOfUsePage: React.FunctionComponent<TermsOfUsePageProps> = ({ }) => {
    const { t } = useTranslation();

    return (
        <>
            <header className="app-header navbar text-center" style={{ display: 'block' }}>
                <a className="navbar-brand" href="/" style={{ backgroundImage: `url(${brand})` }}></a>
            </header>

            <div className="app-body">
                <div className="main">
                    <div className="container mt-3">
                        <div className="header-page hasBtnBack hasInfo">
                            <a className="btn-back" href="javascript:void(0)" onClick={() => history.back()}>
                                <i className="icon fa fa-arrow-left"></i>
                                <span className="label" style={{ display: 'inline-block' }}>{t('TermsOfUsePage.goBackBtn')}</span>
                            </a>
                        </div>

                        <div className="card bg-white px-4 pt-3 mt-2">
                            <div className="card card-section">

                                <div className="card-header">
                                    <h1 className="h5 m-0 float-left title-color">{t('TermsOfUsePage.title')}</h1>
                                </div>

                                <div className="card-body">
                                    <hr className="mt-0 mb-4" />
                                    <div className="row">
                                        <div className="col-12">

                                            <h2 className="h6">{t('TermsOfUsePage.section1.title')}</h2>
                                            <p>{t('TermsOfUsePage.section1.p1')}</p>
                                            <p>{t('TermsOfUsePage.section1.p2')}</p>
                                            <p>{t('TermsOfUsePage.section1.p3')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section2.title')}</h2>
                                            <p>{t('TermsOfUsePage.section2.p1')}</p>
                                            <p>{t('TermsOfUsePage.section2.p2')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section3.title')}</h2>
                                            <p>{t('TermsOfUsePage.section3.p1')}</p>
                                            <ul>
                                                <li>{t('TermsOfUsePage.section3.li1')}</li>
                                                <li>{t('TermsOfUsePage.section3.li2')}</li>
                                                <li>{t('TermsOfUsePage.section3.li3')}</li>
                                                <li>{t('TermsOfUsePage.section3.li4')}</li>
                                                <li>{t('TermsOfUsePage.section3.li5')}</li>
                                                <li>{t('TermsOfUsePage.section3.li6')}</li>
                                                <li>{t('TermsOfUsePage.section3.li7')}</li>
                                                <li>{t('TermsOfUsePage.section3.li8')}</li>
                                            </ul>
                                            <p>{t('TermsOfUsePage.section3.p2')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section4.title')}</h2>
                                            <p>{t('TermsOfUsePage.section4.p1')}</p>
                                            <p>{t('TermsOfUsePage.section4.p2')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section5.title')}</h2>
                                            <p>{t('TermsOfUsePage.section5.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section6.title')}</h2>
                                            <p>{t('TermsOfUsePage.section6.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section7.title')}</h2>
                                            <p>{t('TermsOfUsePage.section7.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section8.title')}</h2>
                                            <p>{t('TermsOfUsePage.section8.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section9.title')}</h2>
                                            <p>{t('TermsOfUsePage.section9.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section10.title')}</h2>
                                            <p>{t('TermsOfUsePage.section10.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section11.title')}</h2>
                                            <p>{t('TermsOfUsePage.section11.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section12.title')}</h2>
                                            <p>{t('TermsOfUsePage.section12.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('TermsOfUsePage.section13.title')}</h2>
                                            <p>{t('TermsOfUsePage.section13.p1')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}