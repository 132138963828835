import { Quill } from 'react-quill';

let Block = Quill.import('blots/block');
export class HeaderBlot extends Block {
    static formats(node: any) {
      return HeaderBlot.tagName.indexOf(node.tagName) + 1;
    }
    static create(value: any) {
        let nodetest 
        if(value == 1) {
          nodetest = document.createElement("h1")
          nodetest.setAttribute('class', 'quill-header-1')
        } else if(value == 2) {
          nodetest = document.createElement("h2")
          nodetest.setAttribute('class', 'quill-header-2')
        }
        return nodetest;
      }
  }
  HeaderBlot.blotName = 'header';
  HeaderBlot.tagName = ['H1','H2'];