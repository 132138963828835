import * as React from 'react';
import { useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../../api/Router';

import { scrollToPosition } from '../../../../api/utils';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../../domain/_shared';
import * as services from '../../../../domain/services';
import { Content } from '../../../../domain/models';

import { GlobalContext } from '../../../../domain/contexts/GlobalContext';

import { P50x } from '../../../../components/P50x';
import { BaseAppLayout } from '../../../../layouts/shared/BaseAppLayout';
import { ButtonBack } from '../../../../components/Buttons/ButtonBack';
import { FeedbackBox } from '../../../../components/Task/FeedbackBox';
import { CoachContentFeedback } from '../../../../components/Task/CoachContentFeedback';
import { CountdownTrigger } from '../../../../components/Task/CountdownTrigger';
import { CardSection, CardSectionLoading, CardViewType } from '../../../../components/Cards/CardSection';
import { ContentArea } from '../../../../components/ContentArea';
import { EffectContentArea } from '../../../../components/EffectContentArea';

export interface ContentPageRouteParameters {
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export interface ContentPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

type State = {
    apiError?: any | undefined;
    content?: Content;
    lock?: boolean;
    submitted?: boolean;
    newStatus?: string;
    showFeedback?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadContent(content?: Content): State;
    SendAcknowledge(): State;
    UnlockSend(): State;
    PostStatus200(newStatus: string): State;
    ToggleFeedback(showFeedback: boolean): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadContent: content => ({ ...state, content }),
    SendAcknowledge: () => ({ ...state, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    PostStatus200: newStatus => ({ ...state, lock: false, submitted: true, newStatus }),
    ToggleFeedback: showFeedback => ({ ...state, showFeedback }),
})

export const ContentPage: React.FunctionComponent<ContentPageProps> = ({
    auth,
    onLogout,
    groupId,
    courseId,
    lessonId,
    taskId
}) => {
    const { state: { currentPostMesssage } } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [{
        apiError,
        content,
        lock,
        submitted,
        newStatus,
        showFeedback
    }, actions] = wrapperReducer(useReducer(reducer, {
        lock: false,
        submitted: false,
        showFeedback: false
    }));

    const goToRoot = () => Router.push('/');
    const goToLessons = () => Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`);

    useLoadContent(auth, taskId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
       
        <div>
         
            {(content)
                ? (
                    <>
                     
                        <div className="card bg-white px-4 pt-3">
                            <CardSection
                                title={content.name}
                                showGridListToggle={false}
                                initialCardViewType={CardViewType.List}
                            >
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />
                                    <EffectContentArea
                                        rawHTML={content.coachBody || ''}
                                        currentPostMessage={currentPostMesssage}
                                        onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))}
                                        onIframeMessageEvent={goToLessons}
                                    />
                                    <hr />
                                    <div className="col-12 text-right">
                                        <ButtonBack text={t('TaskPage.goBack')} btnDefault={true} onClick={goToLessons} />
                                    </div>
                                </div>
                            </CardSection>
                        </div>
                    </>
                )
                : <CardSectionLoading description={true} showGridListToggle={true} initialCardViewType={CardViewType.List} />}
        </div>
    );
}

const useLoadContent = function (auth: Auth,  contentId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            actions.send(a => a.LoadContent(undefined));
            const content = await services.getContent(auth,  contentId) as Content;
            if (isRequestError(content)) {
                // TODO: log the error
                // TODO: Diogo - create request error message
                // TODO: use error boundary
                console.log(content);
            }
            else {
                actions.send(a => a.LoadContent(content));
                scrollToPosition(0, 0);

                if (content.status !== 'NotStarted') {
                    actions.send(a => a.ToggleFeedback(true));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [contentId]);
}


