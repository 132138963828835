import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface AssigneeProps {
  assignedTo: string;
}

export const Assignee: React.FunctionComponent<AssigneeProps> = ({assignedTo}) => {
  const { t } = useTranslation();
  return (
    <>
    {(() => {
      switch (assignedTo) {
          case "Trainee": return t('ContentPanel.trainee');
          case "Coach": return t('ContentPanel.coach');
          case "0": return t('ContentPanel.trainee');
          case "1": return t('ContentPanel.coach');
          default: return ;
      }
  })()}
  </>
  );
}