import * as React from "react";
import {useState, useEffect} from "react";
import { Lesson, Task } from "../../../domain/models";
import { LessonStatus } from "../../../domain/enums";
export interface LessonCollapsePanelProps {
  lesson: Lesson;
  index: number;
  selectedTaskId: number;
  onTaskClick: (task: Task, lessonId: number) => void;
}
export const LessonCollapsePanel: React.FunctionComponent<LessonCollapsePanelProps> = ({lesson, index, onTaskClick, selectedTaskId}) => {

  const [isCollapsed, setIsCollapsed] = useState(true)
  useEffect(() => {
    if(lesson.status === LessonStatus.InProgress)
      setIsCollapsed(false)
  }, [])

  return (
    <div className="lessons-collapse-group" onClick={() => {setIsCollapsed(!isCollapsed)}}>
      <div className="lessons-collapse-btn">
        <p className="lessons-collapse-info small">
          Lesson {index + 1} -{" "}
          {
            (lesson.tasks || []).filter(
              itemLesson =>
                itemLesson.status == "Finished" || itemLesson.status == "Passed"
            ).length
          }
          /{(lesson.tasks || []).length}
        </p>
        <h3 className="lessons-collapse-title">{lesson.name}</h3>
        <span className="lessons-collapse-toogle">
          <i
            className={
              !isCollapsed
                ? "icon fa fa-angle-up"
                : "icon fa fa-angle-down"
            }
          ></i>
        </span>
      </div>
      <div className="lessons-collapse-content">
        <ul className="lessons-collapse-list">
          {!isCollapsed && lesson.tasks &&
            lesson.tasks.map(function(task: any, key: number) {
              return (
                <li
                  key={key}
                  className={
                    "lessons-collapse-list-item " +
                    makeActiveByStatus(task.status, task.isEnabled) +
                    getHighlighted(task.id, selectedTaskId)
                  }
                  onClick={(e) => { e.stopPropagation();onTaskClick(task, lesson.id)}}
                >
                  <i
                    className={
                      "icon-status " +
                      getStatusByType(task.status, task.isEnabled)
                    }
                  />
                  <i className={"icon-type " + getTaskByType(task.type)} />
                  <span className="lessons-collapse-list-title">
                    {task.name}
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
function getTaskByType(taskType: string) {
  switch (taskType.toLowerCase()) {
      case "content":
          return 'icon-doc'
      case "meeting":
          return 'icon-calendar'
      case "editableform":
          return 'icon-note'
      case "quiz":
          return 'icon-question'
      case "survey":
          return 'icon-chart'
      default:
          return ''
  };
}

function getStatusByType(status: string, isEnabled: boolean) {
  if (status.toLowerCase() == 'finished' || status.toLowerCase() == 'passed') {
      return 'fa fa-check done'
  }
  else if ((status.toLowerCase() == 'notstarted' && isEnabled == true) || status.toLowerCase() == 'waitingforcoachapproval') {
      return 'required'
  }
  else {
      return 'optional'
  }

}

function makeActiveByStatus(status: string, isEnabled: boolean) {
  if (status.toLowerCase() == 'finished' || status.toLowerCase() == 'passed') {
      return ''
  }
   if ((status.toLowerCase() == 'notstarted' && isEnabled == true) 
   ||(status.toLowerCase() == 'waitingforcoachapproval')) {
       return ''
   }
  else {
      return 'disabled-list'
  }

}

function getHighlighted(currentTaskId: number, selectedTaskId: number) {
  return currentTaskId == selectedTaskId ? ' active' : ''
}