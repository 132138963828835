import * as React from 'react';
import { QuestionOption } from '../../domain/models';
import { QuizAnswerField } from './QuizAnswerField';

export interface QuizSingleLineTextProps {
    onChangeOption: (options: QuestionOption[]) => void;
    onSetAnswer: (options: QuestionOption[]) => void;
}

export const QuizSingleLineText: React.FunctionComponent<QuizSingleLineTextProps> = ({ 
  onChangeOption,
  onSetAnswer
   }) => {


  const initialOptionsValue: string[] = ['']
  const [options, setOptions] = React.useState(initialOptionsValue)

  const onChangeHandler = (val: string, index: number) => {
    const updatedOptions = [...options]
    updatedOptions.splice(index, 1, val)
    setOptions([...updatedOptions])
    if(updatedOptions[0]!==""){
    let questionOptions = updatedOptions.map((option, index) => {
      return {
        id: 0,
        body: option,
        order: index + 1
      }
    })
    onChangeOption(questionOptions)
    onSetAnswer(questionOptions);
}
else{
    onSetAnswer([]);
}
  }

    return (
      <div>
        {
        options.map((item, index) => (
              <QuizAnswerField
                key={index}
                index={index}
                active={true}
                placeholder={'Enter an answer choice'}
                value={item}
                isValid={false}
                isInvalid={false}
                onChange={onChangeHandler}
              />
        ))
         
        }
        
      </div>
    );
}