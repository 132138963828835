import * as React from 'react';
import { QuestionOption, Question, SubQuestion } from '../../../../../domain/models';
import { EditSubQuestion } from './EditSubQuestion';
import { EditOption } from './EditOption';
import { RadioboxGrid } from '../../../../../components/Forms/RadioboxGrid';
import { CheckboxGrid } from '../../../../../components/Forms/CheckboxGrid';
import { toggleArrayItem } from '../../../../../api/utils';

export interface EditCheckBoxBoxGridProps {
  question?: Question;
  currentOptions?: QuestionOption[];
  currentSubQuestions?: SubQuestion[];
  onCompleteOptionEdit?: (item: QuestionOption) => void;
  onCompleteSubQuestionEdit?: (item: QuestionOption) => void;
  onAddOption?: (item: QuestionOption) => void;
  onDeleteOption?: (id: number ) => void;
  onAddSubQuestion?: (item: QuestionOption) => void;
  onDeleteSubQuestion?: (id: number ) => void;
  updateExpectedAnswer?: (subQuestionId: number, expectedAnswerId: number) => void;
  deleteExpectedAnswer?: (subQuestionId: number, expectedAnswerId: number) => void;
}

export const EditCheckBoxGrid: React.FunctionComponent<
EditCheckBoxBoxGridProps
> = ({
  currentOptions,
  currentSubQuestions,
  onCompleteOptionEdit,
  onCompleteSubQuestionEdit,
  onAddOption,
  onDeleteOption,
  onAddSubQuestion,
  onDeleteSubQuestion,
  updateExpectedAnswer,
  deleteExpectedAnswer
}) => {
  const [answerKeyMode, setAnswerKeyMode] = React.useState(false);
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    let temp: { [subQuestionId: number]: number[] | undefined } = {}
    currentSubQuestions!.forEach((subQ) => {
      temp[subQ.id] = subQ.expectedOptions
    })
    setValue(temp)
  }, [])

  const isAlreadyAddedArrayItem = function (array: number[] | undefined, value: number): boolean {
    let i = array ? array.indexOf(value) : -1;
    let val = (i === -1) ? false : true
    return val;
  } 

  const handleAnswerGridEdit = ([subQId, val]: [number, number]) => {
    let isUnchecked = isAlreadyAddedArrayItem((value && value[subQId]), val)
    if(isUnchecked) deleteExpectedAnswer!(subQId, val)
    else updateExpectedAnswer!(subQId, val)
    setValue({ ...value, [subQId]: toggleArrayItem((value && value[subQId]), val) })
  };

  return (
    <>
      {!answerKeyMode && (
        <>
          Row
          <EditSubQuestion
            currentOptions={currentSubQuestions!}
            onCompleteEdit={onCompleteSubQuestionEdit!}
            onAddOption={onAddSubQuestion}
            onDeleteOption={onDeleteSubQuestion}
          />
          Column
          <EditOption
            currentOptions={currentOptions!}
            onCompleteEdit={onCompleteOptionEdit!}
            onAddOption={onAddOption}
            onDeleteOption={onDeleteOption}
          />
        </>
      )}
      {answerKeyMode && (
          <CheckboxGrid
            active={true}
            answered={false}
            questionBody={''}
            options={currentOptions!}
            subQuestions={currentSubQuestions}
            selected={value}
            onChange={handleAnswerGridEdit}
          />
      )}
      {!answerKeyMode && (
        <div className="d-flex flex-row-reverse survey-form-t-margin">
          <button
            className="btn btn-light m-1"
            onClick={() => setAnswerKeyMode(true)}
          >
            Answer key
          </button>
        </div>
      )}
      {answerKeyMode && (
        <div className="d-flex flex-row-reverse survey-form-t-margin">
          <button
            className="btn btn-light m-1"
            onClick={() => setAnswerKeyMode(false)}
          >
            Options
          </button>
        </div>
      )}
    </>
  );
};