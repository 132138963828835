import * as React from 'react';

export interface PercentageBarProps {
  height: number;     // px
  width: number;      // px
  percentage: number; // between 0 and 100
}

export const PercentageBar: React.FunctionComponent<PercentageBarProps> = ({ height, width, percentage }) => {
  return (
    <div className="progress-component">
      <span className="progress-number">
      {percentage.toFixed(1)}{'%'}
      </span> 
      <div className="progress" style={{
        height: `${height}px`,
        width: `${width}%`
      }}>
        <div className="progress-bar bg-primary" style={{
          width: `${(percentage <= 100) ? Math.floor(percentage) : 100}%`
        }}>
        </div>
      </div >
    </div>
  )
}