import moment from 'moment';

import { toggleArrayItem } from '../../api/utils';
import { APP_DatePattern, APP_DateTimePattern, APP_TimePattern } from '../../domain/_defaults';
import { Question, EditableForm, Quiz, Survey, Answer, AnswersData } from '../../domain/models';

const convertISODate = function (inbound: string): string {
    return moment.utc(inbound).format(APP_DatePattern);
}

const convertISODateTime = function (inbound: string): string {
    return moment.utc(inbound).format(APP_DateTimePattern);
}

const convertISOTime = function (inbound: string): string {
    return moment.utc(inbound).format(APP_TimePattern);
}

export const extractInitialFrom = function (form: EditableForm | Quiz | Survey): AnswersData {
    const answers: AnswersData = {};

    (form && form.questions && form.questions.map(
        ({
            type: questionType,
            id: questionId,
            selectedOptions: initialOptions,    // OBS.: value can be 'null' (not allowed by model)
            actualAnswer: initialAnswer,        // OBS.: value can be 'null' (not allowed by model)
            subQuestions,
            ...rest
        }) => {
            switch (questionType.toLowerCase()) {
                case 'checkbox':
                    answers[questionId] = (!!initialOptions ? initialOptions.slice() : []);         // OBS.: enabling empty responses
                    break;
                case 'checkboxgrid':
                    let ans1: { [subQId: number]: number[] | undefined } = {};
                    (subQuestions && subQuestions.map(
                        ({ id: subQuestionId, selectedOptions: initialOptions }) => {
                            ans1[subQuestionId] = (!!initialOptions ? initialOptions.slice() : []); // OBS.: enabling empty responses
                        }
                    ))
                    answers[questionId] = ans1;
                    break;
                case 'date':
                    answers[questionId] = (!!initialAnswer ? convertISODate(initialAnswer as string) : undefined);
                    break;
                case 'datetime':
                    answers[questionId] = (!!initialAnswer ? convertISODateTime(initialAnswer as string) : undefined);
                    break;
                case 'dropdown':
                    answers[questionId] = (!!initialOptions ? initialOptions[0] : undefined);
                    break;
                case 'linearscale':
                    answers[questionId] = (!!initialAnswer ? (initialAnswer as number) : undefined);
                    break;
                case 'multilinetext':
                    answers[questionId] = (!!initialAnswer ? (initialAnswer as string) : undefined);
                    break;
                case 'radiobox':
                    answers[questionId] = (!!initialOptions ? initialOptions[0] : undefined);
                    break;
                case 'radioboxgrid':
                    let ans2: { [subQId: number]: number | undefined } = {};
                    (subQuestions && subQuestions.map(
                        ({ id: subQuestionId, selectedOptions: initialOptions }) => {
                            ans2[subQuestionId] = (!!initialOptions ? initialOptions[0] : undefined);
                        }
                    ))
                    answers[questionId] = ans2;
                    break;
                case 'singlelinetext':
                    answers[questionId] = (!!initialAnswer ? (initialAnswer as string) : undefined);
                    break;
                case 'time':
                    answers[questionId] = (!!initialAnswer ? convertISOTime(initialAnswer as string) : undefined);
                    break;
                default:
                    answers[questionId] = undefined;
            }
        }
    ))

    return answers;
}

export const updateSingleAnswer = function (questionType: string, questionId: number, value: Answer, answers?: AnswersData): AnswersData {
    switch (questionType) {
        case 'checkbox':
            return { ...answers, [questionId]: toggleArrayItem((answers && answers[questionId] as number[] | undefined), (value as number)) }
        case 'checkboxgrid':
            const objLikeTuple = value as { [subQuestionId: number]: number };  // OBS.: 'value' derives from onChange tuple [subQuestionId, value]
            const subQId = Number(Object.keys(objLikeTuple)[0]);

            const currentQuestionAnswer = (answers && answers[questionId]) as { [subQuestionId: number]: number[] | undefined } | undefined;
            const currentArray = currentQuestionAnswer ? currentQuestionAnswer[subQId] : [];

            return {
                ...answers,
                [questionId]: {
                    ...currentQuestionAnswer,
                    [subQId]: toggleArrayItem(currentArray, Object.values(objLikeTuple)[0])
                }
            }
        case 'radioboxgrid':
            return {
                ...answers,
                [questionId]: {
                    ...((answers && answers[questionId]) as { [subQuestionId: number]: number } | undefined),
                    ...(value as { [subQuestionId: number]: number })  // OBS.: 'value' derives from onChange tuple [subQuestionId, value]
                }
            }
        default:
            return { ...answers, [questionId]: value }
    }
}

export const missingRequiredAnswers = function (form: EditableForm | Quiz | Survey, answers: AnswersData | undefined): Array<Question["id"]> {
    const missing: Array<Question["id"]> = [];

    function pushMissing(isMissing: boolean, id: Question["id"]): boolean {
        if (isMissing) {
            if (missing.indexOf(id) === -1) missing.push(id);
            return true;
        }
        return false;
    }

    (form && form.questions && form.questions.map(
        ({
            type: questionType,
            id: questionId,
            isRequired,
            subQuestions,
            ...rest
        }) => {
            if (isRequired) {
                switch (questionType.toLowerCase()) {
                    case 'checkbox':
                        pushMissing(!Array.isArray(answers && answers[questionId]), questionId);
                        break;
                    case 'checkboxgrid':
                        if (!pushMissing(!(answers && answers[questionId]), questionId)) {
                            let miss1 = false;
                            (subQuestions && subQuestions.map(
                                ({ id: subQuestionId }) => {
                                    miss1 = pushMissing(miss1 || !Array.isArray(((answers && answers[questionId]) as { [subQuestionId: number]: number[] | undefined })
                                    [subQuestionId]), questionId);
                                }
                            ))
                        }
                        break;
                    case 'multilinetext':
                        pushMissing(!(answers && answers[questionId]), questionId); // OBS.: empty string '' considered invalid
                        break;
                    case 'radioboxgrid':
                        if (!pushMissing(!(answers && answers[questionId]), questionId)) {
                            let miss2 = false;
                            (subQuestions && subQuestions.map(
                                ({ id: subQuestionId }) => {
                                    miss2 = pushMissing(miss2 || !((answers && answers[questionId]) as { [subQuestionId: number]: number | undefined })
                                    [subQuestionId], questionId);
                                }
                            ))
                        }
                        break;
                    case 'singlelinetext':
                        pushMissing(!(answers && answers[questionId]), questionId); // OBS.: empty string '' considered invalid
                        break;
                    default:
                        pushMissing(!(answers && answers[questionId]), questionId);
                }
            }
        }
    ))

    return missing;
}

export const isOwnTask = function (task: EditableForm | Quiz | Survey, userRole: 'trainee' | 'coach'): boolean {
    return !!task && (task.assignedTo.toLowerCase() === userRole)
}

export const isAnswerable = function (task: EditableForm | Quiz | Survey, userRole: 'trainee' | 'coach'): boolean {
    return !!task
        && task.isEnabled
        && isOwnTask(task, userRole)
        && (task.type.toLowerCase() === 'editableform'
        || task.type.toLowerCase() !== 'editableform' && task.status.toLowerCase() !== 'finished' && task.status.toLowerCase() !== 'passed');
}

export const isAnswered = function (task: EditableForm | Quiz | Survey): boolean {
    switch (!!task && task.status.toLowerCase()) {
        case 'failed':
            return true;
        case 'passed':
            return true;
        case 'finished':
            return true;
    }
    return false;
}
