import * as React from 'react';
import { useContext } from 'react';
import classnames from 'classnames';

import { CardViewType, CardViewTypeContext } from './CardSection';
import { LoadingPlaceHolder } from '../LoadingPlaceHolder';

export interface CardProps {
    header?: () => JSX.Element | string | null;
    body?: () => JSX.Element | string | null;
    footer?: () => JSX.Element | string | null;
    cardViewType?: CardViewType;
}

export const Card: React.FunctionComponent<CardProps> = ({ header, body, footer, cardViewType }) => {
    const ctxCardViewType = cardViewType || useContext(CardViewTypeContext);
    const isList = ctxCardViewType === CardViewType.List;

    return (
        <div className={classnames({
            'mb-4 col-12': isList,
            'mb-4 col-sm-6 col-lg-4 col-xl-3': !isList,
        })}>
            <div className={classnames(
                'card h-100 mb-0 text-dark',
                { 
                  'card-list': isList,
                  'card-grid': !isList
                } 
            )}>
                {header && <div className="card-header p-3 p-md-4">{header()}</div>}
                {body && <div className="card-body p-3 pt-0 p-md-4">{body()}</div>}
                {footer && <div className="card-footer p-3 p-md-4">{footer()}</div>}
            </div>
        </div>
    );
}

export const CardLoading: React.FunctionComponent = ({ }) => {
    const ctxCardViewType = CardViewType.Grid || useContext(CardViewTypeContext);
    const isList = ctxCardViewType === CardViewType.List;

    return (
        <div className={classnames({
            'mb-4 col-12': isList,
            'mb-4 col-sm-6 col-lg-4 col-xl-3': !isList,
        })}>
            <div className={classnames(
                'card h-100 mb-0 text-dark border-danger',
                { 'card-list': isList }
            )}>
                <div className="card-header">
                    <LoadingPlaceHolder style={{ width: 'auto' }} />
                </div>
                <div className="card-body">
                    <LoadingPlaceHolder style={{ width: 'auto' }} />
                </div>
                <div className="card-footer">
                    <LoadingPlaceHolder style={{ width: 'auto' }} />
                </div>
            </div>
        </div>
    );
}
