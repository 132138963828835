import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import _ from 'lodash';

export interface TraineeSorterDropDownProps {
    label: string;
    styleName?: string;
    icon?: string,
    changeSorter: (sorter: string) => void
}

export const TraineeSorterDropDown: React.FunctionComponent<TraineeSorterDropDownProps> = ({ label, styleName, icon,  changeSorter }) => {
    const { t } = useTranslation();
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);

    const closeDropdown = () => {
        if (openDropdownMenu) toggleDropdownMenu(false);
    }

    const handleSortSelection = (sorter: string) => (value: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      changeSorter(sorter)
    }

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(closeDropdown, 500);
    }

    return (
        <div className="btn-group ml-3"  style={{float:"none"}} onBlur={handleOnBlur}>
            <div className="dropdown">
                <button className={`btn dropdown-toggle ${styleName ? styleName : 'btn-light'} ${openDropdownMenu ? 'active' : ''}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
                    {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
                    <span className="btn-label">{label}</span>
                </button>
                  <div className={classnames('dropdown-menu dropdown-menu-left', { 'show': openDropdownMenu })}>    
                    <a className="dropdown-item small" href="#" onClick={handleSortSelection('joinDate')}>{t('MembersSorterDropdown.byEnrollDate')}</a>
                    <a className="dropdown-item small" href="#" onClick={handleSortSelection('name')}>{t('MembersSorterDropdown.byName')}</a>
                </div>
            </div>
        </div>
    )
}