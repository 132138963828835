import * as React from 'react';
import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../../../api/Router';

export interface AddMemberDropDownProps {
    label: string;
    styleName?: string;
    icon?:string
    courseId:number;
    groupId:number;
    selfPaced?: boolean;
    status?:string;
}

export const AddMemberDropDown: React.FunctionComponent<AddMemberDropDownProps> = ({ label, styleName, icon,courseId,groupId, selfPaced,status }) => {
    const { t } = useTranslation();
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);
    const addmemberdropdowndisplay = status === 'Draft' ? { display: 'none' } : {}
  
    const closeDropdown = () => {
        if (openDropdownMenu) toggleDropdownMenu(false);
    }

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(closeDropdown, 500);
    }

    return (
      <div className="btn-group" onBlur={handleOnBlur}>
        <div className="dropdown" style={addmemberdropdowndisplay}>
          <button className={`btn btn-lg dropdown-toggle ${styleName ? styleName : 'btn-light'}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
            {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
            {label}
          </button>
          <div className={classnames('dropdown-menu dropdown-menu-right', { 'show': openDropdownMenu })}>
            {!selfPaced&&
                 <a className="dropdown-item" href="javascript:void(0)" onClick={() => Router.push(`/groups/${groupId}/admin/courses/${courseId}/0/addcoach`)} >{t('AddUserDropDownPage.Coach')}</a>
            }
            <a className="dropdown-item" href="javascript:void(0)"  onClick={() => Router.push(`/groups/${groupId}/admin/courses/${courseId}/0/addtrainee`)}>{t('AddUserDropDownPage.Trainee')}</a>
          </div>
        </div>
      </div>
    )
}