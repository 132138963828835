import * as React from 'react';
import { QuizOptionField } from './QuizOptionField';
import { QuestionOption, Question } from '../../domain/models';
import _ from 'lodash';
import { CheckboxGrid } from '../Forms/CheckboxGrid';
import { toggleArrayItem } from '../../api/utils';
import { EditOption } from '../../pages/Admin/CourseManagementPage/components/tab1/EditOption';
import { EditQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditQuestion';
import { EditSubQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditSubQuestion';
import { EditCheckBoxGrid } from '../../pages/Admin/CourseManagementPage/components/tab1/EditCheckBoxGrid';

export interface QuizCheckBoxGridProps {
    onChangeRowOption: (rowOptions: QuestionOption[]) => void;
    onChangeColumnOption: (columnOptions: QuestionOption[]) => void;
    onSetAnswer: (options: QuestionOption[]) => void;
    question?: Question;
    order?: number;
    currentOptions?: QuestionOption[];
    currentSubQuestions?: QuestionOption[];
    onCompleteOptionEdit?: (item: QuestionOption) => void;
    onCompleteSubQuestionEdit?: (item: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
    onAddSubQuestion?: (item: QuestionOption) => void;
    onDeleteSubQuestion?: (id: number) => void;
    updateQuestion?: (question: Question) => void;
    updateExpectedAnswer?: (subQuestionId: number, expectedAnswerId: number) => void;
    deleteExpectedAnswer?: (subQuestionId: number, expectedAnswerId: number) => void;
}
interface SubQuestion {
    id: number;
    body: string;
    expectedAnswer: string;
    selectedOptions?: number[];
}

export const QuizCheckBoxGrid: React.FunctionComponent<QuizCheckBoxGridProps> = ({
    onChangeRowOption,
    onChangeColumnOption,
    onSetAnswer,
    question,
    updateQuestion,
    currentOptions,
    currentSubQuestions,
    onCompleteOptionEdit,
    onCompleteSubQuestionEdit,
    onAddOption,
    onDeleteOption,
    onAddSubQuestion,
    onDeleteSubQuestion,
    updateExpectedAnswer,
    deleteExpectedAnswer,
    order }) => {


    const initialOptionsValue: QuestionOption[] = [{ id: 1, body: '' }, { id: 2, body: '' }, { id: 3, body: '' }]
    const initialRowOptionValue: SubQuestion[] = [{ id: 1, body: '', expectedAnswer: '' }, { id: 1, body: '', expectedAnswer: '' }, { id: 1, body: '', expectedAnswer: '' }]
    const [rowOptions, setRowOptions] = React.useState(initialRowOptionValue)
    const [columnOptions, setColumnOptions] = React.useState(initialOptionsValue)
    const [optionList, setOptionList] = React.useState([] as QuestionOption[])
    const [subQuestionList, setSubQuestionList] = React.useState([] as SubQuestion[])
    const [answerKeyMode, setAnswerKeyMode] = React.useState(false)
    const [value, setValue] = React.useState();
    const [expectedAnswers, setExpectedAnswers] = React.useState([] as QuestionOption[])
    const [isEditMode, setEditMode] = React.useState(false)
    const [validationRowOn, toggleRowValidation] = React.useState(false)
    const [validationColumnOn, toggleColumnValidation] = React.useState(false)
    const [isSaveRowDisabled, setIsSaveRowDisabled] = React.useState(false)

    React.useEffect(() => {
        if (question) setEditMode(true)
    }, [])

    const getValidOptions = (options: QuestionOption[]) => {
        let validOptions: QuestionOption[] = []
        options.forEach((option) => {
            if (!_.isEmpty(option.body)) {
                validOptions.push(option)
            }
        })
        validOptions = validOptions.map((option, index) => {
          option.id = index + 1 
          return option
        })
        return validOptions
    }
    const getValidRowOptions = (options: SubQuestion[]) => {
        let validOptions: SubQuestion[] = []
        options.forEach((option) => {
            if (!_.isEmpty(option.body)) {
                validOptions.push(option)
            }
        })
        validOptions = validOptions.map((option, index) => {
          option.id = index + 1 
          return option
        })
        return validOptions
    }
    const isAlreadyAddedArrayItem = function (array: number[] | undefined, value: number): boolean {
        let i = array ? array.indexOf(value) : -1;
        let val = (i === -1) ? false : true
        return val;
    }

    React.useEffect(() => {
        let subQuestions = getValidRowOptions(rowOptions)
        let options = getValidOptions(columnOptions)
        setOptionList(options)
        setSubQuestionList(subQuestions)
        if(subQuestions.length>0 && options.length>0)
        {
            setIsSaveRowDisabled(true)
        }
        if(subQuestions.length===0 && options.length===0)
        {
            setIsSaveRowDisabled(false)
        }
    }, [rowOptions, columnOptions])

    const onAddNewRowOptionHandler = (index: number) => {
        const updatedOptions = [...rowOptions]
        updatedOptions.splice(index + 1, 0, { id: rowOptions.length + 1, body: '', expectedAnswer: '' })
        setRowOptions([...updatedOptions])
        toggleRowValidation(false)
    }

    const onRemoveRowOptionHandler = (index: number) => {
        const updatedOptions = rowOptions.filter((_, i) => i != index).map((option, i) => {
            return {
                id: i + 1,
                body: option.body,
                expectedAnswer: option.expectedAnswer,
                selectedOptions: option.selectedOptions
            }
        })
        if (rowOptions.length < 2) {
            toggleRowValidation(true)
            return
        }
        setRowOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option.body,
                order: index + 1
            }
        })
        onChangeRowOption(questionOptions)
        setIsSaveRowDisabled(false)
    }

    const onChangeRowHandler = (val: string, index: number) => {
        const updatedOptions = [...rowOptions]
        updatedOptions.splice(index, 1, { id: index + 1, body: val, expectedAnswer: '' })
        setRowOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option.body,
                order: index + 1
            }
        })
        onChangeRowOption(questionOptions)
        toggleRowValidation(false)
    }

    const onAddNewColumnOptionHandler = (index: number) => {
        const updatedOptions = [...columnOptions]
        updatedOptions.splice(index + 1, 0, { id: columnOptions.length + 1, body: '' })
        setColumnOptions([...updatedOptions])
        toggleColumnValidation(false)
    }

    const onRemoveColumnOptionHandler = (index: number) => {
        const updatedOptions = columnOptions.filter((_, i) => i != index).map((option, i) => {
            return {
                id: i + 1,
                body: option.body,
                expectedAnswer: option.expectedAnswer,
            }
        })
        if (columnOptions.length < 2) {
            toggleColumnValidation(true)
            return
        }
        setColumnOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option.body,
                order: index + 1
            }
        })
        onChangeColumnOption(questionOptions)
        setIsSaveRowDisabled(false)
    }

    const onChangeColumnHandler = (val: string, index: number) => {
        const updatedOptions = [...columnOptions]
        updatedOptions.splice(index, 1, { id: index + 1, body: val })
        setColumnOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option.body,
                order: index + 1
            }
        })
        onChangeColumnOption(questionOptions)
        toggleColumnValidation(false)
    }

    const handleAnswerGrid = ([subQId, val]: [number, number]) => {
        let subQuestion = { id: subQuestionList[subQId - 1].id, body: subQuestionList[subQId - 1].body, expectedAnswer: optionList[val - 1].body }
        let array = [...expectedAnswers]

        let isAdded = isAlreadyAddedArrayItem((value && value[subQId]), val)
        if (!isAdded) {
            array.push(subQuestion)
        } else {
            let index = array.findIndex((val) => {
                if (val.body === subQuestion.body && val.expectedAnswer === subQuestion.expectedAnswer) {
                    return true
                } else {
                    return false
                }
            })
            array.splice(index, 1)
        }

        setExpectedAnswers([...array])
        onSetAnswer(array)
        setValue({ ...value, [subQId]: toggleArrayItem((value && value[subQId]), val) })
        setExpectedAnswers(array)
        onSetAnswer(array)
        setValue({ ...value, [subQId]: toggleArrayItem((value && value[subQId]), val) })
    }

    return (
        <div className="col-md-9">
            <EditQuestion question={question} updateQuestion={updateQuestion} order={order} />
            {!isEditMode &&
                <>
                    <div className="mt-3">
                        <p>Rows</p>
                    </div>
                    {!answerKeyMode &&
                        <div>
                            <div>
                                {rowOptions.map((item, index) => (
                                    <div className="d-flex survey-form-t-margin">
                                        <QuizOptionField
                                            index={index}
                                            active={true}
                                            placeholder={'Enter row label'}
                                            value={item.body}
                                            isValid={false}
                                            isInvalid={false}
                                            onChange={onChangeRowHandler}
                                            onAddOption={onAddNewRowOptionHandler}
                                            onRemoveOption={onRemoveRowOptionHandler}
                                        />
                                    </div>
                                ))}
                                {validationRowOn &&
                                    <div className="invalid-feedback d-flex">You cant delete all options.</div>
                                }
                            </div>
                            <div>
                                Columns
                            </div>
                            <div>
                                {columnOptions.map((item, index) => (
                                    <div className="d-flex survey-form-t-margin">
                                        <QuizOptionField
                                            index={index}
                                            active={true}
                                            placeholder={'Enter column label'}
                                            value={item.body}
                                            isValid={false}
                                            isInvalid={false}
                                            onChange={onChangeColumnHandler}
                                            onAddOption={onAddNewColumnOptionHandler}
                                            onRemoveOption={onRemoveColumnOptionHandler}
                                        />
                                    </div>
                                ))}
                                {validationColumnOn &&
                                    <div className="invalid-feedback d-flex">You cant delete all options.</div>
                                }
                            </div>
                        </div>
                    }
                    {answerKeyMode &&
                        <CheckboxGrid
                            active={true}
                            answered={false}
                            questionBody={''}
                            options={optionList}
                            subQuestions={subQuestionList}
                            selected={value}
                            onChange={handleAnswerGrid}
                        />

                    }
                    {!answerKeyMode &&
                        <div className="d-flex flex-row-reverse survey-form-t-margin">
                            <button className="btn btn-light m-1" disabled={!isSaveRowDisabled} onClick={() => setAnswerKeyMode(true)}>Answer key</button>
                        </div>
                    }
                    {answerKeyMode &&
                        <div className="d-flex flex-row-reverse survey-form-t-margin">
                            <button className="btn btn-light m-1" onClick={() => setAnswerKeyMode(false)}>Options</button>
                        </div>
                    }
                </>
            }
            {isEditMode &&
                <EditCheckBoxGrid
                    question={question}
                    currentOptions={currentOptions}
                    currentSubQuestions={currentSubQuestions}
                    onCompleteOptionEdit={onCompleteOptionEdit}
                    onCompleteSubQuestionEdit={onCompleteSubQuestionEdit}
                    onAddOption={onAddOption}
                    onDeleteOption={onDeleteOption}
                    onAddSubQuestion={onAddSubQuestion}
                    onDeleteSubQuestion={onDeleteSubQuestion}
                    updateExpectedAnswer={updateExpectedAnswer}
                    deleteExpectedAnswer={deleteExpectedAnswer}
                />
            }

        </div>
    );
}