import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface AdminGroupHeaderProps {
    groupName: string;
    userCount?: number;
    creationDate?: string;
}

export const AdminGroupHeader: React.FunctionComponent<AdminGroupHeaderProps> = ({
    groupName,
    userCount,
    creationDate
}) => {
    const { t } = useTranslation();

    return (
        <div className="course-header">
            <div className="row">
                <div className="col-md-8">
                    <h1 className="h4 mt-2 mb-3 mt-md-0 mb-md-0 title-color">{groupName}</h1>
                    <div className="mt-3 mt-md-0 mb-2 text-info">
                        <small><i className="icon icon-settings"></i>
                            {' '} <strong>{t('GroupHeader.youAreAdmin')}</strong></small>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="mt-0">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('GroupHeader.users')}:</span> {userCount}</small>
                    </div>
                    <div className="mt-1 mt-md-2">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('GroupHeader.since')}:</span> {creationDate ? t('dates.short', { valueObj: { type: 'withTZdatetime', data: creationDate } }) : ''}</small>
                    </div>
                </div>

            </div>
        </div>
    )
}