import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Buttons/Button';

export interface EditableFormFeedbackProps {
    isOwnTask: boolean;
    status?: string;
    activeResume: boolean;
    onResume: () => void;
    activeEdit: boolean;
    onEdit: () => void;
}

export const EditableFormFeedback: React.FunctionComponent<EditableFormFeedbackProps> = ({ isOwnTask, status, activeResume, onResume, activeEdit, onEdit }) => {
    const { t } = useTranslation();

    switch (status) {
        case 'NotStarted':
            return (
                <></>   // feedback hidden
            );
        case 'Saved':
            return (
                (isOwnTask) ? (
                    <div className="task-summary-content has-icon">
                        <div className="task-summary-icon">
                            <span className="fa fa-check text-success"></span>
                        </div>
                        <div className="task-summary-info">
                            <p className="task-summary-title text-success"><strong>{t('EditableFormFeedback.formSaved')}</strong></p>
                            <div className="task-summary-btns">
                                <Button
                                    active={activeResume}
                                    label={t('EditableFormFeedback.resumeBtn')}
                                    styleName={(activeResume) ? 'btn btn-primary' : 'btn btn-light'}
                                    href={''}
                                    onClick={() => onResume()}
                                />
                            </div>
                        </div>
                    </div>
                ) : (<></>)
            );
        case 'WaitingForCorrection':
            return (
                <></>
            );
        case 'Finished':
            return (
                (isOwnTask) ? (
                    <div className="task-summary-content has-icon">
                        <div className="task-summary-icon">
                            <span className="fa fa-check text-success"></span>
                        </div>
                        <div className="task-summary-info">
                            <p className="task-summary-title text-success"><strong>{t('EditableFormFeedback.formCompleted')}</strong></p>
                            <div className="task-summary-btns">
                                <Button
                                    active={activeEdit}
                                    label={t('EditableFormFeedback.editBtn')}
                                    styleName={(activeEdit) ? 'btn btn-primary' : 'btn btn-light'}
                                    href={''}
                                    onClick={() => onEdit()}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-check text-success"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-success"><strong>{t('EditableFormFeedback.formCompleted')}</strong></p>
                            </div>
                        </div>
                    )
            );
        default:
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-close text-danger"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-danger"><strong>(unknown status)</strong></p>
                    </div>
                </div>
            );
    }
}