
export type WatchId = () => void;

export type Url = {
    path: string[];
    hash: string;
    search: { [key: string]: string };
}

function safeMakeEvent(eventName: string) {
    if (typeof Event === "function") {
        return new Event(eventName);
    } else {
        var $$event = document.createEvent("Event");
        $$event.initEvent(eventName, true, true);
        return $$event;
    }
}

/* if we ever roll our own parser in the future, make sure you test all url combinations
e.g. foo.com/?#bar
*/
/* sigh URLSearchParams doesn't work on IE11, edge16, etc. */
/* actually you know what, not gonna provide search for now. It's a mess.
   We'll let users roll their own solution/data structure for now */
function path(): string[] {
    var match = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined) {
        var raw = match.location.pathname;
        switch (raw) {
            case "":
            case "/":
                return [];
            default:
                var raw$1 = raw.slice(1);
                var match$1 = raw$1[raw$1.length - 1 | 0];
                var raw$2 = match$1 === "/" ? raw$1.slice(0, -1) : raw$1;
                return raw$2.split("/");
        }
    } else {
        return [];
    }
}

function hash() {
    var match = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined) {
        var raw = match.location.hash;
        switch (raw) {
            case "":
            case "#":
                return "";
            default:
                return raw.slice(1);
        }
    } else {
        return "";
    }
}

function parseQuery(queryString: string) {
    const query: { [key: string]: string } = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        if (pairs[i].indexOf('=') != -1) {
            const pair = [
                pairs[i].substr(0, pairs[i].indexOf('=')),
                pairs[i].substr(pairs[i].indexOf('=') + 1)
            ];
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
    }
    return query;
}

export function search() {
    var match = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined) {
        var raw = match.location.search;
        switch (raw) {
            case "":
            case "?":
                return {};
            default:
                return parseQuery(raw.slice(1));
        }
    } else {
        return {};
    }
}

export function push(path: string) {
    var match = typeof (history) === "undefined" ? undefined : (history);
    var match$1 = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined && match$1 !== undefined) {
        match.pushState(null, "", path);
 
       match$1.dispatchEvent(safeMakeEvent("popstate"));
        return undefined;
    } else {
        return undefined;
    }
}

export function replace(path: string) {
    var match = typeof (history) === "undefined" ? undefined : (history);
    var match$1 = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined && match$1 !== undefined) {
        match.replaceState(null, "", path);
        match$1.dispatchEvent(safeMakeEvent("popstate"));
        return undefined;
    } else {
        return undefined;
    }
}

export function replaceWithClearPath(url: Url) {
    var match = typeof (history) === "undefined" ? undefined : (history);
    var match$1 = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined && match$1 !== undefined) {
        var path = "/" + url.path.join("/");
        match.replaceState(null, "", path);
        match$1.dispatchEvent(safeMakeEvent("popstate"));
        return undefined;
    } else {
        return undefined;
    }
}

export function url(): Url {
    return {
        path: path(),
        hash: hash(),
        search: search()
    };
}

export const dangerouslyGetInitialUrl = url;

export function watchUrl(callback: (url: Url) => void): WatchId {
    var match = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined) {
        var watcherID = function () {
            return callback(url());
        };
        match.addEventListener("popstate", watcherID);
        return watcherID;
    } else {
        return (function () {
            return undefined;
        });
    }
}

export function unwatchUrl(watcherID: WatchId) {
    var match = typeof (window) === "undefined" ? undefined : (window);
    if (match !== undefined) {
        match.removeEventListener("popstate", watcherID as any);
        return undefined;
    } else {
        return undefined;
    }
}
