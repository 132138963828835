import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { validURL } from '../../../../../domain/_defaults';

export interface TraineeCourseHeaderProps {
    courseStatus: string;
    courseType: string;
    courseName: string;
    courseImgUrl?: string;
    groupName?: string;

    coachName: string;
    coachEmail: string;
    startDate: string;
    traineeLastAccessDate: string;
    progress: number;
    certificateUrl: string;
}

export const TraineeCourseHeader: React.FunctionComponent<TraineeCourseHeaderProps> = ({
    courseStatus,
    courseType,
    courseName,
    courseImgUrl,
    groupName,

    coachName,
    coachEmail,
    startDate,
    traineeLastAccessDate,
    progress,
    certificateUrl
}) => {
    const { t } = useTranslation();

    return (
        <div className="course-header">
            <div className="row">
                <div className="col-md-8">
                <div className="header-wrapper">
                  {(courseImgUrl) && <img className="rounded mb-2" src={courseImgUrl} alt="(some-URL)"></img>}
                    <div className="header-content">                    
                      <h1 className="h4 mt-2 mb-3 mt-md-0 mb-md-0 title-color">{courseName}</h1>
                      <div className="mt-2 mt-md-2">
                          <strong>{t('CourseHeader.group')}:</strong>
                          {' '} {groupName}
                      </div>
                      {(courseType === 'CoachAssisted') && <div className="mt-1 mt-md-2">
                          <strong>{t('CourseHeader.coach')}:</strong> {coachName}
                          <a data-placement="top" href={`mailto:${coachEmail}`}>
                              {' '} <i className="fa fa-envelope"></i>
                          </a>
                      </div>}
                      {(courseType === 'SelfPaced') && <div className="mt-1 mt-md-2">
                          <em>{t('CourseHeader.selfPaced')}</em>
                      </div>}
                      {(courseStatus === 'Draft') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}><span className="badge badge-pill badge-info">{t('CourseHeader.draftLbl')}</span></p>
                      </div>}
                      {(courseStatus === 'Test') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}><span className="badge badge-pill badge-light">{t('CourseHeader.testLbl')}</span></p>
                      </div>}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-2 mt-md-0">
                    {startDate && <div className="mt-0 mt-md-2">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.startDate')}:</span> {startDate ? t('dates.short', { valueObj: { type: 'withTZdatetime', data: startDate } }) : ''}</small>
                    </div>}
                    {traineeLastAccessDate && <div className="mt-0 mt-md-2">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.lastEntry')}:</span> {traineeLastAccessDate ? t('dates.long', { valueObj: { type: 'withTZdatetime', data: traineeLastAccessDate } }) : ''}</small>
                    </div>}
                    <div className="mt-0 mt-md-2">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.progress')}:</span> {progress == null ? 0 : (progress * 100).toFixed(1)} %</small>
                    </div>
                    {(progress === 1) && certificateUrl && <div className="mt-0 mt-md-2">
                        <small>
                            <span style={{ fontWeight: 500 }} className="text-muted">{t('CourseHeader.certificate')}:</span>
                            {' '}{validURL(certificateUrl) ? <a href={certificateUrl} target="_blank"><i className="icon-doc" /></a> : <a href={'javascript:void(0)'}><i className="icon-doc" /></a>}
                        </small>
                    </div>}
                </div>

            </div>
        </div>
    )
}
