import * as React from 'react';
import { SurveyOptionsField } from '../../components/Survey/SurveyOptionField';
import { QuestionOption, Question } from '../../domain/models';
import { QuizSingleLineAnswer } from './QuizSingleLineAnswer';

export interface EditSingleLineTextOptionProps {
    currentOptions: string;
    onCompleteEdit: ( item: Question) => void
    question?: Question;
}

export const EditSingleLineTextOption: React.FunctionComponent<EditSingleLineTextOptionProps> = ({ 
  currentOptions,
  onCompleteEdit,
  question
   }) => {

  const [editedOptionValue, setEditedOptionValue] = React.useState()
  const [editedOptionIndex, setEditedOptionIndex] = React.useState()
  const [editorValue, setEditorValue] = React.useState(currentOptions && currentOptions)  
  const [showSpinner, setShowSpinner] = React.useState() 
 

  React.useEffect(() => {
    setShowSpinner(false)
  }, [currentOptions])

  const editOptionHandler = (val: string, index: number) => {
    setEditedOptionValue(val)
    setEditedOptionIndex(index)
    setEditorValue(val)
  }

  const editCompleteHandler = () => {
    setShowSpinner(true)
      if(question){
    let updatedOption: Question = {
      id: question.id,
      name:question.name,
      type:question.type,
      isRequired: question.isRequired,
      weight: question.weight,
      body: question.body,
      order: question.order,
      expectedAnswer: editorValue
     }
     onCompleteEdit(updatedOption)
    }
    // currentOptions.splice(index, 1, updatedOption)
    // setEditedOptionIndex(undefined)
  }

 return(
      <>     
      {/* {currentOptions!.map((item, index) => (   */}
        <QuizSingleLineAnswer
          index={0}
          active={true}
          placeholder={'Enter an answer choice'}
          value={editorValue || ''}
          isValid={false}
          isInvalid={false}
          onChange={editOptionHandler}
          onAddOption={() => {}}
          onRemoveOption={() => {}}
          isEditing={true}
          showSpinner={showSpinner}
          onCompleteEdit={editCompleteHandler}
      />
       {/* ))
     } */}
   </>
 )
          
}