import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { UserProfile } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { ProfileCard } from './ProfileCard';

export interface UserProfilePageRouteParameters {
}

export interface UserProfilePageProps {
    auth: Auth;
    onLogout: () => void;
}

type State = {
    apiError?: any | undefined;
    profile?: UserProfile;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadProfile(profile: UserProfile): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadProfile: profile => ({ ...state, profile }),
})

export const UserProfilePage: React.FunctionComponent<UserProfilePageProps> = ({ auth, onLogout }) => {
    const { t } = useTranslation();
    const [{ apiError, profile }, actions] = wrapperReducer(useReducer(reducer, {}));

    const goToRoot = () => Router.push('/');
    const goToEditProfile = () => Router.push('/editprofile');
    const goToChangePassword = () => Router.push('/changepassword');

    useLoadProfile(auth, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!profile) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myProfile'), active: true }
            ]}
            onLogout={onLogout}
        >

            <ProfileCard
                fullName={profile.name}
                nickName={profile.preferred_username}
                phone={profile.phone_number}
                email={profile.email}
                language={profile.locale}
                timezone={profile.zoneinfo}
                onEditProfileClick={goToEditProfile}
                onChangePasswordClick={goToChangePassword}
            />

        </BaseAppLayout>
    );
}

function useLoadProfile(auth: Auth, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const profile = await services.getUserProfile(auth);
            if (isRequestError(profile)) {
                console.log(profile);
            } else {
                actions.send(a => a.LoadProfile(profile));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
