import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';

interface LinkItem {
    active: boolean;
    id: number | string;
    label: string;
    highlighted?: boolean;
}

export interface ListProps {
    title?: string;
    info?: string;
    linkItems?: LinkItem[];
    activeLink?: LinkItem["id"];
    onClick: (itemId: LinkItem["id"]) => void;
}

export const ScrollDownList: React.FunctionComponent<ListProps> = ({ title, info, linkItems, activeLink, onClick }) => {
    const [active, toggle] = useState(false);

    return (
        <div className={active ? "list-group list-scroll active" : "list-group list-scroll"} onClick={() => toggle(!active)}>
            {(!!title) && <span className="list-group-item">
                <span className="list-group-item-title text-center">{title}</span>
                {' '}
                {(!!info) && <span className="list-group-info">{info}</span>}
            </span>
            }
            {(linkItems) && linkItems.map((linkItem, i) => (
                <a
                    key={i}
                    className={classnames('list-group-item list-group-item-action',
                        { 'active': linkItem.id === activeLink },
                        { 'disabled': linkItem.active !== true }
                    )}
                    href="javascript:void(0)"
                    onClick={() => onClick(linkItem.id)}
                >
                    {linkItem.label}
                    {(linkItem.id === activeLink) && <i className="icon fa fa-angle-right"></i>}
                </a>
            ))}
        </div>
    )
}
