import * as React from 'react';
import { useContext, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as Router from '../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../api/react-helper';
import { validPassword as validInput } from '../../domain/_defaults';
import { isRequestError } from '../../domain/_shared';
import * as services from '../../domain/services';

import { GlobalContext } from '../../domain/contexts/GlobalContext';
import { AuthContext } from '../../domain/contexts/AuthContext';

import { P50x } from '../../components/P50x';
import { Reset } from './Reset';

export interface ResetPasswordPageRouteParameters {
}

export interface ResetPasswordPageProps {
    // OBS.: query string embedded in global Router object
}

type State = {
    apiError?: any | undefined;
    input?: InputType;
    lock?: boolean;
    submitted?: boolean;
    successMessage?: string;
    errorMessage?: string;
}

type Action = {
    Set50xError(apiError: any): State;
    SendReset(email: string, resetCode: string, password: string, samePassword: string): State;
    UnlockSend(): State;
    SetErrorMessage(error: string): State;
    ClearErrorMessage(): State;
    PostStatus204(successMessage: string): State;
    PostStatus400(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    SendReset: (email, resetCode, password, samePassword) => ({
        ...state,
        input: { email, resetCode, password, samePassword },
        lock: true
    }),
    UnlockSend: () => ({ ...state, lock: false }),
    SetErrorMessage: error => ({ ...state, errorMessage: error }),
    ClearErrorMessage: () => ({ ...state, errorMessage: undefined }),
    PostStatus204: successMessage => ({ ...state, lock: false, submitted: true, successMessage }),
    PostStatus400: () => ({ ...state, lock: false, submitted: true }),
})

export const ResetPasswordPage: React.FunctionComponent<ResetPasswordPageProps> = ({ }) => {
    const { actions: globalActions } = useContext(GlobalContext);
    const { actions: authActions } = useContext(AuthContext);
    const { t } = useTranslation();
    const [{
        apiError,
        input,
        lock,
        submitted,
        successMessage,
        errorMessage
    }, actions] = wrapperReducer(useReducer(reducer, {
        input: {},
        submitted: false,
        successMessage: undefined,
        errorMessage: undefined
    }));

    const goToRoot = () => Router.push('/');
    const goToForgotPassword = () => Router.push('/forgotpassword');

    const queryString = Router.search();
    const email = queryString["email"];
    const resetCode = queryString["code"];

    if (!email || !resetCode) {
        setTimeout(goToRoot, 500);
        return null;
    }

    useHandleReset(lock || false, submitted || false, email, resetCode, input || {}, actions, t);

    useEffect(() => {
        if (submitted && successMessage) {
            authActions.send(a => a.ClearAuth());   // NOTE: clears even if User enters this page when logged
            globalActions.send(a => a.ReloadLocalStorage());    // OBS.: stores current memory data
        }
    }, [submitted]);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <Reset
            userEmail={email}
            validationInfo={t('ResetPasswordPage.validationInfo')}
            successMessage={successMessage}
            errorMessage={errorMessage}
            disabled={lock}
            isSending={lock}
            submitted={submitted}
            onChangeLanguage={(lng) => globalActions.send(a => a.SetLanguage(lng))}
            onResetClick={(p, sP) => actions.send(a => a.SendReset(email, resetCode, p, sP))}
            onGoBackClick={goToRoot}
            onRetryForgotClick={goToForgotPassword}
        />
    );
}

const useHandleReset = function (
    lock: boolean,
    submitted: boolean,
    email: string,
    resetCode: string,
    input: InputType | undefined,
    actions: ActionDispatcher<Action, State>,
    t: i18next.TFunction
) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                actions.send(a => a.ClearErrorMessage());

                if (!input || !input.password || !input.samePassword) {
                    actions.send(a => a.SetErrorMessage(t('ResetPasswordPage.errorNoPassword')));
                    actions.send(a => a.UnlockSend());
                } else {
                    if (input.password !== input.samePassword) {
                        actions.send(a => a.SetErrorMessage(t('ResetPasswordPage.differentPassword')));
                        actions.send(a => a.UnlockSend());
                    } else {
                        if (!validInput(input.password)) {
                            actions.send(a => a.SetErrorMessage(t('ResetPasswordPage.invalidInput')));
                            actions.send(a => a.UnlockSend());
                        } else {
                            const status = await services.resetPassword(email, resetCode, input.password, input.samePassword);
                            if (isRequestError(status)) {
                                const err: string | undefined = status.message || status.error_description;
                                const end = err ? err.indexOf('.') : 0;
                                actions.send(a => a.SetErrorMessage(err ? t(`ResetPasswordPage.${err.substring(0, end === -1 ? undefined : end)}`) : t('ResetPasswordPage.errorGeneric')));
                                actions.send(a => a.PostStatus400());
                            } else {
                                actions.send(a => a.PostStatus204(t('ResetPasswordPage.successReset')));
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}

interface InputType {
    password?: string;
    samePassword?: string;
}
