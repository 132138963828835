import * as React from "react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";

import * as Router from "../../../api/Router";

import {
  ActionMatcher,
  ActionDispatcher,
  wrapperReducer,
  useAsyncEffect
} from "../../../api/react-helper";
import { Auth, handleLink, isRequestError } from "../../../domain/_shared";
import * as services from "../../../domain/services";
import { CoachCourse, Trainee } from "../../../domain/models";

import { P50x } from "../../../components/P50x";
import { LoadingPage } from "../../../components/LoadingPage";
import { BaseAppLayout } from "../../../layouts/shared/BaseAppLayout";
import { CoachCourseHeader } from "./components/main/CoachCourseHeader";
import { PageNav } from "../../../components/Navs/PageNav";
import {
  CardSection,
  CardViewType
} from "../../../components/Cards/CardSection";
import { EffectContentArea } from "../../../components/EffectContentArea";
import { TraineeList } from "./components/tab1/TraineeList";

export interface CoachCourseDashboardPageRouteParameters {
  groupId: number;
  courseId: number;
}

export interface CoachCourseDashboardPageProps {
  auth: Auth;
  onLogout: () => void;
  groupId: number;
  courseId: number;
}

export type State = {
  apiError?: any | undefined;
  course?: CoachCourse;
  trainees?: Trainee[];
  tabIndex: number;
  searchString?: string;
  traineeStatusFilter: string[];
  traineeSorter?: string;
  activePage: number;
  resultsPerPage: number;
};

export type Action = {
  Set50xError(apiError: any): State;
  LoadCourse(course: CoachCourse): State;
  LoadTrainees(trainees: Trainee[]): State;
  ChangeTabIndex(index: number): State;
  SetSearchString(searchString: string): State;
  SetTraineeStatusFilter(traineeStatusFilter: string[]): State;
  SetTraineeSorter(traineeSorter: string): State;
  SetActivePage(activePage: number): State;
};

const reducer = (state: State, action: ActionMatcher<Action, State>) =>
  action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadTrainees: trainees => ({ ...state, trainees }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
    SetSearchString: searchString =>({ ...state, searchString}),
    SetTraineeStatusFilter: traineeStatusFilter =>({ ...state, traineeStatusFilter}),
    SetTraineeSorter: traineeSorter => ({ ...state, traineeSorter}),
    SetActivePage: activePage => ({ ...state, activePage})
  });

export const CoachCourseDashboardPage: React.FunctionComponent<CoachCourseDashboardPageProps> = ({
  auth,
  onLogout,
  groupId,
  courseId
}) => {
  const { t } = useTranslation();
  const [{ apiError, course, trainees, tabIndex, searchString, traineeStatusFilter, traineeSorter, activePage, resultsPerPage }, actions] = wrapperReducer(
    useReducer(reducer, { tabIndex: 1, traineeStatusFilter: [], activePage: 0, resultsPerPage: 10})
  );

  const goToRoot = () => Router.push("/");
  const goToMyCourses = () => Router.push("/mycourses");

  useLoadCourse(auth, courseId, actions);
  useLoadTrainees(auth, courseId, actions, searchString, traineeStatusFilter, traineeSorter, activePage);

  const onLessonClick = (
    traineeId: number,
    lessonId: number,
    taskId: number,
    tasktype: string
  ) => {
    Router.push(
      `/groups/${groupId}/coach/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/${tasktype.toLowerCase()}/${traineeId}`
    );
  };

  if (apiError) return <P50x onGoBack={goToRoot} />;
  if (!course || !trainees) return <LoadingPage />;

  return (
    <BaseAppLayout
      breadcrumbs={[
        { text: t("Breadcrumbs.home"), onClick: goToRoot },
        { text: t("Breadcrumbs.myCourses"), onClick: goToMyCourses },
        { text: `${course.name}`, active: true }
      ]}
      onLogout={onLogout}
    >
      <CoachCourseHeader
        courseStatus={course.status || "unknown"}
        courseType={course.type || ""}
        courseName={course.name}
        courseImgUrl={course.imageUrl}
        groupName={course.groupName}
        traineeCount={course.traineeCount || 0}
        notStartedCount={course.notStartedCount || 0}
        inProgressCount={course.inProgressCount || 0}
        finishedCount={course.finishedCount || 0}
      />

      <PageNav>
        <li className="nav-item">
          <a
            className={`nav-link ${tabIndex === 0 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => actions.send(a => a.ChangeTabIndex(0))}
          >
            {t("CourseDashboardPage.tab0")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${tabIndex === 1 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => actions.send(a => a.ChangeTabIndex(1))}
          >
            {t("CourseDashboardPage.tab1")}
          </a>
        </li>
      </PageNav>

      {tabIndex === 0 && (
        <div className="main mt-3 mt-md-4">
          <div className="container-fluid">
            <div className="card bg-white px-4 pt-3">
              <CardSection title={t("CourseDashboardPage.tab0_title")}>
                <div className="col-12">
                  <hr className="mt-0 mb-4" />
                  <EffectContentArea
                    rawHTML={course.body || ""}
                    onLinkClick={url =>
                      handleLink(url, t("miscelaneous.close"))
                    }
                  />
                </div>
              </CardSection>
            </div>
          </div>
        </div>
      )}

      {tabIndex === 1 && (
        <TraineeList
          courseId={courseId}
          onLessonClick={onLessonClick}
          trainees={trainees}
          actions={actions}
          traineeStatusFilter={traineeStatusFilter}
          activePage={activePage}
          resultsPerPage={resultsPerPage}
        />
      )}
    </BaseAppLayout>
  );
};

function useLoadCourse(
  auth: Auth,
  courseId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const course = await services.getCoachCourse(auth, courseId);
      if (isRequestError(course)) {
      } else {
        actions.send(a => a.LoadCourse(course));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, []);
}

function useLoadTrainees(
  auth: Auth,
  courseId: number,
  actions: ActionDispatcher<Action, State>,
  searchString?: string,
  traineeStatusFilter?: string[],
  traineeSorter?: string,
  activePage?: number
) {
  useAsyncEffect(async () => {
    try {
      const trainees = await services.getCoachTrainees(auth, courseId,searchString,traineeStatusFilter,traineeSorter,activePage);
      if (isRequestError(trainees)) {
      } else {
        actions.send(a => a.LoadTrainees(trainees.results));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, [searchString, traineeStatusFilter, traineeSorter, activePage]);
}
