import { __SERVER_API__ } from ".";
import { Auth, get, post } from "../_shared";
import {
  CoachCourse,
  Trainee,
  Lesson,
  Content,
  Meeting,
  EditableForm,
  Quiz,
  Survey,
  AnswersPostRequest,
  TraineeResult
} from "../models";

export async function getCoachCourse(
  auth: Auth,
  courseId: number
): Promise<CoachCourse> {
  return await get(`${__SERVER_API__}/api/coach/courses/${courseId}`, auth);
}

export async function getCoachTrainees(
  auth: Auth,
  courseId: number,
  searchString?: string,
  traineeStatus?: string[],
  sortBy?: string,
  page?: number,
  resultsPerPage?: number
): Promise<TraineeResult> {
  if (searchString == undefined) {
    searchString = "";
  }
  if (sortBy == undefined) {
    sortBy = "";
  }

  let traineeStatusUrl = "";
  if (traineeStatus != undefined) {
    const status = traineeStatus.map(function(status) {
      traineeStatusUrl = traineeStatusUrl.concat("&traineeStatus=" + status);
    });
  }

  if (traineeStatusUrl != "") {
    return await get(
      `${__SERVER_API__}/api/coach/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&page=${page}&resultsPerPage=${resultsPerPage}` +
        traineeStatusUrl,
      auth
    );
  } else {
    return await get(
      `${__SERVER_API__}/api/coach/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&page=${page}&resultsPerPage=${resultsPerPage}`,
      auth
    );
  }
}

export async function getCoachLessons(
  auth: Auth,
  courseId: number,
  traineeId: number
): Promise<Lesson[]> {
  return await get(
    `${__SERVER_API__}/api/coach/courses/${courseId}/trainees/${traineeId}/lessons`,
    auth
  );
}

export async function getCoachContentTask(
  auth: Auth,
  traineeId: number,
  contentId: number
): Promise<Content> {
  return await get(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/content/${contentId}`,
    auth
  );
}

export async function getCoachMeetingTask(
  auth: Auth,
  traineeId: number,
  meetingId: number
): Promise<Meeting> {
  return await get(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/meeting/${meetingId}`,
    auth
  );
}

export async function getCoachEditableFormTask(
  auth: Auth,
  traineeId: number,
  editableFormId: number
): Promise<EditableForm> {
  return await get(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/form/${editableFormId}`,
    auth
  );
}

export async function getCoachQuizTask(
  auth: Auth,
  traineeId: number,
  quizId: number
): Promise<Quiz> {
  return await get(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/form/${quizId}`,
    auth
  );
}

export async function getCoachSurveyTask(
  auth: Auth,
  traineeId: number,
  surveyId: number
): Promise<Survey> {
  return await get(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/form/${surveyId}`,
    auth
  );
}

export async function postCoachContentApproval(
  auth: Auth,
  traineeId: number,
  contentId: number
): Promise<string> {
  return await post<{}, string>(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/content/${contentId}/validate`,
    auth,
    {}
  );
}

export async function postCoachMeetingApproval(
  auth: Auth,
  traineeId: number,
  meetingId: number
): Promise<string> {
  return await post<{}, string>(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/meeting/${meetingId}/validate`,
    auth,
    {}
  );
}

export async function postCoachEditableFormAnswer(
  auth: Auth,
  traineeId: number,
  editableFormId: number,
  postData: AnswersPostRequest
): Promise<string> {
  return await post<AnswersPostRequest, string>(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/form/${editableFormId}`,
    auth,
    postData
  );
}

export async function postCoachQuizAnswer(
  auth: Auth,
  traineeId: number,
  quizId: number,
  postData: AnswersPostRequest
): Promise<string> {
  return await post<AnswersPostRequest, string>(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/form/${quizId}`,
    auth,
    postData
  );
}

export async function postCoachSurveyAnswer(
  auth: Auth,
  traineeId: number,
  surveyId: number,
  postData: AnswersPostRequest
): Promise<string> {
  return await post<AnswersPostRequest, string>(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/form/${surveyId}`,
    auth,
    postData
  );
}

export async function getCoachLessonbyId(
  auth: Auth,
  traineeId: number,
  lessonId: number
): Promise<Lesson> {
  return await get(
    `${__SERVER_API__}/api/coach/trainees/${traineeId}/lessons/${lessonId}`,
    auth
  );
}

export async function getCoachTraineesbyId(
  auth: Auth,
  courseId: number,
  traineeId: number
): Promise<Trainee> {
  return await get(
    `${__SERVER_API__}/api/coach/courses/${courseId}/trainees/${traineeId}`,
    auth
  );
}
