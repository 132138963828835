import * as React from 'react';
import { QuestionOption, Question, SubQuestion } from '../../../../../domain/models';
import { EditSubQuestion } from './EditSubQuestion';
import { EditOption } from './EditOption';

export interface EditCheckBoxGridSurveyProps {
  question?: Question;
  currentOptions?: QuestionOption[];
  currentSubQuestions?: SubQuestion[];
  onCompleteOptionEdit?: (item: QuestionOption) => void;
  onCompleteSubQuestionEdit?: (item: QuestionOption) => void;
  onAddOption?: (item: QuestionOption) => void;
  onDeleteOption?: (id: number ) => void;
  onAddSubQuestion?: (item: QuestionOption) => void;
  onDeleteSubQuestion?: (id: number ) => void;
}

export const EditCheckBoxGridSurvey: React.FunctionComponent<
EditCheckBoxGridSurveyProps
> = ({
  currentOptions,
  currentSubQuestions,
  onCompleteOptionEdit,
  onCompleteSubQuestionEdit,
  onAddOption,
  onDeleteOption,
  onAddSubQuestion,
  onDeleteSubQuestion,
}) => {

  return (
    <>
          Row
          <EditSubQuestion
            currentOptions={currentSubQuestions!}
            onCompleteEdit={onCompleteSubQuestionEdit!}
            onAddOption={onAddSubQuestion}
            onDeleteOption={onDeleteSubQuestion}
          />
          Column
          <EditOption
            currentOptions={currentOptions!}
            onCompleteEdit={onCompleteOptionEdit!}
            onAddOption={onAddOption}
            onDeleteOption={onDeleteOption}
          />
    </>
  );
};