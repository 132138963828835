import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { __SERVER_REPO__ } from '../domain/_defaults';

import { Button } from './Buttons/Button';

export interface P50xProps {
    onGoBack: () => void;
}

export const P50x: React.FunctionComponent<P50xProps> = ({ onGoBack }) => {
    const { t } = useTranslation();

    return (
        <div className="app flex-row align-items-center error-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7 col-lg-6 col-xl-5">
                        <div className="clearfix">
                            <div className="text-center mb-2"><img src={`${__SERVER_REPO__}/system/icon-error.svg`} alt="Error Image" style={{ width: '100%', maxWidth: '230px' }} /></div>
                            <div className="row">
                                <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                                    <h1 className="display-4 mb-0 text-center" style={{ lineHeight: '90%' }}>{t('P50x.500error')}</h1>
                                </div>
                                <div className="col-sm-9 col-md-8 col-lg-8 col-xl-9">
                                    <p className="h4 mt-0">{t('P50x.message1')}</p>
                                    <p className="text-muted">{t('P50x.message2')}</p>
                                    <Button
                                        active={true}
                                        styleName={'btn btn-light'}
                                        label={t('P50x.goToHomepageBtn')}
                                        href={''}
                                        onClick={() => onGoBack()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
