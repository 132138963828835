import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classnames from 'classnames';

import { Participant, Coach } from '../../../../../domain/models';

import { PercentageBar } from '../../../../../components/ProgressBar/PercentageBar';

export interface SelfPacedListProps {
  rowWidth: number,
  participant: Participant,
  index?: number,
  coaches?: Coach[],
  isSelected?: boolean,
  onSelect?: (participantId: number) => void,
  onUnSelect?: (participantId: number) => void,
  onClickAppoveButton: (id: number) => void
}

export const SelfPacedList: React.FunctionComponent<SelfPacedListProps> = ({ rowWidth, participant, onSelect, onUnSelect, isSelected, onClickAppoveButton }) => {
  const { t } = useTranslation();
  const [openInfo, toggleInfo] = useState(false);
  const [openLastActivities, toggleLastActivities] = useState(false);
  const [rowStyle, updateRowStyle] = useState({})
  const approveButtonStyle = participant.enrollmentStatus !== 'Pending' ? { display: 'none' } : {}
  const tableRowInfoRef = useRef(null);
  const [rowHeight, resizeRowHeight] = useState(0 as number);

  useEffect(() => {
    resizeRowHeight(tableRowInfoRef.current ? (tableRowInfoRef.current! as HTMLDivElement).offsetHeight - 2 : 0);

    const resizeHandler = () => {
      if (tableRowInfoRef && tableRowInfoRef.current) {
        resizeRowHeight((tableRowInfoRef.current! as HTMLDivElement).offsetHeight - 2);
      }
    }
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [tableRowInfoRef.current]);

  useEffect(() => {
    if (openInfo) resizeRowHeight(tableRowInfoRef.current ? (tableRowInfoRef.current! as HTMLDivElement).offsetHeight - 2 : 0);
    else resizeRowHeight(0);
  }, [openInfo, openLastActivities]);

  const handleApprove = (id: number) => () => {
    onClickAppoveButton(id)
  }

  useEffect(() => {
    isSelected ? updateRowStyle({ backgroundColor: '#f5fdff' }) : updateRowStyle({})
  }, [isSelected])

  return (
    <>

      <div className='list-table-row'>

        <div className="list-table-col" style={rowStyle}>
          <div className="small">
            <strong>{participant.name}</strong>
          </div>
        </div>

        <div className="list-table-col" style={rowStyle}>
          <div className="small">
            {((participant.progress < 1) && (participant.progress > 0)) &&
              <div className="text-info mb-2">{t('CourseManagementParticipantsPage.InProgressLbl')}</div>
            }
            {(participant.progress === 1) &&
              <div className="text-success mb-2">{t('CourseManagementParticipantsPage.CompletedLbl')}</div>
            }
            {((participant.progress === 0) && (participant.enrollmentStatus === 'Pending')) &&
              <div className="font-italic mb-2">{t('CourseManagementParticipantsPage.PendingLbl')}</div>
            }
            {((participant.progress === 0) && (participant.enrollmentStatus === 'Enrolled')) &&
              <div className="font-italic mb-2">{t('CourseManagementParticipantsPage.NotStartedLbl')}</div>
            }
            <PercentageBar height={5} width={50} percentage={participant.progress * 100} />
          </div>
        </div>

        <div className="list-table-col" style={rowStyle}>
          <div className="small">
            {
              (_.isUndefined(participant.enrollmentDate) || (_.isNull(participant.enrollmentDate))) ? t('miscelaneous.N/A') : t('dates.tiny', { valueObj: { type: 'withTZdatetime', data: participant.enrollmentDate } })
            }
          </div>
        </div>

        <div className="list-table-col" style={rowStyle}>
          <button className="btn btn-light btn-sm m-1" onClick={handleApprove(participant.id)} style={approveButtonStyle}>{t('ListTablePage.Approve')}</button>
        </div>

        <div className="list-table-col" style={rowStyle}>
          <button className="btn" style={{ fontSize: '25px' }} onClick={() => toggleInfo(!openInfo)}>
            <i className={classnames('fa', { 'fa-angle-up': openInfo, 'fa-angle-down': !openInfo })}></i>
          </button>
        </div>

      </div>

      <div className="list-table-row" style={{ height: rowHeight + 'px' }} >
        <div className="list-table-col" style={{ position: 'relative', overflow: 'visible' }}>

          <div ref={tableRowInfoRef} className={classnames('list-table-info', { 'show': openInfo })} style={{ width: (rowWidth + 1) + 'px' }} >
            <div className="list-table-info-content" style={rowStyle}>
            
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.TraineeEmail')}</strong> <a href={`mailto:${participant.email}`}><i className="fa fa-envelope"></i></a> {participant.email}</small> </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.TraineeLastEntry')}</strong> {(_.isUndefined(participant.traineeLastAccessDate) || (_.isNull(participant.traineeLastAccessDate))) ? t('miscelaneous.N/A') : t('dates.long', { valueObj: { type: 'withTZdatetime', data: participant.traineeLastAccessDate } })} </small> </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2"> <small> <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => toggleLastActivities(!openLastActivities)}>{t('CourseManagementParticipantsPage.LastActivity')} <span className={classnames('fa', { 'fa-angle-up': openLastActivities, 'fa-angle-down': !openLastActivities })}></span></span></small> </div>
                  <div className={classnames('lastActivities', { 'show': openLastActivities })}>
                    <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.Lesson')}</strong> {participant.lastLessonName}</small> </div>
                    <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.Task')}</strong> {participant.lastTaskName}</small> </div>
                    <div className="mb-2"> <small> <strong className="text-muted">{t('CourseManagementParticipantsPage.Status')}</strong> <FormatTaskStatus status={participant.lastTaskStatus} /></small> </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

const FormatTaskStatus: React.FunctionComponent<{
  status?: string;
}> = ({ status }) => {
  const { t, i18n } = useTranslation();

  if (status && i18n.exists && i18n.exists(`fromAPI.taskStatuses.${status}`)) {
    return <>{t(`fromAPI.taskStatuses.${status}`)}</>
  }

  return <></>
}
