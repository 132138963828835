import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface LoadingPageProps {
}

export const LoadingPage: React.FunctionComponent<LoadingPageProps> = () => {
    const { t } = useTranslation();

    return (
        <div className="app flex-row align-items-center" style={{minHeight:'1px'}}>
            <div className="container">
                <div className="justify-content-center text-center">
                    <div className="sk-rotating-plane"></div>
                    <p>{t('LoadingPage.loading')}</p>
                </div>
            </div>
        </div>
    );
}