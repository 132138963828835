import React from 'react'
import { useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor'

export interface ImageEditorProps {
    imageSource: string;
    visible: boolean;
    cancelButton?: boolean;
    onClose: () => void;
    onUploadImage: (image: File, completion: (response: string) => void) => void;
    preview?: string;

}

export const ImageEditor: React.FunctionComponent<ImageEditorProps> = ({ imageSource, onClose, visible, cancelButton, onUploadImage, preview }) => {

    const [zoom, updateZoom] = useState(1)
    const [previewUrl, updatepreview] = useState('')
    const imgPreviewStyle = previewUrl === '' ? { display: 'none' } : {}
    const toggledivStyle = previewUrl !== '' ? { display: 'none' } : {}
    const toggleCancelButton = (previewUrl === '' && !cancelButton) ? { display: 'none' } : {}

    let imgeditor: any = null;

    const handleZoomSlider = (value: React.ChangeEvent<any>) => {
        updateZoom(value.target.value)
    }

    const setEditorRef = (editor: any) => {
        imgeditor = editor
    }

    const onImageSaveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const canvasScaled = imgeditor.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();
        let file = dataURLtoFile(croppedImg, imgeditor.props.image);
        onUploadImage(file, (response: string) => {
            updatepreview(response.replace(/(^")|("$)/g, ''))
        })
    }

    function dataURLtoFile(dataurl: any, filename: string) {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const onImageCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        updateZoom(1)
        if (preview) {
          updatepreview('')
         } else {
          updatepreview('')
         }
        onClose()
    }

    useEffect(() => {
        if (preview) {
            updatepreview(preview)
        } else {
          updatepreview('')
        }
    }, [preview]);

    useEffect(() => {
        if (imageSource) {
            updatepreview('')
        }
    }, [imageSource]);

    return (
        <span>
            {visible &&
                <div className="imgeditordisplay" >
                    {imageSource &&
                        <div>
                            <AvatarEditor
                                image={imageSource}
                                width={250}
                                height={250}
                                scale={zoom}
                                rotate={0}
                                ref={setEditorRef}
                                style={toggledivStyle}
                            />
                        </div>
                    }
                    <div>
                        <img src={previewUrl} alt="Preview" className={"image-profile"} style={imgPreviewStyle} />
                        {imageSource &&
                            <div className="divdisplay" style={toggledivStyle}>
                                <label htmlFor="zoom" style={{ marginRight: "10px" }}>Zoom</label>
                                <input type="range" step={"0.01"} min={1} max={10} name="scale" value={zoom} onChange={handleZoomSlider} style={{ width: "100%" }}></input>
                            </div>
                        }
                      
                            <div className="btnimgeditorsave" style={{ marginTop: '10px' }}>
                            {imageSource &&
                                <button className="btn btn-light m-1" style={toggledivStyle} onClick={ onImageSaveClick}>Save </button>
                            }
                                    <button className="btn btn-light m-1" style={toggleCancelButton} onClick={onImageCancelClick} >{'Remove'}</button>
                            </div>
                      
                    </div>
                </div>
            }
        </span>
    )
}


