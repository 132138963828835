import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Buttons/Button';
import { ButtonLoader } from '../Buttons/ButtonLoader';

export interface CoachContentFeedbackProps {
    status?: string;
    locked: boolean;
    onApprove: () => void;
}

export const CoachContentFeedback: React.FunctionComponent<CoachContentFeedbackProps> = ({ status, locked, onApprove }) => {
    const { t } = useTranslation();
    
    switch (status) {
        case 'NotStarted':
            return (
                <></>   // feedback hidden
            );
        case 'WaitingForCoachApproval':
            return (
                <div className="task-summary-content">
                    {/* <div className="task-summary-icon">
                            <span className="fa fa-exclamation text-info"></span>
                        </div> */}
                    <div className="task-summary-info">
                        <p className="task-summary-title text-info"><strong>{t('ContentFeedback.waitingApproval')}</strong></p>
                        <div className="task-summary-btns">
                            {locked
                                ? (
                                    <ButtonLoader
                                        styleName={'btn btn-loader btn-info'}
                                        text={t('ContentFeedback.approveBtn')}
                                    />
                                )
                                : (
                                    <Button
                                        active={true}
                                        label={t('ContentFeedback.approveBtn')}
                                        styleName={'btn btn-info'}
                                        href={''}
                                        onClick={() => {
                                            if (confirm(t('ContentFeedback.approveBtn')))
                                                onApprove();
                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>

            );
        case 'Finished':
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-check text-success"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-success"><strong>{t('ContentFeedback.taskCompleted')}</strong></p>
                    </div>
                </div>
            );
        default:
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-close text-danger"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-danger"><strong>(unknown status)</strong></p>
                    </div>
                </div>
            );
    }
}
