import * as React from 'react';
import { useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { AdminGroup, Course } from '../../../domain/models';

import { MembersMgmtContext } from './MembersMgmtContext';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { AdminGroupHeader } from './components/main/AdminGroupHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { GroupInfo } from './components/tab0/GroupInfo';
import { GroupCoursesList } from './components/tab1/GroupCoursesList';
import { GroupMembersList } from './components/tab2/GroupMembersList';

export interface GroupManagementPageRouteParameters {
    groupId: number;
    tabId:number;
}

export interface GroupManagementPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    tabId:number;
}

type State = {
    apiError?: any | undefined;
    adminGroup?: AdminGroup;
    courses?: Course[];
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadAdminGroup(adminGroup: AdminGroup): State;
    LoadGroupCourses(courses: Course[]): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadAdminGroup: adminGroup => ({ ...state, adminGroup }),
    LoadGroupCourses: courses => ({ ...state, courses }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const GroupManagementPage: React.FunctionComponent<GroupManagementPageProps> = ({ auth, onLogout, groupId,tabId }) => {
    const { state: { apiErrorMember, membersList } } = useContext(MembersMgmtContext);
    const { t } = useTranslation();
    const [{ apiError, adminGroup, courses, tabIndex }, actions] = wrapperReducer(useReducer(reducer, { tabIndex:Number(tabId)}));

    const goToRoot = () => Router.push('/');
    const goToMyGroups = () => Router.push('/mygroups');

    useLoadAdminGroup(auth, groupId, actions);

    if (apiError || apiErrorMember) return <P50x onGoBack={goToRoot} />
    if (!adminGroup ) return <LoadingPage />;

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myGroups'), onClick: goToMyGroups },
                { text: `${adminGroup.name}`, active: true }
            ]}
            onLogout={onLogout}
        >

            <AdminGroupHeader
                groupName={adminGroup.name}
                userCount={adminGroup.userCount}
                creationDate={adminGroup.creationDate}
            />

            <PageNav>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 0 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(0))}>
                        {t('GroupManagementPage.tab0')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 1 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(1))}>
                        {t('GroupManagementPage.tab1')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 2 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(2))}>
                        {t('GroupManagementPage.tab2')}
                    </a>
                </li>
            </PageNav>

            {tabIndex === 0 && (
                <GroupInfo
                    content={adminGroup.body || ''}
                    groupId={groupId}
                    onClickViewCourses={() => actions.send(a => a.ChangeTabIndex(1))}
                />
            )}

            {tabIndex === 1 && (
                <GroupCoursesList groupId={groupId}/>
            )}

            {tabIndex === 2 && (
                <GroupMembersList groupId={groupId} />
            )}

        </BaseAppLayout>
    );
}

function useLoadAdminGroup(auth: Auth, groupId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const adminGroup = await services.getAdminGroup(auth, groupId);
            if (isRequestError(adminGroup)) {

            }
            else {
                actions.send(a => a.LoadAdminGroup(adminGroup));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}


