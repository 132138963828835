import * as React from 'react';
import { useEffect } from 'react';

import * as Router from '../api/Router';

export interface DelayPageRouteParameters {
    seconds: number;
}

export interface DelayPageProps {
    seconds: number;
}

export const DelayPage: React.FunctionComponent<DelayPageProps> = ({ seconds }) => {
    const goToRoot = () => Router.push('/');

    useEffect(() => {
        const timeoutId = window.setTimeout(goToRoot, seconds * 1000);
        return () => window.clearTimeout(timeoutId);
    }, []); // NOTE: componentDidMount

    return (<></>)
}
