
import * as React from 'react';
import { Lesson } from '../../../../domain/models';

export interface LessonHeaderPanelProps {
    lessons: Lesson[];
    lesson: Lesson;
}
export const LessonHeaderPanel: React.FunctionComponent<LessonHeaderPanelProps> = ({ lessons, lesson }) => {
    let currentIndex = lessons.findIndex(lessons => lessons.name === lesson.name)
    let index = currentIndex + 1

    return (
        <div>
            <p className="small task-execution-description mb-0">
                Lesson {index}
            </p>
            <h1 className="task-execution-title h3 mb-4">
                {lesson.name}
            </h1>

        </div>
    );
}