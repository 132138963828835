import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import _ from 'lodash';

export interface ApprovePendingSelfPacedParticipantsPopupProps {
    message?: string;
    isVisible: boolean;
    onClose: () => void;
    onApprove: () => void;

}

export const ApprovePendingSelfPacedParticipantsPopup: React.FunctionComponent<ApprovePendingSelfPacedParticipantsPopupProps> = ({ message, onClose, onApprove, isVisible }) => {
    const { t } = useTranslation();
   
    return (
        <span >
               <Modal open={isVisible} onClose={() => onClose()}>
              <br/>
              
                <p>
                    {message}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onClose()}>{t('miscelaneous.cancel')}</button>
                    <button type="button" className="btn btn-primary"  onClick={() => onApprove()}>{t('miscelaneous.yes')}</button>

                </div>
            </Modal>
        </span>
    );
}
