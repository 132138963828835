import * as React from 'react';
import { Coach } from '../../domain/models';

export interface BadgeFilterProps {
  label: string,
  coach?: Coach,
  value?: string,
  onCloseCoachBadge?: (coach: Coach) => void,
  onCloseFilterBadge?: (filter: string) => void,
}

export const BadgeFilter: React.FunctionComponent<BadgeFilterProps> = ({ label, coach, onCloseCoachBadge, onCloseFilterBadge, value }) => {
const closeButtonHandler = () => {
    if(coach && onCloseCoachBadge) {
      onCloseCoachBadge(coach)
    } else if(onCloseFilterBadge) {
      value ?  onCloseFilterBadge(value) :  onCloseFilterBadge(label)
    }
}
  return(
    <span className="badge badge-pill badge-filter">
      <button className="badge-filter-btn btn" onClick={closeButtonHandler}><i className="fa fa-close" /></button> {label} 
  </span>
  );  
}