import * as React from 'react';
import _ from 'lodash';
import { Question, Task } from '../../../../../domain/models';
import { QuizAnswerField } from '../../../../../components/Quiz/QuizAnswerField';
import { SurveyDropdownI } from '../../../../../components/Survey/SurveyDropdownI';
import { QuizDropdownI } from '../../../../../components/Quiz/QuizDropdownI';
import { CheckboxI } from '../../../../../components/Forms/CheckboxI';
import { RadioboxI } from '../../../../../components/Forms/RadioboxI';
import { useTranslation } from 'react-i18next';

export interface QuestionOptionsPreviewProps {
    index: number;
    showOptions?: number;
    question: Question;
    content: Task;
    type: string;
}

export const QuestionOptionsPreview: React.FunctionComponent<QuestionOptionsPreviewProps> = ({
    index,
    showOptions,
    question,
    content,
    type
}) => {
    const { t } = useTranslation();
    const bindLinearScaleData = (minValue: number, maxValue: number) => {
        const items = [<th scope="col" className="table-header-font-weight">
        </th>]
        for (let i = minValue; i <= maxValue; i++) {
            items.push(<th className="table-header-font-weight" key={i} scope="col">{i}</th>)
        }
        return items;
    }
    const bindLinearScaleControls = (minValue: number, maxValue: number) => {
        const itemsControl = []
        for (let i = minValue; i <= maxValue; i++) {
            itemsControl.push(<td><RadioboxI
                active={false}
                id={'uniqueId'}
                value={''}
                label={''}
                isSelected={false}
                isValid={false}
                isInvalid={false}
                onChange={() => { }} /></td>)
        }
        return itemsControl;
    }

    return (
        <div className="lessons-builder-task-questions-info">
            {!_.isUndefined(showOptions) && showOptions === index &&
                <div className="lessons-builder-task-questions-content">
                    {(question.type.toLowerCase() === 'singlelinetext' &&
                        content.type.toLowerCase() === 'quiz') &&
                        <QuizAnswerField
                            index={0}
                            active={false}
                            placeholder={''}
                            value={question.expectedAnswer as string}
                            isValid={false}
                            isInvalid={false}
                            onChange={() => { }}
                        />
                    }
                    {(question.type.toLowerCase() === 'dropdown' &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform')) &&
                        question.options &&
                        <SurveyDropdownI options={question.options} />
                    }
                    {(question.type.toLowerCase() === 'dropdown' &&
                        content.type.toLowerCase() === 'quiz') &&
                        question.options &&

                        <QuizDropdownI options={question.options}
                            expectedOptions={question.expectedOptions!} />
                    }
                    {(question.type.toLowerCase() === 'checkbox' &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform')) &&
                        question.options && question.options.map((options, index) => (

                            <CheckboxI
                                active={false}
                                id={'uniqueId'}
                                value={'someOptionId'}
                                label={options.body}
                                isChecked={false}
                                isValid={false}
                                isInvalid={false}
                                onChange={() => { }}
                            />

                        ))}
                    {(question.type.toLowerCase() === 'checkbox' &&
                        content.type.toLowerCase() === 'quiz') &&
                        question.options &&
                        question.options.map((options, i) => (
                                <CheckboxI
                                    key={i}
                                    active={false}
                                    id={'uniqueId'}
                                    value={'someOptionId'}
                                    label={options.body}
                                    isChecked={question.expectedOptions!.filter((val) => val === options.id).length > 0}
                                    isValid={false}
                                    isInvalid={false}
                                    onChange={() => { }}
                                />
                        ))}
                    {(question.type.toLowerCase() === 'radiobox' &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform')) &&
                        question.options &&
                        question.options.map((options, index) => (

                            <RadioboxI
                                active={false}
                                id={'uniqueId'}
                                value={'someOptionId'}
                                label={options.body}
                                isSelected={false}
                                isValid={false}
                                isInvalid={false}
                                onChange={() => { }}
                            />

                        ))}
                    {(question.type.toLowerCase() === 'radiobox' &&
                        (content.type.toLowerCase() === 'quiz')) &&
                        question.options &&
                        question.options.map((options, index) => (
                            question.expectedOptions &&
                            question.expectedOptions.map((expectedoption, index) => (

                                <RadioboxI
                                    active={false}
                                    id={'uniqueId'}
                                    value={'someOptionId'}
                                    label={options.body}
                                    isSelected={expectedoption === options.id ? true : false}
                                    isValid={false}
                                    isInvalid={false}
                                    onChange={() => { }}
                                />

                            ))
                        ))}
                    {(question.type.toLowerCase() === 'radioboxgrid' ||
                        question.type.toLowerCase() === 'checkboxgrid') &&
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">
                                    </th>
                                    {(question.type.toLowerCase() === 'radioboxgrid' ||
                                        question.type.toLowerCase() === 'checkboxgrid') &&
                                        question.options &&
                                        question.options.map((options, index) => (
                                            <th scope="col" className="table-header-font-weight">{options.body}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>

                                {(question.type.toLowerCase() === 'radioboxgrid' ||
                                    question.type.toLowerCase() === 'checkboxgrid') &&
                                    question.subQuestions &&
                                    question.subQuestions.map((subQuestion, index) => (
                                        <tr>
                                            <th scope="row" className="table-header-font-weight">{subQuestion.body}</th>
                                            {question.options &&
                                                question.options.map((options, index) => (
                                                    <td>
                                                        {question.type.toLowerCase() === 'checkboxgrid' &&
                                                            <CheckboxI
                                                                active={false}
                                                                id={'uniqueId'}
                                                                value={'someOptionId'}
                                                                label={''}
                                                                isChecked={type.toLowerCase() === 'quiz' &&
                                                                    subQuestion.expectedOptions!.findIndex((val) => val === options.id) !== -1}
                                                                isValid={false}
                                                                isInvalid={false}
                                                                onChange={() => { }}
                                                            />
                                                        }
                                                        {question.type.toLowerCase() === 'radioboxgrid' &&
                                                            <RadioboxI
                                                                active={false}
                                                                id={'uniqueId'}
                                                                value={''}
                                                                label={''}
                                                                isSelected={type.toLowerCase() === 'quiz' &&
                                                                    subQuestion.expectedOptions!.findIndex((val) => val === options.id) !== -1}
                                                                isValid={false}
                                                                isInvalid={false}
                                                                onChange={() => { }}
                                                            />
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>))}
                            </tbody>
                        </table>
                    }

                    {(question.type.toLowerCase() === 'linearscale') &&
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    {bindLinearScaleData(question.minValue!, question.maxValue!)}
                                </tr>
                            </thead>
                            <tbody>
                                {question.type.toLowerCase() === 'linearscale' &&
                                    <tr>
                                        <td className="table-header-font-weight">{question.minValueLabel}</td>
                                        {bindLinearScaleControls(question.minValue!, question.maxValue!)}
                                        <td className="table-header-font-weight">{question.maxValueLabel}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            }
        </div>
    );
}