export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

function arrayMatch<T>(pattern: string[], source: string[]): T | null {
    if (pattern.length != source.length) {
        return null;
    }

    let values = {} as any;

    for (let i = 0; i < pattern.length; i++) {
        if (pattern[i].startsWith(':')) {
            values[pattern[i].substr(1)] = source[i];
        } else if (pattern[i] != source[i]) {
            return null;
        }
    }

    return values;
}

export function matchUrl(source: string[], patterns: [string[], Function][], otherwise: JSX.Element | null) {
    for (let i = 0; i < patterns.length; i++) {
        const [arr, fn] = patterns[i];
        const doMatch = arrayMatch(arr, source);
        if (doMatch != null) {
            return fn(doMatch);
        }
    }
    return otherwise;
}

export const toggleArrayItem = function (array: number[] | undefined, value: number): number[] {
    let copyArr = array ? array.slice() : [];
    let i = array ? array.indexOf(value) : -1;
    (i === -1) ? copyArr.push(value) : copyArr.splice(i, 1);
    return copyArr;
}

export const scrollToPosition = function (x: number, y: number): boolean {
    window.scrollTo(x, y);
    return true;
}

export const isDateExpired = function (expirationDate: string): boolean {
    const exp = Date.parse(expirationDate); // RFC2822 or ISO8601 formats
    if (!Number.isNaN(exp)) {
        const now = Date.now();
        return ((now + 10000) - exp) > 0;   // NOTE: 10 seconds margin to prevent RTT inconsistencies
    }
    return true;
}

export const richEditorModuleOptionsBasic =   {
  toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'blockquote', 'image', 'video'],
      [{ 'header': [1, 2, false] }],
  ]
  ,
  clipboard: {
      matchVisual: false,
  }
}
export const isExpired = function (expiration: number): boolean {
    if (typeof expiration === 'number') {
        const now = Date.now();
        return ((now + 10000) - expiration) > 0;   // NOTE: 10 seconds margin to prevent RTT inconsistencies
    }
    return true;
}
