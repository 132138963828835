import * as React from 'react';

import { Lesson } from '../../domain/models';

export const showLessonPanel = function (lesson: Lesson): boolean {
    switch (lesson.status.toLowerCase()) {
        case 'notstarted':
            return !!lesson.tasks && lesson.tasks.filter((task) => (task.isEnabled)).length > 0;
        case 'inprogress':
            return true;
        case 'finished':
            return !!lesson.tasks && lesson.tasks.filter((task) => (task.status !== 'Finished' && task.status !== 'Passed')).length > 0;
    }
    return false;
}

export const highlightTask = function (isRequired: boolean | undefined, isEnabled: boolean | undefined, status: string, assignedToCoach: boolean, isCoach?: boolean): boolean {
    if (!isCoach) {
        if (!assignedToCoach && !!isRequired && !!isEnabled && (status === 'NotStarted' || status === 'Saved' || status === 'NotScheduled')) return true;
    } else {
        if (assignedToCoach && !!isRequired && !!isEnabled && (status === 'NotStarted' || status === 'Saved' || status === 'NotScheduled')) return true;
        if (!assignedToCoach && (status === 'WaitingForCoachApproval' || !!isRequired && !!isEnabled && status === 'NotScheduled')) return true;   // (TODO) remove NotScheduled, temporary workaround for Meeting (pending Zoom integration)
    }

    return false;
}

export const getTaskTitle = function (type: string, assignedToCoach: boolean, label: string): JSX.Element {
    switch (type.toLowerCase()) {
        case 'content':
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0 ${assignedToCoach ? 'text-info' : ''}`}>
                        <i className="icon-doc list-tasks-icon"></i>{'  '}
                        {assignedToCoach ? <strong>{label}</strong> : label}
                    </p>
                </div>
            );
        case 'editableform':
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0 ${assignedToCoach ? 'text-info' : ''}`}>
                        <i className="icon-note list-tasks-icon"></i>{'  '}
                        {assignedToCoach ? <strong>{label}</strong> : label}
                    </p>
                </div>
            );
        case 'meeting':
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0 ${assignedToCoach ? 'text-info' : ''}`}>
                        <i className="icon-calendar list-tasks-icon"></i>{'  '}
                        {assignedToCoach ? <strong>{label}</strong> : label}
                    </p>
                </div>
            );
        case 'quiz':
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0 ${assignedToCoach ? 'text-info' : ''}`}>
                        <i className="icon-question list-tasks-icon"></i>{'  '}
                        {assignedToCoach ? <strong>{label}</strong> : label}
                    </p>
                </div>
            );
        case 'survey':
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0 ${assignedToCoach ? 'text-info' : ''}`}>
                        <i className="icon-chart list-tasks-icon"></i>{'  '}
                        {assignedToCoach ? <strong>{label}</strong> : label}
                    </p>
                </div>
            );
        case 'video':
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0 ${assignedToCoach ? 'text-info' : ''}`}>
                        <i className="icon-camrecorder list-tasks-icon"></i>{'  '}
                        {assignedToCoach ? <strong>{label}</strong> : label}
                    </p>
                </div>
            );
        default:
            return (
                <div className={`list-tasks-title`}>
                    <p className={`mb-0`}>
                        <i className="icon-layers list-tasks-icon"></i>{'  '}
                        {'Unknown Task Type'}
                    </p>
                </div>
            )
    }
}
