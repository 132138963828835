import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';

import { Coach } from '../../../../../domain/models';

export interface DesignateConfirmPopupProps {
    isVisible: boolean;
    icon?: string,
    coachList?: Coach[],
    coachName?: any;
    onClickDesignate?: () => void;
    onClose: () => void;
}

export const DesignateConfirmPopup: React.FunctionComponent<DesignateConfirmPopupProps> = ({ onClose, isVisible, onClickDesignate }) => {
    const { t } = useTranslation();

    const onCloseConfirmModal = (open: any) => {
        onClose()
    }

    const designateCoachHandler = () => {
        if (onClickDesignate) {
            onClickDesignate()
        }
    }

    useEffect(() => {
        if (!isVisible) {
            onCloseConfirmModal(false)
        }
    }, [isVisible])

    return (
        <span className="coach-trainee-popup">

            <Modal open={isVisible} onClose={() => onCloseConfirmModal(false)}>
                <br />
                <p>{t('DesignateConfirmPopup.confirmMsg')}</p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onCloseConfirmModal(false)}>{t('miscelaneous.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={designateCoachHandler}>{t('miscelaneous.yes')}</button>
                </div>
            </Modal>
        </span>
    );
}
