import * as React from 'react';

export interface TextAreaProps {
    active: boolean;
    rows?: number;
    cols?: number;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (typedValue: string) => void;
}

export const TextArea: React.FunctionComponent<TextAreaProps> = ({ active, rows, cols, placeholder, value, isValid, isInvalid, onChange }) => {
    return (
        <textarea
            className={"form-control " + (isValid ? 'is-valid' : '') + (isInvalid ? 'is-invalid' : '')}
            disabled={!active}
            rows={rows || 5}
            cols={cols || 50}
            maxLength={4000}
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
        />
    );
}