import * as React from "react";
import { CardViewType } from "../../../../../components/Cards/CardSection";
import { TraineeStatusCard } from "./TraineeStatusCard";
import { Trainee } from "../../../../../domain/models";
import { SearchInput } from "../../../../../components/Search/SearchInput";
import { useTranslation } from "react-i18next";
import { TraineeFilterDropDown } from "./TraineeFilterDropDown";
import { TraineeSorterDropDown } from "./TraineeSorterDropDown";
import { ActionDispatcher } from "../../../../../api/react-helper";
import { Action, State } from "../..";
import { BadgeFilter } from "../../../../../components/Badges/BadgeFilter";
import { TraineePagination } from "./TraineePagination";

export interface TraineeListProps {
  courseId: number;
  trainees: Trainee[];
  traineeStatusFilter: string[];
  activePage: number;
  resultsPerPage: number;
  onLessonClick: (
    traineeId: number,
    lessonId: number,
    taskId: number,
    tasktype: string
  ) => void;
  actions: ActionDispatcher<Action, State>
}

export const TraineeList: React.FunctionComponent<TraineeListProps> = ({
  courseId,
  trainees,
  onLessonClick,
  traineeStatusFilter,
  activePage,
  resultsPerPage,
  actions
}) => {
  const { t } = useTranslation();
  const traineeFilterLabels: any = { finished: t('CourseManagementParticipantsPage.CompletedLbl'), inProgress: t('CourseManagementParticipantsPage.InProgressLbl'), notStarted: t('CourseManagementParticipantsPage.NotStartedLbl') }

  return (
    <>
      <div className="mt-3 mt-md-4">
        <div className="container-fluid">
          <div className="list-page-actions">
            <div className="list-page-search">
              <SearchInput
                active={true}
                placeholder={"Search for trainee"}
                value={""}
                isValid={false}
                isInvalid={false}
                onChange={(searchString) => {actions.send(a => a.SetSearchString(searchString))}}
                onFocus={() => ""}
                onBlur={() => ""}
              />
            </div>
            <div className="list-page-controls hasAddBtn">
              <TraineeFilterDropDown
                label={t("CourseManagementParticipantsPage.filtersDd")}
                styleName="btn-outline-light bg-white btn-lg"
                icon="fa fa-filter"
                traineeStatusFilter={traineeStatusFilter}
                changeFilter={(filter) => {actions.send( a => a.SetTraineeStatusFilter(filter))}}
              />
              <TraineeSorterDropDown
                label={t("GroupMembersList.sorterDd")}
                styleName="btn-outline-light bg-white btn-lg"
                icon="fa fa-sort-amount-asc"
                changeSorter={(sorter) => {actions.send( a => a.SetTraineeSorter(sorter))}}
              />

            </div>
          </div>
          <div className="list-page-filters display-flex">
                    <div className="list-page-badges">
                        {traineeStatusFilter &&
                            traineeStatusFilter.map((filter, i) => (
                                <BadgeFilter key={i} value={filter} label={traineeFilterLabels[filter]} onCloseFilterBadge={(filter) => {
                                  actions.send( a => a.SetTraineeStatusFilter(traineeStatusFilter.filter(item => item !== filter)))
                                }} />
                            ))}
                    </div>

                </div>

          <div className="row">
            { trainees.map((trainee, i)=>
                  <TraineeStatusCard
                  key={i}
                  cardViewType={CardViewType.List}
                  name={trainee.name}
                  email={trainee.email}
                  startDate={trainee.startDate}
                  traineeLastAccessDate={trainee.traineeLastAccessDate}
                  coachLastAccessDate={trainee.coachLastAccessDate}
                  finishDate={trainee.finishDate}
                  progress={trainee.progress}
                  lastLessonName={trainee.lastLessonName}
                 lastTaskAssignedTo={trainee.lastTaskAssignedTo}
                  lastTaskName={trainee.lastTaskName}
                  lastTaskStatus={trainee.lastTaskStatus}
                  courseId={courseId}
                  traineeId={trainee.id}
                  onNavigateToLessonsClick={onLessonClick}
                />
                )
            }
          </div>
        </div>
        <div className="list-page-footer">
                <TraineePagination 
                  resultsCount={trainees.length} 
                  activePage={activePage} 
                  resultsPerPage={resultsPerPage} 
                  changePage={(page) => actions.send( a => a.SetActivePage(page))}
                />
            </div>
      </div>
    </>
  );
};
