import * as React from 'react';
import { useReducer, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import * as Router from '../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../api/react-helper';
import { Auth, isRequestError } from '../../domain/_shared';
import * as services from '../../domain/services';
import { AdminCourse, Member } from '../../domain/models';

import { P50x } from '../../components/P50x';
import { LoadingPage } from '../../components/LoadingPage';
import { BaseAppLayout } from '../../layouts/shared/BaseAppLayout';
import { ButtonBack } from '../../components/Buttons/ButtonBack'
import { SearchBlock } from '../../components/Search/SearchBlock';
import { ErrorPopUp } from '../../components/Popup/ErrorPopUp';

import { RequestList } from './CourseManagementPage/components/tab2/DesignateCoachPopup'; // (TODO) fix weird Type

export interface CourseAddTraineePageRouteParameters {
    groupId: number;
    courseId: number;
    tabId: number;
}

export interface CourseAddTraineePageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    initial?: string;
    inputPattern?: string;
    throttle_ms?: number;
    tabId: number;
}

type State = {
    apiError?: any | undefined;
    course?: AdminCourse;
    members?: Member[];
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: AdminCourse): State;
    LoadTraineeCandidates(members: Member[]): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadTraineeCandidates: members => ({ ...state, members }),
})

export const CourseAddTraineePage: React.FunctionComponent<CourseAddTraineePageProps> = ({ auth, onLogout, groupId, courseId, initial, inputPattern, throttle_ms,tabId }) => {
    const { t } = useTranslation();
    const [{ apiError, course, members }, actions] = wrapperReducer(useReducer(reducer, {}));
    const [value, setValue] = useState(initial);
    const [traineeList, updateTraineeList] = useState([] as Array<any>);
    const [searchValue, setSearchValue] = useState("" as string);
    const [result, setResult] = useState([] as Array<any>);
    const [popUpErrorMsg, toggleErrorPopUp] = useState('')

    useLoadCourse(auth, courseId, actions);

    let dispatchedRequest = 0

    const getResults = async function (searchString: string): Promise<any> {
        try {
            const membersdata = await services.getTraineeCandidates(auth, courseId, searchString);
            const members = membersdata.results;
            const data = members;
            const search = (!!searchString) ? matchSearch(data, searchString) : []
            setResult(search)
            if (isRequestError(membersdata)) {
            } else {
                actions.send(a => a.LoadTraineeCandidates(members));
            }
            return search;
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }

    }

    const matchSearch = function (data: Array<any>, searchString: string): Array<any> {
        return data.filter((item) => {
            const v = item["email"] as string;
            const l = item["name"] as string;
            return (v.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                || (l.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);

        });
    }

    const onClickResult = (value: any) => {
        updateTraineeList(value)
        value.active = false;
        updateTraineeList([...traineeList, value])
        let index = result.indexOf(value, 0)
        let list = [...result];
        result[index].active = false;
    }

    // const addTraineeHandler = (value: any) => {
    //         addTrainees(auth, courseId, traineeList, actions);

    // }

    const onDelete = (value: any) => {
        let list = [...traineeList];
        let searchList = [...result]
        let searchItem = _.find(searchList, { email: value });
        let searchItemIndex = searchList.indexOf(searchItem, 0)
        searchList[searchItemIndex].active = true;
        _.remove(list, { email: value });
        updateTraineeList(list)
    }

    const handleRequests = (failedRequests: number[]) => {
        if (failedRequests.length > 0) {
            const errorMsg = t('CourseManagementParticipantsPage.errorPopupMsg', { failedRequests: failedRequests.length })
            toggleErrorPopUp(errorMsg)
        } else {
            Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)
        }
    }

    const onCloseErrorHandler = () => {
        toggleErrorPopUp('')
        Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)
    }

    const addTraineeHandler = (value: any) => {
        AddTrainee(auth, courseId, traineeList, handleRequests)
    }

    useEffect(() => {
        getResults(searchValue).then((r) => setResult(r));
    }, [searchValue]);

    if (apiError) return <P50x onGoBack={() => Router.push('/')} />
    if (!course || !members) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[{
                text: t('Breadcrumbs.home'), href: 'javascript:void(0)', onClick: () => Router.push('/')
            }, {
                text: t('Breadcrumbs.myGroups'), href: 'javascript:void(0)', onClick: () => Router.push('/mygroups')
            }, {
                text: `${course.groupName}`, href: 'javascript:void(0)', onClick: () => Router.push(`/groups/${groupId}/admin/0`)
            }, {
                text: `${course.name}`, href: 'javascript:void(0)', onClick: () => Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)
            }, {
                text: t('Breadcrumbs.addTrainee'), href: 'javascript:void(0)', active: true
            }]}
            onLogout={onLogout}
        >

            <div>
                <div className="main">
                    <div className="container-fluid">
                        <div className="header-page hasBtnBack hasInfo">
                            <ButtonBack text={t('AddTraineeCoachPage.goBack')} onClick={() => history.back()} />
                            <h1 className="h4 header-page-title title-color">{t('AddTraineeCoachPage.AddTrainee')}</h1>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">

                                <div className="list-page">

                                    <div className="list-page-header">
                                        <div className="list-page-search">
                                            <SearchBlock
                                                active={true}
                                                searchPlaceholder={t('AddTraineeCoachPage.searchInputPlaceholder')}
                                                inputPattern={'(someRegex)'}
                                                searchString={value}
                                                throttle_ms={500}
                                                inactiveItemPlaceholder={t('AddTraineeCoachPage.addedPlaceholder')}
                                                searchResult={result}
                                                emptyResultsMesssage={t('SearchBlock.emptyResultsMsg')}
                                                onChange={(tV) => setValue(tV)}
                                                onThrottledChange={(sV) => setSearchValue(sV)}
                                                onClickResult={onClickResult.bind(value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="list-page-body">
                                        <div className="table-group">
                                            <div className="list-table hasDropDown fromSm">

                                                {traineeList.map((trainee, i) => (
                                                    <div key={i} className="list-table-row">
                                                      <div className="list-table-col">
                                                          <div className="">
                                                              <strong>{trainee.name}</strong>
                                                          </div>
                                                      </div>
                                                      <div className="list-table-col">
                                                          <div className="small">
                                                              {trainee.email}
                                                          </div>
                                                      </div>
                                                      <div className="list-table-col">
                                                        <div className="list-table-action">
                                                          <button className="btn text-danger" onClick={() => { onDelete(trainee.email) }}>
                                                              <i className="fa fa-trash"></i>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-page-footer">
                                        <div className={`${traineeList.length == 0 ? 'display-none' : 'table-group-footer mt-2 text-right'}`}>
                                            <button className="btn btn-light m-1" onClick={() => Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)} >{t('miscelaneous.cancel')}</button>
                                            <button className="btn btn-primary m-1" onClick={addTraineeHandler}>{t('AddTraineeCoachPage.AddTrainees')}</button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ErrorPopUp isVisible={!_.isEmpty(popUpErrorMsg)} onClose={onCloseErrorHandler} message={popUpErrorMsg} />
        </BaseAppLayout>
    );
}

function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getAdminCourse(auth, courseId);
            if (isRequestError(course)) {
            }
            else {
                actions.send(a => a.LoadCourse(course));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

const AddTrainee = async (auth: Auth, courseId: number, traineeList: Array<any>, handleRequests: (failedRequests: number[]) => void) => {

    let dispatchedRequest = 0
    let failedRequests: number[] = []
    let addCoachRequestList: RequestList[] = []

    try {
        for (let i = 0; i < traineeList.length; i++) {
            const request = services.addTrainee(auth, courseId, traineeList[i].id)
            addCoachRequestList.push({ promise: request, traineeID: traineeList[i].id })
        }
        addCoachRequestList.forEach((request) => {
            request.promise
                .then(() => {
                    dispatchedRequest = dispatchedRequest + 1;
                    if (dispatchedRequest >= traineeList.length) {
                        handleRequests(failedRequests)
                    }
                })
                .catch((error) => {
                    dispatchedRequest = dispatchedRequest + 1;
                    failedRequests.push(request.traineeID)
                    if (dispatchedRequest >= traineeList.length) {
                        handleRequests(failedRequests)
                    }
                })
        })
    } catch (error) {
        return;
    }

}
