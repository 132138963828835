import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Sources, Delta } from 'quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import { HeaderBlot } from '../../domain/utils/quill-header';
import { ImageResize } from '../../domain/utils/QuillImageEditor';
import { RichEditorImageUploader } from './RichEditorImageUploader';
import { VideoBlot } from '../../domain/utils/quill-video-editor';

Quill.register(VideoBlot)
Quill.register(HeaderBlot )
Quill.register('modules/ImageResize', ImageResize);
Quill.debug(false)

export interface RichTextEditorProps {
  value: string;
  theme:string;
  options?: any;
  placeholder?:string;
  onChange: (val: string) => void;
  onUploadImage: (image: File, completion: (response: string) => void) => void;
}
type firstChild = {
  firstChild: HTMLElement
}
interface QuillEditor extends Quill {
  container?: firstChild
}

const textAreaStyle = "width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none"
let quill: QuillEditor
let reactQuillRef: ReactQuill

export const RichTextEditor: React.FunctionComponent<RichTextEditorProps> = ({ value,theme, options, onChange, onUploadImage,placeholder}) => {
 
  let txtArea = document.createElement('textarea')

  const [isFileUploadVisible, toggleFileUpload] = useState(false)
  const [componentDidMount, updateComponent] = useState(false)
  const [initialValueUpdated, toggleInitialValue] = useState(false)


  const handleContentChange = (
    content: string,
    delta: Delta,
    source: Sources,
    editor: any
  ): void => {

    
    // don't trigger updates if the source of changes is not the user
    if (!onChange || source !== 'user') {
      if (!initialValueUpdated) {
        setTimeout(() => {
          toggleInitialValue(true)
        }, 100)
        return
      } 
      return;
    }

    const text = editor.getText() as string
    if (!text) {
      content = '';
    }
    onChange(content);
  
  };

  const imageHandler = async (image: File) => {
    const selection = quill.getSelection()
    let position = selection ? selection.index : 0;
    toggleFileUpload(false)
    onUploadImage(image, (response: string) => {
      quill.insertEmbed(position, 'image', response);
      onChange(quill.root.innerHTML)
    })
  }

  // setting the value to the innerHTML on load
  useEffect(() => {
    if(initialValueUpdated) if(quill) quill.container!.firstChild.innerHTML = value 
  },[initialValueUpdated])

  // Adding the source switch options
  useEffect(() => {
    if (reactQuillRef) {
      quill = reactQuillRef.getEditor()      
      quill.getModule('toolbar').addHandler('image', () => toggleFileUpload(true))
      if (!componentDidMount) {

        txtArea.style.cssText = textAreaStyle
        txtArea.addEventListener('blur', function () {
          onChange(txtArea.value)
        })

        let htmlEditor = quill.addContainer('ql-custom')
        htmlEditor.appendChild(txtArea)
        quill.on('text-change', () => {
          let html = quill.root.innerHTML
          txtArea.value = html
        })
        let customButton = document.querySelector('.ql-showHtml');
        if(!options) {
          customButton!.addEventListener('click', function () {
            if (txtArea.style.display === '') {
              let html = txtArea.value
              quill.container!.firstChild.innerHTML = html
            }
            txtArea.style.display = txtArea.style.display === 'none' ? '' : 'none'
          });
        }
       
      }
    }
    updateComponent(true)
  })

  const modulesOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link', 'blockquote', 'image','video'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ align: 'center' }, { align: 'right' }, { align: 'justify' }, { align: '' }],
      [{ 'header': [1, 2,false] }],
      ['showHtml']
    ]
    ,
    clipboard: {
      matchVisual: false,
    },
    ImageResize: {
      displaySize: true
    }
    
  }

  const formats = [
    'bold', 'italic', 'underline', 'blockquote', 'link', 'image','header','video',
    'list', 'ordered', 'bullet', 'align'
  ]



  return (
    <div className="col-md-12" id="editor" style={{padding:"0"}}>
      <ReactQuill className="quill-height"
      theme={theme}
        ref={(val) => { reactQuillRef = val! }}
        onChange={handleContentChange}
        value={value || ''}
        modules={options || modulesOptions}
        formats={formats}
        placeholder={placeholder}
      />
      <RichEditorImageUploader isVisible={isFileUploadVisible} onClose={() => { toggleFileUpload(false) }} uploadedFiles={imageHandler} />
    </div>
  )

}
