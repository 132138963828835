import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface TraineeMeetingFeedbackProps {
    status?: string;

    taskIsEnabled: boolean;     // (TODO) remove temporary workaround (after Zoom integration)
}

export const TraineeMeetingFeedback: React.FunctionComponent<TraineeMeetingFeedbackProps> = ({ status, taskIsEnabled }) => {
    const { t } = useTranslation();

    switch (status) {
        case 'NotScheduled':
            return (
                (!taskIsEnabled)
                    ? (<></>)   // feedback hidden
                    : (
                        <div className="task-summary-content">
                            {/* <div className="task-summary-icon">
                                <span className="fa fa-exclamation text-info"></span>
                            </div> */}
                            <div className="task-summary-info">
                                <p className="task-summary-title text-info"><strong>{t('MeetingFeedback.waitingApproval')}</strong></p>
                            </div>
                        </div>
                        // (TODO) need to fix states
                    )
            );
        case 'WaitingForCoachApproval':
            return (
                <div className="task-summary-content">
                    {/* <div className="task-summary-icon">
                                <span className="fa fa-exclamation text-info"></span>
                            </div> */}
                    <div className="task-summary-info">
                        <p className="task-summary-title text-info"><strong>{t('MeetingFeedback.waitingApproval')}</strong></p>
                    </div>
                </div>
            );
        case 'Finished':
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-check text-success"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-success"><strong>{t('MeetingFeedback.taskCompleted')}</strong></p>
                    </div>
                </div>
            );
        default:
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-close text-danger"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-danger"><strong>(unknown status)</strong></p>
                    </div>
                </div>
            );
    }
}
