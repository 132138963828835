import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import {  AdminGroup } from '../../../domain/models';
import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { DraftGroupInfo } from './components/DraftGroupInfo';

export interface GroupEditionPageRouteParameters {
    groupId: number;
}

export interface GroupEditionPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
}

type State = {
    apiError?: any | undefined;
    group?: AdminGroup;
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadGroup(group: AdminGroup): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadGroup: group => ({ ...state, group }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const GroupEditionPage: React.FunctionComponent<GroupEditionPageProps> = ({ auth, onLogout, groupId }) => {
    const { t } = useTranslation();
    const [{
        apiError,
        group,
        tabIndex
    }, actions] = wrapperReducer(useReducer(reducer, {
        tabIndex: 2
    }));

    useLoadAdminGroup(auth, groupId, actions);

    if (apiError) return <P50x onGoBack={() => Router.push('/')} />
    if (!group) return <LoadingPage />

    return (
        <BaseAppLayout
           
            onLogout={onLogout}
        >
                <DraftGroupInfo groupId={groupId}  auth={auth}/>
        </BaseAppLayout>
    );
}

function useLoadAdminGroup(auth: Auth, groupId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const adminGroup = await services.getAdminGroup(auth, groupId);
            if (isRequestError(adminGroup)) {

            }
            else {
                actions.send(a => a.LoadGroup(adminGroup));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}