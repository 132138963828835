import * as React from 'react';
import { TextI } from '../Forms/TextI';

export interface QuizAnswerFieldProps {
    index: number;
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (typedValue: string, index: number) => void;
}

export const QuizAnswerField: React.FunctionComponent<QuizAnswerFieldProps> = ({index, active, placeholder, value, isValid, isInvalid, onChange }) => {
 
const onChangeHandler = (value: string) => {
  onChange(value, index)
}

    return (
      <div className="d-flex full-width">
        <TextI 
          active={active} 
          placeholder={placeholder} 
          value={value} 
          isValid={isValid} 
          isInvalid={isInvalid}
          onChange={onChangeHandler}
        />
      </div>
    );
}