export function addCss(el: Element, ...css: string[]) {
    css
        .forEach(cls => (cls.trim().match(/\S+/g) || [])
            .forEach(cName => {
                if (!el.classList.contains(cName)) {
                    el.classList.add(cName);
                }
            }));
    return el;
}

export function removeCss(el: Element, ...css: string[]) {
    css
        .forEach(cls => (cls.trim().match(/\S+/g) || [])
            .forEach(cName => {
                if (el.classList.contains(cName)) {
                    el.classList.remove(cName);
                }
            }));
    return el;
}