import * as React from 'react';

export interface ContentAreaProps {
    rawHTML: string;
}

export const ContentArea: React.FunctionComponent<ContentAreaProps> = ({ rawHTML }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: rawHTML }}
            onClick={contentClickSuppressor}
        />
    );
}

const contentClickSuppressor = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
        event.preventDefault();
        event.stopPropagation();
    } catch (e) {
        console.error(`Some error while suppressing onClick in ContentArea:\n${e}`);
    }
}
