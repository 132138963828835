import * as React from 'react';
import { ActionMatcher } from '../../../../../api/react-helper';
import { Auth } from '../../../../../domain/_shared';
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { LessonConfigs } from '../../../../../domain/models';
import { useTranslation } from 'react-i18next';
import { wrapperReducer } from '../../../../../api/react-helper';
import { number } from 'prop-types';
import { DropdownI } from '../../../../../components/Forms/DropdownI';

export interface NewLessonProps {
    courseId: number;
    groupId: number;
    index?: number;
    name?: string;
    description?: string;
    sequenceLevel?: number;
    required?: boolean;
    nameLength?: number;
    onClose: () => void;
    onSave: (auth: Auth, lesson: LessonConfigs) => void;
}

type State = {
    lessonNameText: string;
    lessonDescription?: string;
    seqLevel?: number;
    isRequired?: boolean;
    apiError?: any | undefined;
}

type Action = {
    SetLessonName(lessonNameText: string): State;
    SetDescription(lessonDescription: string): State;
    SetSequenceLevel(seqLevel: number): State;
    SetRequired(isRequired: boolean): State;
    Set50xError(apiError: any): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetLessonName: lessonNameText => ({ ...state, lessonNameText }),
    SetDescription: lessonDescription => ({ ...state, lessonDescription }),
    SetSequenceLevel: seqLevel => ({ ...state, seqLevel }),
    SetRequired: isRequired => ({ ...state, isRequired }),
    Set50xError: apiError => ({ ...state, apiError }),
})

export const NewLesson: React.FunctionComponent<NewLessonProps> = ({
    courseId,
    groupId,
    index,
    onClose,
    onSave,
    name,
    description,
    sequenceLevel,
    required, nameLength }) => {

    const { t } = useTranslation();
    const isEditing = name || description || sequenceLevel || required || nameLength
    const [{
        lessonNameText,
        lessonDescription,
        seqLevel,
        isRequired,
        apiError
    }, actions] = wrapperReducer(React.useReducer(
        reducer, {
        lessonNameText: name || '',
        lessonDescription: description,
        isRequired: required === false ? false : true,
        seqLevel: isEditing ? sequenceLevel || 1 : 1
    }));

    const [state, setState] = React.useState({
        lessonNameText: lessonNameText || '',
        invalidLessonNameMessage: undefined as string | undefined,

    });

    const { invalidLessonNameMessage } = state;
    const [counter, updateCounter] = React.useState(nameLength ? 80 - nameLength : 80)
    const { state: { auth } } = React.useContext(AuthContext);
    const [seqlevel, updatelevel] = React.useState(sequenceLevel || 1)

    const handleUpdateCounter = (event: any) => {
        var textEntered, counter;
        textEntered = event.target.value;
        counter = (80 - (textEntered.length));
        updateCounter(counter)
    }

    const handleKeyUp = (event: any) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleLessonnameTextChange = (value: any) => {
        setState({
            ...state,
            lessonNameText: value.target.value,
            invalidLessonNameMessage: undefined,
        });
    }

    const handleSeqLevelChange = (value: string) => {
        updatelevel(Number(value))
    }

    function onSaveClick() {
        let lessonName: string | undefined;

        if (lessonNameText.length === 0) lessonName = t('NewLessonPage.fieldRequired');

        if (!lessonName ) {
            const newLesson: LessonConfigs = {
                name: lessonNameText,
                description: lessonDescription,
                level: Number(seqlevel),
                order: 1,
                isRequired: isRequired
            }            
            onSave(auth!, newLesson)
            onClose();
        }
        else {
            setState({
                ...state,
                invalidLessonNameMessage: lessonName

            });
        }
    }

    return (
        <div className="builder-new-task-content">
            <div className="builder-new-task-content-btn-wrapper">
                <a className="builder-new-task-content-btn-close"
                    href="javascript:void(0)"
                    onClick={onClose}>
                    <i
                        className="fa fa-close"/>Close

                    
                </a>
            </div>
            <div className="card mb-0">
                <div className="card-header">
                    <h1 className="h6">
                        {!isEditing &&
                            <strong>{t('NewLessonPage.NewLesson')}</strong>
                        }
                        {isEditing &&
                            <strong>{t('NewLessonPage.EditLesson')}</strong>
                        }
                    </h1>
                </div>
                <div className="card-body builder-new-task-content-form">
                    <form className="form-horizontal">
                        <div className="row">
                            <div className="form-group  col-md-12">
                                <div className="input-group">
                                    <input
                                        className={"form-control " + (invalidLessonNameMessage ? 'is-invalid' : '')}
                                        id="title"
                                        onMouseDown={() => { return false }}
                                        onDragStart={() => { return false }}
                                        maxLength={80}
                                        type="text"
                                        name="title"
                                        placeholder="Enter a title"
                                        value={lessonNameText}
                                        onKeyUp={handleUpdateCounter}
                                        onChange={(value) => { const val = value.target.value; actions.send(a => a.SetLessonName(val)) }}
                                        onKeyDown={handleLessonnameTextChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{counter}</span>
                                    </div>
                                    {invalidLessonNameMessage &&
                                        <div className="invalid-feedback">{invalidLessonNameMessage}</div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group  col-md-12">
                                <div className="input-group">
                                    <textarea
                                        className="form-control"
                                        id="textarea-input"
                                        name="textarea-input"
                                        rows={3}
                                        placeholder="Enter a description (optional)"
                                        maxLength={200}
                                        onChange={(value) => { const val = value.target.value; actions.send(a => a.SetDescription(val)) }}
                                        value={lessonDescription || ''} />

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label >{t('NewLessonPage.SequenceLevel')}</label>
                                <DropdownI
                                    active={true}
                                    options={[
                                        { value: '1', label: '1' },
                                        { value: '2', label: '2' },
                                        { value: '3', label: '3' },
                                        { value: '4', label: '4' },
                                        { value: '5', label: '5' },
                                        { value: '6', label: '6' },
                                        { value: '7', label: '7' },
                                        { value: '8', label: '8' },
                                        { value: '9', label: '9' },
                                        { value: '10', label: '10' },
                                        { value: '11', label: '11' },
                                        { value: '12', label: '12' },
                                        { value: '13', label: '13' },
                                        { value: '14', label: '14' },
                                        { value: '15', label: '15' },
                                        { value: '16', label: '16' },
                                        { value: '17', label: '17' },
                                        { value: '18', label: '18' },
                                        { value: '19', label: '19' },
                                        { value: '20', label: '20' },
                                    ]}
                                    selected={seqlevel.toString() || undefined}
                                    isValid={false}
                                    isInvalid={false}
                                    invalidMessage={undefined}
                                    onChange={handleSeqLevelChange}
                                />
                              
                            </div>
                            <div className="form-group col-md-4">

                                <div className="form-switch form-switch-right">
                                    <input
                                        className="form-switch-input"
                                        id="requireManualEnrollmentApproval"
                                        type="checkbox"
                                        checked={isRequired}
                                        onChange={(value) => { const val = value.target.checked; actions.send(a => a.SetRequired(val)) }} />
                                    <label
                                        className="form-switch-label"
                                        htmlFor="requireManualEnrollmentApproval">
                                        <small>{t('NewLessonPage.Required')}</small>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-12">
                                <div className="controls">
                                    <div className="input-group">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="controls">

                                </div>

                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-6 col-form-label display-toggle">

                            </div>
                            <div className="col-md-6 col-form-label">

                            </div>
                        </div>
                    </form>
                </div>
                <div className="card-footer text-right">
                    <button
                        className="btn btn-light m-1"
                        type="submit"
                        onClick={onClose}>
                        {t('NewLessonPage.Cancel')}
                    </button>
                    {!isEditing &&
                        <button
                            className="btn btn-primary m-1"
                            type="submit"
                            onClick={() => onSaveClick()}>
                            {t('NewLessonPage.AddLesson')}
                        </button>}
                    {isEditing &&
                        <button
                            className="btn btn-primary m-1"
                            type="submit"
                            onClick={() => onSaveClick()}>
                            {t('NewLessonPage.UpdateLesson')}
                        </button>}
                </div>
            </div>
        </div>
    );
}