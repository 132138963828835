import * as React from 'react';

import { Auth } from '../../domain/_shared';

import { MyGroupsPage } from './MyGroupsPage';
import { MyCoursesPage } from './MyCoursesPage';
import { UserProfilePage } from './UserProfilePage';
import { EditProfilePage } from './EditProfilePage';
import { ChangePasswordPage } from './ChangePasswordPage';

export const userRoutes = (auth: Auth, handleLogout: () => void): [string[], Function][] => {
    return [
        [['mygroups'],
        () => (
            <MyGroupsPage
                auth={auth!}
                onLogout={() => handleLogout()}
            />
        )],
        [['mycourses'],
        () => (
            <MyCoursesPage
                auth={auth!}
                onLogout={() => handleLogout()}
            />
        )],
        [['profile'],
        () => (
            <UserProfilePage
                auth={auth!}
                onLogout={() => handleLogout()}
            />
        )],
        [['editprofile'],
        () => (
            <EditProfilePage
                auth={auth!}
                onLogout={() => handleLogout()}
            />
        )],
        [['changepassword'],
        () => (
            <ChangePasswordPage
                auth={auth!}
                onLogout={() => handleLogout()}
            />
        )]
    ]
}