import * as React from 'react';

export interface DropdownIProps {
    active: boolean;
    options: { value: string, label: string }[];
    selected?: string;
    isValid: boolean;
    isInvalid: boolean;
    invalidMessage?: string;
    onChange: (selectedValue: string) => void;
}

export const DropdownI: React.FunctionComponent<DropdownIProps> = ({ active, options, selected, isValid, isInvalid, invalidMessage, onChange }) => {
    return (
        <div>
            <select
                className={"custom-select mb-2 mr-sm-2 mb-sm-0 " + (isValid ? 'is-valid' : '') + (isInvalid ? 'is-invalid' : '')}
                disabled={!active}
                value={selected ? +selected : undefined}
                onChange={e => onChange(e.target.value)}
            >
                {(!selected) && <option value={undefined}></option>}
                {options && options.map((option, i) => (
                    <option key={i} value={option.value}>{option.label}</option>
                ))}
            </select>
            {(isInvalid) && <span className="form-text text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i>{invalidMessage}</span>}
        </div>
    );
}