import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface FooterProps {
    onTermsClick?: () => void;
    onPrivacyClick?: () => void;
}

export const Footer: React.FunctionComponent<FooterProps> = ({ onTermsClick, onPrivacyClick }) => {
    const { t } = useTranslation();

    return (
        <footer className="app-footer">
            <div className="container-fluid">
                <ul className="footer-menu">
                    <li className="footer-menu-item">
                        <a href="javascript:void(0)" onClick={onTermsClick} className="small text-muted">{t('BaseAppLayout.termsOfUse')}</a>
                    </li>
                    <li className="footer-menu-item">
                        <a href="javascript:void(0)" onClick={onPrivacyClick} className="small text-muted">{t('BaseAppLayout.privacyPolicy')}</a>
                    </li>
                </ul>
                <div className="footer-credits">
                    <span className="small text-muted">{t('BaseAppLayout.galileeTrademark')}</span>
                    {/* <a href="https://dyd.com"></a> */}
                </div>
            </div>
        </footer>
    );
}