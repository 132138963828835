import * as React from 'react';
import { TextI } from '../Forms/TextI';
import plusIcon from '../../assets/img/plus.png'
import minusIcon from '../../assets/img/minus.png'
import { Question } from '../../domain/models';

export interface QuizSingleLineAnswerProps {
    index: number;
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    isEditing?: boolean;
    showSpinner?: boolean;
    onChange: (typedValue: string, index: number) => void;
    onAddOption: (index: number) => void;
    onRemoveOption: (index: number) => void;
    onCompleteEdit?: () => void,
    question?: Question;
}

export const QuizSingleLineAnswer: React.FunctionComponent<QuizSingleLineAnswerProps> = ({
  index, 
  active, 
  placeholder, 
  value, 
  isValid, 
  isInvalid, 
  onChange, 
  onAddOption, 
  onRemoveOption, 
  isEditing, 
  showSpinner,
  onCompleteEdit,
  question }) => {
 

const onChangeHandler = (value: string) => {
  onChange(value, index)
}

    return (
      <div className="d-flex full-width" onBlur={onCompleteEdit}>
        <TextI 
          active={active} 
          placeholder={placeholder} 
          value={value} 
          isValid={isValid} 
          isInvalid={isInvalid}
          showSpinner={showSpinner}
          onChange={onChangeHandler}          
        />
       
      </div>
    );
}