import * as React from 'react';

export interface TimerDisplayProps {
  value?: string;
}

export const TimerDisplay: React.FunctionComponent<TimerDisplayProps> = ({ value }) => {
  return (
    <div className="timer">
      <span className="timer-effect sk-spinner sk-spinner-pulse"></span>
      <span className="timer-number">{value}</span>
    </div>
  )
}
