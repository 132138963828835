import dates from './dates.json';
import miscelaneous from './miscelaneous.json';
import IANA from './iana.json';
import fromAPI from './fromAPI.json';
// (TODO) move out rest of the pages
import restOfzhHans from './zh-hans.json';

// (TODO) specific page translations added here
import CreateNewCoursePage from './CreateNewCoursePage.json';
import ContentPanel from './ContentPanel.json';
import NewContent from './NewContent.json';
import ContentOptions from './ContentOptions.json';
import CourseCard from './CourseCard.json';
import CourseHeader from './CourseHeader.json';
import QuestionPanel from './QuestionPanel.json';
import DraftGroupInfo from './DraftGroupInfo.json';
import ResetComponent from './ResetComponent.json';
import ResetPasswordPage from './ResetPasswordPage.json';
import PrivacyPolicyPage from './PrivacyPolicyPage.json';
import TermsOfUsePage from './TermsOfUsePage.json';

export default {
    'dates': { ...dates },
    'miscelaneous': { ...miscelaneous },
    'IANA': { ...IANA },
    'fromAPI': { ...fromAPI },
    'ContentPanel': { ...ContentPanel },
    'NewContent':{...NewContent},
    'ContentOptions':{...ContentOptions},
    'CreateNewCoursePage': { ...CreateNewCoursePage },
    'CourseCard': { ...CourseCard },
    'CourseHeader': { ...CourseHeader },
    'QuestionPanel': { ...QuestionPanel },
    'DraftGroupInfo': { ...DraftGroupInfo },
    'ResetComponent': { ...ResetComponent },
    'ResetPasswordPage': { ...ResetPasswordPage },
    'PrivacyPolicyPage': { ...PrivacyPolicyPage },
    'TermsOfUsePage': { ...TermsOfUsePage },

    ...restOfzhHans // (TODO) refactor
};
