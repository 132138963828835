import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';

export interface PopupProps {
    coachName?: string;
}

export const Popup: React.FunctionComponent<PopupProps> = ({ coachName }) => {
    const { t } = useTranslation();
    const [open, stateChange] = useState(false);
    const onOpenModal = (open: any) => {
        stateChange(open)
    }
    const onCloseModal = (open: any) => {
        stateChange(open)
    }

    return (
        <span >
            <button onClick={() => onOpenModal(true)} style={{ position: 'absolute', right: '0' }} className="btn-remove" >
                <i className="fa fa-trash" style={{ margin: '0' }}></i></button>
            <Modal open={open} onClose={() => onCloseModal(false)}>
                <h6>{t('RemoveCoachPopup.RemoveCoach')}</h6>
                <p>
                    {t('RemoveCoachPopup.AlertMessage', { coachName: coachName })}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onCloseModal(false)}>{t('miscelaneous.close')}</button>
                </div>
            </Modal>
        </span>
    );
}
