import * as React from 'react';

import { Auth } from '../../domain/_shared';

import { CoachCourseDashboardPage, CoachCourseDashboardPageRouteParameters } from './CourseDashboardPage';
import { CourseMentoringPage, CourseMentoringPageRouteParameters } from './CourseMentoringPage';
import { TaskPage, TaskPageRouteParameters } from './TaskPage';

export const coachRoutes = (auth: Auth, handleLogout: () => void): [string[], Function][] => {
    return [
        [['groups', ':groupId', 'coach', 'courses', ':courseId'],
        (params: CoachCourseDashboardPageRouteParameters) => (
            <CoachCourseDashboardPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
            />
        )],
        [['groups', ':groupId', 'coach', 'courses', ':courseId', 'trainee', ':traineeId'],
        (params: CourseMentoringPageRouteParameters) => (
            <CourseMentoringPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
                traineeId={params.traineeId}
            />
        )],
        [['groups', ':groupId', 'coach', 'courses', ':courseId', 'lessons', ':lessonId', 'tasks', ':taskId', ':taskType', ':traineeId'],
        (params: TaskPageRouteParameters) => (
            <TaskPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
                traineeId={params.traineeId}
                lessonId={params.lessonId}
                taskId={params.taskId}
                taskType={params.taskType}
            />
        )],
    ]
}