import * as React from 'react';
import { ButtonBack } from '../../../../../components/Buttons/ButtonBack';
import { RichTextEditor } from '../../../../../../src/components/RichTextEditor/RichTextEditor';
import { ActionMatcher, wrapperReducer, ActionDispatcher, useAsyncEffect } from '../../../../../api/react-helper';
import { useReducer, useState, useContext } from 'react';
import { CourseConfigs, AdminCourse, Category } from '../../../../../domain/models';
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { Auth, isRequestError } from '../../../../../domain/_shared';
import { useTranslation } from 'react-i18next';
import * as AdminActions from '../../../GroupManagementPage/actions';
import { ImageUpload } from '../../../CreateNewCoursePage/components/ImageUpload';
import * as services from '../../../../../domain/services';
import { LoadingPage } from '../../../../../components/LoadingPage';
import * as Router from '../../../../../api/Router';
import { P50x } from '../../../../../components/P50x';
import { ImageLoading } from '../../../../../components/ImageLoader/ImageLoading'
import { ImageEditor } from '../../../../../components/ImageEditor/ImageEditor';
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import $ from 'jquery';
import _ from 'lodash';
import { lang } from 'moment';
import { CreateNewCoursePage } from '../../../CreateNewCoursePage';
import { getCategorybyId } from '../../../../../domain/services';
import { set } from 'react-ga';
import { APP_Language_List } from "../../../../../domain/_defaults";

export interface DraftCourseInfoProps {
    auth: Auth;
    groupId: number;
    courseId: number;
}
export interface DraftCourseInfoPageRouteParameters {
    groupId: number;
    courseId: number;
}

type State = {
    courseNameText: string;
    description?: string;
    courseType?: string;
    bodyContent?: string;
    isGroupMembershipRequired?: boolean;
    isEnrollmentApprovalRequired?: boolean;
    imageUrl?: string | undefined;
    course?: AdminCourse;
    apiError?: any | undefined;
    categoryName: string;
    category: Category[];
    language?: string;
  }

type Action = {
    SetCourseName(courseNameText: string): State;
    SetDescription(description: string): State;
    SetCourseType(courseType: string): State;
    SetBody(bodyContent: string): State;
    SetGroupMembership(isGroupMembershipRequired: boolean): State;
    SetEnrollmentApproval(isEnrollmentApprovalRequired: boolean): State;
    LoadCourse(course: AdminCourse): State;
    Set50xError(apiError: any): State;
    SetImageUrl(imageUrl: string): State;
    SetCategory(categoryName: string): State;
    LoadCategories(catagory: Category[]): State;
    SetLanguage(language: string): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetCourseName: courseNameText => ({ ...state, courseNameText }),
    SetDescription: description => ({ ...state, description }),
    SetCourseType: courseType => ({ ...state, courseType }),
    SetBody: bodyContent => ({ ...state, bodyContent }),
    SetGroupMembership: isGroupMembershipRequired => ({ ...state, isGroupMembershipRequired }),
    SetEnrollmentApproval: isEnrollmentApprovalRequired => ({ ...state, isEnrollmentApprovalRequired }),
    LoadCourse: course => ({ ...state, course }),
    Set50xError: apiError => ({ ...state, apiError }),
    SetImageUrl: imageUrl => ({ ...state, imageUrl }),
    SetCategory: categoryName => ({ ...state, categoryName }),
    LoadCategories: category => ({ ...state, category }),
    SetLanguage: language => ({ ...state, language }),
})

export const DraftCourseInfo: React.FunctionComponent<DraftCourseInfoProps> = ({ groupId, courseId }) => {
    const { t } = useTranslation();
    const [{
        courseNameText,
        description,
        courseType,
        bodyContent,
        isGroupMembershipRequired,
        isEnrollmentApprovalRequired,
        course,
        apiError,
        imageUrl,
        category,
        categoryName,
        language,
    }, actions] = wrapperReducer(useReducer(reducer, {
        courseNameText: '',
        categoryName:'',
        category: [],
    }));

    const [state, setState] = useState({
        courseNameText: courseNameText || '',
        invalidCourseNameMessage: undefined as string | undefined,
        invalidCategoryMessage: undefined as string | undefined,
        language: language || '',
    });

    const { invalidCourseNameMessage,  invalidCategoryMessage } = state;
    const { state: { auth } } = useContext(AuthContext);
    const [imageSource, updateImageSource] = useState(imageUrl!)
    const [isVisible, toggleVisibility] = useState(true)
    const [imageUpdateUrl, updateimageUrl] = useState();
    const [selectedLanguage, setLanguage] = useState();
    const [selectedCategory, setCategory] = useState( );

    useLoadCourse(auth!, courseId, actions, setCategory);
    useLoadCatagory(auth!, actions)

    const handleCoursenameTextChange = (value: any) => {
        setState({
            ...state,
            courseNameText: value.target.value,
            invalidCourseNameMessage: undefined,
        });
    }
    const onCategoryValueChange = (val: string) => {
        setCategory(parseInt(val));
    };

     React.useEffect(() => {
        if (imageUrl) toggleVisibility(true)
    }, [imageUrl])

    function onEditClick() {
        let body = $('#react-trumbowyg').trumbowyg('html');
        let courseName: string | undefined;
        if (courseNameText.length === 0)  courseName = t('NewCoursePage.fieldRequired');

        if (courseNameText.length > 0 )  {
            const editCourse: CourseConfigs = {
                name: courseNameText,
                description: description,
                body: body,
                type: courseType,
                language: language,
                categoryId :selectedCategory ,
                isGroupMembershipRequired: isGroupMembershipRequired,
                isEnrollmentApprovalRequired: isEnrollmentApprovalRequired,
        }
            useSendCourse(auth!, editCourse);
        }
        
        else {
            setState({
                ...state,
                invalidCourseNameMessage: courseName,
            });
        }
    }

    function onCancelClick() {
        Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)
    }

    const handleFileChange = (event: any) => {
        window.URL = window.URL;
        let url = window.URL.createObjectURL(event.target.files[0]);
        updateImageSource(url)
        toggleVisibility(true)
    }

    const [isImageLoading, updateImageUploadingStatus] = useState(false)

    const imageUpload = (image: File, completion: (imgURL: string) => void) => {
        updateImageUploadingStatus(true)
        services.saveImage(auth!, image)
            .then(response => {
                updateImageUploadingStatus(false)
                completion(response)
            })
            .catch((error) => {
                updateImageUploadingStatus(false)
                actions.send(a => a.Set50xError(error))
            })
    }

    const logoUpload = (image: File, completion: (imgURL: string) => void) => {
        imageUpload(image, (imgURL: string) => {
            updateimageUrl(image)
            completion(imgURL)
        })
    }

    const deleteImage = (courseId: number) => {
        try {
            services.removeImage(auth!, courseId).then((result) => {
                if (isRequestError(result)) {

                } else {

                }
            })
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }

    const onCloseLogoImageEditor = () => {
        updateImageSource('')
        updateimageUrl('')
        deleteImage(courseId);
    }

    const useSendCourse = async function (
        auth: Auth,
        editCourse: CourseConfigs
    ) {
        services.updateCourse(auth, courseId, editCourse)
            .then(() => {

                if (imageUpdateUrl) {
                    services.updateCourseImage(auth, courseId, imageUpdateUrl)
                        .then(() => {
                            Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)

                        })
                        .catch((e) => {
                            actions.send(a => a.Set50xError(e));
                        })
                } else {
                    Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)

                }
            })
            .catch((e) => {
                actions.send(a => a.Set50xError(e));
            })
    }
    if (!course) return <LoadingPage />;
    if (apiError) return <P50x onGoBack={() => Router.push('/')} />
    return (
        <div>
            <div className="main">
                <div className="container-fluid">

                    <div className="header-page hasBtnBack hasInfo">
                        {/* <ButtonBack text="Back To..." onClick={() => history.back()} /> */}
                        <h1 className="h4 header-page-title title-color">{t('NewCoursePage.EditCourse')}</h1>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <form className="form-horizontal">

                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="title">{t('NewCoursePage.Title')}</label>
                                    <div className="col-md-9">
                                        <input className={"form-control " + (invalidCourseNameMessage ? 'is-invalid' : '')} id="title" type="text" name="title" placeholder="Title"
                                            value={courseNameText}
                                            onChange={(value) => {
                                                const val = value.target.value;
                                                actions.send(a => a.SetCourseName(val))
                                            }}
                                            onKeyUp={handleCoursenameTextChange} />
                                        {invalidCourseNameMessage && <div className="invalid-feedback">{invalidCourseNameMessage}</div>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="description">{t('NewCoursePage.Description')}</label>
                                    <div className="col-md-9">
                                        <textarea className="form-control" id="description" name="description" placeholder="Description (optional)"
                                            onChange={(value) => {
                                                const val = value.target.value;
                                                actions.send(a => a.SetDescription(val))
                                            }}
                                            value={description || ''} />
                                    </div>
                                </div>  
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="description">{t('EditProfileCard.language')}</label>
                                    <div className="col-md-9">
                                        <LanguageSelectElement
                                        language={language ||course.language}
                                            selected={language}
                                            disabled={false}
                                            onChange={val => {
                                                actions.send(a => a.SetLanguage(val));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row removeSpace" style={{ marginBottom: "1rem" }} >
                                    <label
                                        className="col-md-3 col-form-label"
                                        htmlFor="catagory"
                                    >
                                        {t("CreateNewCoursePage.Catagory")}
                                    </label>
                                <div className="col-md-9">
                                        <CategorySelectElement
                                            selected={categoryName}
                                            invalidCategoryMessage={invalidCategoryMessage}
                                            category={category}
                                            disabled={false}
                                            onChange={val => {
                                                onCategoryValueChange(val);
                                                setState({
                                                    ...state,
                                                    invalidCategoryMessage: undefined
                                                });
                                                actions.send(a => a.SetCategory(val));
                                            }}
                                        />
                                    </div>
                                </div>                            
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="file-input">{t('NewCoursePage.ImageProfile')}</label>
                                    <div className="col-md-9 display-toggle">
                                        <div className="imgeditorbtn-width" >
                                            <input type="file" id="file" accept="image/*" onChange={(event) => {
                                                handleFileChange(event); event.target.value = ''
                                            }} />
                                            <label htmlFor="file" >{t('NewCoursePage.ChooseImages')}</label>
                                        </div>

                                        <ImageEditor cancelButton={false} imageSource={imageSource} preview={imageUrl} visible={isVisible} onClose={onCloseLogoImageEditor} onUploadImage={logoUpload} />

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12 quill-editor-height">
                                        <Trumbowyg id='react-trumbowyg'
                                            buttons={
                                                [
                                                    ['viewHTML'],
                                                    ['formatting'],
                                                    'btnGrp-semantic',
                                                    'btnGrp-justify',
                                                    'btnGrp-lists',
                                                    ['table'], // I ADDED THIS FOR THE TABLE PLUGIN BUTTON
                                                    ['fullscreen']
                                                ]
                                            }
                                            data={bodyContent || ''}
                                            placeholder='Type your text!'
                                            onChange={() => { }}
                                            onPaste={() => { }}
                                            semantic={false}
                                        />
                                        {/* <RichTextEditor theme={'snow'} onUploadImage={imageUpload} value={bodyContent || ''} onChange={(val) => { actions.send(a => a.SetBody(val.replace(/&quot;/g, ''))) }}></RichTextEditor> */}
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div className="card-footer">
                            <div className="row">

                                <div className="col-6">
                                    <div className="text-right">
                                        <div className="form-switch form-switch-right">
                                            <i className="fa fa-question-circle-o icn-gap" aria-hidden="true" title="Some explanations about this control using tooltip."> </i>

                                            <input className="form-switch-input" id="requireGroupMembership" type="checkbox" checked={isGroupMembershipRequired || false} onChange={(value) => { const val = value.target.checked; actions.send(a => a.SetGroupMembership(val)) }} />


                                            <label className="form-switch-label" htmlFor="requireGroupMembership"><small><strong>{t('NewCoursePage.Requiregroupmembership')}</strong></small></label>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="form-switch form-switch-right">
                                            <input className="form-switch-input" id="requireManualEnrollmentApproval" type="checkbox" checked={isEnrollmentApprovalRequired || false} onChange={(value) => { const val = value.target.checked; actions.send(a => a.SetEnrollmentApproval(val)) }} />
                                            <label className="form-switch-label" htmlFor="requireManualEnrollmentApproval"><small><strong>{t('NewCoursePage.Requiremanualenrollmentapproval')}</strong></small></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2"></div>

                                <div className="col-4">
                                    <div className="row">
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label"><strong>{t('NewCoursePage.Typeofcourse')}</strong></label>
                                            <div className="col-md-8 col-form-label">
                                                <div className="form-radio">
                                                    <input className="form-radio-input" id="radio1" type="radio" value="SelfPaced" name="radios" onChange={(value) => { const val = value.target.value; actions.send(a => a.SetCourseType(val)) }} onClick={() => { }} checked={courseType === 'SelfPaced' ? true : false} />
                                                    <label className="form-radio-label" htmlFor="radio1"><strong>{t('NewCoursePage.SelfPaced')}</strong></label>
                                                </div>
                                                <div className="form-radio">
                                                    <input className="form-radio-input" id="radio2" type="radio" value="CoachAssisted" name="radios" onChange={(value) => { const val = value.target.value; actions.send(a => a.SetCourseType(val)) }} onClick={() => { }} checked={courseType === 'CoachAssisted' ? true : false} />
                                                    <label className="form-radio-label" htmlFor="radio2"><strong>{t('NewCoursePage.Coachassisted')}</strong></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <ImageLoading isVisible={isImageLoading} />
                    </div>

                    <div className="text-right">
                        <button className="btn btn-light m-1" type="submit" onClick={() => onCancelClick()}>
                            {t('NewCoursePage.Cancel')}
                        </button>
                        <button className="btn btn-primary m-1" type="submit" onClick={() => onEditClick()}>
                            {t('miscelaneous.update')}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )

}
function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>, setCategory: React.Dispatch<React.SetStateAction<number | undefined>>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getAdminCourse(auth, courseId);
            const category = await services.getCategorybyId(auth, course.categoryId!);
            if (isRequestError(course)) {
            }
            else {
                actions.send(a => a.LoadCourse(course));
                actions.send(a => a.SetDescription(course.description!))
                actions.send(a => a.SetCourseName(course.name))
                actions.send(a => a.SetBody(course.body!))
                actions.send(a => a.SetImageUrl(course.imageUrl!))
                actions.send(a => a.SetGroupMembership(course.isGroupMembershipRequired!))
                actions.send(a => a.SetEnrollmentApproval(course.isEnrollmentApprovalRequired!))
                actions.send(a => a.SetCourseType(course.type))
                actions.send(a => a.SetCategory(category.name))
                setCategory(category.id);
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

const useLoadCatagory = function (
    auth: Auth,
    actions: ActionDispatcher<Action, State>,
 ) {
    useAsyncEffect(async () => {
        try {
                const category = await services.getCategories(auth);
                if (isRequestError(category)) {
                    console.log("",category);
                } else {
                    actions.send(a => a.LoadCategories(category));
                }
           
        } catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
};


interface LanguageSelectElementProps {
    language?:string;
    selected?: string;
    disabled: boolean;
    onChange: (selectedValue: string) => void;
}

const LanguageSelectElement: React.FunctionComponent<LanguageSelectElementProps> = ({
    disabled,
    onChange,
    language
}) => {
    const { t } = useTranslation();
    const languages = APP_Language_List;
    return (
        <div >
            <select
                className={
                    "form-control "
                }
                disabled={disabled}
                id="profile-language"
                name="profile-language"
                value={language}
                onChange={e => onChange(e.target.value)}
            >
                <option value={""} disabled>
                    {t("EditProfileCard.pleaseSelect")}
                </option>
                {languages.map((lng, i) => (
                    <option key={i} value={lng}>
                        {t(`IANA.${lng}`)}
                    </option>
                ))}
            </select>
          </div>
    );
};

interface CategorySelectElementProps {
    selected?: string;
    invalidCategoryMessage?: string;
    disabled: boolean;
    category: Category[];
    onChange: (selectedValue: string) => void;

}

const CategorySelectElement: React.FunctionComponent<CategorySelectElementProps> = (
    {
    selected,
    invalidCategoryMessage,
    disabled,
    onChange,
    category,
}) => {
    let categoryselected;
    const { t } = useTranslation();
    let result =category.filter(item => {
        if(item.name === selected){
            return item.id; 
        }
        else{
            return null;          
        }
      }) ; 
      if(result && result.length>0)
      {
        categoryselected=result[0].id
      }
    return (
        <div>
            <select
            
                className={
                    "form-control " + (invalidCategoryMessage ? "languageblock" : "")
                }

                disabled={disabled}
                id="profile-language"
                name="profile-language"
                value={categoryselected} 
                onChange={e => onChange(e.target.value)}
            >             
                {category.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </select>
            {invalidCategoryMessage && (
                <div className="invalid-msg">{invalidCategoryMessage}</div>
            )}
        </div>
    );
};
