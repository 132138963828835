import * as React from 'react';
import { useEffect } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { addCss, removeCss } from '../api/DomHelper';

import { Body } from './Body';

export interface SidebarNavProps {
    hidden?: boolean;
    name?: string;
}

export const SidebarNav: React.FunctionComponent<SidebarNavProps> = ({ hidden, children, name }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (hidden) {
            addCss(document.body, 'sidebar-hidden');
        } else {
            removeCss(document.body, 'sidebar-hidden');
        }
        return () => {
            removeCss(document.body, 'sidebar-hidden');
        }
    }, [hidden]);

    return (
        <Body className={classnames('sidebar-fixed sidebar-show', { 'sidebar-hidden sidebar-show': hidden })}>
            <div className="sidebar">
                <div className="sidebar-profile d-flex align-items-center">
                    <p className="sidebar-profile-name lead mb-0">{t('Sidebar.greeting')}, <br />{name}</p>
                    <Avatar name={name} round={true} size="45px" />
                </div>
                <nav className="sidebar-nav">
                    <ul className="nav">
                        {children}
                    </ul>
                </nav>
                {/* <button className="sidebar-minimizer" type="button"></button> */}
            </div>
        </Body>
    );
}

export interface SidebarNavItemmProps {
    href: string;
    text: string;
    icon?: string;
    goTo?: () => void;
}

export const SidebarNavItemm: React.FunctionComponent<SidebarNavItemmProps> = ({ href, text, icon, goTo }) => {
    return (
        <li className="nav-item">
            <a className="nav-link active" href={href} onClick={goTo}>
                {icon && <i className={icon}></i>}
                {text}
            </a>
        </li>
    );
}