import * as React from 'react';

export interface ButtonDashedProps {
    label: string;
    icon?: string;
    onClick: () => void;
}

export const ButtonDashed: React.FunctionComponent<ButtonDashedProps> = ({ label, onClick, icon }) => {
    return (
        <button
            onClick={onClick}
            className="btn btn-dashes"
            type="button"
        >
            {icon &&
                <i className={icon}></i>
            }
            {' '}{label}
        </button>
    )
}
