import * as React from 'react';
import * as AdminActions from '../../actions';
import * as services from '../../../../../domain/services';
import * as Router from '../../../../../api/Router';
import _ from 'lodash';
import { AddButton } from '../../../../../components/Lessons/AddButton';
import { AddLesson } from '../../../../../components/Lessons/AddLesson';
import { ActionDispatcher, ActionMatcher, useAsyncEffect, wrapperReducer } from '../../../../../api/react-helper';
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { Auth, isRequestError } from '../../../../../domain/_shared';
import { ContentConfigs, Form, Lesson, LessonConfigs, Question, QuestionOption } from '../../../../../domain/models';
import DragList from '../../../../../domain/utils/DragList'
import { LessonsPanel } from './LessonsPanel';
import { LoadingPage } from '../../../../../components/LoadingPage';
import { NewLesson } from './NewLesson';
import { P50x } from '../../../../../components/P50x';

export interface AdminLessonPanelProps {
    courseId: number;
    groupId: number;
    courseType: string;
    index?: number;
    lessons?: Lesson[];
}

type State = {
    apiError?: any | undefined;
    lessons?: Lesson[]
}

type Action = {
    Set50xError(apiError: any): State;
    LoadLessons(lessons: Lesson[]): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadLessons: lessons => ({ ...state, lessons }),
})

export const AdminLessonPanel: React.FunctionComponent<AdminLessonPanelProps> = ({
    courseId,
    groupId,
    courseType }) => {
    const { state: { auth } } = React.useContext(AuthContext);
    const [{ apiError, lessons }, actions] = wrapperReducer(React.useReducer(reducer, {}));
    const [selectedLesson, setLesson] = React.useState()
    const [isDisplayed, toggle] = React.useState(false)
    const [isVisibleAddNewContent, setAddNewContent] = React.useState(undefined)
    const [isContentDisplayed, showcontent] = React.useState(false)
    const [editingContent, setContentEditing] = React.useState()

    useLoadLessons(auth!, courseId, actions);
    if (!lessons) return <LoadingPage />;

    const handleClick = () => {
        toggle(true);
    }

    const createNewLesson = async function (
        auth: Auth,
        newLesson: LessonConfigs,
        order: number
    ) {
        newLesson.order = order
        services.createLesson(auth, courseId, newLesson)
            .then(async (_) => {
                const lessons = await services.getParticipantCourseLessons(auth!, courseId);
                actions.send(a => a.LoadLessons(lessons));
            })
            .catch((e) => {
                actions.send(a => a.Set50xError(e));
            })
    }

    const deleteLessonHandler = (lessonId: number) => {
        try {
            AdminActions.deleteLesson(auth!, lessonId)
                .then(async (_) => {
                    const lessons = await services.getParticipantCourseLessons(auth!, courseId);
                    actions.send(a => a.LoadLessons(lessons));
                })
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }

    const updateLesson = async function (
        auth: Auth,
        lesson: LessonConfigs,
        lessonId: number,
        order: number
    ) {
        lesson.order = order
        services.updateLesson(auth, lessonId, lesson)
            .then(async (_) => {
                const lessons = await services.getParticipantCourseLessons(auth!, courseId);
                actions.send(a => a.LoadLessons(lessons));
            })
            .catch((e) => {
                actions.send(a => a.Set50xError(e));
            })
    }

    const onClickEditButon = (lessonId: any) => {
        setLesson(lessonId)
    }

    const createNewContent = async function (
        newContent: ContentConfigs,
        lessonId: number,
    ) {

        if (newContent.type.toLowerCase() === "content") {
            services.createContent(auth!, lessonId, newContent)
                .then((result) => {
                    if (isRequestError(result)) {
                        actions.send(a => a.Set50xError(result));
                    }
                    else {
                        Router.push(`/groups/${groupId}/admin/1`)
                        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                    }
                })
                .catch((e) => {
                    actions.send(a => a.Set50xError(e));
                })
        }

        if (newContent.type.toLowerCase() === "meeting") {
            services.createMeeting(auth!, lessonId, newContent)
                .then((result) => {
                    if (isRequestError(result)) {
                        actions.send(a => a.Set50xError(result));
                    }
                    else {
                        Router.push(`/groups/${groupId}/admin/1`)
                        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                    }
                })
                .catch((e) => {
                    actions.send(a => a.Set50xError(e));
                })
        }

        if (newContent.type.toLowerCase() === "quiz" ||
            newContent.type.toLowerCase() === "survey" ||
            newContent.type.toLowerCase() === "editableform") {
            services.createContentForms(auth!, lessonId, newContent)
                .then((result) => {
                    if (isRequestError(result)) {
                        actions.send(a => a.Set50xError(result));
                    }
                    else {
                        Router.push(`/groups/${groupId}/admin/1`)
                        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                    }
                })
                .catch((e) => {
                    actions.send(a => a.Set50xError(e));
                })
        }
    }

    const deleteContentHandler = (contentId: number, lessonId: number, type: string) => {
        try {
            const index = lessons.findIndex(lesson => lesson.id == lessonId)
            if (type.toLowerCase() === "content") {
                AdminActions.deleteContent(auth!, contentId)
                    .then(async (_) => {
                        Router.push(`/groups/${groupId}/admin/1`)
                        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                    })
                    .catch((error) => {
                        actions.send(a => a.Set50xError(error));
                    })
            }
            if (type.toLowerCase() === "meeting") {
                AdminActions.deleteMeeting(auth!, contentId)
                    .then(async (_) => {
                        Router.push(`/groups/${groupId}/admin/1`)
                        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                    })
                    .catch((error) => {
                        actions.send(a => a.Set50xError(error));
                    })
            }
            if (type.toLowerCase() === "editableform" ||
                type.toLowerCase() === "quiz" ||
                type.toLowerCase() === "survey"
            ) {
                AdminActions.deleteContentForms(auth!, contentId)
                    .then(async (_) => {
                        Router.push(`/groups/${groupId}/admin/1`)
                        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                    })
                    .catch((error) => {
                        actions.send(a => a.Set50xError(error));
                    })
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }

    const updateContent = async function (
        newContent: Form,
        lessonId?: number,
        fromIndex?: number,
        toIndex?: number
    ) {
        let lesson = lessons.find(lesson => lesson.id == lessonId)
        let updatedTaskIndex = lesson!.tasks.findIndex(content => content.id == newContent.id)
        let newOrder = !_.isUndefined(toIndex) ? toIndex + 1 : updatedTaskIndex + 1
        let updatedContent = { ...newContent }
        updatedContent.order = newOrder

        if (newContent.type.toLowerCase() === "content") {
            services.updateContent(auth!, newContent.id!, updatedContent)
                .then(async (_) => {
                    Router.push(`/groups/${groupId}/admin/1`)
                    Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                })
                .catch((e) => {
                    actions.send(a => a.Set50xError(e));
                })
        }

        if (newContent.type.toLowerCase() === "meeting") {
            services.updateMeeting(auth!, newContent.id!, updatedContent)
                .then(async (_) => {
                    Router.push(`/groups/${groupId}/admin/1`)
                    Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                })
                .catch((e) => {
                    actions.send(a => a.Set50xError(e));
                })
        }

        if (newContent.type.toLowerCase() === "survey" ||
            newContent.type.toLowerCase() === "quiz" ||
            newContent.type.toLowerCase() === "editableform"
        ) {
            services.updateContentForms(auth!, newContent.id!, updatedContent)
                .then(async (_) => {
                    Router.push(`/groups/${groupId}/admin/1`)
                    Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                })
                .catch((e) => {
                    actions.send(a => a.Set50xError(e));
                })
        }
    }

    const addSubQuestions = (subQuestions: QuestionOption[], questionId: number, success: (subQuestionIdList: { id: string, body: string }[]) => void, error: () => void) => {
        let subQuestionIdList: { id: string, body: string }[] = []
        for (let i = 0; i < subQuestions.length; i++) {
            services.createSubQuestions(auth!, questionId, subQuestions[i])
                .then((subQuestionid) => {
                    subQuestionIdList.push({ id: subQuestionid, body: subQuestions[i].body })
                    if (subQuestionIdList.length === subQuestions.length) {
                        success(subQuestionIdList)
                    }
                })
                .catch((error) => {
                    if (subQuestionIdList.length === subQuestions.length) {
                        success(subQuestionIdList)
                    } else {
                        // error()
                    }
                })
        }

    }

    const addOptions = (options: QuestionOption[], questionId: number, success: (optionsIdList: { id: string, body: string }[]) => void, error: () => void) => {
        let optionsIdList: { id: string, body: string }[] = []
        for (let i = 0; i < options.length; i++) {
            services.createQuestionOptions(auth!, questionId, options[i])
                .then((optionId) => {
                    optionsIdList.push({ id: optionId, body: options[i].body })
                    if (optionsIdList.length >= options.length) {
                        success(optionsIdList)
                    }
                })
                .catch(() => {
                    if (optionsIdList.length >= options.length) {
                        success(optionsIdList)
                    } else {
                        // error()
                    }
                })
        }

    }

    const addSubQuestionExpectedAnswer = (subQuestionIdList: { id: string, body: string }[], optionsIdList: { id: string, body: string }[], expectedAnswer: QuestionOption[]) => {
        for (let i = 0; i < expectedAnswer.length; i++) {
            let subQuestion = subQuestionIdList.find((subQuestion) => {
                return subQuestion.body === expectedAnswer[i].body
            })
            let option = optionsIdList.find((option) => {
                return option.body === expectedAnswer[i].expectedAnswer
            })
            services.setExpectedSubQuestionAnswer(auth!, Number(subQuestion!.id), Number(option!.id))
            Router.push(`/groups/${groupId}/admin/1`)
            Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
        }
    }

    const addOptionSuccessHandler = (subQuestionIdList: { id: string, body: string }[], expectedAnswer: QuestionOption[]) => (optionsList: { id: string, body: string }[]) => {
        addSubQuestionExpectedAnswer(subQuestionIdList, optionsList, expectedAnswer)
    }

    const addSubQuestionSuccessHandler = (options: QuestionOption[], questionId: number, expectedAnswer: QuestionOption[]) => (subQuestionIdList: { id: string, body: string }[]) => {
        addOptions(options, questionId, addOptionSuccessHandler(subQuestionIdList, expectedAnswer), () => { }) //TODO :: Error Handling
    }

    const createNewForms = async function (
        questions: Question,
        options: QuestionOption[],
        subQuestions: QuestionOption[],
        formId: number,
        expectedAnswer: QuestionOption[],
        type: string,
        questionType: string
    ) {
        services.createQuestions(auth!, formId, questions)
            .then(response => {
                if (type.toLowerCase() === "quiz" && questionType === '3') { // multi line
                    if (options) {
                        for (let i = 0; i < options.length; i++) {
                            services.addExpectedAnswer(auth!, response, options[i].body)
                        }
                    }
                }
                if (questionType !== '3') { // All question and survey types except Multi line
                    if (options && !subQuestions) { // if there is no subQuestion
                        for (let i = 0; i < options.length; i++) {
                            services.createQuestionOptions(auth!, response, options[i]).then(res => {
                                if (expectedAnswer) { // radio box and check box
                                    for (let j = 0; j < expectedAnswer.length; j++) {
                                        if (options[i].body === expectedAnswer[j].body) {
                                            services.setExpectedOptions(auth!, response, Number(res))
                                                .then(() => {
                                                    Router.push(`/groups/${groupId}/admin/1`)
                                                    Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
                                                })

                                        }
                                    }

                                }
                            })
                        }
                    }
                }
                if (subQuestions) {
                    if (expectedAnswer) { // Quiz
                        addSubQuestions(subQuestions, response, addSubQuestionSuccessHandler(options, response, expectedAnswer), () => { })

                    } else { // Survey
                        for (let i = 0; i < subQuestions.length; i++) {
                            services.createSubQuestions(auth!, response, subQuestions[i])
                        }
                        for (let i = 0; i < options.length; i++) {
                            services.createQuestionOptions(auth!, response, options[i])
                        }
                    }

                }

                Router.push(`/groups/${groupId}/admin/1`)
                Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)

            })
            .catch((e) => {
                console.log("error")
                actions.send(a => a.Set50xError(e));
            })
    }

    const onCancelForms = function () {
        Router.push(`/groups/${groupId}/admin/1`)
        Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)
    }

    const updateQuestion = (question: Question) => {
        services.updateQuestion(auth!, question.id, question)
            .catch((error) => {
                actions.send(a => a.Set50xError(error));
            })
    }
    const onCompleteEdit = (item: QuestionOption) => {
        services.updateQuestionOptions(auth!, item.id, item)
            .catch((error) => {
                actions.send(a => a.Set50xError(error));
            })
    }

    const errorHandler = (error: any) => {
        actions.send(a => a.Set50xError(error));
    }

    const dragProps = {
        onDragEnd(fromIndex: number, toIndex: number) {
            const data = lessons;
            const item = data.splice(fromIndex, 1)[0];
            let lessonProfile: LessonConfigs = {}
            lessonProfile.name = item.name
            lessonProfile.description = item.description
            lessonProfile.isRequired = item.isRequired
            lessonProfile.level = item.level
            lessonProfile.body = ''
            lessonProfile.order = toIndex + 1
            updateLesson(auth!, lessonProfile, item.id, toIndex + 1)
            data.splice(toIndex, 0, item);
            actions.send(a => a.LoadLessons(data))
        },
        nodeSelector: 'li',
        handleSelector: 'dd'
    };

    if (apiError) return <P50x onGoBack={() => Router.push('/')} />

    return (
        <div>
            <DragList {...dragProps}>
                <ol className="lessons-builder-list">
                    {lessons &&
                        lessons.map((lesson, index) => (
                            <li key={index} className="lessons-builder-list-item">
                                <LessonsPanel
                                    courseId={courseId}
                                    groupId={groupId}
                                    courseType={courseType}
                                    index={index! + 1}
                                    lesson={lesson!}
                                    onClickDeleteLesson={() => { deleteLessonHandler(lesson!.id) }}
                                    onClickEditButon={onClickEditButon}
                                    onClickContentDelete={deleteContentHandler}
                                    onContentUpdate={(Content, lessonId, fromIndex, toIndex) => updateContent(Content, lessonId, fromIndex, toIndex)}
                                    onAddNewContent={(newContent, lessonId) => createNewContent(newContent, lessonId)}
                                    onClick={handleClick}
                                    onAddNewLesson={(auth, newLesson) => createNewLesson(auth, newLesson, index + 1)}
                                    onAddNewForms={createNewForms}
                                    onCancelForms={onCancelForms}
                                    updateQuestion={updateQuestion}
                                    onCompleteEdit={onCompleteEdit}
                                    errorHandler={errorHandler}
                                    isVisibleAddNewContent={isVisibleAddNewContent}
                                    setAddNewContent={(id) => setAddNewContent(id)}
                                    isContentDisplayed={isContentDisplayed}
                                    showcontent={showcontent}
                                    editingContent={editingContent}
                                    setContentEditing={(id) => setContentEditing(id)}
                                >
                                    {selectedLesson && (selectedLesson === lesson.id) &&
                                        <NewLesson
                                            courseId={courseId}
                                            index={index! + 1}
                                            groupId={groupId}
                                            name={lesson.name}
                                            description={lesson.description}
                                            required={lesson.isRequired}
                                            sequenceLevel={lesson.level}
                                            onClose={() => setLesson(undefined)}
                                            onSave={(auth, newLesson) => updateLesson(auth, newLesson, lesson.id, index! + 1)}
                                            nameLength={lesson.name.length}
                                        />
                                    }
                                </LessonsPanel>
                            </li>
                        ))}
                </ol>
            </DragList>
            {lessons.length <= 0 ?
                <AddLesson onClick={handleClick} />
                : !isDisplayed &&
                <div className="lessons-builder-lesson">
                    <div className="lessons-builder-lesson-add">
                        <AddButton active={true} label={"Lesson"} onAdd={handleClick} />
                    </div>
                </div>

            }
            {isDisplayed &&
                <NewLesson
                    courseId={courseId}
                    groupId={groupId}
                    onClose={() => { toggle(false) }}
                    onSave={(auth, newLesson) => createNewLesson(auth, newLesson, lessons.length + 1)}
                >
                </NewLesson>
            }
        </div>
    )
}

function useLoadLessons(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const lessons = await services.getParticipantCourseLessons(auth, courseId);
            if (isRequestError(lessons)) {
            }
            else {
                actions.send(a => a.LoadLessons(lessons));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

