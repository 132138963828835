import * as React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "../../../../../components/Cards/Card";
import { CardViewType } from "../../../../../components/Cards/CardSection";
import {
  Auth,
  handleLink,
  isRequestError
} from "../../../../../domain/_shared";
import * as services from "../../../../../domain/services";
import { Lesson } from "../../../../../domain/models";
import {
  ActionMatcher,
  wrapperReducer,
  useAsyncEffect,
  ActionDispatcher
} from "../../../../../api/react-helper";
import { AuthContext } from "../../../../../domain/contexts/AuthContext";
import { LoadingPage } from "../../../../../components/LoadingPage";

export interface TraineeStatusCardProps {
  cardViewType?: CardViewType;
  name: string;
  email: string;
  startDate?: string;
  traineeLastAccessDate?: string;
  coachLastAccessDate?: string;
  finishDate?: string;
  progress: number;
  lastLessonName?: string;
  lastTaskAssignedTo?: string;
  lastTaskName?: string;
  lastTaskStatus?: string;
  courseId: number;
  traineeId: number;
  onNavigateToLessonsClick: (
    traineeId: number,
    lessonId: number,
    taskId: number,
    tasktype: string
  ) => void;
}

type State = {
  apiError?: any | undefined;
  lessons?: Lesson[];
};

type Action = {
  Set50xError(apiError: any): State;
  LoadLessonsList(lessons: Lesson[]): State;
};

const reducer = (state: State, action: ActionMatcher<Action, State>) =>
  action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadLessonsList: lessons => ({ ...state, lessons })
  });

export const TraineeStatusCard: React.FunctionComponent<TraineeStatusCardProps> = ({
  cardViewType,
  name,
  email,
  startDate,
  traineeLastAccessDate,
  coachLastAccessDate,
  finishDate,
  progress,
  lastLessonName,
  lastTaskAssignedTo,
  lastTaskName,
  lastTaskStatus,
  courseId,
  traineeId,
  onNavigateToLessonsClick
}) => {
  const { t } = useTranslation();
  const [{ apiError, lessons }, actions] = wrapperReducer(
    React.useReducer(reducer, {})
  );
  const {
    state: { auth }
  } = React.useContext(AuthContext);
  useLoadLessons(auth!, courseId, traineeId, actions);
  if (!lessons) return <LoadingPage />;
  return (
    <Card
      cardViewType={cardViewType}
      header={() => (
        <>
          <h2 className="card-title h6">
            <a
              href="javascript:void(0)"
              onClick={() =>
                onNavigateToLessonsClick(
                  traineeId,
                  lessons[0].id,
                  lessons[0].tasks[0].id,
                  lessons[0].tasks[0].type
                )
              }
              style={{ cursor: "hand" }}
            >
              {name}
            </a>
          </h2>
          <div className="mt-1 mt-md-2">
            {" "}
            <a data-placement="top" href={`mailto:${email}`}>
              {" "}
              <i className="fa fa-envelope"></i>
            </a>{" "}
            {email}
          </div>
        </>
      )}
      body={() => (
        <>
          {startDate && (
            <div className="">
              <small>
                <span style={{ fontWeight: 500 }} className="text-muted">
                  {t("TraineeStatusCard.startDate")}:
                </span>{" "}
                {startDate
                  ? t("dates.short", {
                      valueObj: { type: "withTZdatetime", data: startDate }
                    })
                  : ""}
              </small>
            </div>
          )}
          {traineeLastAccessDate && progress < 1 && (
            <div className="mt-1 mt-md-2">
              <small>
                <span style={{ fontWeight: 500 }} className="text-muted">
                  {t("TraineeStatusCard.traineeLastEntry")}:
                </span>{" "}
                {traineeLastAccessDate
                  ? t("dates.long", {
                      valueObj: {
                        type: "withTZdatetime",
                        data: traineeLastAccessDate
                      }
                    })
                  : ""}
              </small>
            </div>
          )}
          {coachLastAccessDate && progress < 1 && (
            <div className="mt-1 mt-md-2">
              <small>
                <span style={{ fontWeight: 500 }} className="text-muted">
                  {t("TraineeStatusCard.coachLastEntry")}:
                </span>{" "}
                {coachLastAccessDate
                  ? t("dates.long", {
                      valueObj: {
                        type: "withTZdatetime",
                        data: coachLastAccessDate
                      }
                    })
                  : ""}
              </small>
            </div>
          )}
          {finishDate && progress === 1 && (
            <div className="mt-1 mt-md-1">
              <small>
                <span style={{ fontWeight: 500 }} className="text-muted">
                  {t("TraineeStatusCard.finishDate")}:
                </span>{" "}
                {finishDate
                  ? t("dates.short", {
                      valueObj: { type: "withTZdatetime", data: finishDate }
                    })
                  : ""}
              </small>
            </div>
          )}
          <div className="mt-1 mt-md-1">
            <small>
              <span style={{ fontWeight: 500 }} className="text-muted">
                {t("TraineeStatusCard.progress")}:
              </span>{" "}
              {(progress * 100).toFixed(1)} % <div></div>
            </small>
          </div>
          {lastTaskName && (
            <div className="mb-md-1 mt-3 mt-md-3">
              <small>
                <strong className="text-dark">
                  {t("TraineeStatusCard.lastActivity")}
                </strong>
              </small>{" "}
              <br />
              <div className="small d-inline-block my-1">
                <strong className="text-muted">
                  {t("TraineeStatusCard.lastLesson")}:
                </strong>{" "}
                {lastLessonName ? lastLessonName : ""} <br />
                <strong className="text-muted">
                  {t("TraineeStatusCard.lastTask")}:
                </strong>{" "}
                {lastTaskName} <br />
                <strong className="text-muted">
                  {t("TraineeStatusCard.lastTaskStatus")}:
                </strong>{" "}
                <FormatTaskStatus status={lastTaskStatus} />
                <br />
                <strong className="text-muted">
                  {t("TraineeStatusCard.lastTaskAssignedTo")}:
                </strong>{" "}
                <FormatAssignedTo role={lastTaskAssignedTo} />
              </div>
            </div>
          )}
        </>
      )}
      footer={() => (
        <button
          className="btn btn-primary"
          type="button"
          onClick={() =>
            onNavigateToLessonsClick(
              traineeId,
              lessons[0].id,
              lessons[0].tasks[0].id,
              lessons[0].tasks[0].type
            )
          }
        >
          {t("TraineeStatusCard.viewLessonsBtn")}
        </button>
      )}
    />
  );
};

const FormatTaskStatus: React.FunctionComponent<{
  status?: string;
}> = ({ status }) => {
  const { t, i18n } = useTranslation();

  if (status && i18n.exists && i18n.exists(`fromAPI.taskStatuses.${status}`)) {
    return <>{t(`fromAPI.taskStatuses.${status}`)}</>;
  }

  return <></>;
};

const FormatAssignedTo: React.FunctionComponent<{
  role?: string;
}> = ({ role }) => {
  const { t } = useTranslation();

  if (role) {
    switch (role.toLowerCase()) {
      case "trainee":
        return (
          <span className={"mb-0"}>
            {" "}
            <strong>{t(`fromAPI.userRoles.Trainee`)}</strong>
          </span>
        );
      case "coach":
        return (
          <span className={"mb-0 text-info"}>
            {" "}
            <strong>{t(`fromAPI.userRoles.Coach`)}</strong>
          </span>
        );
      case "both":
        return (
          <span className={"mb-0"}>
            {" "}
            <strong>{t(`fromAPI.userRoles.Both`)}</strong>
          </span>
        );
      default:
        return <>(INVALID)</>;
    }
  }

  return <></>;
};

function useLoadLessons(
  auth: Auth,
  courseId: number,
  traineeId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const lessons = await services.getCoachLessons(auth, courseId, traineeId);
      if (isRequestError(lessons)) {
        console.log(lessons);
      } else {
        actions.send(a => a.LoadLessonsList(lessons));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, []);
}
