import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';

import * as services from '../../../../../domain/services';
import { Coach } from '../../../../../domain/models';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';

export interface RemoveCoachPopupProps {
    coachId: number;
    updatedcoachList?: Coach[],
}

export const RemoveCoachPopup: React.FunctionComponent<RemoveCoachPopupProps> = ({ coachId, updatedcoachList }) => {
    const { state: { auth, courseId, coaches }, actions } = useContext(ParticipantsMgmtContext);
    const { t } = useTranslation();
    const [searchText, updateSearch] = useState('' as string);
    const [filteredCoachList, updateFilteredCoaches] = useState(updatedcoachList as Coach[]);
    const [open, stateChange] = useState(false);

    const onOpenModal = (open: any) => {
        stateChange(open)
    }

    const onCloseModal = (open: any) => {
        stateChange(open)
    }

    useEffect(() => {
        if (updatedcoachList) {
            const filteredList = updatedcoachList.filter((t: Coach) => t.id === coachId)
            updateFilteredCoaches([...filteredList])
        }
    }, [searchText]);

    async function removeCoach(coachId: number) {
        try {
            const participantList = await services.removeCoach(auth, courseId, coachId);
        }
        catch (e) {
            return;
        }
    }

    const coachRemoveHandler = (coachId: number) => {
        removeCoach(coachId)
            .then((val) => {
                if (coaches) {
                    let coachList = coaches
                    const filteredList = coachList.filter((coach) => {
                        return coach.id !== coachId
                    })
                    actions.send(a => a.LoadParticipantCoaches(filteredList))
                    actions.send((a: any) => a.updateParticipantsList(val))
                }
            })
    }

    return (
        <span >
            <button onClick={() => onOpenModal(true)} style={{ position: 'absolute', right: '0' }} className="btn-remove" >
                <i className="fa fa-trash" style={{ margin: '0' }}></i></button>
            <Modal open={open} onClose={() => onCloseModal(false)}>
                <h6>{t('RemoveCoachPopup.RemoveCoach')}</h6>
                <p>
                    {t('RemoveCoachPopup.ConfirmMessage')}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onCloseModal(false)}>{t('RemoveCoachPopup.Close')}</button>
                    <button type="button" className="btn btn-primary" onClick={() => { coachRemoveHandler(coachId) }}>{t('RemoveCoachPopup.RemoveCoach')}</button>
                </div>
            </Modal>
        </span>
    );
}
