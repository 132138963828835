import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Buttons/Button';

export interface SurveyFeedbackProps {
    isOwnTask: boolean;
    status?: string;
    activeResume: boolean;
    onResume: () => void;
    activeRetake: boolean;
    onRetake: () => void;
}

export const SurveyFeedback: React.FunctionComponent<SurveyFeedbackProps> = ({ isOwnTask, status, activeResume, onResume, activeRetake, onRetake }) => {
    const { t } = useTranslation();

    switch (status) {
        case 'NotStarted':
            return (
                <></>   // feedback hidden
            );
        case 'Saved':
            return (
                (isOwnTask)
                    ? (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-check text-success"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-success"><strong>{t('SurveyFeedback.surveySaved')}</strong></p>
                                <div className="task-summary-btns">
                                    <Button
                                        active={activeResume}
                                        label={t('SurveyFeedback.resumeBtn')}
                                        styleName={(activeResume) ? 'btn btn-primary' : 'btn btn-light'}
                                        href={''}
                                        onClick={() => onResume()}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="task-summary-content has-icon">
                            <div className="task-summary-icon">
                                <span className="fa fa-check text-success"></span>
                            </div>
                            <div className="task-summary-info">
                                <p className="task-summary-title text-success"><strong>{t('SurveyFeedback.surveySaved')}</strong></p>
                            </div>
                        </div>
                    )
            );
        case 'WaitingForCorrection':
            return (
                <></>   // (TODO) need more information on this scenario
            );
        case 'Finished':
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-check text-success"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-success"><strong>{t('SurveyFeedback.taskCompleted')}</strong></p>
                    </div>
                </div>
            );
        default:
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-close text-danger"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-danger"><strong>(unknown status)</strong></p>
                    </div>
                </div>
            );
    }
}