import * as React from "react";
import * as Router from "../../../api/Router";
import * as services from "../../../domain/services";
import { Auth, isRequestError } from "../../../domain/_shared";
import { P404 } from "../../../components/P404";
import { ContentPage } from "./ContentPage";
import { MeetingPage } from "./MeetingPage";
import { EditableFormPage } from "./EditableFormPage";
import { QuizPage } from "./QuizPage";
import { SurveyPage } from "./SurveyPage";
import {
  CoachCourse,
  Lesson,
  Trainee,
  TraineeResult
} from "../../../domain/models";
import {
  ActionMatcher,
  wrapperReducer,
  ActionDispatcher,
  useAsyncEffect
} from "../../../api/react-helper";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../../components/LoadingPage";
import { Footer } from "../../../components/Footer";
import { TaskRightSidePanel } from "./TaskRightSidePanel";
import { LessonHeaderPanel } from "./LessonHeaderPanel";
import { TaskPagination } from "./TaskPagination";
import { Pagination } from "./Pagination";

import Avatar from "react-avatar";
import { UserContext } from "../../../domain/contexts/UserContext";
import _ from "lodash";

const brand = require("../../../assets/img/logo.png");

export interface TaskPageRouteParameters {
  groupId: number;
  courseId: number;
  traineeId: number;
  lessonId: number;
  taskId: number;
  taskType: "content" | "meeting" | "editableform" | "quiz" | "survey";
}

type State = {
  apiError?: any | undefined;
  course?: CoachCourse;
  lessons?: Lesson[];
  lesson?: Lesson;
  trainee?: Trainee;
  trainees?: TraineeResult;
};

type Action = {
  Set50xError(apiError: any): State;
  LoadCourse(course: CoachCourse): State;
  LoadLessonsList(lessons: Lesson[]): State;
  LoadLessonbyId(lesson: Lesson): State;
  LoadTraineebyId(trainee?: Trainee): State;
  LoadTrainees(trainees: TraineeResult): State;
};

const reducer = (state: State, action: ActionMatcher<Action, State>) =>
  action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadLessonsList: lessons => ({ ...state, lessons }),
    LoadLessonbyId: lesson => ({ ...state, lesson }),
    LoadTraineebyId: trainee => ({ ...state, trainee }),
    LoadTrainees: trainees => ({ ...state, trainees })
  });

export interface TaskIndexPageProps extends TaskPageProps {
  taskType: "content" | "meeting" | "editableform" | "quiz" | "survey";
}

interface TaskPageProps {
  auth: Auth;
  onLogout: () => void;
  groupId: number;
  courseId: number;
  traineeId: number;
  lessonId: number;
  taskId: number;
}

export const TaskPage: React.FunctionComponent<TaskIndexPageProps> = ({
  taskType,
  ...taskProps
}) => {
  const [
    { apiError, course, lessons, lesson, trainee, trainees },
    actions
  ] = wrapperReducer(React.useReducer(reducer, {}));
  const { t } = useTranslation();
  useLoadLessons(
    taskProps.auth,
    taskProps.courseId,
    taskProps.traineeId,
    actions
  );
  useLoadCourse(taskProps.auth, taskProps.courseId, actions);
  useLoadLessonbyId(
    taskProps.auth,
    taskProps.lessonId,
    taskProps.traineeId,
    actions
  );
  useLoadTraineebyId(
    taskProps.auth,
    taskProps.courseId,
    taskProps.traineeId,
    actions
  );
  useLoadTrainees(taskProps.auth, taskProps.courseId, actions);

  const [showSidebar, setShowSidebar] = React.useState(true);

  const nextTaskClick = (
    nextlessonid: number,
    nexttaskId: number,
    nexttaskType: string
  ) => {
    Router.push(
      `/groups/${taskProps.groupId}/coach/courses/${
        taskProps.courseId
      }/lessons/${nextlessonid}/tasks/${nexttaskId}/${nexttaskType.toLowerCase()}/${
        taskProps.traineeId
      }`
    );
  };

  const prevTaskClick = (
    prevlessonid: number,
    prevtaskId: number,
    prevtaskType: string
  ) => {
    Router.push(
      `/groups/${taskProps.groupId}/coach/courses/${
        taskProps.courseId
      }/lessons/${prevlessonid}/tasks/${prevtaskId}/${prevtaskType.toLowerCase()}/${
        taskProps.traineeId
      }`
    );
  };

  const nextLessonClick = (
    nextlessonid: number,
    nexttaskId: number,
    nexttaskType: string
  ) => {
    Router.push(
      `/groups/${taskProps.groupId}/coach/courses/${
        taskProps.courseId
      }/lessons/${nextlessonid}/tasks/${nexttaskId}/${nexttaskType.toLowerCase()}/${
        taskProps.traineeId
      }`
    );
  };

  const prevLessonClick = (
    prevlessonid: number,
    prevtaskId: number,
    prevtaskType: string
  ) => {
    Router.push(
      `/groups/${taskProps.groupId}/coach/courses/${
        taskProps.courseId
      }/lessons/${prevlessonid}/tasks/${prevtaskId}/${prevtaskType.toLowerCase()}/${
        taskProps.traineeId
      }`
    );
  };

  const [isOpen, toggleProfile] = React.useState(false);

  const [selTraineeID, selectedTraineeID] = React.useState(
    _.isUndefined(taskProps.traineeId)
      ? trainees!.results[0].id.toString()
      : taskProps.traineeId.toString()
  );

  const {
    state: { presets }
  } = React.useContext(UserContext);

  if (!course || !lessons || !lesson || !trainee || !trainees)
    return <LoadingPage />;

  return (
    <>
      <div className={`task-execution ${showSidebar ? "showSidebar" : ""}`}>
        <header className="app-header navbar justify-content-start align-content-center task-execution-header">
          <button
            className="navbar-back"
            type="button"
            onClick={() => window.history.back()}
          >
            <span className="icon fa fa-arrow-left"></span>
          </button>
          <a
            className="navbar-brand"
            href="/"
            style={{ backgroundImage: `url(${brand})` }}
            onClick={() => {}}
          ></a>
          <h1
            className="text-white mb-0 navbar-title"
            style={{ fontSize: "14px" }}
          >
            {course.name}
          </h1>

          <a
            className="sub-profile text-white"
            onClick={() => toggleProfile(!isOpen)}
          >
            <div className="d-flex align-items-center">
              <span
                className="profile-name text-right mr-2 text-muted"
                style={{ fontSize: ".75rem" }}
              >
                <span className="lead">
                  {t("Header.greeting")},{" "}
                  {presets ? presets.nickName : "(unknown)"}
                </span>
              </span>
              <Avatar
                name={presets ? presets.nickName : "(unknown)"}
                round={true}
                size="30px"
              />
              <div className="ml-1">
                <span className="fa fa-angle-down"></span>
              </div>
            </div>
          </a>
          {isOpen && (
            <div className="dpdown-menu">
              <div className="dropdown-header">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => Router.push("/profile")}
                >
                  Profile
                </span>
              </div>
              <div className="divider"></div>
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={() => taskProps.onLogout()}
              >
                <i className="icon-logout text-muted"></i> Logout
              </a>
            </div>
          )}
          <button
            className={`navbar-toggle-sidebar ${showSidebar ? "active" : ""}`}
            type="button"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            Lectures{" "}
            <span
              className={`icon fa ${showSidebar ? "fa-close" : "fa-list-ul"}`}
            ></span>
          </button>
        </header>
        <main className="app-body d-flex">
          <div className="row marginTop" style={{ width: "100%" }}>
            <div
              className={`bg-white task-execution-body ${
                showSidebar ? "col-md-8 col-lg-9" : "col-12"
              }`}
            >
              <button
                className="task-execution-toggle-sidebar"
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <i
                  className={`${
                    showSidebar
                      ? "fa fa-angle-double-right"
                      : "fa fa-angle-double-left"
                  }`}
                ></i>
              </button>
              <div className="task-wrapper">
                <div className="task-execution-info py-3 px-4">
                  <div className="task-wrapper">
                    <div className="small">
                      Trainee: <strong>{trainee.name}</strong>
                    </div>
                    <div className="small">
                      Trainee Progress:{" "}
                      <strong>{trainee.progress * 100} %</strong>
                    </div>
                  </div>
                </div>
                <div className="px-4 pt-3 task-execution-content">
                  <div className="task-execution-content-header">
                    <LessonHeaderPanel lessons={lessons} lesson={lesson} />
                    {!showSidebar && (
                      <TaskPagination
                        lessons={lessons}
                        lesson={lesson}
                        taskId={taskProps.taskId}
                        onNavigateToNextTaskClick={nextTaskClick}
                        onNavigateToPrevTaskClick={prevTaskClick}
                      />
                    )}
                  </div>
                  {getTaskByType(taskType, taskProps)}
                </div>
              </div>
              <Pagination
                lessons={lessons}
                lesson={lesson}
                onNavigateToNextLessonsClick={nextLessonClick}
                onNavigateToPrevLessonsClick={prevLessonClick}
              />
            </div>
            {showSidebar && lessons && (
              <TaskRightSidePanel
                lessons={lessons}
                courseName={course.name}
                courseIcon={course.imageUrl}
                trainees={trainees}
                selectedTaskId={taskProps.taskId}
                onTaskClick={(task, lessonId) => {
                  Router.push(
                    `/groups/${taskProps.groupId}/coach/courses/${
                      taskProps.courseId
                    }/lessons/${lessonId}/tasks/${
                      task.id
                    }/${task.type.toLowerCase()}/${taskProps.traineeId}`
                  );
                }}
                onChange={val => {
                  selectedTraineeID(val);
                  Router.push(
                    `/groups/${taskProps.groupId}/coach/courses/${
                      taskProps.courseId
                    }/lessons/${lessons[0].id}/tasks/${
                      lessons[0].tasks[0].id
                    }/${lessons[0].tasks[0].type.toLowerCase()}/${val}`
                  );
                }}
                traineeID={selTraineeID}
              />
            )}
          </div>
        </main>
        <Footer
          onTermsClick={() => Router.push("/terms")}
          onPrivacyClick={() => Router.push("/privacy")}
        ></Footer>
      </div>
    </>
  );
};

const getTaskByType: (t: string, p: TaskPageProps) => JSX.Element = (
  taskType: string,
  taskProps: TaskPageProps
) => {
  switch (taskType) {
    case "content":
      return <ContentPage {...taskProps}></ContentPage>;
    case "meeting":
      return <MeetingPage {...taskProps}></MeetingPage>;
    case "editableform":
      return <EditableFormPage {...taskProps}></EditableFormPage>;
    case "quiz":
      return <QuizPage {...taskProps}></QuizPage>;
    case "survey":
      return <SurveyPage {...taskProps}></SurveyPage>;
    default:
      return <P404 onGoBack={() => Router.push("/")} />;
  }
};

function useLoadCourse(
  auth: Auth,
  courseId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const course = await services.getCoachCourse(auth, courseId);
      if (isRequestError(course)) {
        console.log(course);
      } else {
        actions.send(a => a.LoadCourse(course));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, []);
}

function useLoadTraineebyId(
  auth: Auth,
  courseId: number,
  traineeId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      actions.send(a => a.LoadTraineebyId(undefined));
      const trainee = await services.getCoachTraineesbyId(
        auth,
        courseId,
        traineeId
      );
      if (isRequestError(trainee)) {
        console.log(trainee);
      } else {
        actions.send(a => a.LoadTraineebyId(trainee));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, [traineeId]);
}

function useLoadLessonbyId(
  auth: Auth,
  lessonId: number,
  traineeId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const lesson = await services.getCoachLessonbyId(
        auth,
        traineeId,
        lessonId
      );
      if (isRequestError(lesson)) {
        console.log(lesson);
      } else {
        actions.send(a => a.LoadLessonbyId(lesson));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, [lessonId, traineeId]);
}

function useLoadLessons(
  auth: Auth,
  courseId: number,
  traineeId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const lessons = await services.getCoachLessons(auth, courseId, traineeId);
      if (isRequestError(lessons)) {
        console.log(lessons);
      } else {
        actions.send(a => a.LoadLessonsList(lessons));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, [traineeId]);
}

function useLoadTrainees(
  auth: Auth,
  courseId: number,
  actions: ActionDispatcher<Action, State>
) {
  useAsyncEffect(async () => {
    try {
      const trainees = await services.getCoachTrainees(auth, courseId);
      if (isRequestError(trainees)) {
        console.log(trainees);
      } else {
        actions.send(a => a.LoadTrainees(trainees));
      }
    } catch (e) {
      actions.send(a => a.Set50xError(e));
    }
  }, []);
}
