import React, { useState, useContext} from 'react';
import { ActionMatcher, wrapperReducer} from '../../api/react-helper';
import { AuthContext } from '../../domain/contexts/AuthContext';
import { Form } from '../../domain/models';
import { DropdownI } from '../Forms/DropdownI';
import { useTranslation } from 'react-i18next';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import * as services from '../../domain/services';
import _ from 'lodash';
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import $ from 'jquery'

export interface NewContentProps {
    type: string;
    lessonId: number;
    courseType:string;
    name?: string;
    sequenceLevel?: number;
    duration?:number;
    required?: boolean;
    coachValidationRequired?: boolean;
    privateAssign?:boolean;
    assigndTo?: string;
    contentId?: number;
    traineeContent?: string;
    coachContent?: string;
    minPassingScore?: number;
    nameLength?: number;
    onClose: () => void;
    onSave: (content: Form, lessonId: number) => void;
}

type State = {
    contentNameText: string;
    durationMinutes?:string;
    seqLevel?: number;
    isRequired?: boolean;
    assignedTo?: string;
    isCoachValidationRequired?: boolean;
    apiError?: any | undefined;
    isPrivate?: boolean;
    isResponsePrivate?: boolean;
    contentDetail?: any;
    traineeBody: any;
    minPassScore?: string;
    isAutoCorrectionEnabled?: boolean;

}

type Action = {
    SetContentName(contentNameText: string): State;
    SetSequenceLevel(seqLevel: number): State;
    SetDuration(durationMinutes: string): State;
    SetRequired(isRequired: boolean): State;
    SetAssignedTo(assignedTo: string): State;
    SetCoachValidationRequired(isCoachValidationRequired: boolean): State;
    SetPrivate(isPrivate: boolean): State;
    loadContentDetail(contentDetail: any): State;
    Set50xError(apiError: any): State;
    SetTraineeBody(traineeBody: any): State;
    SetMinimumPassingScore(minPassScore: string): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetContentName: contentNameText => ({ ...state, contentNameText }),
    SetSequenceLevel: seqLevel => ({ ...state, seqLevel }),
    SetDuration: durationMinutes => ({ ...state, durationMinutes }),
    SetRequired: isRequired => ({ ...state, isRequired }),
    SetAssignedTo: assignedTo => ({ ...state, assignedTo }),
    SetCoachValidationRequired: isCoachValidationRequired => ({ ...state, isCoachValidationRequired }),
    SetPrivate: isPrivate => ({ ...state, isPrivate }),
    loadContentDetail: contentDetail => ({ ...state, contentDetail }),
    Set50xError: apiError => ({ ...state, apiError }),
    SetTraineeBody: traineeBody => ({ ...state, traineeBody }),
    SetMinimumPassingScore: minPassScore => ({ ...state, minPassScore })
})

export const NewContent: React.FunctionComponent<NewContentProps> = ({
    lessonId,
    courseType,
    type,
    onClose,
    onSave,
    name,
    coachValidationRequired,
    privateAssign,
    sequenceLevel,
    required,
    traineeContent,
    coachContent,
    assigndTo,
    contentId,
    nameLength,
    minPassingScore,
    duration }) => {
    const { t } = useTranslation();
    const isEditing = name || coachValidationRequired || sequenceLevel || required || assigndTo || nameLength || minPassingScore || privateAssign || duration
    const { state: { auth } } = useContext(AuthContext);
    const [{
        contentNameText,
        seqLevel,
        isRequired,
        assignedTo,
        isCoachValidationRequired,
        apiError,
        isPrivate,
        isResponsePrivate,
        contentDetail,
        traineeBody,
        minPassScore,
        isAutoCorrectionEnabled,
        durationMinutes
    }, actions] = wrapperReducer(React.useReducer(
        reducer, {
        contentNameText: name || '',
        seqLevel: isEditing ? sequenceLevel || 1 : 1,
        isPrivate: isEditing?privateAssign: false,
        isResponsePrivate: false,
        isCoachValidationRequired: coachValidationRequired === false ? false : true,
        isRequired: required === false ? false : true,
        traineeBody: traineeContent || '',
        isAutoCorrectionEnabled: false,
        minPassScore: minPassingScore ? minPassingScore.toString() : undefined,
        durationMinutes :duration ?duration.toString() : undefined
    }));

    const [state, setState] = useState({
        contentNameText: contentNameText || '',
        traineeBodyContent: traineeContent || '',
        minPassScore: minPassScore || '',
        durationMinutes:durationMinutes || '',
        invalidContentNameMessage: undefined as string | undefined,
        invalidContentBodyMessage: undefined as string | undefined,
        invalidMinPassingScoreMessage: undefined as string | undefined,
        invalidDurationMessage: undefined as string | undefined,

    });

    const { invalidContentNameMessage } = state;
    const { invalidContentBodyMessage } = state;
    const { invalidMinPassingScoreMessage } = state;
    const { invalidDurationMessage } = state;
    const [counter, updateCounter] = useState(nameLength ? 80 - nameLength : 80)
    const [assignto, updateAssignto] = useState(assigndTo || 'Trainee')
    const [isSelectedTraineeContentTab, setTraineeContentTab] = useState(true)
    const [traineeBodyContent, setTraineeBodyContent] = useState()
    const [coachBodyContent, setCoachBodyContent] = useState()
    const [seqlevel, updatelevel] = React.useState(sequenceLevel || 1)
    const [iscoachValidationOn, setcoachValidationOn] = useState()
    const traineeContentHedearButtonStyle = isSelectedTraineeContentTab ? 'content-header-selected' : 'content-header'
    const coachContentHedearButtonStyle = isSelectedTraineeContentTab ? 'content-header-left' : 'content-header-left-selected'

    React.useEffect(() => {
      setCoachBodyContent(coachContent)
      setTraineeContentTab(false)
      setTraineeContentTab(true)
      setTraineeBodyContent(traineeContent)
      if(!assigndTo){
        setcoachValidationOn(false);
        return
      }
      if(assigndTo === "Trainee" || assigndTo === "0")
      {
        setcoachValidationOn(false);
      }
      else{
        setcoachValidationOn(true);
      }

    }, [])


    const imageUpload = (image: File, completion: (imgURL: string) => void) => {
        services.saveImage(auth!, image)
            .then(response => {
                completion(response)
            })
            .catch((error) => {
                actions.send(a => a.Set50xError(error))
            })
    }

    const handleUpdateCounter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        let textEntered, counter;
        textEntered = target.value;
        counter = (80 - (textEntered.length));
        updateCounter(counter)
    }

    const  numberOnly=(e: React.ChangeEvent<HTMLInputElement>)=> {      
        const target = e.target as HTMLInputElement 
        let regex=/^(12[0-0]|1[01][0-9]|[1-9]?[0-9])$/;
        if (target.value.match(regex) !== null) {
            setState({
                ...state,
                durationMinutes: target.value,
            });
        }
       else{
         if(target.value === '')
         setState({...state, durationMinutes: ''})
       }
    }

    const handleDurationTextChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        setState({
            ...state,
            durationMinutes: target.value,
            invalidDurationMessage: undefined,
        });
    }

    const handleMinScoreTextChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        setState({
            ...state,
            minPassScore: target.value,
            invalidMinPassingScoreMessage: undefined,
        });
    }

    const  restrictAlphabets=(e: React.ChangeEvent<HTMLInputElement>)=> {      
        const target = e.target as HTMLInputElement        
        let regex = /^(100(\.0{0,2})?|(\d|[1-9]\d)(\.\d{0,2})?%?)$/;
        if (target.value.match(regex) !== null) {
            setState({
                ...state,
                minPassScore: target.value,
            });
        }
       else{
         if(target.value === '')
         setState({...state, minPassScore: ''})
       }
    }

    const setTraineeBodyContentHandler = (value: any) => {
        setTraineeBodyContent(value.replace(/&quot;/g, ''))
        if (value !== "<p><br></p>" && value.length > 0) {
            setState({
                ...state,
                invalidContentBodyMessage: undefined,
            });
        }
    }

    const changeAssigndTo = (value: string) => {
        if(value==="trainee" || value==="0"){
            setcoachValidationOn(false)
        }
        else{
            setcoachValidationOn(true)
            actions.send(a => a.SetCoachValidationRequired(false))
        }
        updateAssignto(value)
    }

    const handleContentnameTextChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        setState({
            ...state,
            contentNameText: target.value,
            invalidContentNameMessage: undefined,
        });
    }

    const handleSeqLevelChange = (value: string) => {
        updatelevel(Number(value))
    }

    function onSaveClick() {
        let contentName: string | undefined;
        let traineeBodyExist: string | undefined;
        let minPassingScoreExist: string | undefined;
        let durationExist: string | undefined;
        let minPassScoreNum 
        let durationMin
        if (contentNameText.length === 0 || contentNameText === "") contentName = t('NewLessonPage.fieldRequired');
        if (traineeBodyContent===undefined || traineeBodyContent.length === 0 || traineeBodyContent === "<p><br></p>") traineeBodyExist = t('NewContent.trainee_Body_Required');
        if (type.toLowerCase() === "quiz") {
            if (state.minPassScore === undefined || state.minPassScore ==="")
                minPassingScoreExist = "Please enter minimum score.";
            else
            {
               var integer = parseFloat(state.minPassScore);
                 if(integer <= 0 || integer > 1){
                    minPassingScoreExist = "Please enter score between 0 and 1";
                  }
                  else{
                    minPassScoreNum = state.minPassScore.indexOf('%') >= 0 ? Number(state.minPassScore.slice(0, state.minPassScore.length - 1)) : Number(state.minPassScore)
                 }
            }
        } 
        if (type.toLowerCase() === "meeting") {
            if (state.durationMinutes === undefined || state.durationMinutes ==="")
            durationExist = "Please enter duration.";
            else
            durationMin =  Number(state.durationMinutes)
        }         

        if (!contentName && !traineeBodyExist && !minPassingScoreExist && !durationExist) {
            const newContent: Form = {
                id: 0,
                type: type,
                status: '',
                isEnabled: false,
                name: contentNameText,
                level: Number(seqlevel),
                order: Number(seqlevel),
                isRequired: isRequired,
                coachValidationRequired: isCoachValidationRequired,
                traineeBody: isSelectedTraineeContentTab?  $('#react-trumbowyg-traineecontent').trumbowyg('html'): traineeBodyContent,
                coachBody: isSelectedTraineeContentTab? coachBodyContent: $('#react-trumbowyg-coachcontent').trumbowyg('html'),
                assignedTo: assignto!,
                isPrivate: isPrivate,
                isResponsePrivate: isResponsePrivate || false,
                minPassingScore: minPassScoreNum,
                isAutoCorrectionEnabled: isAutoCorrectionEnabled || false,
                body: '',
                questions: [],
                durationMinutes :durationMin,
            }
            onClose();
            onSave(newContent, lessonId)
        }

        else {
            setState({
                ...state,
                invalidContentNameMessage: contentName,
                invalidContentBodyMessage: traineeBodyExist
            });
            if (type.toLowerCase() === "quiz") {
                setState({
                    ...state,
                    invalidContentNameMessage: contentName,
                    invalidContentBodyMessage: traineeBodyExist,
                    invalidMinPassingScoreMessage: minPassingScoreExist,
                });
            }
            if (type.toLowerCase() === "meeting") {
                setState({
                    ...state,
                    invalidContentNameMessage: contentName,
                    invalidContentBodyMessage: traineeBodyExist,
                    invalidDurationMessage: durationExist,
                });
            }
        }
    }
if(!type) return<div/>
    return (
        <div className="builder-new-task-content">
            <div className="builder-new-task-content-btn-wrapper">
                <a className="builder-new-task-content-btn-close"
                    href="javascript:void(0)"
                    onClick={onClose}>
                    <i
                        className="fa fa-close" />Close
                 
                </a>
            </div>
            <div className="card mb-0">
                <div className="card-header">
                    <h1 className="h6">
                        <strong>
                            {!isEditing &&
                                t('miscelaneous.new') + (type.toLowerCase()==='editableform'?'Editable Form':type)

                            }
                            {isEditing &&
                                t('miscelaneous.update') + (type.toLowerCase()==='editableform'?'Editable Form':type)

                            }
                        </strong>
                    </h1>
                </div>
                <div className="card-body builder-new-task-content-form">
                    <form className="form-horizontal">
                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="controls">
                                    <div className="input-group">
                                        <input
                                            className={"form-control " + (invalidContentNameMessage ? 'is-invalid' : '')}
                                            id="title-input"
                                            maxLength={80}
                                            type="text"
                                            name="title-input"
                                            placeholder={t('miscelaneous.enter_Title')}
                                            value={contentNameText}
                                            onKeyUp={handleUpdateCounter}
                                            onChange={(value) => { const val = value.target.value; actions.send(a => a.SetContentName(val)) }}
                                            onKeyDown={handleContentnameTextChange}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{counter}</span>
                                        </div>
                                        {invalidContentNameMessage &&
                                            <div className="invalid-feedback">{invalidContentNameMessage}</div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label htmlFor="ccmonth">{t('miscelaneous.sequence_Level')}</label>
                                <DropdownI
                                    active={true}
                                    options={[
                                        { value: '1', label: '1' },
                                        { value: '2', label: '2' },
                                        { value: '3', label: '3' },
                                        { value: '4', label: '4' },
                                        { value: '5', label: '5' },
                                        { value: '6', label: '6' },
                                        { value: '7', label: '7' },
                                        { value: '8', label: '8' },
                                        { value: '9', label: '9' },
                                        { value: '10', label: '10' },
                                        { value: '11', label: '11' },
                                        { value: '12', label: '12' },
                                        { value: '13', label: '13' },
                                        { value: '14', label: '14' },
                                        { value: '15', label: '15' },
                                        { value: '16', label: '16' },
                                        { value: '17', label: '17' },
                                        { value: '18', label: '18' },
                                        { value: '19', label: '19' },
                                        { value: '20', label: '20' },
                                    ]}
                                    selected={seqlevel.toString() || undefined}
                                    isValid={false}
                                    isInvalid={false}
                                    invalidMessage={undefined}
                                    onChange={handleSeqLevelChange}
                                />
                            </div>
                            {type.toLowerCase() !== 'meeting' &&
                                <div className="form-group col-md-4">
                                    <label htmlFor="ccyear">{t('NewContent.assign_to')}</label>
                                    {courseType.toLowerCase()!=='selfpaced' &&
                                        <DropdownI
                                            active={true}
                                            options={[
                                                { value: '0', label: 'Trainee' },
                                                { value: '1', label: 'Coach' },
                                            ]}
                                            selected={assignto}
                                            isValid={false}
                                            isInvalid={false}
                                            invalidMessage={undefined}
                                            onChange={changeAssigndTo}
                                        />
                                    }
                                    {courseType.toLowerCase()==='selfpaced' &&
                                        <DropdownI
                                            active={true}

                                            options={[
                                                { value: '0', label: 'Trainee' }
                                            ]}
                                            selected={assignto}
                                            isValid={false}
                                            isInvalid={false}
                                            invalidMessage={undefined}
                                            onChange={changeAssigndTo}
                                        />
                                    }
                                </div>
                            }
                            {type.toLowerCase() === 'meeting' &&
                                <div className="form-group col-md-4">
                                    <label htmlFor="ccmonth">{"Duration"}</label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                title="Enter between 0 and 120"
                                                value={state.durationMinutes}
                                                style={{ padding: "5px" }}
                                                onChange={numberOnly}
                                                maxLength={3}
                                                onKeyPress={handleDurationTextChange}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{"min"}</span>
                                            </div>
                                        </div>
                                        {invalidDurationMessage &&
                                            <div className={invalidDurationMessage ? 'display-block  invalid-feedback' : 'invalid-feedback'}>{invalidDurationMessage}</div>
                                        }
                                </div>
                            }
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div className="form-switch form-switch-right">
                                        <input
                                            className="form-switch-input"
                                            id="required"
                                            type="checkbox"
                                            checked={isRequired}
                                            onChange={(value) => { const val = value.target.checked; actions.send(a => a.SetRequired(val)) }}
                                        />
                                        <label
                                            className="form-switch-label"
                                            htmlFor="required">
                                            <small>{t('miscelaneous.required')}</small>
                                        </label>
                                    </div>
                                    {type.toLowerCase() !== 'meeting' &&
                                        <div className="form-switch form-switch-right">
                                            <input
                                                className="form-switch-input"
                                                id="private"
                                                type="checkbox"
                                                checked={isPrivate}
                                                onChange={(value) => { const val = value.target.checked; actions.send(a => a.SetPrivate(val)) }}
                                            />
                                            <label
                                                className="form-switch-label"
                                                htmlFor="private">
                                                <small>{"Private"}</small>
                                            </label>
                                        </div>
                                    }
                                    {type.toLowerCase() === 'content' &&
                                        <div className="form-switch form-switch-right">
                                            <input
                                                className="form-switch-input"
                                                id="requireCoachValidation"
                                                type="checkbox"
                                                disabled={iscoachValidationOn}
                                                checked={isCoachValidationRequired}
                                                onChange={(value) => { const val = value.target.checked; actions.send(a => a.SetCoachValidationRequired(val)) }}
                                            />

                                            <label
                                                className="form-switch-label"
                                                htmlFor="requireCoachValidation">
                                                <small> {t('NewContent.coach_Validation_Required')}</small>
                                            </label>
                                            
                                        </div>
                                    }                                   
                                </div>
                            </div>                           
                        </div>
                        <div className="row">
                            {type.toLowerCase() === 'quiz' &&
                                        <div className="form-group col-md-4 ">
                                             <label htmlFor="ccmonth">{'Minimum Score'}</label>
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                value={state.minPassScore}
                                                style={{ padding: "5px" }}
                                                onInputCapture={restrictAlphabets}
                                                onKeyPress={handleMinScoreTextChange}
                                            />
                                            {invalidMinPassingScoreMessage &&
                                                <div className={invalidMinPassingScoreMessage ? 'display-block  invalid-feedback' : 'invalid-feedback'}>{invalidMinPassingScoreMessage}</div>
                                            }
                                        </div>
                                    }
                            </div>
                    </form>
                </div>
                <div className="card-body builder-new-task-content-editor pt-0">
                    <p><strong>Add Content</strong></p>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={`nav-link ${traineeContentHedearButtonStyle}`}
                                onClick={() => {
                                  setCoachBodyContent($('#react-trumbowyg-coachcontent').trumbowyg('html'))
                                  setTraineeContentTab(true)
                                  }}>
                                {t('ContentPanel.trainee')}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${coachContentHedearButtonStyle}`}
                                onClick={() => {
                                  setTraineeBodyContent($('#react-trumbowyg-traineecontent').trumbowyg('html'))
                                  setTraineeContentTab(false)
                                  }}>
                                {t('ContentPanel.coach')}
                            </a>
                        </li>
                    </ul>
                    {isSelectedTraineeContentTab &&
                    <div className="content-editor">
                        {/* <RichTextEditor
                            theme={'snow'}
                            value={traineeBodyContent || ''}
                            onChange={setTraineeBodyContentHandler}
                            onUploadImage={imageUpload}
                        /> */}
                        <Trumbowyg id='react-trumbowyg-traineecontent'
                                            buttons={
                                                [
                                                    ['viewHTML'],
                                                    ['formatting'],
                                                    'btnGrp-semantic',
                                                    'btnGrp-justify',
                                                    'btnGrp-lists',
                                                    ['table'], // I ADDED THIS FOR THE TABLE PLUGIN BUTTON
                                                    ['fullscreen']
                                                ]
                                            }
                                            data={traineeBodyContent || ''}
                                            placeholder='Type your text!'
                                            onChange={() => {}}
                                            onPaste={() => { }}
                                            onBlur={() => {
                                              setTraineeBodyContent($('#react-trumbowyg-traineecontent').trumbowyg('html'))}
                                            }
                                            semantic={false}
                                        />
                    </div>
                    }

                    {!isSelectedTraineeContentTab &&
                    <div className="content-editor">
                        {/* <RichTextEditor
                            theme={'snow'}
                            value={coachBodyContent || ''}
                            onChange={setCoachBodyContent}
                            onUploadImage={imageUpload}
                        /> */}
                        <Trumbowyg id='react-trumbowyg-coachcontent'
                                            buttons={
                                                [
                                                    ['viewHTML'],
                                                    ['formatting'],
                                                    'btnGrp-semantic',
                                                    'btnGrp-justify',
                                                    'btnGrp-lists',
                                                    ['table'], // I ADDED THIS FOR THE TABLE PLUGIN BUTTON
                                                    ['fullscreen']
                                                ]
                                            }
                                            data={coachBodyContent || ''}
                                            placeholder='Type your text!'
                                            onChange={() => {}}
                                            onPaste={() => { }}
                                            onBlur={() => {
                                              setCoachBodyContent($('#react-trumbowyg-coachcontent').trumbowyg('html'))}
                                            }
                                            semantic={false}
                                        />
                        </div>
                    }

                </div>
                {invalidContentBodyMessage &&
                    <div style={{ paddingLeft: "15px" }}
                        className={invalidContentBodyMessage ? 'display-block  invalid-feedback' : 'invalid-feedback'}>{invalidContentBodyMessage}</div>
                }
                <div className="card-footer text-right">
                    <button
                        className="btn btn-light m-1"
                        type="submit"
                        onClick={onClose}>
                        {t('miscelaneous.cancel')}
                    </button>
                    {!isEditing &&
                        <button
                            className="btn btn-primary m-1"
                            type="submit"
                            onClick={() => onSaveClick()}>
                            {t('miscelaneous.add')}{type.toLowerCase()==='editableform'?'Editable Form':type}
                        </button>
                    }
                    {isEditing &&
                        <button
                            className="btn btn-primary m-1"
                            type="submit"
                            onClick={() => onSaveClick()}>
                            {t('miscelaneous.update')} {type.toLowerCase()==='editableform'?'Editable Form':type}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}
