import './App.css';

import * as React from 'react';
import { useContext, useReducer, useEffect } from 'react';

import * as Router from './api/Router';
import { Url } from './api/Router';

import { matchUrl, isDateExpired } from './api/utils';
import { ActionMatcher, wrapperReducer } from './api/react-helper';
import { Auth } from './domain/_shared';

import { GlobalContext } from './domain/contexts/GlobalContext';
import { AuthContext } from './domain/contexts/AuthContext';
import { UserContextProvider } from './domain/contexts/UserContext';

import { LoginPage } from './pages/LoginPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { TermsOfUsePage } from './pages/TermsOfUsePage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { HomePage } from './pages/HomePage';
import { P404 } from './components/P404';
import { DelayPage, DelayPageRouteParameters } from './pages/DelayPage';

import { unloggedRoutes } from './pages/Unlogged/unlogged-routes';
import { userRoutes } from './pages/User/user-routes';
import { memberRoutes } from './pages/Member/member-routes';
import { participantRoutes } from './pages/Participant/participant-routes';
import { traineeRoutes } from './pages/Trainee/trainee-routes';
import { coachRoutes } from './pages/Coach/coach-routes';
import { adminRoutes } from './pages/Admin/admin-routes';
import { sysAdminRoutes } from './pages/SysAdmin/sys-admin-routes';

type State = {
    url: Url;
}

type Action = {
    UpdateUrl(url: Url): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    UpdateUrl: url => ({ ...state, url })
})

export const App: React.FunctionComponent = () => {
    const { actions: globalActions } = useContext(GlobalContext);
    const { state: { auth }, actions: authActions } = useContext(AuthContext);
    const [{ url }, actions] = wrapperReducer(useReducer(reducer, { url: Router.dangerouslyGetInitialUrl() }));

    useEffect(() => {
        const watchId = Router.watchUrl(url => actions.send(a => a.UpdateUrl(url)));
        return () => Router.unwatchUrl(watchId);
    }, []);

    useEffect(() => {
        const receiveMessage = (event: MessageEvent) => {
            globalActions.send(a => a.SetCurrentPostMessage(event));
        }

        // NOTE: 'window.parent.postMessage' event does not have Synthetic event support in React,
        // it cannot be captured in lower level components, so 'useCapture' needs to be true
        window.addEventListener('message', receiveMessage, true);
        return window.removeEventListener('message', receiveMessage);
    }, [window]);

    function isAuthenticated() {
        return auth != null;
    }

    function handleLogout() {
        authActions.send(a => a.ClearAuth());               // OBS.: clears localStorage for security
        Router.push('/');
        globalActions.send(a => a.ReloadLocalStorage());    // OBS.: stores current memory data
    }

    function checkExpiration(auth: Auth) {
        if (isDateExpired(auth[".expires"])) handleLogout();
    }

    useEffect(() => {
        if (auth != null) {
            const checkId = window.setInterval(() => checkExpiration(auth), 1000);
            return () => window.clearInterval(checkId);
        }
        return () => { };
    }, [auth]); // WARNING: make sure this runs after index.html loads

    function renderHomePage() {
        return () => <HomePage onLogout={() => handleLogout()} />
    }

    return (
        matchUrl(
            url.path,
            [   // WARNING: unauthenticated paths
                [['forgotpassword'],
                () => (
                    <ForgotPasswordPage onLogout={() => handleLogout()} />
                )],
                [['resetpassword'],
                () => (
                    <ResetPasswordPage />
                )],
                [['terms'],
                () => (
                    <TermsOfUsePage />
                )],
                [['privacy'],
                () => (
                    <PrivacyPolicyPage />
                )],
                [['d', ':seconds'],
                (params: DelayPageRouteParameters) => (
                    <DelayPage seconds={params.seconds} />
                )],
                ...unloggedRoutes()     // (TODO) TBD, e.g. public courses links
            ],
            !isAuthenticated()
                ? <LoginPage />
                : <UserContextProvider>
                    {matchUrl(
                        url.path,
                        [
                            [[], renderHomePage()],
                            ...userRoutes(auth!, handleLogout),         // everybody logged in
                            ...memberRoutes(auth!, handleLogout),       // members of a group (common or admin)
                            ...participantRoutes(auth!, handleLogout),  // participants of a course (member or not of course's group)
                            ...traineeRoutes(auth!, handleLogout),      // trainee (and participant) in a course
                            ...coachRoutes(auth!, handleLogout),        // coach (and participant) in a course
                            ...adminRoutes(auth!, handleLogout),        // only with admin priviledges in the group
                            ...sysAdminRoutes(auth!, handleLogout),     // only with sys-admin priviledges
                        ],
                        <P404 onGoBack={() => Router.push('/')} />
                    )}
                </UserContextProvider>
        )
    );
}
