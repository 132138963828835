import * as React from 'react';

import { ResultItem } from './ResultItem';

export interface ResultsListProps {
    active: boolean;
    inactiveItemPlaceholder?: string;
    searchResult?: Array<any>;
    emptyResultsMessage?: string;
    onClick: (itemValue: Array<any>) => void;
}

export const ResultsList: React.FunctionComponent<ResultsListProps> = ({ active, inactiveItemPlaceholder, searchResult, emptyResultsMessage, onClick }) => {

    return (
        (active)
            ? (
                <ul className="list-group list-results">
                    {
                        (searchResult) && (searchResult.length > 0) ?
                            searchResult.map((item, i) => (
                                <div key={i}>
                                    <ResultItem
                                        active={item.active || (item.active == undefined) ? true : false}
                                        inactivePlaceholder={inactiveItemPlaceholder}
                                        value={item}
                                        label={item.name}
                                        description={item.email}
                                        onClick={(val) => onClick(val)}
                                    />
                                </div>
                            ))
                            : (
                                <li className="list-group-item list-results-item">
                                    <div className="list-results-info">
                                        {emptyResultsMessage}
                                    </div>
                                </li>
                            )
                    }
                </ul>
            )
            : (
                <ul className="list-group list-results">
                    <li className="list-group-item list-results-item disabled">
                        <div className="list-results-info">
                            {emptyResultsMessage}
                        </div>
                    </li>
                </ul>
            )
    );
}
