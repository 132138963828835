import * as React from 'react';

import { Body } from './Body';

export const AppBody: React.FunctionComponent = ({ children }) => {
    return (
        <Body className={'app'}>
            <div className={'app-body'}>
                {children}
            </div>
        </Body>
    );
}