import * as React from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Participant } from '../../../../../domain/models';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';
import * as AdminActions from './actions';

import { LoadingPage } from '../../../../../components/LoadingPage';
import { SearchInput } from '../../../../../components/Search/SearchInput';
import { SelfPacedFiltersDropDown } from './SelfPacedFiltersDropDown';
import { SelfPacedSorterDropDown } from './SelfPacedSorterDropDown';
import { ButtonDropdown } from './ButtonDropdown';
import { AddMemberDropDown } from './AddParticipantDropDown';
import { BadgeFilter } from '../../../../../components/Badges/BadgeFilter';
import { ApprovePendingSelfPacedParticipantsPopup } from './ApprovePendingSelfPacedParticipantsPopup';
import { SelfPacedList } from './SelfPacedList';
import { ParticipantPaginationCustom } from './ParticipantPagination';
import { ErrorPopUp } from '../../../../../components/Popup/ErrorPopUp';

export interface SelfPacedParticipantsListProps {
    courseId: any;
    groupId: any;
    status: string;
}

export const SelfPacedParticipantsList: React.FunctionComponent<SelfPacedParticipantsListProps> = ({ groupId, status }) => {
    const { state: { auth, participantsList, coaches, searchText, selectedTraineeFilters, resultsPerPage, selectedParticipants, enrollmentStatusList, reloadParticipants, courseId }, actions } = useContext(ParticipantsMgmtContext)
    const { t } = useTranslation();
    const [isVisibleApproveAllPopUp, toggleApproveAllPopUp] = useState(false)
    const [popUpErrorMsg, toggleErrorPopUp] = useState('')
    const tableDivRef = useRef(null);
    const [tableRowWidth, resizeRow] = useState(1600);
    const addmemberdropdowndisplay = status === 'Draft' ? { display: 'none' } : {}

    useEffect(() => {
        resizeRow(tableDivRef.current ? (tableDivRef.current! as HTMLDivElement).offsetWidth - 2 : window.innerWidth);

        const resizeHandler = () => {
            if (tableDivRef && tableDivRef.current) {
                resizeRow((tableDivRef.current! as HTMLDivElement).offsetWidth - 2);
            }
        }
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [tableDivRef.current]);

    if (!participantsList || !coaches) return <LoadingPage />
    const participants = participantsList.results as Participant[]
    const traineeFilterLabels: any = { finished: t('CourseManagementParticipantsPage.CompletedLbl'), inProgress: t('CourseManagementParticipantsPage.InProgressLbl'), notStarted: t('CourseManagementParticipantsPage.NotStartedLbl') }

    const searchHandler = (searchText: string) => {
        actions.send(a => a.updateSearchText(searchText))
    }

    const filterBadgeCloseHandler = (filter: string) => {
        let updatedFilterList = !selectedTraineeFilters ? [] : [...selectedTraineeFilters]
        updatedFilterList.splice(updatedFilterList.indexOf(filter), 1)
        actions.send(a => a.updateTraineeFilters(updatedFilterList))
    }

    const PendingBadgeCloseHandler = () => {
        actions.send(a => a.updateEnrolmentStatusList([]))
    }

    const isPendingUsersExist = () => {
        let pendingUsers = []
        pendingUsers = _.filter(participants, function (participant) {
            return participant.enrollmentStatus.toLowerCase() === 'pending';
        })
        return pendingUsers.length > 0 ? true : false
    }

    const onSelectAllParticipants = () => {
        if (selectedParticipants.length) {
            actions.send(a => a.updateParticipantSelection([]))
        } else {
            const selectedParticipantsID = participants.map((participant) => { return participant.id })
            actions.send(a => a.updateParticipantSelection([...selectedParticipantsID]))
        }
    }

    const removeSelectionHandler = (participantId: number) => {
        let updatedSelectionList = selectedParticipants
        updatedSelectionList.splice(updatedSelectionList.indexOf(participantId), 1)
        actions.send(a => a.updateParticipantSelection([...updatedSelectionList]))
    }

    const addSelectionHandler = (participantId: number) => {
        let updatedSelectionList = selectedParticipants
        updatedSelectionList.push(participantId)
        actions.send(a => a.updateParticipantSelection([...updatedSelectionList]))
    }

    const tableChecked = (participantId: number) => {
        return selectedParticipants.includes(participantId)
    }

    const handleRequests = (failedRequests: number[]) => {
        toggleApproveAllPopUp(false)

        if (failedRequests.length > 0) {
            const errorMsg = t('CourseManagementParticipantsPage.errorPopupMsg', { failedRequests: failedRequests.length })
            toggleErrorPopUp(errorMsg)
            actions.send(a => a.reloadParticipantList(!reloadParticipants))

        } else {
            actions.send(a => a.reloadParticipantList(!reloadParticipants))
        }
    }

    const approveAllHandler = () => {
        AdminActions.approveCourseMembers(auth, courseId, participants, handleRequests)
    }

    const approveSelected = (id: number) => {
        AdminActions.approveCourseMembers(auth, courseId, participants, handleRequests, id)
    }

    return (
        <div className="list-page">

            <div className="list-page-header">

                <div className="list-page-actions">
                    <div className="list-page-search">
                        <SearchInput
                            active={true}
                            placeholder={t('CourseManagementParticipantsPage.searchInputPlaceholder')}
                            value={searchText}
                            isValid={false}
                            isInvalid={false}
                            onChange={searchHandler}
                            onFocus={() => ''}
                            onBlur={() => ''}
                        />
                    </div>
                    <div className="list-page-controls hasAddBtn">
                        <SelfPacedFiltersDropDown label={t('CourseManagementParticipantsPage.filtersDd')} styleName="btn-outline-light bg-white btn-lg" icon="fa fa-filter" />
                        <SelfPacedSorterDropDown label={t('CourseManagementParticipantsPage.sorterDd')} styleName="btn-outline-light bg-white btn-lg" icon="fa fa-sort-amount-asc" />
                        <AddMemberDropDown label={t('ParticipantListPage.AddBtn')} styleName="btn-primary addmemberdropdowndisplay" icon="fa fa-plus" courseId={courseId} groupId={groupId} selfPaced={true} status={status} />

                    </div>
                </div>

                <div className="list-page-filters display-flex">
                    <div className="list-page-badges">
                        {selectedTraineeFilters &&
                            selectedTraineeFilters.map((filter, i) => (
                                <BadgeFilter key={i} value={filter} label={traineeFilterLabels[filter]} onCloseFilterBadge={filterBadgeCloseHandler} />
                            ))}

                        {enrollmentStatusList &&
                            enrollmentStatusList.length > 0 &&
                            <BadgeFilter label={t('CourseManagementParticipantsPage.PendingLbl')} value={'pending'} onCloseFilterBadge={PendingBadgeCloseHandler} />
                        }
                    </div>

                </div>

            </div>

            <div className="list-page-body">
                <div className="table-group">

                    <div className="table-group-header hasInfo">
                        <div>
                            <button className={`${!isPendingUsersExist() ? 'display-none' : 'btn btn-light btn-sm m-1'}`} onClick={() => { toggleApproveAllPopUp(true) }}>{t('ListTablePage.ApproveAll')}</button>
                            <ApprovePendingSelfPacedParticipantsPopup isVisible={isVisibleApproveAllPopUp} onApprove={approveAllHandler} onClose={() => { toggleApproveAllPopUp(false) }} message={t('ListTablePage.approveAllMsg')} />
                        </div>
                        <div className="table-group-info">
                            {t('miscelaneous.paginationItems', { begin: (participantsList.page * resultsPerPage) + 1, end: (participantsList.page * resultsPerPage) + participantsList.results.length, total: participantsList.resultsCount })}
                        </div>
                    </div>

                    <div className="table-group-body">

                        <div ref={tableDivRef} className="list-table hasDropDown fromMd">


                            <div className="list-table-row list-table-header">
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.TraineeLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.StatusLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                    <strong>{t('ParticipantListPage.EnrollDateLbl')}</strong>
                                </div>
                                <div className="list-table-col">
                                </div>
                                <div className="list-table-col">
                                </div>
                            </div>

                            {participants.map((participant, index) => (
                                <SelfPacedList key={participant.id} rowWidth={tableRowWidth} participant={participant} onSelect={addSelectionHandler} onUnSelect={removeSelectionHandler} isSelected={tableChecked(participant.id)} onClickAppoveButton={approveSelected} />
                            ))
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div className="list-page-footer">
                <ParticipantPaginationCustom resultsCount={participantsList.resultsCount} />
            </div>
            <ErrorPopUp isVisible={!_.isEmpty(popUpErrorMsg)} onClose={() => { toggleErrorPopUp('') }} message={popUpErrorMsg} />

        </div>

    )
}
