import * as React from 'react';
import { TextI } from '../Forms/TextI';
import plusIcon from '../../assets/img/plus.png'
import minusIcon from '../../assets/img/minus.png'

export interface SurveyOptionPreviewProps {
    index: number;
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    isEditing?: boolean;
    onChange: (typedValue: string, index: number) => void;
    onAddOption: (index: number) => void;
    onRemoveOption: (index: number) => void;
    onCompleteEdit?: () => void
}

export const SurveyOptionPreview: React.FunctionComponent<SurveyOptionPreviewProps> = ({index, active, placeholder, value, isValid, isInvalid, onChange, onAddOption, onRemoveOption, isEditing, onCompleteEdit }) => {
  const addOptionHandler = () => {
      onAddOption(index)
  }
  const removeOptionHandler = () => {
    onRemoveOption(index)
}

const onChangeHandler = (value: string) => {
  onChange(value, index)
}

    return (
      <div className="d-flex full-width">
        <TextI 
          active={active} 
          placeholder={placeholder} 
          value={value} 
          isValid={isValid} 
          isInvalid={isInvalid}
          onChange={onChangeHandler}          
        />
        {isEditing&&
        <div className="d-flex flex-column justify-content-center" style={{cursor: "pointer"}} onClick={onCompleteEdit}>
          <i className="fa fa-check"></i>
        </div>
        }
        {!isEditing&&
          <div className="survey-field-icon"></div>
        }
        
        </div>
    );
}