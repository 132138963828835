import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { handleLink } from '../../../../../domain/_shared';
import * as Router from '../../../../../api/Router';
import { ButtonEdit } from '../../../../../components/Buttons/ButtonEdit';
import { CardSection } from '../../../../../components/Cards/CardSection';
import { EffectContentArea } from '../../../../../components/EffectContentArea';

export interface GroupInfoProps {
    content: string | undefined;
    groupId: number;
    onClickViewCourses: () => void;
}

export const GroupInfo: React.FunctionComponent<GroupInfoProps> = ({ content, groupId, onClickViewCourses }) => {
    const { t } = useTranslation();

    return (
        <div className="main mt-3 mt-md-4">
            <div className="btn-align-right">
                <ButtonEdit active={true} label={'Edit'} onEdit={() => Router.push(`/groups/${groupId}/admin/edit/0`)} />
            </div>
            <div className="container-fluid">
                <div className="card bg-white px-4 pt-3 group-info">
                    <CardSection title={t('GroupManagementPage.tab0_title')}>
                        <div className="col-12">
                            <hr className="mt-0 mb-4" />
                            <div id="overview" className="col-md-12 ql-editor">
                                <EffectContentArea rawHTML={content || ''} onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))} />
                            </div>
                            <div>
                                <hr className="mt-4 mb-4" />
                                <div className="text-right mb-3 mb-sm-2">
                                    <a className="btn btn-primary" href="javascript:void(0)" onClick={() => onClickViewCourses()}>
                                        <span className="label">{t('GroupManagementPage.tab0_viewCoursesBtn')}</span>
                                        <i className="icon fa fa-arrow-right ml-2"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </CardSection>
                </div>
            </div>
        </div >
    )
}
