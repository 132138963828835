import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import * as Router from '../../../api/Router';

import { __SERVER_REPO__ } from '../../../domain/_defaults';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { TraineeCourse, CoachCourse } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { CardSection, CardViewType } from '../../../components/Cards/CardSection';
import { TraineeCourseCard } from './components/main/TraineeCourseCard';
import { CoachCourseCard } from './components/main/CoachCourseCard';

export interface MyCoursesPageRouteParameters {
}

export interface MyCoursesPageProps {
    auth: Auth;
    onLogout: () => void;
}

type State = {
    apiError?: any | undefined;
    courses?: (TraineeCourse | CoachCourse)[];
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourseList(courses: (TraineeCourse | CoachCourse)[]): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourseList: courses => ({ ...state, courses }),
})

export const MyCoursesPage: React.FunctionComponent<MyCoursesPageProps> = ({ auth, onLogout }) => {
    const { t } = useTranslation();
    const [{ apiError, courses }, actions] = wrapperReducer(useReducer(reducer, {}));

    const goToRoot = () => Router.push('/');

    useLoadCourses(auth, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!courses) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myCourses'), active: true }
            ]}
            onLogout={onLogout}
        >
            <CardSection
                title={t('MyCoursesPage.title')}
                showGridListToggle={(courses.length > 0) ? true : undefined}
                initialCardViewType={CardViewType.List}
            >
                {(courses.length > 0)
                    ? courses.map((course, i) => (
                        (!(course.userAsCoach))
                            ? (
                                <TraineeCourseCard
                                    key={i}

                                    courseStatus={course.status || 'unknown'}
                                    courseType={course.type || 'unknown'}
                                    name={course.name}
                                    courseImgUrl={course.imageUrl}
                                    groupName={course.groupName}
                                    description={course.description!}

                                    coachName={(course as TraineeCourse).coachName}
                                    coachEmail={(course as TraineeCourse).coachEmail}
                                    startDate={(course as TraineeCourse).startDate}
                                    traineeLastAccessDate={(course as TraineeCourse).traineeLastAccessDate}
                                    progress={(course as TraineeCourse).progress || 0}
                                    lastLessonName={(course as TraineeCourse).lastLessonName}
                                    lastTaskAssignedTo={(course as TraineeCourse).lastTaskAssignedTo}
                                    lastTaskName={(course as TraineeCourse).lastTaskName}
                                    lastTaskStatus={(course as TraineeCourse).lastTaskStatus}

                                    onCourseClick={() => Router.push(`/groups/${course.groupId}/trainee/courses/${course.id}`)}
                                />
                            ) : (
                                <CoachCourseCard
                                    key={i}
                                    
                                    courseStatus={course.status || 'unknown'}
                                    courseType={'CoachAssisted'}    // NOTE: verify endpoint for implicit data
                                    name={course.name}
                                    courseImgUrl={course.imageUrl}
                                    groupName={course.groupName}
                                    description={course.description!}

                                    traineeCount={(course as CoachCourse).traineeCount}
                                    notStartedCount={(course as CoachCourse).notStartedCount}
                                    inProgressCount={(course as CoachCourse).inProgressCount}
                                    finishedCount={(course as CoachCourse).finishedCount}

                                    onCourseClick={() => Router.push(`/groups/${course.groupId}/coach/courses/${course.id}`)}
                                />
                            )
                    ))
                    : (
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-md-7">
                                    <div className="clearfix">
                                        <div className="text-center mb-2"><img src={`${__SERVER_REPO__}/system/icon-courses.svg`} alt="Error Image" style={{ width: '100%', maxWidth: '230px' }} /></div>
                                        <p className="h4 mt-0 text-center">{t('MyCoursesPage.noCourses')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </CardSection>
        </BaseAppLayout>
    );
}

function useLoadCourses(auth: Auth, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const courses = await services.getUserCourses(auth);
            if (isRequestError(courses)) {
                console.log(courses);
            } else {
                const allCourses = _.sortBy([
                    ...courses.coachCourses.map(x => ({ ...x, userAsCoach: true })),
                    ...courses.traineeCourses.map(x => ({ ...x, userAsCoach: false })),
                ], 'id');

                actions.send(a => a.LoadCourseList(allCourses));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
