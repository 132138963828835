import * as React from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { SingleLineIcon } from '../../components/Icons';
import * as Sli from '../../components/Icons';

import { GlobalContext } from '../../domain/contexts/GlobalContext';

import { LanguageSelector } from '../../components/i18n/LanguageSelector';

const logo = require('../../assets/img/logo.png');

export interface ForgotProps {
    errorMessage?: string;
    disabled?: boolean;
    isSending?: boolean;
    onChangeLanguage: (lng: string) => void;
    onForgotClick: (email: string) => void;
    onGoBackClick: () => void;
}

export const Forgot: React.FunctionComponent<ForgotProps> = ({
    errorMessage,
    disabled,
    isSending,
    onChangeLanguage,
    onForgotClick,
    onGoBackClick
}) => {
    const { state: { currentLanguage } } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [emailText, setEmailText] = useState('');

    function handleFormForgotClick() {
        onForgotClick(emailText);
    }

    return (
        <div className="login-page bg-gradient">
            <header className="app-header text-right">
                <div className="container-fluid pt-3 mb-xs-3">
                    <LanguageSelector
                        styleName="bg-primary text-white border-primary"
                        contentPosition="dropdown-menu-right"
                        currentLanguage={currentLanguage}
                        disabled={!!disabled}
                        onFlagClick={onChangeLanguage}
                    />
                </div>
            </header>

            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center py-4">

                        <div className="col-12 text-center mb-3">
                            <img alt="Galilee" className="brand-logo" width="200" src={logo} onClick={onGoBackClick} style={{ cursor: 'pointer' }} />
                        </div>

                        <div className="col-12 col-md-6 col-lg-5">
                            <div className="card-group">
                                <div className="card p-2 p-md-3">
                                    <div className="card-body">

                                        <h1 className="h3">{t('ForgotComponent.title')}</h1>
                                        <p className="">{t('ForgotComponent.instruction')}</p>

                                        <ForgotForm
                                            emailText={emailText}
                                            errorMessage={errorMessage}
                                            disabled={disabled}
                                            isSending={isSending}
                                            onEmailTextChange={text => setEmailText(text)}
                                            onForgotClick={handleFormForgotClick}
                                            onGoBackClick={onGoBackClick}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

interface ResetFormProps {
    emailText: string;
    errorMessage?: string;
    disabled?: boolean;
    isSending?: boolean;
    onEmailTextChange: (text: string) => void;
    onForgotClick: () => void;
    onGoBackClick: () => void;
}

const ForgotForm: React.FunctionComponent<ResetFormProps> = ({
    emailText,
    errorMessage,
    disabled,
    isSending,
    onEmailTextChange,
    onForgotClick,
    onGoBackClick
}) => {
    const { t } = useTranslation();
    const emailEl = useRef(null);

    useEffect(() => {
        if (emailEl && emailEl.current) {
            (emailEl.current! as HTMLInputElement).focus();
        }
    }, [errorMessage]);

    function handleKeyPress(e: React.KeyboardEvent) {
        if (e.keyCode === 13) {
            e.preventDefault();    // don't trigger onChange
            e.stopPropagation();
            onForgotClick();
        }
    }

    return (
        <form>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <div className="input-group mb-1">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <SingleLineIcon icon={Sli.envelope} />
                    </span>
                </div>
                <input
                    ref={emailEl}
                    className={classnames('form-control', { 'is-invalid': errorMessage })}
                    placeholder={t('ForgotComponent.emailPlaceholder')}
                    type="email"
                    value={emailText}
                    disabled={disabled}
                    onChange={e => onEmailTextChange(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>

            <div className="row">
                <div className="col-12">
                    {isSending
                        ? (
                            <button
                                type="button"
                                className="btn btn-loader btn-primary"
                                disabled={true}
                                style={{ width: '100%' }}
                            >
                                <div className="sk-rotating-plane"></div>
                                <span className="label small">{t('ForgotComponent.submitLabel')}</span>
                            </button>
                        )
                        : (
                            <button
                                type="button"
                                className="btn btn-block btn-primary text-center"
                                disabled={disabled}
                                onClick={() => onForgotClick()}
                            >
                                {t('ForgotComponent.submitLabel')}
                            </button>
                        )
                    }
                </div>
                <div className="col-12 text-center text-sm-left mt-3 mt-md-2 mb-1">
                    <button
                        disabled={disabled}
                        type="button"
                        className="btn btn-link p-0 small text-muted"
                        onClick={onGoBackClick}
                    >
                        <i className="icon fa fa-arrow-left mr-1" />
                        {t('ForgotComponent.goBack')}
                    </button>
                </div>
            </div>
        </form>
    );
};
