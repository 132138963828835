import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';

export interface ErrorPopUp {
    message?: string;
    isVisible: boolean;
    onClose: () => void;
}

export const ErrorPopUp: React.FunctionComponent<ErrorPopUp> = ({isVisible, message, onClose}) => {
    const { t } = useTranslation();    

    return (
        <span >
               <Modal open={isVisible} onClose={() => {onClose()}}>
               <br/>
                <p>
                    {message}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {onClose()}}>{t('miscelaneous.close')}</button>
                </div>
            </Modal>
        </span>
    );
}
