import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Lesson } from '../../../../../domain/models';
import { getTaskTitle } from '../../../../../components/LessonPanel/synchronous-helpers';

export interface ParticipantLessonPanelProps {
    index: number;
    lesson: Lesson;
}

export const ParticipantLessonPanel: React.FunctionComponent<ParticipantLessonPanelProps> = ({ index, lesson }) => {
    const { t } = useTranslation();
    const [show, toggle] = useState(true);

    return (
        <div className="list-group list-lessons mb-4">
            <a className="list-group-item list-group-item-action list-lessons-item" href="javascript:void(0)" onClick={() => toggle(!show)}>
                <div className="list-lessons-info">
                    <div className="list-lessons-category">
                        <p className="mb-3 small text-muted">{t('LessonPanel.lesson', { index: index })} </p>
                    </div>
                    <div className="list-lessons-actions">
                        <p className="mb-0 small text-muted">
                            <strong>
                                {t('LessonPanel.totalTasks')}: {lesson.tasks.length}
                            </strong>
                        </p>
                        <i className={show ? "icon fa fa-angle-up" : "icon fa fa-angle-down"}></i>
                    </div>
                </div>
                <div className="list-lessons-title">
                    <h3 className="h6 text-primary">{lesson.name}</h3>
                </div>
            </a>

            {show && <div className="list-group list-tasks">
                {(lesson.tasks || []).map((task, i) => (
                    <span key={i} className="list-group-item list-group-item-action list-tasks-item">
                        {getTaskTitle(task.type, task.assignedTo == 'Coach', task.name)}
                        {(!task.isRequired) && <span className="list-tasks-info"><span className="badge badge-warning">{t('LessonPanel.optional')}</span></span>}
                    </span>
                ))}
            </div>}
        </div>
    )
}