import * as React from 'react';

import classnames from 'classnames';

export interface ButtonBackProps {
    text: string,
    btnDefault ?: boolean,
    onClick: () => void;
}

export const ButtonBack: React.FunctionComponent<ButtonBackProps> = ({ text, btnDefault, onClick }) => {
    return (
        <a className={btnDefault ? "btn btn-light" : "btn-back"} href="javascript:void(0)" onClick={onClick}>
            <i className="icon fa fa-arrow-left"></i>
            {' '} <span className="label">{text}</span>
        </a>
    )
}