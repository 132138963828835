import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { APP_Language_List } from '../../domain/_defaults';

export interface LanguageSelectorProps {
    styleName?: string;
    contentPosition?: string;
    currentLanguage?: string;
    disabled: boolean;
    onFlagClick: (lng: string) => void;
}

export const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({
    styleName,
    contentPosition,
    currentLanguage,
    disabled,
    onFlagClick
}) => {
    const { t } = useTranslation();
    const [show, toggle] = useState(false);

    const close = () => {
        if (show) toggle(false);
    }

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(close, 500);
    }

    const languages = APP_Language_List;
    const currLng = languages.indexOf(currentLanguage || '');
    function stringsToObjects(a: string[]): { label: string, value: string }[] {
        return a.reduce((acc, lng) => { acc.push({ label: t(`IANA.${lng}`), value: lng }); return acc; }, [] as { label: string, value: string }[]);
    }
    const filteredList = (currLng === -1) ? stringsToObjects(languages) : stringsToObjects(languages.filter((el) => el !== currentLanguage));

    return (
        <div className="btn-group" onBlur={handleOnBlur}>
            <div className="dropdown">
                <button className={`btn dropdown-toggle ${styleName ? styleName : 'bg-white border-light'}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => { if (!disabled) toggle(!show) }}>
                    <i className="d-inline-block mr-2 fa fa-globe fa-lg"></i>
                    {(currLng !== -1) ? t(`IANA.${currentLanguage}`) : 'Languages'}
                </button>
                <div className={classnames(`dropdown-menu ${contentPosition ? contentPosition : 'dropdown-menu-left'}`, { ' show': show })}>
                    {filteredList.map(({ label, value }, i) =>
                        <LanguageOption key={i} label={label} onClick={() => { clearTimeout(blurId); onFlagClick(value); close(); }} />
                    )}
                </div>
            </div>
        </div>
    )
}

interface LanguageOptionProps {
    label: string;
    onClick: () => void;
}

const LanguageOption: React.FunctionComponent<LanguageOptionProps> = ({ label, onClick }) => {
    return (
        <a className="dropdown-item" href="javascript:void(0)" onClick={onClick}>{label}</a>
    )
}
