import * as React from 'react';
// import { useReducer } from 'react';

// import * as Router from '../../api/Router';

// import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../api/react-helper';
// import * as services from '../../domain/services';

import { UserWelcomePage } from './UserWelcomePage';
import { P404 } from '../../components/P404';

export interface HomePageRouteParameters {
}

export interface HomeIndexPageProps extends HomePageProps {
}

interface HomePageProps {
    onLogout: () => void;
}

// type State = {
//     groupRoles?: { [groupdId: number]: string[] } | undefined;
// }

// type Action = {
//     LoadGroupRoles(groupRoles: { [groupdId: number]: string[] } | undefined): State;
// }

// const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
//     LoadGroupRoles: groupRoles => ({ ...state, groupRoles }),
// })

// const isSomeAdmin = function (groupRoles: { [groupdId: number]: string[] } | undefined): boolean {
//     let adm = false;
//     !!groupRoles && Object.keys(groupRoles).forEach((k) => {
//         if (groupRoles[k as unknown as number].indexOf('Admin') !== -1) adm = true;
//     });
//     return adm;
// }

export const HomePage: React.FunctionComponent<HomeIndexPageProps> = ({ onLogout }) => {


    if (/*isMember*/true) return (
        <UserWelcomePage onLogout={onLogout} />
    );

    // (TODO) other options of pages (e.g. site landing page, marketplace)

    // return <P404 />;
}
