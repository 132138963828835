import * as React from 'react';

import { TextI } from './TextI';

export interface SLTextBlockProps {
    active: boolean;
    answered: boolean;
    placeholder?: string;
    questionBody: string;
    expectedAnswer?: string;
    value?: string;
    onChange: (newValue: string) => void;
}

export const SingleLineTextBlock: React.FunctionComponent<SLTextBlockProps> = ({ active, answered, placeholder, questionBody, expectedAnswer, value, onChange }) => {

    const isValid = function (value: string | undefined): boolean {
        let valueIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            valueIsExpected = (expectedAnswer === value);   // (TODO) for advanced comparison, toLowercase or RegExp 
        }

        return answered && valueIsExpected;
    }

    const isInvalid = function (value: string | undefined): boolean {
        let valueIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            valueIsExpected = (expectedAnswer === value);   // (TODO) could have more error cases, like partially wrong, first word, last word, etc.
        }

        return answered && !valueIsExpected;
    }

    return (
        <>
            <div className="form-group">
                <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
                <TextI
                    active={active}
                    placeholder={placeholder}
                    value={value}
                    isValid={isValid(value)}
                    isInvalid={isInvalid(value)}
                    onChange={(typedValue: string) => onChange(typedValue)}
                />
            </div>
        </>
    );
}
