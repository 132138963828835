import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { handleLink } from '../../domain/_shared';

import { EffectContentArea } from '../EffectContentArea';

// (TODO) delete TEMPORARY object definition (transpose to MeetingBodyProps)
interface Meeting {
    id?: number;
    name?: string;
    description?: string;
    durationMinutes?: number;
    scheduledDate?: string;     // 'yyyy-MM-dd' format
    startDate?: string;
    finishDate?: string;
    status?: string;
    body?: string;
}

export interface MeetingBodyProps {
    meeting: Meeting;
}

export const MeetingBody: React.FunctionComponent<MeetingBodyProps> = ({ meeting }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="card card-meeting">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-2 col-xl-1 d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <div className="card-meetin-icon d-flex p-1" style={{ alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', borderRadius: '5px' }}>
                                <span className="icon-calendar text-info h2 mb-0"></span>
                            </div>
                        </div>
                        <div className="col-sm-10 col-xl-11 mt-3 mt-sm-0">
                            <p className="card-title mb-1 text-info h6"><strong>{meeting.name}</strong></p>
                            {meeting.scheduledDate &&
                                <p className="card-text text-muted"><strong>{t('dates.long', { valueObj: { type: 'withTZdatetime', data: meeting.scheduledDate } })}</strong></p>
                            }
                            <p className="mb-0 text-info"><strong>{t('MeetingBody.duration')}</strong>: {meeting.durationMinutes}{meeting.durationMinutes ? 'min' : ''}</p>
                            {(meeting.status === 'Finished') && <p className="mb-0 text-info"><strong>{t('MeetingBody.status')}</strong>: {t('MeetingBody.finished')}</p>}
                            {/* <p className="card-text mb-0"><strong>Start</strong>: {meeting.startDate}</p>
                          <p className="card-text"><strong>End</strong>: {meeting.finishDate}</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <EffectContentArea rawHTML={meeting.body || ''} onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))} />
        </div>
    );
}