import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../../../components/Cards/Card';
import { CardViewType } from '../../../../../components/Cards/CardSection';

export interface CoachCourseCardProps {
    cardViewType?: CardViewType;

    courseStatus: string;
    courseType: string;
    name: string;
    courseImgUrl?: string;
    groupName?: string;
    description: string;

    traineeCount?: number;
    notStartedCount?: number;
    inProgressCount?: number;
    finishedCount?: number;

    onCourseClick?: () => void;
}

export const CoachCourseCard: React.FunctionComponent<CoachCourseCardProps> = ({
    cardViewType,

    courseStatus,
    courseType,
    name,
    courseImgUrl,
    groupName,
    description,

    traineeCount,
    notStartedCount,
    inProgressCount,
    finishedCount,

    onCourseClick,
}) => {
    const { t } = useTranslation();

    if (['Draft', 'Test', 'Published'].indexOf(courseStatus) === -1
        || ['CoachAssisted'].indexOf(courseType) === -1) {
        return (
            <Card
                cardViewType={cardViewType}
                body={() => (
                    <>
                        <h2 className="card-title h6">
                            <a href="javascript:void(0)" onClick={() => onCourseClick!()}>{name}</a>
                        </h2>
                        <div style={{ color: 'red' }} className="mt-1 mt-md-2"><em>(invalid course)</em></div>
                    </>
                )}
            />
        );
    }

    return (
        <Card
            cardViewType={cardViewType}
            header={() => (
                <>
                  <div className="header-wrapper">
                    {(courseImgUrl) && <img className="card-img-top rounded mb-2" src={courseImgUrl} alt="(some-URL)"></img>}
                    <div className="header-content">                    
                      <h2 className="card-title h6">
                          <a href="javascript:void(0)" onClick={() => onCourseClick!()}>{name}</a>
                      </h2>
                      <div className="mt-1 mt-md-2 text-info">
                          <small><i className="icon icon-people"></i>
                              {' '} <strong>{t('CourseCard.youAreCoach')}</strong></small>
                      </div>
                      <div className="mt-1 mt-md-2">
                          <strong>{t('CourseCard.group')}:</strong>
                          {' '} {groupName}
                      </div>
                      {(courseStatus === 'Draft') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}><span className="badge badge-pill badge-info">{t('CourseCard.draftLbl')}</span></p>
                      </div>}
                      {(courseStatus === 'Test') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}><span className="badge badge-pill badge-light">{t('CourseCard.testLbl')}</span></p>
                      </div>}
                      <p className="card-text text-muted mt-1 mt-md-2 mb-1 d-none d-sm-block">{description}</p>
                    </div>
                  </div>
                </>
            )}
            body={() => (
                <>
                    <div className="row">
                        <div className="col-6 col-md-12 col-lg-6">
                            <div className="">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.trainees')}:</span> {traineeCount}</small>
                            </div>
                            <div className="">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.notStarted')}:</span> {notStartedCount}</small>
                            </div>
                        </div>
                        <div className="col-6 col-md-12 col-lg-6">
                            <div className="">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.ongoing')}:</span> {inProgressCount}</small>
                            </div>
                            <div className="">
                                <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.finished')}:</span> {finishedCount}</small>
                            </div>
                        </div>
                    </div>
                </>
            )}
            footer={() => (
                <button className="btn btn-primary" type="button" onClick={() => onCourseClick!()}>{t('CourseCard.traineesProgressBtn')}</button>
            )}
        />
    )
}

