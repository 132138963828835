import * as React from 'react';

export interface CheckboxIProps {
    active: boolean;
    id: string;
    value: string;
    label: string;
    isChecked: boolean;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (checkedValue: string) => void;
}

export const CheckboxI: React.FunctionComponent<CheckboxIProps> = ({ active, id, value, label, isChecked, isValid, isInvalid, onChange }) => {
    return (
        <div className={"form-check survey-form-t-margin" + (isValid ? 'is-valid' : '') + (isInvalid ? 'is-invalid' : '')}>
            <input
                className="form-check-input"
                type="checkbox"
                disabled={!active}
                id={id}
                value={value}
                checked={isChecked}
                onChange={e => onChange(e.target.value)}
            />
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
}