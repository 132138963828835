import * as React from 'react';
import { useContext, useReducer, useEffect } from 'react';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../api/react-helper';
import { APP_Default_Language, APP_Default_Timezone, APP_Default_UUID } from '../_defaults';
import { Auth, isRequestError, UserPresets } from '../_shared';
import * as services from '../services';

import { GlobalContext } from './GlobalContext';
import { AuthContext } from './AuthContext';

import { LoadingPage } from '../../components/LoadingPage';

type State = {
    presets?: UserPresets;
    firstLoad?: boolean;
}

type Action = {
    SetPresets(presets: UserPresets): State;
    ClearPresets(): State;
    OverwriteNickname: (nickName: string) => State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetPresets: presets => {
        localStorage.setItem('presets', JSON.stringify(presets));
        return { ...state, presets, firstLoad: true };
    },
    ClearPresets: () => {
        localStorage.removeItem('presets');
        return { ...state, presets: undefined, firstLoad: false };
    },
    OverwriteNickname: nickName => {
        if (state.presets) {
            const newUP: UserPresets = { ...state.presets, nickName };
            localStorage.setItem('presets', JSON.stringify(newUP));
            return { ...state, presets: newUP };
        }
        return state;   // OBS.: no effect if unlogged
    }
})

export interface UserContextProps { state: State, actions: ActionDispatcher<Action, State> };
export const UserContext = React.createContext<UserContextProps>({} as { state: State, actions: ActionDispatcher<Action, State> });
export const UserContextProvider: React.FunctionComponent = ({ children }) => {
    const { actions: globalActions } = useContext(GlobalContext);
    const { state: { auth } } = useContext(AuthContext);
    const [state, actions] = wrapperReducer(useReducer(reducer, {
        presets: tryRetrievePresets(),
        firstLoad: false
    }));
    const { presets, firstLoad } = state;

    useLoadPresets(auth, actions, presets);

    useEffect(() => {
        if (firstLoad) {
            if (presets) {
                globalActions.send(a => a.SetLanguageAndTimezone(
                    presets.language || APP_Default_Language,
                    presets.timezone || APP_Default_Timezone
                ));
            }
            else {
                globalActions.send(a => a.SetLanguageAndTimezone(
                    APP_Default_Language,
                    APP_Default_Timezone
                ));
            }
        }
    }, [firstLoad]);

    if (!presets) return <LoadingPage />

    return (
        <UserContext.Provider value={{ state, actions }}>
            {children}
        </UserContext.Provider>
    )
}

function useLoadPresets(auth: Auth | undefined, actions: ActionDispatcher<Action, State>, currentPresets?: UserPresets) {
    useAsyncEffect(async () => {
        try {
            if (auth) {
                if (!currentPresets) {
                    // (TODO) workaround getting nickName & language & timezone from profile, but need later 
                    // to separate front-end custom configurations (getUserPresets) from UserProfile data
                    const presets = await services.getUserProfile(auth);
                    if (isRequestError(presets)) {
                        console.log(presets);
                    } else {
                        actions.send(a => a.SetPresets({
                            uuid: presets.sub || APP_Default_UUID,
                            nickName: presets.preferred_username || '(unknown)',
                            language: presets.locale || APP_Default_Language,
                            timezone: presets.zoneinfo || APP_Default_Timezone
                            // other UserProfile fields coming from API ignored
                        }));
                    }
                }
            } else {
                actions.send(a => a.ClearPresets());
            }
        }
        catch (e) {
            console.error(`Unable to GET UserPresets:\n` + JSON.stringify(e));
        }
    }, [auth]);
}

function tryRetrievePresets(): UserPresets | undefined {
    const storagePresets = localStorage.getItem('presets');

    if (!!storagePresets) {
        try {
            const presets = JSON.parse(storagePresets);
            if (isUserPresetsType(presets)) {
                return presets;
            } else {
                console.error('LOCALSTORAGE: \'presets\' parsed object doesn\'t match, removing key...');
            }
        } catch (e) {
            console.error('LOCALSTORAGE: \'presets\' is not a parseable object, removing key...');
        }
    }

    localStorage.removeItem('presets');
    return undefined;
}

// NOTE: SHALLOW TYPE CHECK
function isUserPresetsType(obj: any): obj is UserPresets {
    if (obj !== undefined && obj !== null && typeof (obj) === 'object'
        && obj.uuid && typeof (obj.uuid) === 'number'
        && obj.nickName && typeof (obj.nickName) === 'string'
        && obj.language && typeof (obj.language) === 'string'
        && obj.timezone && typeof (obj.timezone) === 'string'
        // NOTE: not checking extra properties if existent
    ) {
        return true;
    }

    return false;
}
