import * as React from 'react';
import { useTranslation } from 'react-i18next';

const brand = require('../assets/img/logo.png');

export interface PrivacyPolicyPageRouteParameters {
}

export interface PrivacyPolicyPageProps {
}

export const PrivacyPolicyPage: React.FunctionComponent<PrivacyPolicyPageProps> = ({ }) => {
    const { t } = useTranslation();

    return (
        <>
            <header className="app-header navbar text-center" style={{ display: 'block' }}>
                <a className="navbar-brand" href="/" style={{ backgroundImage: `url(${brand})` }}></a>
            </header>

            <div className="app-body">
                <div className="main">
                    <div className="container mt-3">
                        <div className="header-page hasBtnBack hasInfo">
                            <a className="btn-back" href="javascript:void(0)" onClick={() => history.back()}>
                                <i className="icon fa fa-arrow-left"></i>
                                <span className="label" style={{ display: 'inline-block' }}>{t('PrivacyPolicyPage.goBackBtn')}</span>
                            </a>
                        </div>

                        <div className="card bg-white px-4 pt-3 mt-2">
                            <div className="card card-section">

                                <div className="card-header">
                                    <h1 className="h5 m-0 float-left title-color">{t('PrivacyPolicyPage.title')}</h1>
                                </div>

                                <div className="card-body">
                                    <hr className="mt-0 mb-4" />
                                    <div className="row">
                                        <div className="col-12">

                                            <p><strong>{t('PrivacyPolicyPage.section1.strong')}</strong></p>
                                            <p>{t('PrivacyPolicyPage.section1.p1')}</p>
                                            <p>{t('PrivacyPolicyPage.section1.p2')}</p>
                                            <p>{t('PrivacyPolicyPage.section1.p3')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section2.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section2.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section3.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section3.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section4.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section4.p1')}</p>
                                            <p>{t('PrivacyPolicyPage.section4.p2')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section5.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section5.p1')}</p>
                                            <ul>
                                                <li>{t('PrivacyPolicyPage.section5.li1')}</li>
                                                <li>{t('PrivacyPolicyPage.section5.li2')}</li>
                                                <li>{t('PrivacyPolicyPage.section5.li3')}</li>
                                                <li>{t('PrivacyPolicyPage.section5.li4')}</li>
                                            </ul>
                                            <p>{t('PrivacyPolicyPage.section5.p2')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section6.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section6.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section7.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section7.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section8.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section8.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section9.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section9.p1')}</p>

                                            <hr className="mt-4 mb-4" />
                                            <h2 className="h6">{t('PrivacyPolicyPage.section10.title')}</h2>
                                            <p>{t('PrivacyPolicyPage.section10.p1')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}