import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { validPassword } from '../../../domain/_defaults';

export interface ChangePasswordCardProps {
    feedbackError?: string;
    disabled?: boolean;
    isSending?: boolean;
    onCancel: () => void;
    onSubmit: (oldPassword: string, newPassword: string, confirmPassword: string) => void;
}

export const ChangePasswordCard: React.FunctionComponent<ChangePasswordCardProps> = ({
    feedbackError,
    disabled,
    isSending,
    onCancel,
    onSubmit
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        oldPassword: '',
        invalidOldMessage: undefined as string | undefined,
        newPassword: '',
        invalidNewMessage: undefined as string | undefined,
        confirmPassword: '',
        invalidConfirmMessage: undefined as string | undefined
    });
    const { oldPassword, invalidOldMessage, newPassword, invalidNewMessage, confirmPassword, invalidConfirmMessage } = state;

    function checkAndSend() {
        let a: string | undefined;
        let b: string | undefined;
        let c: string | undefined;

        if (oldPassword.length === 0) a = t('ChangePasswordCard.fieldRequired');

        if (newPassword.length === 0) b = t('ChangePasswordCard.fieldRequired');
        if (!validPassword(newPassword)) b = t('ChangePasswordCard.invalidPasswordFormat');

        if (confirmPassword.length === 0) c = t('ChangePasswordCard.fieldRequired');
        if (confirmPassword !== newPassword) c = t('ChangePasswordCard.differentPasswords');

        if (!a && !b && !c) {
            onSubmit(oldPassword, newPassword, confirmPassword);
        } else {
            setState({ ...state, invalidOldMessage: a, invalidNewMessage: b, invalidConfirmMessage: c });
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h1 className="h5"><strong>{t('ChangePasswordCard.changePassword')}</strong></h1>
            </div>

            <div className="card-body">
                <ChangePasswordForm
                    feedbackError={feedbackError}
                    disabled={disabled}
                    oldPasswordText={oldPassword || ''}
                    invalidOldMessage={invalidOldMessage}
                    newPasswordText={newPassword || ''}
                    invalidNewMessage={invalidNewMessage}
                    confirmPasswordText={confirmPassword || ''}
                    invalidConfirmMessage={invalidConfirmMessage}
                    onOldPasswordTextChange={text => setState({ ...state, oldPassword: text, invalidOldMessage: undefined })}
                    onNewPasswordTextChange={text => setState({ ...state, newPassword: text, invalidNewMessage: undefined })}
                    onConfirmPasswordTextChange={text => setState({ ...state, confirmPassword: text, invalidConfirmMessage: undefined })}
                />
            </div>

            <div className="card-footer text-right">
                <FooterBtns
                    disabled={disabled || false}
                    isSending={isSending || false}
                    onCancelClick={() => onCancel()}
                    onSaveClick={() => checkAndSend()}
                />
            </div>
        </div >
    )
}

interface ChangePasswordFormProps {
    feedbackError?: string;
    disabled?: boolean;
    oldPasswordText: string;
    invalidOldMessage?: string;
    newPasswordText: string;
    invalidNewMessage?: string;
    confirmPasswordText: string;
    invalidConfirmMessage?: string;
    onOldPasswordTextChange: (text: string) => void;
    onNewPasswordTextChange: (text: string) => void;
    onConfirmPasswordTextChange: (text: string) => void;
}

const ChangePasswordForm: React.FunctionComponent<ChangePasswordFormProps> = ({
    feedbackError,
    disabled,
    oldPasswordText,
    invalidOldMessage,
    newPasswordText,
    invalidNewMessage,
    confirmPasswordText,
    invalidConfirmMessage,
    onOldPasswordTextChange,
    onNewPasswordTextChange,
    onConfirmPasswordTextChange
}) => {
    const { t } = useTranslation();

    return (
        <form className="form-horizontal col-md-10 col-lg-8 col-xlg-6">
            {feedbackError && <div className="alert alert-danger">{feedbackError}</div>}

            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-oldpassword">{t('ChangePasswordCard.oldPassword')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidOldMessage ? 'is-invalid' : '')}
                        disabled={disabled}
                        id="profile-oldpassword"
                        type="password"
                        name="profile-oldpassword"
                        value={oldPasswordText}
                        onChange={e => onOldPasswordTextChange(e.target.value)}
                    />
                    {invalidOldMessage && <div className="invalid-feedback">{invalidOldMessage}</div>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-newpassword">{t('ChangePasswordCard.newPassword')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidNewMessage ? 'is-invalid' : '')}
                        disabled={disabled}
                        id="profile-newpassword"
                        type="password"
                        name="profile-newpassword"
                        value={newPasswordText}
                        onChange={e => onNewPasswordTextChange(e.target.value)}
                    />
                    {invalidNewMessage && <div className="invalid-feedback">{invalidNewMessage}</div>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-confirmPassword">{t('ChangePasswordCard.confirmPassword')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidConfirmMessage ? 'is-invalid' : '')}
                        disabled={disabled}
                        id="profile-confirmPassword"
                        type="password"
                        name="profile-confirmPassword"
                        value={confirmPasswordText}
                        onChange={e => onConfirmPasswordTextChange(e.target.value)}
                    />
                    {invalidConfirmMessage && <div className="invalid-feedback">{invalidConfirmMessage}</div>}
                </div>
            </div>
        </form>
    )
}

interface FooterBtnsProps {
    disabled: boolean;
    isSending: boolean;
    onCancelClick: () => void;
    onSaveClick: () => void;
}

const FooterBtns: React.FunctionComponent<FooterBtnsProps> = ({
    disabled,
    isSending,
    onCancelClick,
    onSaveClick
}) => {
    const { t } = useTranslation();

    return (
        isSending
            ? (
                <>
                    <button
                        type="button"
                        className="btn btn-light m-1"
                        disabled={true}
                    >
                        {t('ChangePasswordCard.cancelBtn')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-loader btn-primary m-1"
                        disabled={true}
                    >
                        <div className="sk-rotating-plane"></div>
                        <span className="label small">{t('ChangePasswordCard.saveBtn')}</span>
                    </button>
                </>
            )
            : (
                <>
                    <button
                        type="button"
                        className="btn btn-light m-1"
                        disabled={disabled}
                        onClick={() => onCancelClick()}
                    >
                        {t('ChangePasswordCard.cancelBtn')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary m-1"
                        disabled={disabled}
                        onClick={() => onSaveClick()}
                    >
                        {t('ChangePasswordCard.saveBtn')}
                    </button>
                </>
            )

    )
}
