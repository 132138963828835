import * as React from 'react';
import { useState, useEffect } from 'react';

import { ClickableContentArea } from './ClickableContentArea';

export interface EffectContentAreaProps {
    rawHTML: string;
    // iFrameLoadedPattern: string Regex (TODO)
    // iFrameEffectPattern: string Regex (TODO)
    currentPostMessage?: Event | undefined;
    onLinkClick: (url: string) => void;
    onIframeMessageEvent?: (data: {}) => void;
}

export const EffectContentArea: React.FunctionComponent<EffectContentAreaProps> = ({ rawHTML, currentPostMessage, onLinkClick, onIframeMessageEvent }) => {
    if (!onIframeMessageEvent) {
        // NOTE: bypass iFrame message check
        return (<ClickableContentArea rawHTML={rawHTML} onLinkClick={onLinkClick} />)
    }

    return (
        <IFrameContentArea
            rawHTML={rawHTML}
            // iFrameLoadedPattern: string Regex (TODO)
            // iFrameEffectPattern: string Regex (TODO)
            currentPostMessage={currentPostMessage}
            onLinkClick={onLinkClick}
            onIframeMessageEvent={onIframeMessageEvent}
        />
    );
}

interface IFrameContentAreaProps {
    rawHTML: string;
    // iFrameLoadedPattern: string Regex (TODO)
    // iFrameEffectPattern: string Regex (TODO)
    currentPostMessage?: Event | undefined;
    onLinkClick: (url: string) => void;
    onIframeMessageEvent: (data: {}) => void;
}

const IFrameContentArea: React.FunctionComponent<IFrameContentAreaProps> = ({ rawHTML, currentPostMessage, onLinkClick, onIframeMessageEvent }) => {
    const receivedMessage = currentPostMessage as MessageEvent | undefined;
    const [isLoaded, setIsLoaded] = useState(false as boolean);

    const checkIsReady = (e: MessageEvent | undefined): void => {
        if (e == null) return;   // initial state of channel is set as 'undefined'

        let m: any;
        try {
            m = JSON.parse(e.data);
            if (typeof m !== 'object' || m === null) throw "malformed";
        } catch (err) {
            // just ignore any posted messages that do not consist of valid JSON
            return;
        }

        if (typeof m.method === 'string') {
            const ar = m.method.split('::');
            if (ar.length == 2 && ar[1] === '__ready') setIsLoaded(true);
        }

        // setIsLoaded(false) iFrame still loading
        // NOTE: be sure that postMessage's data pattern is "{\"method\":\"JSInput::__ready\",\"params\":\"ping\"}"
    }

    const checkStatusChange = (e: MessageEvent | undefined): void => {
        if (e == null) return;   // if for some reason the channel gets cleaned up

        //console.log(`E.data: ${JSON.stringify(e.data)}`);
        let m: any;
        try {
            if (typeof e.data === 'object') {
                m = e.data;
            } else {
                m = JSON.parse(e.data);
                if (typeof m !== 'object' || m === null) throw "malformed";
            }
        } catch (err) {
            // just ignore any posted messages that do not consist of valid JSON
            return;
        }

        // console.log(`M: ${JSON.stringify(m)}`);
        if (m.height !== undefined) onIframeMessageEvent(m);    // (TODO) change this to Regex comparison for generalization

        // NOTE: nothing happens unless message format is specifically {height:"some-string"}
    }

    useEffect(() => {
        console.log(`IFRAME ISLOADED: ${isLoaded}`);
        if (!isLoaded) {
            checkIsReady(receivedMessage);
        } else {
            checkStatusChange(receivedMessage);
        }
    }, [isLoaded, receivedMessage]);

    return (
        <ClickableContentArea rawHTML={rawHTML} onLinkClick={onLinkClick} />
    );
}
