import * as React from 'react';
import { useReducer } from 'react';
import jwt_decode from 'jwt-decode';

import { isExpired } from '../../api/utils';
import { getCookie, setCookie, deleteCookie } from '../../api/browser';
import { ActionMatcher, ActionDispatcher, wrapperReducer } from '../../api/react-helper';
import { Auth, __TOKEN_DOMAIN__ } from '../_shared';

const cookieDomain = __TOKEN_DOMAIN__ ? `.${__TOKEN_DOMAIN__}` : 'localhost';

type State = {
    auth?: Auth;
}

type Action = {
    SetAuth: (auth: Auth) => State;
    ClearAuth: () => State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetAuth: auth => {
        localStorage.setItem('auth', JSON.stringify(auth));
        overrideCookie('dydBearer', auth.access_token, auth[".expires"]);
        return { ...state, auth };
    },
    ClearAuth: () => {
        localStorage.clear();
        deleteCookie('dydBearer', cookieDomain);
        return { ...state, auth: undefined }
    }
})

export interface AuthContextProps { state: State, actions: ActionDispatcher<Action, State> };
export const AuthContext = React.createContext<AuthContextProps>({} as { state: State, actions: ActionDispatcher<Action, State> });
export const AuthContextProvider: React.FunctionComponent = ({ children }) => {
    const [state, actions] = wrapperReducer(useReducer(reducer, {
        auth: tryRetrieveAuth()
    }));

    return (
        <AuthContext.Provider value={{ state, actions }}>
            {children}
        </AuthContext.Provider>
    )
}

function tryRetrieveAuth(): Auth | undefined {
    try {
        const cookieToken = getCookie('dydBearer');
        if (!!cookieToken) {
            const decoded = jwt_decode<BearerToken>(cookieToken);
            if (!!decoded.exp) {
                if (!isExpired(decoded.exp * 1000)) {
                    // NOTE: if Cookie OK, check if current Auth is same
                    const storageAuth = localStorage.getItem('auth');

                    if (!!storageAuth) {
                        try {
                            const auth = JSON.parse(storageAuth);
                            if (isAuthType(auth)) {
                                if (cookieToken === auth.access_token) {
                                    return auth;
                                } else {
                                    console.error('LOCALSTORAGE: \'auth\' differs from \'dydBearer\' (cookie), replacing key...');
                                }
                            } else {
                                console.error('LOCALSTORAGE: \'auth\' parsed object doesn\'t match, replacing key...');
                            }
                        } catch (e) {
                            console.error('LOCALSTORAGE: \'auth\' is not a parseable object, replacing key...');
                        }
                    }

                    // NOTE: Set a placeholder Auth object
                    localStorage.removeItem('auth');
                    const auth = {
                        '.expires': (new Date(decoded.exp * 1000)).toUTCString(),
                        '.issued': 'Thu, 01 Jan 1970 00:00:01 GMT', // NOTE: placeholder value (not used)
                        access_token: cookieToken,
                        expires_in: 9999999,                        // NOTE: placeholder value (not used)
                        token_type: 'bearer',
                        userName: 'placeholder'                     // NOTE: placeholder value (not used)
                    };
                    localStorage.setItem('auth', JSON.stringify(auth));
                    return auth;

                } else {
                    console.error('COOKIE: \'dydBearer\' already expired, removing key...');
                }
            } else {
                console.error('COOKIE: \'dydBearer\' has no \'exp\' property, removing key...');
            }
        } else {
            console.error('COOKIE: \'dydBearer\' doesn\'t exist, clearing auth...');
        }
    } catch (e) {
        console.error('[AuthContext]: some error while retrieving stored token...');
    }

    localStorage.removeItem('auth');
    localStorage.removeItem('presets');
    deleteCookie('dydBearer', cookieDomain);
    return undefined;
}

// NOTE: SHALLOW TYPE CHECK
function isAuthType(obj: any): obj is Auth {
    if (obj !== undefined && obj !== null && typeof (obj) === 'object'
        && obj[".expires"] && typeof (obj[".expires"]) === 'string'
        && obj[".issued"] && typeof (obj[".issued"]) === 'string'
        && obj.access_token && typeof (obj.access_token) === 'string'
        && obj.userName && typeof (obj.userName) === 'string'
        && obj.expires_in && typeof (obj.expires_in) === 'number'
        && obj.token_type && typeof (obj.token_type) === 'string'
        // NOTE: not checking extra properties if existent
    ) {
        return true;
    }

    return false;
}

type BearerToken = {
    sub: number;
    aud: string[];
    nbf: number;
    exp: number;
    iat: number;
    iss: string;
}

const overrideCookie = (key: string, token: string, expiration: string): void => {
    const previousCookie = getCookie(key);
    if (!!previousCookie) console.log(`[overrideCookie]: previous cookie overridden`);

    deleteCookie(key, cookieDomain);
    if (!setCookie(key, token, cookieDomain, '/', expiration, false)) {
        console.error(`[overrideCookie]: Unable to set cookie for domain Auth sharing`);
    }
}
