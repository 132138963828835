import * as React from 'react';
import { useState, useEffect } from 'react';

export interface SearchInputProps {
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (typedValue: string) => void;
    onFocus: () => void;
    onBlur?: () => void;
}

export const SearchInput: React.FunctionComponent<SearchInputProps> = ({ active, placeholder, value, isValid, isInvalid, onChange, onFocus, onBlur }) => {
    const [searchInputValue, updteSearchValue] = useState('');

    useEffect(() => {
        onChange(searchInputValue)
    }, [searchInputValue]);

    return (
        <div className="form-group form-search">
            <i className="fa fa-search form-search-icon"></i>
            <input
                className={"form-control " + (isValid ? 'is-valid' : '') + (isInvalid ? 'is-invalid' : '')}
                type="text"
                disabled={!active}
                placeholder={placeholder}
                value={searchInputValue}
                maxLength={200}
                onChange={(e) => updteSearchValue(e.target.value)}
                onFocus={() => onFocus()}
            // onBlur={() => onBlur()}
            />
        </div>
    );
}
