import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { scrollToPosition } from '../../../api/utils';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { Quiz, AnswersData, Question } from '../../../domain/models';
import { extractInitialFrom, updateSingleAnswer, missingRequiredAnswers, isOwnTask, isAnswerable, isAnswered } from '../../../components/Task/synchronous-helpers';

import { P50x } from '../../../components/P50x';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { ButtonBack } from '../../../components/Buttons/ButtonBack';
import { FeedbackBox } from '../../../components/Task/FeedbackBox';
import { QuizFeedback } from '../../../components/Task/QuizFeedback';
import { CountdownTrigger } from '../../../components/Task/CountdownTrigger';
import { CardSection, CardSectionLoading, CardViewType } from '../../../components/Cards/CardSection';
import { ClickableContentArea } from '../../../components/ClickableContentArea';
import { QuestionsList } from '../../../components/Forms/QuestionsList';
import { SubmitForm } from '../../../components/Buttons/SubmitForm';
import { CancelForm } from '../../../components/Buttons/CancelForm';

export interface QuizPageRouteParameters {
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export interface QuizPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

type State = {
    apiError?: any | undefined;
    quiz?: Quiz;
    answers?: AnswersData;
    missingAnswers?: Question["id"][];
    operation?: string;
    lock?: boolean;
    submitted?: boolean;
    newStatus?: string;
    showFeedback?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadQuiz(quiz?: Quiz): State;
    SetAnswers(answers: AnswersData): State;
    CheckAnswers(missingAnswers: Question["id"][]): State;
    RemoveMissingAnswer(missingAnswers: Question["id"][], questionId: number): State;
    InactivateForm(): State;
    SendForm(operation: string): State;
    UnlockSend(): State;
    PostStatus200(newStatus: string): State;
    ToggleFeedback(showFeedback: boolean): State;
    ReactivateForm(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadQuiz: quiz => ({ ...state, quiz }),
    SetAnswers: answers => ({ ...state, answers }),
    CheckAnswers: missingAnswers => ({ ...state, missingAnswers }),
    RemoveMissingAnswer: (missingAnswers, questionId) => ({ ...state, missingAnswers: missingAnswers.filter((i) => i !== questionId) }),
    InactivateForm: () => ({ ...state, submitted: true }),
    SendForm: operation => ({ ...state, operation, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    PostStatus200: newStatus => ({ ...state, lock: false, submitted: true, newStatus }),
    ToggleFeedback: showFeedback => ({ ...state, showFeedback }),
    ReactivateForm: () => ({ ...state, submitted: false })
})

export const QuizPage: React.FunctionComponent<QuizPageProps> = ({
    auth,
    onLogout,
    groupId,
    courseId,
    lessonId,
    taskId
}) => {
    const { t } = useTranslation();
    const [{
        apiError,
        quiz,
        answers,
        missingAnswers,
        operation,
        lock,
        submitted,
        newStatus,
        showFeedback
    }, actions] = wrapperReducer(useReducer(reducer, {
        lock: false,
        submitted: false,
        showFeedback: false
    }));

    const goToRoot = () => Router.push('/');
    const goToLessons = () => Router.push(`/groups/${groupId}/trainee/courses/${courseId}`);

    useLoadQuiz(auth, taskId, actions);
    useSendQuiz(lock || false, submitted || false, auth, taskId, operation || '', answers || {}, missingAnswers || [], actions);
    useReloadQuiz(submitted || false, newStatus, auth, taskId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <div>
            {/* <div className="header-page hasBtnBack hasInfo">
                <ButtonBack text={t('TaskPage.goBack')} onClick={goToLessons} />
                <h1 className="h4 header-page-title title-color">{t('TaskPage.task')}</h1>
            </div> */}

            {(quiz)
                ? (
                    <>
                        {showFeedback && <FeedbackBox>
                            <QuizFeedback
                                isOwnTask={isOwnTask(quiz, 'trainee')}
                                status={(!newStatus) ? quiz.status : newStatus}
                                activeResume={!!submitted}
                                onResume={() => actions.send(a => a.ReactivateForm())}
                                scoreCriteria={quiz.minPassingScore}
                                score={quiz.score || 0}
                                activeRetry={!!submitted}
                                onRetry={() => actions.send(a => a.ReactivateForm())}
                            />
                            {/* {(newStatus === 'Passed') &&
                                <CountdownTrigger
                                    cancellable={true}
                                    initialSeconds={2}
                                    onTick={() => { }}  // OBS.: optional 1s trigger
                                    onTrigger={goToLessons}
                                />
                            } */}
                        </FeedbackBox>}

                        <div className="card bg-white px-4 pt-3">
                            <CardSection
                                title={quiz.name}
                                showGridListToggle={false}
                                initialCardViewType={CardViewType.List}
                            >
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />

                                    {quiz.body && (
                                        <>
                                            <ClickableContentArea
                                                rawHTML={quiz.body || ''}
                                                onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))}
                                            />
                                            <hr className="mt-0 mb-4" />
                                        </>
                                    )}

                                    <QuestionsList
                                        active={isAnswerable(quiz, 'trainee') && !lock && !submitted}
                                        visible={isOwnTask(quiz, 'trainee') || !quiz.isResponsePrivate}
                                        answered={isAnswered(quiz)}
                                        questions={quiz.questions}
                                        currentValues={answers}
                                        missingAnswers={missingAnswers}
                                        onChange={(type, id, val) => {
                                            actions.send(a => a.SetAnswers(updateSingleAnswer(type, id, val, answers)));
                                            (!!missingAnswers) && actions.send(a => a.RemoveMissingAnswer(missingAnswers, id));
                                        }}
                                    />

                                    {/* <hr /> */}

                                    <div className="col-12 text-right">
                                        {(isAnswerable(quiz, 'trainee'))
                                            ? (<>
                                                <SubmitForm
                                                    active={!lock && !submitted}
                                                    secondaryColor={true}
                                                    label={t('TaskPage.saveBtn')}
                                                    loading={!!lock && (operation === 'save')}
                                                    onSubmit={() => {
                                                        actions.send(a => a.CheckAnswers([]));
                                                        actions.send(a => a.SendForm('save'));
                                                    }}
                                                />
                                                <SubmitForm
                                                    active={!lock && !submitted}
                                                    label={t('TaskPage.submitBtn')}
                                                    loading={!!lock && (operation === 'submit')}
                                                    onSubmit={() => {
                                                        actions.send(a => a.CheckAnswers(missingRequiredAnswers(quiz, answers)));
                                                        actions.send(a => a.SendForm('submit'));
                                                    }}
                                                />
                                                <CancelForm
                                                    active={!lock && !submitted}
                                                    label={t('TaskPage.cancelBtn')}
                                                    onCancel={() => { actions.send(a => a.SetAnswers({})); goToLessons(); }}
                                                />
                                            </>)
                                            : ""
                                        }
                                    </div>

                                </div>
                            </CardSection>
                        </div>
                    </>
                )
                : <CardSectionLoading description={true} showGridListToggle={true} initialCardViewType={CardViewType.List} />}
        </div>
    );
}

const useLoadQuiz = function (auth: Auth, quizId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            actions.send(a => a.LoadQuiz(undefined));
            const quiz = await services.getTraineeQuizTask(auth, quizId);
            if (isRequestError(quiz)) {
                // TODO: log the error
                // TODO: Diogo - create request error message
                // TODO: use error boundary
                console.log(quiz);
            }
            else {
                actions.send(a => a.LoadQuiz(quiz));
                actions.send(a => a.SetAnswers(extractInitialFrom(quiz)));
                scrollToPosition(0, 0);

                if (quiz.status !== 'NotStarted') {
                    actions.send(a => a.InactivateForm());
                    actions.send(a => a.ToggleFeedback(true));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [quizId]);
}

const useSendQuiz = function (lock: boolean, submitted: boolean, auth: Auth, quizId: number, operation: string, answers: AnswersData, missingAnswers: Question["id"][], actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                if (missingAnswers.length === 0) {
                    const status = await services.postTraineeQuizAnswer(auth, quizId, { op: operation, answers });
                    if (isRequestError(status)) {
                        // TODO: log the error
                        // TODO: Diogo - create request error message
                        // TODO: use error boundary
                        console.log(status);

                        // (TODO) unlock resend in any error case?
                        actions.send(a => a.UnlockSend());
                    }
                    else {
                        actions.send(a => a.PostStatus200(status));           // Freezes Submit/Cancel
                        actions.send(a => a.ToggleFeedback(true));
                        scrollToPosition(0, 0);
                    }
                } else {
                    actions.send(a => a.UnlockSend());
                    scrollToPosition(0, 0);
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}

const useReloadQuiz = function (submitted: boolean, newStatus: string | undefined, auth: Auth, quizId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (submitted && (newStatus === 'Failed' || newStatus === 'Passed')) {
                const quiz = await services.getTraineeQuizTask(auth, quizId);
                if (isRequestError(quiz)) {
                    // TODO: log the error
                    // TODO: Diogo - create request error message
                    // TODO: use error boundary
                    console.log(quiz);
                }
                else {
                    actions.send(a => a.LoadQuiz(quiz));
                    actions.send(a => a.SetAnswers(extractInitialFrom(quiz)));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [submitted, newStatus]);
}
