import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { isRequestError } from '../../../../../domain/_shared';
import * as services from '../../../../../domain/services';
import * as AdminActions from '../../actions';

import { MembersMgmtContext } from '../../MembersMgmtContext';

import { LoadingPage } from '../../../../../components/LoadingPage';
import { SearchInput } from '../../../../../components/Search/SearchInput';
import { MembersFiltersDropDown } from './MembersFiltersDropDown';
import { MembersSorterDropDown } from './MembersSorterDropDown';
import { BadgeFilter } from '../../../../../components/Badges/BadgeFilter';
import { ApprovePendingMembersPopup } from './ApprovePendingMembersPopup';
import { MembersList } from './MembersList';
import { MembersPaginationCustom } from './MembersPagination';
import { ErrorPopUp } from '../../../../../components/Popup/ErrorPopUp';

export interface GroupMembersListProps {
    groupId: any;
}

export const GroupMembersList: React.FunctionComponent<GroupMembersListProps> = ({ groupId }) => {
    const { state: { auth, membersList, searchText, selectedFilters, resultsPerPage, selectedMembers, reloadMembersList, isAdmin }, actions } = useContext(MembersMgmtContext);
    const { t } = useTranslation();
    const [popUpErrorMsg, toggleErrorPopUp] = useState('')
    const [isVisibleApprovePendingPopUp, toggleApprovePendingPopUp] = useState(false)

    if (!membersList) return <LoadingPage />
    const members = membersList.results

    let dispatchedRequest = 0

    const searchHandler = (searchText: string) => {
        actions.send(a => a.updateSearchText(searchText))
    }

    const filterBadgeCloseHandler = (filter: string) => {
        let updatedFilterList = !selectedFilters ? [] : [...selectedFilters]
        updatedFilterList.splice(updatedFilterList.indexOf(filter), 1)
        actions.send(a => a.updateFilters(updatedFilterList))
    }

    const PendingBadgeCloseHandler = () => {
        actions.send((a: any) => a.changeAdminFilter())
    }

    const isPendingUsersExist = () => {
        let pendingUsers = []
        pendingUsers = _.filter(members, function (member) {
            return member.membershipStatus ? member.membershipStatus.toLowerCase() === 'pending' : false;
        })
        return pendingUsers.length > 0 ? true : false
    }

    const removeSelectionHandler = (memberId: number) => {
        let updatedSelectionList = selectedMembers
        updatedSelectionList.splice(updatedSelectionList.indexOf(memberId), 1)
        actions.send(a => a.updateMembersSelection([...updatedSelectionList]))
    }

    const addSelectionHandler = (memberId: number) => {
        let updatedSelectionList = selectedMembers
        updatedSelectionList.push(memberId)
        actions.send(a => a.updateMembersSelection([...updatedSelectionList]))
    }

    const tableChecked = (memberId: number) => {
        return selectedMembers.includes(memberId)
    }

    const handleRequests = (failedRequests: number[]) => {

        actions.send(a => a.reloadMembersList(!reloadMembersList))
        toggleApprovePendingPopUp(false)

        if (failedRequests.length > 0) {
            const errorMsg = t('GroupMembersList.errorPopupMsg', { failedRequests: failedRequests.length })
            toggleErrorPopUp(errorMsg)
        }
    }

    const handleApproveButton = (id: number) => {
        AdminActions.approveGroupMembers(auth, groupId, membersList.results, handleRequests, id)
    }

    const handleApproveAll = () => {
        AdminActions.approveGroupMembers(auth, groupId, membersList.results, handleRequests)
    }

    // (TODO) refactor method, doesn't make sense
    const setAdminService = async (id?: number) => {
        let selectedUsers = []
        id ? selectedUsers.push({ 'id': id }) : selectedUsers = _.filter(members, function (member) {
            return member.membershipStatus ? member.membershipStatus.toLowerCase() === 'pending' : false;
        })
        try {
            for (var i = 0; i < selectedUsers.length; i++) {
                services.setAsAdmin(auth, groupId, selectedUsers[i].id)
                    .then(() => {
                        dispatchedRequest = dispatchedRequest + 1;
                        if (dispatchedRequest >= selectedUsers.length) {
                            actions.send(a => a.reloadMembersList(!reloadMembersList))
                        }
                    })
                    .catch(error => {
                        actions.send(a => a.Set50xError(error))
                    })
            }
        } catch (e) {
            return;

        }
    }

    const setNonAdminService = async (id: number) => {
        try {
            const status = services.setAsNonAdmin(auth, groupId, id);
            if (isRequestError(status)) {
            }
            else {
                actions.send(a => a.reloadMembersList(!reloadMembersList))
            }
        } catch (e) {
            actions.send(a => a.Set50xError(e))
        }
    }

    return (
        <div className="mt-3 mt-md-4">
            <div className="container-fluid">

                <div className="list-page">
                    <div className="list-page-header">

                        <div className="list-page-actions">
                            <div className="list-page-search">
                                <SearchInput
                                    active={true}
                                    placeholder={t('GroupMembersList.searchInputPlaceholder')}
                                    value={searchText}
                                    isValid={false}
                                    isInvalid={false}
                                    onChange={searchHandler}
                                    onFocus={() => ''}
                                    onBlur={() => ''}
                                />
                            </div>
                            <div className="list-page-controls">
                                <MembersFiltersDropDown label={t('GroupMembersList.filtersDd')} styleName="btn-outline-light bg-white btn-lg" icon="fa fa-filter" />
                                <MembersSorterDropDown label={t('GroupMembersList.sorterDd')} styleName="btn-outline-light bg-white btn-lg" icon="fa fa-sort-amount-asc" />
                            </div>

                        </div>

                        <div className="list-page-filters display-flex">
                            <div className="list-page-badges">
                                <div>
                                    {selectedFilters &&
                                        selectedFilters.map((filter, i) => (
                                            <BadgeFilter key={i} label={filter === 'member' ? t('GroupMembersList.approvedStatus') : filter === 'pending' ? t('GroupMembersList.pendingStatus') : t('GroupMembersList.unknownStatus')} onCloseFilterBadge={filterBadgeCloseHandler} />
                                        ))}
                                    {isAdmin &&
                                        <BadgeFilter label={t('GroupMembersList.groupAdminRole')} onCloseFilterBadge={PendingBadgeCloseHandler} />
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="list-page-body">

                        <div className="table-group">
                            <div className="table-group-header hasInfo">
                                <div className="float-left">
                                    <div >
                                        <button className={`${!isPendingUsersExist() ? 'display-none' : 'btn btn-light btn-sm m-1'}`} onClick={() => { toggleApprovePendingPopUp(true) }}>{t('GroupMembersList.approveAll')}</button>
                                        <ApprovePendingMembersPopup isVisible={isVisibleApprovePendingPopUp} onClose={() => { toggleApprovePendingPopUp(false) }} onApprove={handleApproveAll} message={t('GroupMembersList.approveAllMsg')} groupId={groupId} />
                                    </div>
                                </div>
                                <div className="table-group-info">
                                    {t('miscelaneous.paginationItems', { begin: (membersList.page * resultsPerPage) + 1, end: (membersList.page * resultsPerPage) + membersList.results.length, total: membersList.resultsCount })}
                                </div>
                            </div>

                            <div className="table-group-body">

                                <div className="list-table fromMd">
                                    <div className="list-table-row list-table-header">
                                        <div className="list-table-col">
                                            <strong>{t('GroupMembersList.member')}</strong>
                                        </div>
                                        <div className="list-table-col">
                                            <strong>{t('GroupMembersList.email')}</strong>
                                        </div>
                                        <div className="list-table-col">
                                            <strong>{t('GroupMembersList.enrollDate')}</strong>
                                        </div>
                                        <div className="list-table-col">
                                        </div>
                                    </div>

                                    {members.map((member, index) => (
                                        <div key={member.id} className="list-table-row">
                                            <MembersList
                                                member={member}
                                                onSelect={addSelectionHandler}
                                                onUnSelect={removeSelectionHandler}
                                                isSelected={tableChecked(member.id)}
                                                onClickAppoveButton={handleApproveButton}
                                                onClickSetAdminButton={setAdminService}
                                                onClickSetNonAdminButton={setNonAdminService}
                                            />
                                        </div>
                                    ))
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="list-page-footer">
                        <MembersPaginationCustom resultsCount={membersList.resultsCount} />
                    </div>
                    <ErrorPopUp isVisible={!_.isEmpty(popUpErrorMsg)} onClose={() => { toggleErrorPopUp('') }} message={popUpErrorMsg} />
                </div>

            </div>
        </div>
    )
}