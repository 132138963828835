import * as React from 'react';

import { validURL } from '../domain/_defaults';

export interface ClickableContentAreaProps {
    rawHTML: string;
    onLinkClick: (url: string) => void;
}

export const ClickableContentArea: React.FunctionComponent<ClickableContentAreaProps> = ({ rawHTML, onLinkClick }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: rawHTML }}
            onClick={(e) => contentClickHandler(e, onLinkClick)}
        />
    );
}

const contentClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, onLinkClick: (url: string) => void) => {
    try {
        const targetLink = event.target ? (event.target as Element).closest('a') : null;
        if (!targetLink) return;

        event.preventDefault();
        const url = targetLink.href;
        if (validURL(url)) onLinkClick(url);
        else console.error(`Invalid link href: ${url}`);
    } catch (e) {
        console.error(`Some error while handling onClick in ClickableContentArea:\n${e}`);
    }
}
