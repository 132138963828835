import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Coach } from '../../../../../domain/models';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';

import { Popup } from './Popup';
import { RemoveCoachPopup } from './RemoveCoachPopup';

export interface CoachDropdownProps {
    label: string;
    styleName?: string;
    icon?: string,
    hasSearch?: boolean,
    hasDeleteBtn?: boolean,
    coachList?: Coach[],
}

export const CoachDropdown: React.FunctionComponent<CoachDropdownProps> = ({
    label,
    styleName,
    icon,
    hasSearch,
    hasDeleteBtn,
}) => {
    const { state: { coaches, selectedCoaches }, actions } = useContext(ParticipantsMgmtContext);
    const { t } = useTranslation();
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);
    const [searchText, updateSearch] = useState('' as string);
    const [filteredCoachList, updateFilteredCoaches] = useState(coaches as Coach[]);

    const closeDropdown = () => {
        if (openDropdownMenu) toggleDropdownMenu(false);
    }

    useEffect(() => {
        if (searchText !== '' && coaches) {
            const filteredList = coaches.filter((t: Coach) => t.name.toLowerCase().includes(searchText.toLowerCase()))
            updateFilteredCoaches([...filteredList])
        }
        if (searchText == '' && coaches) {
            updateFilteredCoaches([...coaches])

        }
    }, [searchText]);

    const handleCoachListCheckBox = (coach: Coach) => (value: React.MouseEvent<HTMLInputElement>) => {
        const checkbox = value.target as HTMLInputElement
        let updatedCoachList = !selectedCoaches ? [] : [...selectedCoaches]
        checkbox.checked ? updatedCoachList.push(coach) : updatedCoachList.splice(updatedCoachList.indexOf(coach), 1)
        actions.send(a => a.updateCoachSelection(updatedCoachList))
    }

    const handleSearch = (value: any) => {
        updateSearch(value.target.value)
    }

    const isCoachesChecked = (coach: Coach) => {
        if (!selectedCoaches) return false
        const isChecked = selectedCoaches.filter((selectedCoach) => {
            return selectedCoach.id === coach.id
        })
        return (isChecked.length > 0)
    }

    useEffect(() => {
        updateFilteredCoaches(coaches!)
    }, [coaches])

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(closeDropdown, 500);
    }

    return (

        <div className="btn-group" onBlur={handleOnBlur}>
            <div className="dropdown dropdownHasDeleteBtn">
                <button className={`btn dropdown-toggle ${styleName ? styleName : 'btn-light'} ${openDropdownMenu ? 'active' : ''}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
                    {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
                    <span className="btn-label">{label}</span>
                </button>
                <div className={classnames('dropdown-menu dropdown-menu-left', { 'show': openDropdownMenu })}>
                    <div className="p-2">
                        {hasSearch && <div className="form-group form-search">
                            <i className="fa fa-search form-search-icon"></i>
                            <input className="form-control" type="text" value={searchText} placeholder={t('ParticipantListPage.PlaceholderLbl')} onChange={handleSearch} />
                        </div>}
                    </div>
                    {(filteredCoachList) &&
                        filteredCoachList.map((coach, i) =>
                            (
                              <li className="dropdown-item small" key={i}>
                                  <div className="form-check">
                                      <input className="form-check-input" type="checkbox" id={i.toString()} value={coach.id.toString()} onClick={handleCoachListCheckBox(coach)} onChange={() => { }} checked={isCoachesChecked(coach)} />
                                      <label className="form-check-label" htmlFor={i.toString()}><span className="small">{coach.name + ' (' + coach.traineeCount + ')'}</span></label>
                                      {hasDeleteBtn && (coach.traineeCount > 0) ? <Popup coachName={coach.name} /> : <RemoveCoachPopup updatedcoachList={coaches} coachId={coach.id} />}
                                  </div>
                              </li>
                            )
                        )
                    }
                </div>
            </div>
        </div>

    )
}

