import * as React from 'react';

import { Auth } from '../../domain/_shared';

import { GroupMembershipPage, GroupMembershipPageRouteParameters } from './GroupMembershipPage';

export const memberRoutes = (auth: Auth, handleLogout: () => void): [string[], Function][] => {
    return [
        [['groups', ':groupId'],
        (params: GroupMembershipPageRouteParameters) => (
            <GroupMembershipPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
            />
        )]
        // (TODO) other non-Admin routes
    ]
}
