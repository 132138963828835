import * as React from 'react';

export interface FeedbackBoxProps {
}

export const FeedbackBox: React.FunctionComponent<FeedbackBoxProps> = ({ children }) => {
  return (
    <div className="task-summary">
      {(!!children) ? (children) : <div className="m-2 text-center">( Some component for Feedback )</div>}
    </div>
  )
}