import * as React from 'react';
import { isMobile } from 'react-device-detect';

import { QuestionOption, SubQuestion } from '../../domain/models';
import { RadioboxBlock } from './RadioboxBlock';

export interface RadioboxGridProps {
  active: boolean;
  answered: boolean;
  questionBody: string;
  options: QuestionOption[];
  subQuestions?: SubQuestion[];
  selected?: { [subQuestionId: number]: number | undefined };
  onChange: (newSelected: [number, number]) => void;
}

export const RadioboxGrid: React.FunctionComponent<RadioboxGridProps> = ({ active, answered, questionBody, options, subQuestions, selected, onChange }) => {
  return (
    <div className="question-grid">
      <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
      <div className={`question-grid-anwsers ${isMobile ? 'hasRightScroll isScrolling' : ''}`}>
        <div className="question-grid-container">
          <div className="question-grid-table">
            <div className="question-grid-row question-grid-header">
              <div className="question-grid-cel ">
              </div>
              {options && options.map((option, i) => {
                return (<div className="question-grid-cel" key={i}>{option.body}</div>)
              })}
            </div>
            {subQuestions && subQuestions.map((subQuestion) => {
              return (
                <RadioboxBlock
                  key={subQuestion.id}
                  active={active}
                  answered={answered}
                  questionBody={subQuestion.body}
                  options={options}
                  hideOptionLabels={true}
                  expectedOptions={subQuestion.expectedOptions}
                  selected={selected ? selected[subQuestion.id] : undefined}
                  onChange={(v: number) => onChange([subQuestion.id, v])}
                />
              );
            })}
          </div>

          {/* This duplicated table is necessary to mobile scroll effect */}
          <div className="question-grid-table question-duplicated">
            <div className="question-grid-row question-grid-header">
              <div className="question-grid-cel ">
              </div>
              {options && options.map((option, i) => {
                return (<div className="question-grid-cel" key={i}>{option.body}</div>)
              })}
            </div>
            {subQuestions && subQuestions.map((subQuestion) => {
              return (
                <RadioboxBlock
                  key={subQuestion.id}
                  active={active}
                  answered={answered}
                  questionBody={subQuestion.body}
                  options={options}
                  hideOptionLabels={true}
                  expectedOptions={subQuestion.expectedOptions}
                  selected={selected ? selected[subQuestion.id] : undefined}
                  onChange={(v: number) => onChange([subQuestion.id, v])}
                />
              );
            })}
          </div>

          <div className="question-grid-shadow"></div>
        </div>
      </div>
    </div>
  );
}
