import * as React from 'react';

export interface DateIProps {
    active: boolean;
    value?: string;     // 'yyyy-MM-dd' format
    min?: string;       // 'yyyy-MM-dd' format
    max?: string;       // 'yyyy-MM-dd' format
    isValid: boolean;
    isInvalid: boolean;
    onChange: (typedValue: string) => void;
}

export const DateI: React.FunctionComponent<DateIProps> = ({ active, value, min, max, isValid, isInvalid, onChange }) => {
    return (
        <input
            className={"form-control " + (isValid ? 'is-valid' : '') + (isInvalid ? 'is-invalid' : '')}
            type="date"
            disabled={!active}
            value={value}
            min={min}
            max={max}
            onChange={e => onChange(e.target.value)}
        />
    );
}