import * as React from 'react';
import { useReducer, useEffect } from 'react';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../../../api/react-helper';
import { Auth, isRequestError } from '../../../../../domain/_shared';
import * as services from '../../../../../domain/services';
import { ParticipantsList, Coach } from '../../../../../domain/models';

type State = {
    apiErrorParticipant?: any | undefined;
    auth: Auth;
    courseId: number;
    participantsList?: ParticipantsList;
    coaches?: Coach[];
    selectedCoaches?: Coach[];
    enrollmentStatusList?: string[];
    searchText?: string;
    selectedTraineeFilters?: string[];
    coachStatusFilter?: boolean;
    selectedSorter?: string;
    page: number;
    resultsPerPage: number;
    selectedParticipants: number[];
    reloadCoaches: boolean;
    reloadParticipants: boolean;
}

type Action = {
    Set50xError(apiErrorParticipant: any): State;
    updateParticipantsList(participantsList: ParticipantsList): State;
    LoadParticipantCoaches(coaches: Coach[]): State;
    updateCoachSelection(selectedCoaches: Coach[]): State;
    updateSearchText(searchText: string): State;
    updateTraineeFilters(selectedTraineeFilters: string[]): State;
    updateCoachFilters(coachStatusFilter: boolean): State;
    updateEnrolmentStatusList(enrollmentStatusList: string[]): State;
    updateSorter(selectedSorter: string): State;
    changePage(page: number): State;
    updateParticipantSelection(selectedParticipants: number[]): State;
    reloadCoachList(reloadCoaches: boolean): State;
    reloadParticipantList(reloadParticipants: boolean): State;
}

interface ContextProps {
    auth: Auth,
    courseId: number
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiErrorParticipant => ({ ...state, apiErrorParticipant }),
    updateParticipantsList: participantsList => ({ ...state, participantsList }),
    LoadParticipantCoaches: coaches => ({ ...state, coaches }),
    updateCoachSelection: selectedCoaches => ({ ...state, selectedCoaches, page: 0, selectedParticipants: [] }),
    updateSearchText: searchText => ({ ...state, searchText, page: 0, selectedParticipants: [] }),
    updateTraineeFilters: selectedTraineeFilters => ({ ...state, selectedTraineeFilters, page: 0, selectedParticipants: [] }),
    updateCoachFilters: coachStatusFilter => ({ ...state, coachStatusFilter, page: 0, selectedParticipants: [] }),
    updateEnrolmentStatusList: enrollmentStatusList => ({ ...state, enrollmentStatusList, page: 0, selectedParticipants: [] }),
    updateSorter: selectedSorter => ({ ...state, selectedSorter, selectedParticipants: [] }),
    changePage: page => ({ ...state, page, selectedParticipants: [] }),
    updateParticipantSelection: selectedParticipants => ({ ...state, selectedParticipants }),
    reloadCoachList: reloadCoaches => ({ ...state, reloadCoaches }),
    reloadParticipantList: reloadParticipants => ({ ...state, reloadParticipants, selectedParticipants: [] })
})

export interface ParticipantsMgmtContextProps { state: State, actions: ActionDispatcher<Action, State> };
export const ParticipantsMgmtContext = React.createContext<ParticipantsMgmtContextProps>({} as { state: State, actions: ActionDispatcher<Action, State> })
export const ParticipantsMgmtContextProvider: React.FunctionComponent<ContextProps> = (props) => {

    const { auth, courseId } = props
    const [state, actions] = wrapperReducer(useReducer(reducer, { auth, courseId, page: 0, resultsPerPage: 10, selectedParticipants: [] as number[], reloadCoaches: false, reloadParticipants: false }));

    useLoadCoaches(auth, courseId, actions);

    const getParticipantList = async () => {
        let coachList = [] as number[]
        if (state.selectedCoaches) {
            coachList = state.selectedCoaches.map((coach) => {
                return coach.id
            })
        }
        try {
            const participantList = await services.getTrainees(auth, courseId, state.searchText, state.coachStatusFilter, coachList, state.selectedTraineeFilters, state.enrollmentStatusList, state.selectedSorter, state.page, state.resultsPerPage);
            if (isRequestError(participantList)) {
            }
            else {
                actions.send(a => a.updateParticipantsList(participantList));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }

    const getCoachList = async () => {
        try {
            const coaches = await services.getCoaches(auth, courseId);
            if (isRequestError(coaches)) {
            }
            else {
                actions.send(a => a.LoadParticipantCoaches(coaches));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }

    if (!state.participantsList) getParticipantList()

    useEffect(() => {
        getParticipantList()
    }, [state.selectedCoaches, state.searchText, state.selectedTraineeFilters, state.coachStatusFilter, state.selectedSorter, state.page, state.enrollmentStatusList, state.reloadParticipants])

    useEffect(() => {
        getCoachList()
    }, [state.reloadCoaches])

    return (
        <ParticipantsMgmtContext.Provider value={{ state, actions }}>
            {props.children}
        </ParticipantsMgmtContext.Provider>
    )

}

function useLoadCoaches(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const coaches = await services.getCoaches(auth, courseId);
            if (isRequestError(coaches)) {
            }
            else {
                actions.send(a => a.LoadParticipantCoaches(coaches));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
