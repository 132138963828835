import * as React from 'react';
import classnames from 'classnames';

export interface ButtonProps {
    active: boolean;
    label: string;
    styleName?: string;
    href: string;
    onClick: (event: React.MouseEvent) => void;
}

export const Button: React.FunctionComponent<ButtonProps> = ({ active, label, styleName, href, onClick }) => {
    return (
        <a
            className={classnames(styleName,
                { 'disabled': !active }
            )}
            href={(active && href) || 'javascript:void(0)'}
            onClick={(e) => { if (active && !href) onClick(e) }}
        >
            {label}
        </a>
    )
}