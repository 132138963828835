import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../api/Router';

import { __SERVER_REPO__ } from '../../domain/_defaults';

import { UserContext } from '../../domain/contexts/UserContext';

import { BaseAppLayout } from '../../layouts/shared/BaseAppLayout';
import { CardSection, CardViewType } from '../../components/Cards/CardSection';

export interface UserWelcomePageRouteParameters {
}

export interface UserWelcomePageProps {
    onLogout: () => void;
}

export const UserWelcomePage: React.FunctionComponent<UserWelcomePageProps> = ({ onLogout }) => {
    const { state: { presets } } = useContext(UserContext);
    const { t } = useTranslation();

    return (
        <BaseAppLayout
            onLogout={onLogout}
        >
            <CardSection
                title={`${t('WelcomePage.welcome')}, ${presets!.nickName}`}
                showGridListToggle={false}
                initialCardViewType={CardViewType.Grid}
            >
                <div className="col">

                    <div className="row mb-1">
                        <div className="col">
                            <p className="mt-0 mb-4 d-none d-md-block">{t('WelcomePage.intro')}</p>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-sm-6">
                            <div className="card">
                                <div className="row">
                                    <div className="col-lg-5 text-center p-2 p-sm-4">
                                        <img src={`${__SERVER_REPO__}/system/icon-courses.svg`} alt="" style={{ width: '100%', maxWidth: '180px' }} />
                                    </div>
                                    <div className="col-lg-7 d-flex align-items-center">
                                        <div className="d-flex flex-column pb-4 px-4 pt-md-4" style={{ width: '100%' }}>
                                            <h2 className="h4 d-none d-md-block title-color">{t('WelcomePage.myCourses')}</h2>
                                            <p className="d-none d-md-block text-muted">{t('WelcomePage.myCoursesDescription')}</p>
                                            <button
                                                className="btn btn-primary mt-1 btn-icon"
                                                type="button"
                                                onClick={() => Router.push('/mycourses')}
                                                style={{}}
                                            >
                                                {t('WelcomePage.myCourses')}
                                                <i className={`icon fa fa-angle-right`}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="card">
                                <div className="row">
                                    <div className="col-lg-5 text-center p-2 p-sm-4">
                                        <img src={`${__SERVER_REPO__}/system/icon-groups.svg`} alt="" style={{ width: '100%', maxWidth: '180px' }} />
                                    </div>
                                    <div className="col-lg-7 d-flex align-items-center">
                                        <div className="d-flex flex-column pb-4 px-4 pt-md-4" style={{ width: '100%' }}>
                                            <h2 className="h4 d-none d-md-block title-color">{t('WelcomePage.myGroups')}</h2>
                                            <p className="d-none d-md-block text-muted">{t('WelcomePage.myGroupsDescription')}</p>
                                            <button
                                                className="btn btn-primary mt-1 btn-icon"
                                                type="button"
                                                onClick={() => Router.push('/mygroups')}
                                                style={{}}
                                            >
                                                {t('WelcomePage.myGroups')}
                                                <i className={`icon fa fa-angle-right`}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </CardSection>
        </BaseAppLayout>
    );
}

