import i18n from 'i18next';
import momentTZ from 'moment-timezone';

import { APP_Default_Language, APP_Default_Timezone } from '../domain/_defaults';

import enUS from './en-us/index';
import ptBR from './pt-br/index';
import zhHans from './zh-hans/index';
import zhHant from './zh-hant/index';

export const getNewI18N = (currentLng?: string, currentTz?: string) => {
    const newInstance = i18n.createInstance();

    newInstance
        .init({
            resources: {
                'en-us': { translation: enUS },
                'pt-br': { translation: ptBR },
                'zh-hans': { translation: zhHans },
                'zh-hant': { translation: zhHant },

                // (TODO) more translations
            },

            lng: currentLng ? currentLng.toLowerCase() : APP_Default_Language.toLowerCase(),
            fallbackLng: APP_Default_Language.toLowerCase(),
            lowerCaseLng: true,     // 'en-US' must be converted to 'en-us'

            ns: ['translation'],
            defaultNS: 'translation',

            interpolation: {
                format: function (value: string | { type: string, data: string } | any, format, lng) {
                    switch (value.type) {
                        case 'noTZdatetime':
                            return momentTZ.utc(value.data).format(format);
                        case 'withTZdatetime':
                            return momentTZ.utc(value.data).tz(currentTz || APP_Default_Timezone).format(format);
                        // (TODO) Numeral.js
                        default:
                            break;
                    }
                    return value; // Default: non-changed
                },
                escapeValue: false              // WARNING: possible XSS injection, React does escaping
            }
            // , debug: true
        }, () => {
            console.log(`I18N instance created with \'${currentLng || APP_Default_Language}\' and \'${currentTz || APP_Default_Timezone}\'`);
        });

    return newInstance;
}
