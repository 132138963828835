import * as React from 'react';

import * as Router from '../../../../../api/Router';

import { Course } from '../../../../../domain/models';

import { CardViewType } from '../../../../../components/Cards/CardSection';
import { MemberCourseStatusCard } from './MemberCourseStatusCard';

export interface GroupCoursesListProps {
    groupId: number;
    courses: Course[];
}

export const RO_GroupCoursesList: React.FunctionComponent<GroupCoursesListProps> = ({ groupId, courses }) => {

    return (
        <div className="mt-1 mt-md-4">
            <div className="container-fluid">
                <div className="row">
                    {courses.map((course, i) => (
                        <MemberCourseStatusCard
                            key={i}
                            cardViewType={CardViewType.List}
                            name={course.name}
                            totalTrainees={course.traineeCount}
                            totalCoaches={course.type === 'CoachAssisted' ? course.coachCount : undefined}
                            onClick={() => Router.push(`/groups/${groupId}/courses/${course.id}`)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
