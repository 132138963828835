import * as React from 'react';

export interface ButtonAddProps {
    active: boolean,
    label: string,
    onAdd: () => void,
}

export const ButtonAdd: React.FunctionComponent<ButtonAddProps> = ({ active, label, onAdd }) => {
    return (
        <button
            disabled={!active}
            onClick={onAdd}
            className="btn btn-primary"
            type="submit"
        >
            <i className="icon fa fa-plus"></i>
            {' '}{label}
        </button>
    )
}