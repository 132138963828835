import * as React from 'react';

export interface CancelFormProps {
    active: boolean,
    label: string,
    onCancel: () => void,
}

export const CancelForm: React.FunctionComponent<CancelFormProps> = ({ active, label, onCancel }) => {
    return (
        <button
            disabled={!active}
            onClick={onCancel}
            className="btn btn-light m-1"
            type="submit"
        >
            {' '}{label}
        </button>
    )
}