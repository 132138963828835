import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ContentOptionsProps {
  isVisible: boolean;
  onClick: (value: string) => void;
  onClose: () => void;
  couseType:string;
}

export const ContentOptions: React.FunctionComponent<ContentOptionsProps> = ({ isVisible, onClick, onClose,couseType }) => {
  const { t } = useTranslation();

 // const showDropdownMenu = isVisible ? { display: 'flex' } : { display: 'none' }
  function handleOnBlur() {
    onClose()
  }
  return (
    <div className="new-task-box"  onClick={handleOnBlur}>
        <div style={{textAlign:"center",marginLeft:"575px"}}>
        <a className="builder-new-task-content-btn-close"
                    href="javascript:void(0)"
                    onClick={onClose}>
                    <i
                        className="fa fa-close" />Close
                 
                </a>
        </div>
        <div>
        <ul className="new-task-box-list">

<li className="new-task-box-item">
  <a className="btn-primary-outline" href="javascript:void(0)" onClick={() => { onClick('Content') }}>
    <div className="new-task-box-icon">
      <i className="fa fa-file-o"></i>
    </div>
    <div className="new-task-box-text">
      {t('ContentOptions.content')}
    </div>
  </a>
</li>

<li className="new-task-box-item">
  <a className="btn-primary-outline" href="javascript:void(0)" onClick={()=>{onClick('EditableForm')}}>
    <div className="new-task-box-icon">
      <i className="fa fa-pencil-square-o"></i>
    </div>
    <div className="new-task-box-text">{t('ContentOptions.editable_Form')}</div>
  </a>
</li>
{couseType.toLowerCase()==="coachassisted" &&
<li className="new-task-box-item">
  <a className="btn-primary-outline" href="javascript:void(0)" onClick={()=>{onClick('Meeting')}}>
    <div className="new-task-box-icon">
      <i className="fa fa-users"></i>
    </div>
    <div className="new-task-box-text">{t('ContentOptions.meeting')}</div>
  </a>
</li>
}

<li className="new-task-box-item">
  <a className="btn-primary-outline" href="javascript:void(0)" onClick={()=>{onClick('Quiz')}}>
    <div className="new-task-box-icon">
      <i className="fa fa-question"></i>
    </div>
    <div className="new-task-box-text">{t('ContentOptions.quiz')}</div>
  </a>
</li>

<li className="new-task-box-item">
  <a className="btn-primary-outline" href="javascript:void(0)" onClick={() => { onClick('Survey') }}>
    <div className="new-task-box-icon">
      <i className="fa fa-line-chart"></i>
    </div>
    <div className="new-task-box-text">{t('ContentOptions.survey')}</div>
  </a>
</li>

</ul>

        </div>
      
    </div>
  );
}