import * as React from 'react';
import { useReducer, useEffect } from 'react';
import _ from 'lodash';

import { ActionMatcher, ActionDispatcher, wrapperReducer } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { MembersList } from '../../../domain/models';

type State = {
    apiErrorMember?: any | undefined;
    auth: Auth;
    groupId: number;
    membersList?: MembersList;
    searchText?: string;
    selectedFilters?: string[];
    selectedSorter?: string;
    page: number;
    resultsPerPage: number;
    selectedMembers: number[];
    isAdmin?: boolean;
    reloadMembersList: boolean,
    filterStatus?: string[];
}

type Action = {
    Set50xError(apiErrorMember: any): State;
    updateMembersList(membersList: MembersList): State;
    updateSearchText(searchText: string): State;
    updateFilters(selectedFilters: string[]): State;
    updateSorter(selectedSorter: string): State;
    changePage(page: number): State;
    updateMembersSelection(selectedMembers: number[]): State;
    changeAdminFilter(isAdmin: boolean): State;
    reloadMembersList(reloadMembersList: boolean): State;
    updateStatusFilters(filterStatus: string[]): State;
}

interface ContextProps {
    auth: Auth,
    groupId: number
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiErrorMember => ({ ...state, apiErrorMember }),
    updateMembersList: membersList => ({ ...state, membersList }),
    updateSearchText: searchText => ({ ...state, searchText, page: 0, selectedMembers: [] }),
    updateFilters: selectedFilters => ({ ...state, selectedFilters, page: 0, selectedMembers: [] }),
    updateSorter: selectedSorter => ({ ...state, selectedSorter, selectedMembers: [] }),
    changePage: page => ({ ...state, page }),
    updateMembersSelection: selectedMembers => ({ ...state, selectedMembers }),
    changeAdminFilter: isAdmin => ({ ...state, isAdmin }),
    reloadMembersList: reloadMembersList => ({ ...state, reloadMembersList }),
    updateStatusFilters: filterStatus => ({ ...state, filterStatus, page: 0, selectedMembers: [] }),
})

export interface MembersMgmtContextProps { state: State, actions: ActionDispatcher<Action, State> };
export const MembersMgmtContext = React.createContext<MembersMgmtContextProps>({} as { state: State, actions: ActionDispatcher<Action, State> })
export const MembersMgmtContextProvider: React.FunctionComponent<ContextProps> = (props) => {

    const { auth, groupId } = props
    const [state, actions] = wrapperReducer(useReducer(reducer, { auth, groupId, page: 0, resultsPerPage: 10, selectedMembers: [] as number[], reloadMembersList: false }));

    const getMembersList = async () => {
        try {
            const memberList = await services.getMembers(auth!, groupId, state.searchText, state.selectedFilters, state.isAdmin, state.selectedSorter, state.page, state.resultsPerPage);
            if (isRequestError(memberList)) {
            }
            else {
                actions.send(a => a.updateMembersList(memberList));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }

    if (!state.membersList) getMembersList()

    useEffect(() => {
        getMembersList()
    }, [state.searchText, state.selectedFilters, state.selectedSorter, state.page, state.isAdmin, state.filterStatus, state.reloadMembersList])

    return (
        <MembersMgmtContext.Provider value={{ state, actions }}>
            {props.children}
        </MembersMgmtContext.Provider>
    )

}


