import * as React from "react";
import Pagination from "react-js-pagination";

export interface CourseListPaginationProps {
  resultsCount: number;
  page: number;
  resultsPerPage: number;
  onChangePage: (pageNumber: number) => void;
}

export const CourseListPagination: React.FunctionComponent<CourseListPaginationProps> = ({
  resultsCount,
  page,
  resultsPerPage,
  onChangePage
}) => {

  // const handlePageChange = (pageNumber: number) => {
  //   // actions.send(a => a.changePage(pageNumber - 1))
  // };

  return (
    <div>
      <Pagination
        activePage={page + 1}
        itemsCountPerPage={resultsPerPage}
        totalItemsCount={resultsCount}
        pageRangeDisplayed={10}
        onChange={pageNumber => onChangePage(pageNumber - 1)}
        firstPageText={<i className="fa fa-angle-double-left"></i>}
        prevPageText={<i className="fa fa-angle-left"></i>}
        lastPageText={<i className="fa fa-angle-double-right"></i>}
        nextPageText={<i className="fa fa-angle-right"></i>}
      />
    </div>
  );
};
