import * as React from 'react';
import * as Fa_ from '@fortawesome/free-solid-svg-icons';
export { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Fa = Fa_;

export const SingleLineIcon: React.FunctionComponent<{ icon: string }> = ({ icon }) => {
    return <i className={icon}></i>;
}

export const user = 'icon-user';
export const people = 'icon-people';
export const userFemale = 'icon-user-female';
export const userFollow = 'icon-user-follow';
export const userFollowing = 'icon-user-following';
export const userUnfollow = 'icon-user-unfollow';
export const login = 'icon-login';
export const logout = 'icon-logout';
export const emotsmile = 'icon-emotsmile';
export const phone = 'icon-phone';
export const callEnd = 'icon-call-end';
export const callIn = 'icon-call-in';
export const callOut = 'icon-call-out';
export const map = 'icon-map';
export const locationPin = 'icon-location-pin';
export const direction = 'icon-direction';
export const directions = 'icon-directions';
export const compass = 'icon-compass';
export const layers = 'icon-layers';
export const menu = 'icon-menu';
export const list = 'icon-list';
export const optionsVertical = 'icon-options-vertical';
export const options = 'icon-options';
export const arrowDown = 'icon-arrow-down';
export const arrowLeft = 'icon-arrow-left';
export const arrowRight = 'icon-arrow-right';
export const arrowUp = 'icon-arrow-up';
export const arrowUpCircle = 'icon-arrow-up-circle';
export const arrowLeftCircle = 'icon-arrow-left-circle';
export const arrowRightCircle = 'icon-arrow-right-circle';
export const arrowDownCircle = 'icon-arrow-down-circle';
export const check = 'icon-check';
export const clock = 'icon-clock';
export const plus = 'icon-plus';
export const minus = 'icon-minus';
export const close = 'icon-close';
export const event = 'icon-event';
export const exclamation = 'icon-exclamation';
export const organization = 'icon-organization';
export const trophy = 'icon-trophy';
export const screenSmartphone = 'icon-screen-smartphone';
export const screenDesktop = 'icon-screen-desktop';
export const plane = 'icon-plane';
export const notebook = 'icon-notebook';
export const mustache = 'icon-mustache';
export const mouse = 'icon-mouse';
export const magnet = 'icon-magnet';
export const energy = 'icon-energy';
export const disc = 'icon-disc';
export const cursor = 'icon-cursor';
export const cursorMove = 'icon-cursor-move';
export const crop = 'icon-crop';
export const chemistry = 'icon-chemistry';
export const speedometer = 'icon-speedometer';
export const shield = 'icon-shield';
export const screenTablet = 'icon-screen-tablet';
export const magicWand = 'icon-magic-wand';
export const hourglass = 'icon-hourglass';
export const graduation = 'icon-graduation';
export const ghost = 'icon-ghost';
export const gameController = 'icon-game-controller';
export const fire = 'icon-fire';
export const eyeglass = 'icon-eyeglass';
export const envelopeOpen = 'icon-envelope-open';
export const envelopeLetter = 'icon-envelope-letter';
export const bell = 'icon-bell';
export const badge = 'icon-badge';
export const anchor = 'icon-anchor';
export const wallet = 'icon-wallet';
export const vector = 'icon-vector';
export const speech = 'icon-speech';
export const puzzle = 'icon-puzzle';
export const printer = 'icon-printer';
export const present = 'icon-present';
export const playlist = 'icon-playlist';
export const pin = 'icon-pin';
export const picture = 'icon-picture';
export const handbag = 'icon-handbag';
export const globeAlt = 'icon-globe-alt';
export const globe = 'icon-globe';
export const folderAlt = 'icon-folder-alt';
export const folder = 'icon-folder';
export const film = 'icon-film';
export const feed = 'icon-feed';
export const drop = 'icon-drop';
export const drawer = 'icon-drawer';
export const docs = 'icon-docs';
export const doc = 'icon-doc';
export const diamond = 'icon-diamond';
export const cup = 'icon-cup';
export const calculator = 'icon-calculator';
export const bubbles = 'icon-bubbles';
export const briefcase = 'icon-briefcase';
export const bookOpen = 'icon-book-open';
export const basketLoaded = 'icon-basket-loaded';
export const basket = 'icon-basket';
export const bag = 'icon-bag';
export const actionUndo = 'icon-action-undo';
export const actionRedo = 'icon-action-redo';
export const wrench = 'icon-wrench';
export const umbrella = 'icon-umbrella';
export const trash = 'icon-trash';
export const tag = 'icon-tag';
export const support = 'icon-support';
export const frame = 'icon-frame';
export const sizeFullscreen = 'icon-size-fullscreen';
export const sizeActual = 'icon-size-actual';
export const shuffle = 'icon-shuffle';
export const shareAlt = 'icon-share-alt';
export const share = 'icon-share';
export const rocket = 'icon-rocket';
export const question = 'icon-question';
export const pieChart = 'icon-pie-chart';
export const pencil = 'icon-pencil';
export const note = 'icon-note';
export const loop = 'icon-loop';
export const home = 'icon-home';
export const grid = 'icon-grid';
export const graph = 'icon-graph';
export const microphone = 'icon-microphone';
export const musicToneAlt = 'icon-music-tone-alt';
export const musicTone = 'icon-music-tone';
export const earphonesAlt = 'icon-earphones-alt';
export const earphones = 'icon-earphones';
export const equalizer = 'icon-equalizer';
export const like = 'icon-like';
export const dislike = 'icon-dislike';
export const controlStart = 'icon-control-start';
export const controlRewind = 'icon-control-rewind';
export const controlPlay = 'icon-control-play';
export const controlPause = 'icon-control-pause';
export const controlForward = 'icon-control-forward';
export const controlEnd = 'icon-control-end';
export const volume1 = 'icon-volume-1';
export const volume2 = 'icon-volume-2';
export const volumeOff = 'icon-volume-off';
export const calendar = 'icon-calendar';
export const bulb = 'icon-bulb';
export const chart = 'icon-chart';
export const ban = 'icon-ban';
export const bubble = 'icon-bubble';
export const camrecorder = 'icon-camrecorder';
export const camera = 'icon-camera';
export const cloudDownload = 'icon-cloud-download';
export const cloudUpload = 'icon-cloud-upload';
export const envelope = 'icon-envelope';
export const eye = 'icon-eye';
export const flag = 'icon-flag';
export const heart = 'icon-heart';
export const info = 'icon-info';
export const key = 'icon-key';
export const link = 'icon-link';
export const lock = 'icon-lock';
export const lockOpen = 'icon-lock-open';
export const magnifier = 'icon-magnifier';
export const magnifierAdd = 'icon-magnifier-add';
export const magnifierRemove = 'icon-magnifier-remove';
export const paperClip = 'icon-paper-clip';
export const paperPlane = 'icon-paper-plane';
export const power = 'icon-power';
export const refresh = 'icon-refresh';
export const reload = 'icon-reload';
export const settings = 'icon-settings';
export const star = 'icon-star';
export const symbolFemale = 'icon-symbol-female';
export const symbolMale = 'icon-symbol-male';
export const target = 'icon-target';
export const creditCard = 'icon-credit-card';
export const paypal = 'icon-paypal';
export const socialTumblr = 'icon-social-tumblr';
export const socialTwitter = 'icon-social-twitter';
export const socialFacebook = 'icon-social-facebook';
export const socialInstagram = 'icon-social-instagram';
export const socialLinkedin = 'icon-social-linkedin';
export const socialPinterest = 'icon-social-pinterest';
export const socialGithub = 'icon-social-github';
export const socialGoogle = 'icon-social-google';
export const socialReddit = 'icon-social-reddit';
export const socialSkype = 'icon-social-skype';
export const socialDribbble = 'icon-social-dribbble';
export const socialBehance = 'icon-social-behance';
export const socialFoursqare = 'icon-social-foursqare';
export const socialSoundcloud = 'icon-social-soundcloud';
export const socialSpotify = 'icon-social-spotify';
export const socialStumbleupon = 'icon-social-stumbleupon';
export const socialYoutube = 'icon-social-youtube';
export const socialDropbox = 'icon-social-dropbox';
export const socialVkontakte = 'icon-social-vkontakte';
export const socialSteam = 'icon-social-steam';
