import { __AUTH_API__ } from '.';
import { Auth, RequestError, processResponse } from '../_shared';

export async function signIn(username: string, password: string): Promise<Auth | RequestError> {
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('grant_type', 'password');

    const response = await fetch(`${__AUTH_API__}/api/token`, {
        method: 'POST',
        headers: {},
        body: body.toString()
    });

    switch (response.status) {
        case 200:
            try {
                const result = await response.json();
                return result as Auth;                          // isAuthError/RequestError = false
            } catch (e) {
                return {
                    error: e.message,
                    error_description: 'Something went wrong when parsing Auth object'
                } as RequestError;
            }
        case 400:
            try {
                const result = await response.json();
                return {
                    message: result.error,
                    error_description: 'Error 400 from API server',
                    ...result   // OBS.: overwrite fields
                } as RequestError;                              // isAuthError/RequestError = true
            } catch (e) {
                return {
                    error: e.message,
                    error_description: 'Error 400 from API server, without Response object'
                } as RequestError;                              // isAuthError/RequestError = true
            }
        default:
            return new Promise((resolve, reject) => {
                reject({
                    error: 'Some error ocurred',
                    error_description: `Error ${response.status} from API server`
                } as RequestError)                              // isAuthError/RequestError = true
            });
    }
}

export async function forgotPassword(email: string): Promise<void> {
    const body = new URLSearchParams();
    body.set('email', email);

    const response = await fetch(`${__AUTH_API__}/api/account/forgotpassword`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body.toString()
    });

    return processResponse<void>(response);
}

export async function resetPassword(email: string, passwordResetCode: string, newPassword: string, confirmPassword: string): Promise<void> {
    const response = await fetch(`${__AUTH_API__}/api/account/resetpassword`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            passwordResetCode,
            newPassword,
            confirmPassword
        })
    });

    return processResponse<void>(response);
}
