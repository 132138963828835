import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { scrollToPosition } from '../../../api/utils';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { TraineeCourse, Lesson } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { TraineeCourseHeader } from './components/main/TraineeCourseHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { CardSection } from '../../../components/Cards/CardSection';
import { EffectContentArea } from '../../../components/EffectContentArea';
import { TraineeLessonPanel } from './components/main/TraineeLessonPanel';

export interface CourseExecutionPageRouteParameters {
    groupId: number;
    courseId: number;
}

export interface CourseExecutionPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
}

type State = {
    apiError?: any | undefined;
    course?: TraineeCourse;
    lessons?: Lesson[];
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: TraineeCourse): State;
    LoadLessonsList(lessons: Lesson[]): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadLessonsList: lessons => ({ ...state, lessons }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const CourseExecutionPage: React.FunctionComponent<CourseExecutionPageProps> = ({ auth, onLogout, groupId, courseId }) => {
    const { t } = useTranslation();
    const [{ apiError, course, lessons, tabIndex }, actions] = wrapperReducer(useReducer(reducer, { tabIndex: 0 }));

    const goToRoot = () => Router.push('/');
    const goToMyCourses = () => Router.push('/mycourses');

    useLoadCourse(auth, courseId, actions);
    useLoadLessons(auth, courseId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!course || !lessons) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myCourses'), onClick: goToMyCourses },
                { text: `${course.name}`, active: true }
            ]}
            onLogout={onLogout}
        >

            <TraineeCourseHeader
                courseStatus={course.status || 'unknown'}
                courseType={course.type || ''}
                courseName={course.name}
                courseImgUrl={course.imageUrl}
                groupName={course.groupName}

                coachName={course.coachName!}
                coachEmail={course.coachEmail!}
                startDate={course.startDate}
                traineeLastAccessDate={course.traineeLastAccessDate}
                progress={course.progress!}
                certificateUrl={course.certificateUrl || ''}
            />

            <PageNav>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 0 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(0))}>
                        {t('CourseExecutionPage.tab0')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 1 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(1))}>
                        {t('CourseExecutionPage.tab1')}
                    </a>
                </li>
            </PageNav>

            {tabIndex === 0 && (
                <div className="main mt-3 mt-md-4">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <CardSection title={t('CourseExecutionPage.tab0_title')}>
                                    <div className="col-12">
                                        <hr className="mt-0 mb-4" />
                                        <EffectContentArea rawHTML={course.body || ''} onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))} />
                                        <div>
                                            <hr className="mt-4 mb-4" />
                                            <div className="text-right mb-3 mb-sm-2">
                                                {(course.progress !== 1)
                                                    ? (<a className="btn btn-primary" href="javascript:void(0)" onClick={() => {
                                                        scrollToPosition(0, 0);
                                                        actions.send(a => a.ChangeTabIndex(1));
                                                    }}>
                                                        <span className="label">
                                                            {(course.progress > 0 || course.startDate !== undefined)
                                                                ? t('CourseExecutionPage.continueLesson')
                                                                : t('CourseExecutionPage.startCourse')}
                                                        </span>
                                                        <i className="icon fa fa-arrow-right ml-2"></i>
                                                    </a>)
                                                    : (<a className="btn btn-light" href="javascript:void(0)" onClick={() => {
                                                        scrollToPosition(0, 0);
                                                        actions.send(a => a.ChangeTabIndex(1));
                                                    }}>
                                                        <span className="label">{t('CourseExecutionPage.reviewLessonsBtn')}</span>
                                                        <i className="icon fa fa-arrow-right ml-2"></i>
                                                    </a>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </CardSection>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {tabIndex === 1 && (
                <div>
                    <div className="main">
                        <div className="container-fluid">
                            {(course && lessons) && (
                                <div className="mt-3 mt-md-4">
                                    <div className="container-fluid">
                                        {lessons.map((lesson, i) => (
                                            <TraineeLessonPanel
                                                key={i}
                                                index={i + 1}
                                                lesson={lesson}
                                                onTaskClick={task => Router.push(`/groups/${groupId}/trainee/courses/${courseId}/lessons/${lesson.id}/tasks/${task.id}/${task.type.toLowerCase()}`)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </BaseAppLayout>
    );
}

function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getTraineeCourse(auth, courseId);
            if (isRequestError(course)) {
                console.log(course);
            }
            else {
                actions.send(a => a.LoadCourse(course));

                if (course.progress > 0 || course.startDate !== undefined) {
                    scrollToPosition(0, 0);
                    actions.send(a => a.ChangeTabIndex(1));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadLessons(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const lessons = await services.getTraineeLessons(auth, courseId);
            if (isRequestError(lessons)) {
                console.log(lessons);
            }
            else {
                actions.send(a => a.LoadLessonsList(lessons));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
