import * as React from 'react';
import { ButtonBack } from '../../../../components/Buttons/ButtonBack';
import { RichTextEditor } from '../../../../components/RichTextEditor/RichTextEditor';
import { ActionMatcher, wrapperReducer, ActionDispatcher, useAsyncEffect } from '../../../../api/react-helper';
import { useReducer, useState, useContext } from 'react';
import { CourseConfigs, AdminCourse, AdminGroup, GroupConfigs } from '../../../../domain/models';
import { AuthContext } from '../../../../domain/contexts/AuthContext';
import { Auth, isRequestError } from '../../../../domain/_shared';
import { useTranslation } from 'react-i18next';
import * as AdminActions from '../../GroupManagementPage/actions';
import { ImageUpload } from '../../CreateNewCoursePage/components/ImageUpload';
import * as services from '../../../../domain/services';
import { LoadingPage } from '../../../../components/LoadingPage';
import * as Router from '../../../../api/Router';
import { P50x } from '../../../../components/P50x';
import { ImageLoading } from '../../../../components/ImageLoader/ImageLoading'
import { ImageEditor } from '../../../../components/ImageEditor/ImageEditor';
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import $ from 'jquery'

export interface DraftGroupInfoProps {
    auth: Auth;
    groupId: number;
}
export interface DraftGroupInfoPageRouteParameters {
    groupId: number;
}

type State = {
    groupNameText: string;
    description?: string;
    bodyContent?: string;
    adminGroup?: AdminGroup;
    apiError?: any | undefined;
}

type Action = {
    SetGroupName(groupNameText: string): State;
    SetDescription(description: string): State;
    SetBody(bodyContent: string): State;
    LoadAdminGroup(adminGroup: AdminGroup): State;
    Set50xError(apiError: any): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    SetGroupName: groupNameText => ({ ...state, groupNameText }),
    SetDescription: description => ({ ...state, description }),
    SetBody: bodyContent => ({ ...state, bodyContent }),
    LoadAdminGroup: adminGroup => ({ ...state, adminGroup }),
    Set50xError: apiError => ({ ...state, apiError }),
})

export const DraftGroupInfo: React.FunctionComponent<DraftGroupInfoProps> = ({ groupId }) => {
    const { t } = useTranslation();
    const [{
        groupNameText,
        description,
        bodyContent,
        adminGroup,
        apiError
    }, actions] = wrapperReducer(useReducer(reducer, {
        groupNameText: ''
    }));


    const [state, setState] = useState({
        groupNameText: groupNameText || '',
        invalidGroupNameMessage: undefined as string | undefined
    });

    const { invalidGroupNameMessage } = state;
    const { state: { auth } } = useContext(AuthContext);

    useLoadAdminGroup(auth!, groupId, actions);

    const handleGroupnameTextChange = (value: any) => {
        setState({
            ...state,
            groupNameText: value.target.value,
            invalidGroupNameMessage: undefined,
        });
    }

    const richEditorOptions = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            ['link', 'blockquote', 'image', 'video'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ align: 'center' }, { align: 'right' }, { align: 'justify' }, { align: '' }],
            [{ 'header': [1, 2, false] }],
        ]
        ,
        clipboard: {
            matchVisual: false,
        }
    }


    const [isImageLoading, updateImageUploadingStatus] = useState(false)

    const imageUpload = (image: File, completion: (imgURL: string) => void) => {
        updateImageUploadingStatus(true)
        services.saveImage(auth!, image)
            .then(response => {
                updateImageUploadingStatus(false)
                completion(response)
            })
            .catch((error) => {
                updateImageUploadingStatus(false)
                actions.send(a => a.Set50xError(error))
            })
    }

    function onEditClick() {
        let body = $('#react-trumbowyg').trumbowyg('html');
        let groupName: string | undefined;
        if (groupNameText.length === 0) groupName = t('NewCoursePage.fieldRequired');

        if (!groupName) {
            const editGroup: GroupConfigs = {
                name: groupNameText,
                description: description,
                body: body,
            }
            useSendGroup(auth!, editGroup);
        }
        else {
            setState({
                ...state,
                invalidGroupNameMessage: groupName,
            });
        }
    }

    function onCancelClick() {
        Router.push(`/groups/${groupId}/admin/0`)
    }

    const useSendGroup = async function (
        auth: Auth,
        editGroup: GroupConfigs
    ) {
        services.updateGroup(auth, groupId, editGroup)
            .then(() => {
                Router.push(`/groups/${groupId}/admin/0`)
            })
            .catch((e) => {
                actions.send(a => a.Set50xError(e));
            })
    }

    if (!adminGroup) return <LoadingPage />;
    if (apiError) return <P50x onGoBack={() => Router.push('/')} />
    return (
        <div>
            <div className="main">
                <div className="container-fluid">

                    <div className="header-page hasBtnBack hasInfo">
                        <h1 className="h4 header-page-title title-color">{t('DraftGroupInfo.edit_Group')}</h1>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <form className="form-horizontal">
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="title">{t('DraftGroupInfo.name')}</label>
                                    <div className="col-md-9">
                                        <input
                                            className={"form-control " + (invalidGroupNameMessage ? 'is-invalid' : '')}
                                            id="title"
                                            type="text"
                                            name="title"
                                            placeholder="Title"
                                            value={groupNameText}
                                            onChange={(value) => { const val = value.target.value; actions.send(a => a.SetGroupName(val)) }}
                                            onKeyUp={handleGroupnameTextChange} />
                                        {invalidGroupNameMessage && <div className="invalid-feedback">{invalidGroupNameMessage}</div>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label" htmlFor="description">{t('DraftGroupInfo.description')}</label>
                                    <div className="col-md-9">
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            placeholder="Description (optional)"
                                            onChange={(value) => { const val = value.target.value; actions.send(a => a.SetDescription(val)) }}
                                            value={description || ''} />

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-12 quill-editor-height">
                                        {/* <RichTextEditor
                                            theme={'snow'}
                                            options={richEditorOptions}
                                            onUploadImage={imageUpload}
                                            value={bodyContent || ''}
                                            onChange={(val) => { actions.send(a => a.SetBody(val.replace(/&quot;/g, ''))) }}>
                                        </RichTextEditor> */}
                                        <Trumbowyg id='react-trumbowyg'

                                            buttons={
                                                [
                                                    ['viewHTML'],
                                                    ['formatting'],
                                                    'btnGrp-semantic',
                                                    'btnGrp-justify',
                                                    'btnGrp-lists',
                                                    ['table'], // I ADDED THIS FOR THE TABLE PLUGIN BUTTON
                                                    ['fullscreen']
                                                ]
                                            }
                                            data={bodyContent || ''}
                                            placeholder='Type your text!'
                                            onChange={() => { }}
                                            onPaste={() => { }}
                                            semantic={false}

                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                        <ImageLoading isVisible={isImageLoading} />
                    </div>

                    <div className="text-right">
                        <button className="btn btn-light m-1" type="submit" onClick={() => onCancelClick()}>
                            {t('NewCoursePage.Cancel')}
                        </button>
                        <button className="btn btn-primary m-1" type="submit" onClick={() => onEditClick()}>
                            {t('miscelaneous.update')}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )

}
function useLoadAdminGroup(auth: Auth, groupId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const adminGroup = await services.getAdminGroup(auth, groupId);
            if (isRequestError(adminGroup)) {

            }
            else {
                actions.send(a => a.LoadAdminGroup(adminGroup));
                actions.send(a => a.SetDescription(adminGroup.description!))
                actions.send(a => a.SetGroupName(adminGroup.name))
                actions.send(a => a.SetBody(adminGroup.body!))
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}