import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Question,
    CheckboxQuestion,
    CheckboxGridQuestion,
    DateQuestion,
    DateTimeQuestion,
    DropdownQuestion,
    LinearScaleQuestion,
    MultiLineTextQuestion,
    RadioboxQuestion,
    RadioboxGridQuestion,
    SingleLineTextQuestion,
    TimeQuestion,
    Answer,
    AnswersData,
} from '../../domain/models';
import { Alert } from '../Alert/Alert';
import { IsRequiredBaloon } from './IsRequiredBaloon';
import { CheckboxBlock } from './CheckboxBlock';
import { CheckboxGrid } from './CheckboxGrid';
import { DateBlock } from './DateBlock';
import { DateTimeBlock } from './DateTimeBlock';
import { DropdownBlock } from './DropdownBlock';
import { LinearScale } from './LinearScale';
import { MultiLineTextBlock } from './MLTextBlock';
import { RadioboxBlock } from './RadioboxBlock';
import { RadioboxGrid } from './RadioboxGrid';
import { SingleLineTextBlock } from './SLTextBlock';
import { TimeBlock } from './TimeBlock';

export interface QuestionsListProps {
    active: boolean;
    visible: boolean;
    answered: boolean;
    questions: Question[];
    currentValues?: AnswersData;
    missingAnswers?: Question["id"][];
    onChange: (questionType: string, questionId: number, value: Answer) => void;
}

export const QuestionsList: React.FunctionComponent<QuestionsListProps> = ({ active, visible, answered, questions, currentValues, missingAnswers, onChange }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                (!visible) ? <><Alert text={t('QuestionsList.privateAnswers')} styleName="alert alert-info" /> <hr /></> : <></>
            }
            {
                (questions && questions.map((question, i) => (
                    <IsRequiredBaloon
                        key={i}
                        active={(!!missingAnswers && (missingAnswers.indexOf(question.id) !== -1))}
                        requiredMsg={t('QuestionsList.answerRequired')}
                    >
                        {
                            getQuestionByType(
                                i,
                                active,
                                answered,
                                question,
                                (visible) ? (currentValues) && currentValues[question.id] : undefined,
                                onChange
                            )
                        }
                    </IsRequiredBaloon>
                )))
            }
        </>
    );
}

const getQuestionByType = function (
    index: number,
    active: boolean,
    answered: boolean,
    question: Question,
    currentValue: Answer,
    onChange: (questionType: string, questionId: number, value: Answer) => void
): JSX.Element {
    switch (question.type.toLowerCase()) {
        case 'checkbox':
            return (
                <div key={index} className="mb-5">
                    <CheckboxBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as CheckboxQuestion).body}
                        options={(question as CheckboxQuestion).options}
                        hideOptionLabels={false}
                        expectedOptions={(question as CheckboxQuestion).expectedOptions}
                        checked={(currentValue as number[] | undefined)}
                        onChange={(v: number) => onChange('checkbox', question.id, v)}
                    />
                </div>
            );
        case 'checkboxgrid':
            return (
                <div key={index} className="mb-5">
                    <CheckboxGrid
                        active={active}
                        answered={answered}
                        questionBody={(question as CheckboxGridQuestion).body}
                        options={(question as CheckboxGridQuestion).options}
                        subQuestions={(question as CheckboxGridQuestion).subQuestions}
                        selected={currentValue as { [subQuestionId: number]: number[] | undefined } | undefined}
                        onChange={([subQId, v]) => onChange('checkboxgrid', question.id, { [subQId]: v })}
                    />
                </div>
            );
        case 'date':
            return (
                <div key={index} className="mb-56">
                    <DateBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as DateQuestion).body}
                        expectedAnswer={(question as DateQuestion).expectedAnswer}
                        value={(currentValue as string | undefined)}
                        onChange={(v: string) => onChange('date', question.id, v)}
                    />
                </div>
            );
        case 'datetime':
            return (
                <div key={index} className="mb-5">
                    <DateTimeBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as DateTimeQuestion).body}
                        expectedAnswer={(question as DateTimeQuestion).expectedAnswer}
                        value={(currentValue as string | undefined)}
                        onChange={(v: string) => onChange('datetime', question.id, v)}
                    />
                </div>
            );
        case 'dropdown':
            return (
                <div key={index} className="mb-5">
                    <DropdownBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as DropdownQuestion).body}
                        options={(question as DropdownQuestion).options}
                        expectedOptions={(question as DropdownQuestion).expectedOptions}
                        selected={(currentValue as number | undefined)}
                        onChange={(v: number) => onChange('dropdown', question.id, v)}
                    />
                </div>
            );
        case 'linearscale':
            return (
                <div key={index} className="mb-5">
                    <LinearScale
                        active={active}
                        answered={answered}
                        questionBody={(question as LinearScaleQuestion).body}
                        minValue={(question as LinearScaleQuestion).minValue}
                        maxValue={(question as LinearScaleQuestion).maxValue}
                        step={undefined}
                        minValueLabel={(question as LinearScaleQuestion).minValueLabel}
                        maxValueLabel={(question as LinearScaleQuestion).maxValueLabel}
                        hideOptionLabels={false}
                        expectedAnswer={(question as LinearScaleQuestion).expectedAnswer}
                        selectedValue={(currentValue as number | undefined)}
                        onChange={(v: number) => onChange('linearscale', question.id, v)}
                    />
                </div>
            );
        case 'multilinetext':
            return (
                <div key={index} className="mb-5">
                    <MultiLineTextBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as MultiLineTextQuestion).body}
                        expectedAnswer={(question as MultiLineTextQuestion).expectedAnswer}
                        value={(currentValue as string | undefined)}
                        onChange={(v: string) => onChange('multilinetext', question.id, v)}
                    />
                </div>
            );
        case 'radiobox':
            return (
                <div key={index} className="mb-5">
                    <RadioboxBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as RadioboxQuestion).body}
                        options={(question as RadioboxQuestion).options}
                        hideOptionLabels={false}
                        expectedOptions={(question as RadioboxQuestion).expectedOptions}
                        selected={(currentValue as number | undefined)}
                        onChange={(v: number) => onChange('radiobox', question.id, v)}
                    />
                </div>
            );
        case 'radioboxgrid':
            return (
                <div key={index} className="mb-5">
                    <RadioboxGrid
                        active={active}
                        answered={answered}
                        questionBody={(question as RadioboxGridQuestion).body}
                        options={(question as RadioboxGridQuestion).options}
                        subQuestions={(question as RadioboxGridQuestion).subQuestions}
                        selected={(currentValue as { [subQuestionId: number]: number | undefined } | undefined)}
                        onChange={([subQId, v]) => onChange('radioboxgrid', question.id, { [subQId]: v })}
                    />
                </div>
            );
        case 'singlelinetext':
            return (
                <div key={index} className="mb-5">
                    <SingleLineTextBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as SingleLineTextQuestion).body}
                        expectedAnswer={(question as SingleLineTextQuestion).expectedAnswer}
                        value={(currentValue as string | undefined)}
                        onChange={(v: string) => onChange('text', question.id, v)}
                    />
                </div>
            );
        case 'time':
            return (
                <div key={index} className="mb-5">
                    <TimeBlock
                        active={active}
                        answered={answered}
                        questionBody={(question as TimeQuestion).body}
                        expectedAnswer={(question as TimeQuestion).expectedAnswer}
                        value={(currentValue as string | undefined)}
                        onChange={(v: string) => onChange('time', question.id, v)}
                    />
                </div>
            );
        default:
            return (
                <div key={index} className="mb-2 form-group" style={{ border: '1px dashed red' }}>
                    <h3 className="h6 mb-2">{question.body}</h3>
                    <div className="p-1 text-danger">Invalid Question Type</div>
                </div>
            )
    }
}
