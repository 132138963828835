import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TimerDisplay } from './TimerDisplay';
import { Button } from '../Buttons/Button';

export interface CountdownTriggerProps {
    cancellable: boolean;
    initialSeconds: number;
    onTick: (secondsLeft: number) => void;
    onTrigger: () => void;
}

export const CountdownTrigger: React.FunctionComponent<CountdownTriggerProps> = ({ cancellable, initialSeconds, onTick, onTrigger }) => {
    const { t } = useTranslation();
    const [secondsLeft, setSecondsLeft] = useState(initialSeconds);
    const [block, setBlock] = useState(false);

    if (!block && (secondsLeft !== initialSeconds)) onTick(secondsLeft);

    const decrement = function (count: number): void {
        setTimeout(() => {
            setSecondsLeft(Math.round(count - 1));
        }, 1000);
    }

    if (!block && secondsLeft > 0) decrement(secondsLeft); // Activate 1s timeout;
    if (!block && secondsLeft <= 0) onTrigger();

    return (
        <div className="countdown-timer">
            <div className="countdown-timer-number">
                <TimerDisplay value={(!block) ? secondsLeft.toString() : undefined} />
            </div>
            <div className="countdown-timer-btn">
                {(cancellable && !block) && <Button active={true} label={t('CountdownTrigger.cancelBtn')} styleName="btn-cancel" href="" onClick={() => { setSecondsLeft(0); setBlock(true) }} />}
            </div>
        </div>
    )
}
