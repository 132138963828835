import { __AUTH_API__, __SERVER_API__ } from '.';
import { Auth, get, post, update } from '../_shared';
import {
    UserProfile,
    TraineeCourse,
    CoachCourse,
    UserGroup
} from '../models';

// (TODO) future functionality, needs definition of app custom configurations
// export async function getUserPresets(auth: Auth): Promise<UserPresets> {
//     //return await ...;
//     return new Promise((resolve, reject) => {
//         resolve({
//             language: 'pt-BR'
//         })
//     });
// }

export async function getUserProfile(auth: Auth): Promise<UserProfile> {
    return await get(`${__AUTH_API__}/api/account/userinfo`, auth);
}

export async function updateUserProfile(auth: Auth, profileData: UserProfile): Promise<void> {
    return await update(`${__AUTH_API__}/api/account/userinfo`, auth, profileData);
}

export async function postChangePassword(auth: Auth, oldPassword: string, newPassword: string, confirmPassword: string): Promise<void> {
    return await post(`${__AUTH_API__}/api/account/changepassword`, auth, {
        OldPassword: oldPassword,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword
    });
}

export async function getUserCourses(auth: Auth): Promise<{ coachCourses: CoachCourse[]; traineeCourses: TraineeCourse[] }> {
    return await get(`${__SERVER_API__}/api/mycourses`, auth);
}

export async function getUserGroups(auth: Auth): Promise<UserGroup[]> {
    return await get(`${__SERVER_API__}/api/mygroups`, auth);
}
