import * as React from 'react';
import { useContext, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';

import { APP_Default_Language, APP_Default_Timezone } from '../_defaults';

import { GlobalContext } from './GlobalContext';

import { getNewI18N } from '../../i18n';

export const I18NProvider: React.FunctionComponent = ({ children }) => {
    const { state: { currentLanguage, currentTimezone } } = useContext(GlobalContext);

    const lng = currentLanguage || APP_Default_Language;
    const tz = currentTimezone || APP_Default_Timezone;

    const memoI18N = useMemo(() => getNewI18N(lng, tz), [lng, tz]);

    return (
        <React.Fragment key={lng + tz}>                      {/* FORCE RE-RENDER */}
            <I18nextProvider i18n={memoI18N}>
                {children}
            </I18nextProvider>
        </React.Fragment >
    )
}
