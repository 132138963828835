import React from 'react';
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';

export interface DeleteConfirmPopupProps {
    isVisible: boolean;    
    message?: string;
    onClose: () => void;
    onClickDelete: () => void
}

export const DeleteConfirmPopup: React.FunctionComponent<DeleteConfirmPopupProps> = ({isVisible, message, onClose,onClickDelete}) => {
    const { t } = useTranslation();    

    return (
        <span >
               <Modal open={isVisible} onClose={() => {onClose()}} center>
               <br/>
                <p>
                    {message}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {onClose()}}>{t('Popup.Close')}</button>
                    <button type="button" className="btn btn-primary"  onClick={() => {onClickDelete();onClose()}}>{t('Popup.deleteBtn')}</button>

                </div>
            </Modal>
        </span>
    );
}
