import * as React from 'react';

import { QuestionOption } from '../../domain/models';
import { RadioboxI } from './RadioboxI';

export interface LinearScaleProps {
    active: boolean;
    answered: boolean;
    questionBody: string;
    minValue: number;
    maxValue: number;
    step?: number;                             // e.g. for fractional divisions
    minValueLabel: string;
    maxValueLabel: string;
    hideOptionLabels: boolean;
    expectedAnswer?: number;
    selectedValue?: number;
    onChange: (newSelected: number) => void;
}

let uniqueID: number = 0;

export const LinearScale: React.FunctionComponent<LinearScaleProps> = ({ active, answered, questionBody, minValue, maxValue, step, minValueLabel, maxValueLabel, hideOptionLabels, expectedAnswer, selectedValue, onChange }) => {
    const linearScaleId = ++uniqueID;

    const options: QuestionOption[] = buildOptions(minValue, maxValue, step);

    const oneIsSelected = function (optionId: number): boolean {
        return (selectedValue === optionId);
    }

    const oneIsValid = function (value: number, oneIsSelected: boolean): boolean {        
        let oneIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            oneIsExpected = (expectedAnswer === value);
        }

        return answered && oneIsSelected && oneIsExpected;
    }

    const oneIsInvalid = function (value: number, oneIsSelected: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            oneIsExpected = (expectedAnswer === value);
        }

        return answered && (oneIsSelected && !oneIsExpected || !oneIsSelected && oneIsExpected);
    }

    return (
        <div className="question-linearScale">
            <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
            <div className="question-linearScale-anwsers">

                <div className="question-linearScale-column">
                    <div className="question-linearScale-cel" />
                    <div className="question-linearScale-cel">{minValueLabel}</div>
                </div>

                {options && options.map((option, i) => {
                    const oneIsSltd: boolean = oneIsSelected(option.id);
                    return (
                        <RadioboxI
                            key={i}
                            active={active}
                            id={`linear${linearScaleId}${i}`}
                            value={option.id.toString()}
                            label={hideOptionLabels ? '' : option.body}
                            isSelected={oneIsSltd}
                            isValid={oneIsValid(option.id, oneIsSltd)}
                            isInvalid={oneIsInvalid(option.id, oneIsSltd)}
                            onChange={(selectedValue: string) => onChange(+selectedValue)}
                        />
                    )
                })}

                <div className="question-linearScale-column">
                    <div className="question-linearScale-cel" />
                    <div className="question-linearScale-cel">{maxValueLabel}</div>
                </div>
            </div>
        </div>
    );
}

const buildOptions = function (min: number, max: number, step?: number): QuestionOption[] {
    let options: QuestionOption[] = [];

    if (!step) {
        const a = min / 1;  // OBS.: rounded to integer
        const b = max / 1;  // OBS.: rounded to integer
        let i = a;
        do {
            options.push({ id: i, body: i.toString() });
            i++;
        } while (i<=b);
    } else {
        const a = min;
        const b = max;
        let i = a;
        do {
            options.push({ id: i, body: i.toPrecision(4) });
            i = Math.round((i + step) * 10)/10;
        } while (i<=b);
    }

    return options;
}
