import * as React from 'react';
import { SurveyOptionsField } from './SurveyOptionField';
import { QuestionOption, Question } from '../../domain/models';
import { EditQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditQuestion';
import { EditOption } from '../../pages/Admin/CourseManagementPage/components/tab1/EditOption';
import { useTranslation } from 'react-i18next';

export interface SurveyDropDownProps {
    onChangeOption: (options: QuestionOption[]) => void;
    updateQuestion?: (question: Question) => void;
    question?: Question;
    currentOptions?: QuestionOption[];
    onCompleteEdit?: (item: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
    order?: number;
}

export const SurveyDropDown: React.FunctionComponent<SurveyDropDownProps> = ({
    onChangeOption,
    question,
    updateQuestion,
    currentOptions,
    onCompleteEdit,
    onAddOption,
    onDeleteOption,
    order
}) => {
    const { t } = useTranslation();
    const initialOptionsValue: string[] = ['', '', '']
    const [options, setOptions] = React.useState(initialOptionsValue)
    const [isEditMode, setEditMode] = React.useState(false)
    const [validationOn, toggleValidation] = React.useState(false)

    React.useEffect(() => {
        if (question) setEditMode(true)
    }, [])

    const onAddNewOptionHandler = (index: number) => {
        const updatedOptions = [...options]
        updatedOptions.splice(index + 1, 0, '')
        setOptions([...updatedOptions])
        toggleValidation(false)
    }
    const onRemoveOptionHandler = (index: number) => {
        const updatedOptions = [...options]
        if (updatedOptions.length < 2) {
            toggleValidation(true)
            return
        }
        updatedOptions.splice(index, 1)
        setOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option,
                order: index + 1
            }
        })
        onChangeOption(questionOptions)
    }

    const onChangeHandler = (val: string, index: number) => {
        const updatedOptions = [...options]
        updatedOptions.splice(index, 1, val)
        setOptions([...updatedOptions])
        let questionOptions = updatedOptions.map((option, index) => {
            return {
                id: 0,
                body: option,
                order: index + 1
            }
        })
        onChangeOption(questionOptions)
        toggleValidation(false)
    }

    return (
        <div>
            <EditQuestion question={question} updateQuestion={updateQuestion} order={order} />
            <>
                {!isEditMode &&
                    options.map((item, index) => (
                        <SurveyOptionsField
                            index={index}
                            active={true}
                            placeholder={t('ContentPanel.option_place_holder')}
                            value={item}
                            isValid={false}
                            isInvalid={false}
                            onChange={onChangeHandler}
                            onAddOption={onAddNewOptionHandler}
                            onRemoveOption={onRemoveOptionHandler}
                        />
                    ))}
                {validationOn &&
                    <div className="invalid-feedback d-flex">You cant delete all options.</div>
                }
                {isEditMode &&
                    <EditOption
                        currentOptions={currentOptions!}
                        onCompleteEdit={onCompleteEdit!}
                        onAddOption={onAddOption}
                        onDeleteOption={onDeleteOption}
                    />
                }
            </>
        </div>
    );
}