import * as React from 'react';
import classnames from 'classnames';

export interface IsRequiredBaloonProps {
    active: boolean;
    requiredMsg?: string;
}

export const IsRequiredBaloon: React.FunctionComponent<IsRequiredBaloonProps> = ({ active, requiredMsg, children }) => {
    return (
        <div
            style={active ? {
                display: ''
            } : undefined}
        >
            <div className={classnames({
                'is-required': active,
                'mb-5': active,
            })}>
                {(active) && <Baloon>{requiredMsg}</Baloon>}
                {children}
            </div>
        </div>
    );
}

const Baloon: React.FunctionComponent = ({ children }) => {
    return (
        <div className={'alert alert-danger'}>
            {children}
        </div>
    );
}