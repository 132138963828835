import * as React from 'react';
import { QuestionOption, Question, SubQuestion } from '../../../../../domain/models';
import { EditSubQuestion } from './EditSubQuestion';
import { EditOption } from './EditOption';
import { RadioboxGrid } from '../../../../../components/Forms/RadioboxGrid';

export interface EditRadioBoxGridProps {
  question?: Question;
  currentOptions?: QuestionOption[];
  currentSubQuestions?: SubQuestion[];
  onCompleteOptionEdit?: (item: QuestionOption) => void;
  onCompleteSubQuestionEdit?: (item: QuestionOption) => void;
  onAddOption?: (item: QuestionOption) => void;
  onDeleteOption?: (id: number ) => void;
  onAddSubQuestion?: (item: QuestionOption) => void;
  onDeleteSubQuestion?: (id: number ) => void;
  updateExpectedAnswer?: (subQuestionId: number, expectedAnswerId: number) => void;
}

export const EditRadioBoxGrid: React.FunctionComponent<
  EditRadioBoxGridProps
> = ({
  currentOptions,
  currentSubQuestions,
  onCompleteOptionEdit,
  onCompleteSubQuestionEdit,
  onAddOption,
  onDeleteOption,
  onAddSubQuestion,
  onDeleteSubQuestion,
  updateExpectedAnswer,
}) => {
  const [answerKeyMode, setAnswerKeyMode] = React.useState(false);
  const [value, setValue] = React.useState();
  React.useEffect(() => {
    let temp: { [subQuestionId: number]: number | undefined } = {}
    currentSubQuestions!.forEach((subQ) => {
      temp[subQ.id] = subQ.expectedOptions![0]
    })
    setValue(temp)
  }, [])

  const handleAnswerGridEdit = ([subQId, val]: [number, number]) => {
    updateExpectedAnswer!(subQId, val)
    setValue({ ...value, [subQId]: val });
  };

  return (
    <>
      {!answerKeyMode && (
        <>
          Row
          <EditSubQuestion
            currentOptions={currentSubQuestions!}
            onCompleteEdit={onCompleteSubQuestionEdit!}
            onAddOption={onAddSubQuestion}
            onDeleteOption={onDeleteSubQuestion}
          />
          Column
          <EditOption
            currentOptions={currentOptions!}
            onCompleteEdit={onCompleteOptionEdit!}
            onAddOption={onAddOption}
            onDeleteOption={onDeleteOption}
          />
        </>
      )}
      {answerKeyMode && (
        <RadioboxGrid
          active={true}
          answered={false}
          questionBody={""}
          options={currentOptions!}
          subQuestions={currentSubQuestions!}
          selected={value}
          onChange={handleAnswerGridEdit}
        />
      )}
      {!answerKeyMode && (
        <div className="d-flex flex-row-reverse survey-form-t-margin">
          <button
            className="btn btn-light m-1"
            onClick={() => setAnswerKeyMode(true)}
          >
            Answer key
          </button>
        </div>
      )}
      {answerKeyMode && (
        <div className="d-flex flex-row-reverse survey-form-t-margin">
          <button
            className="btn btn-light m-1"
            onClick={() => setAnswerKeyMode(false)}
          >
            Options
          </button>
        </div>
      )}
    </>
  );
};