import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface TraineeContentFeedbackProps {
    status?: string;
}

export const TraineeContentFeedback: React.FunctionComponent<TraineeContentFeedbackProps> = ({ status }) => {
    const { t } = useTranslation();

    switch (status) {
        case 'NotStarted':
            return (
                <></>   // feedback hidden
            );
        case 'WaitingForCoachApproval':
            return (
                <div className="task-summary-content">
                    {/* <div className="task-summary-icon">
                                <span className="fa fa-exclamation text-info"></span>
                            </div> */}
                    <div className="task-summary-info">
                        <p className="task-summary-title text-info"><strong>{t('ContentFeedback.waitingApproval')}</strong></p>
                    </div>
                </div>
            );
        case 'Finished':
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-check text-success"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-success"><strong>{t('ContentFeedback.taskCompleted')}</strong></p>
                    </div>
                </div>
            );
        default:
            return (
                <div className="task-summary-content has-icon">
                    <div className="task-summary-icon">
                        <span className="fa fa-close text-danger"></span>
                    </div>
                    <div className="task-summary-info">
                        <p className="task-summary-title text-danger"><strong>(unknown status)</strong></p>
                    </div>
                </div>
            );
    }
}
