import * as React from 'react';
import plusIcon from '../../assets/img/plus.png'
import minusIcon from '../../assets/img/minus.png'
import { TextI } from '../Forms/TextI';
import { CheckboxI } from '../Forms/CheckboxI';

export interface QuizOptionPreviewProps {
    index: number;
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (typedValue: string, index: number) => void;
    onAddOption: (index: number) => void;
    onRemoveOption: (index: number) => void;
    isEditing?: boolean;
    onCompleteEdit?: () => void;
    expectedAnswer?:string;
}

export const QuizOptionPreview: React.FunctionComponent<QuizOptionPreviewProps> = ({ index, active, placeholder, value, isValid, isInvalid, onChange, onAddOption, onRemoveOption, isEditing, onCompleteEdit,expectedAnswer }) => {
    const addOptionHandler = () => {
        onAddOption(index)
    }
    const removeOptionHandler = () => {
        onRemoveOption(index)
    }

    const onChangeHandler = (value: string) => {
        onChange(value, index)
    }
    return (
        <div className="d-flex full-width">            
            <TextI
                active={active}
                placeholder={placeholder}
                value={value}
                isValid={isValid}
                isInvalid={isInvalid}
                onChange={onChangeHandler}
            />
            {value===expectedAnswer &&
                <div className="d-flex flex-column justify-content-center" style={{cursor: "pointer",color:"#2a9e2a"}} onClick={onCompleteEdit}>
                    <i className="fa fa-check"></i>
                </div>
            }
           
      </div>
    );
}