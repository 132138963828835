import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CardViewType, CardViewTypeContext } from '../../../../../components/Cards/CardSection';
import { Card } from '../../../../../components/Cards/Card';

export interface AdminGroupCardProps {
    cardViewType?: CardViewType;

    name: string;
    description?: string;
    userCount?: number;
    creationDate?: string;

    onClick?: () => void;
}

export const AdminGroupCard: React.FunctionComponent<AdminGroupCardProps> = ({
    cardViewType,
    name,
    description,
    userCount,
    creationDate,
    onClick
}) => {
    const value = useContext(CardViewTypeContext);
    const { t } = useTranslation();

    return (
        <Card
            cardViewType={cardViewType}
            header={() => (
                <>
                    <h2 className="card-title text-dark h4 mb-2">
                        <a href="javascript:void(0)" onClick={() => onClick ? onClick() : null}>{name}</a>
                    </h2>
                    <div className="mb-2 text-info">
                        <small><i className="icon icon-settings"></i>
                            {' '} <strong>{t('GroupCard.youAreAdmin')}</strong></small>
                    </div>
                    <p className="card-text text-muted d-none d-sm-block">{description}</p>
                    {
                        (value == CardViewType.Grid)
                        && (
                            <>
                                <div className="float-left">
                                    <small><span style={{ fontWeight: 500 }} className="text-muted">{t('GroupCard.users')}:</span> {userCount}</small>
                                </div>
                                <div className="float-right">
                                    <small><span style={{ fontWeight: 500 }} className="text-muted">{t('GroupCard.since')}:</span> {creationDate ? t('dates.short', { valueObj: { type: 'withTZdatetime', data: creationDate } }) : ''}</small>
                                </div>
                            </>
                        )
                    }
                </>
            )}
            body={() => (
                <div className="row">
                    <div className="col-md-6">
                        <button
                            className="btn btn-primary mt-1 btn-icon"
                            type="button"
                            onClick={() => onClick ? onClick() : null}
                            style={{ width: '100%', maxWidth: '100%' }}
                        >
                            {t('GroupCard.goToGroupBtn')}
                            <i className={`icon fa fa-angle-right`}></i>
                        </button>
                    </div>
                </div>
            )}
        />
    );
}
