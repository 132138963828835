import * as React from 'react';
import { useEffect } from 'react';

import { addCss, removeCss } from '../api/DomHelper';

export interface BodyProps {
    className?: string;
}

export const Body: React.FunctionComponent<BodyProps> = ({ className, children }) => {
    useEffect(() => {
        addCss(document.body, className || '');
        return () => {
            removeCss(document.body, className || '');
        }
    }, []);
    return <>{children}</>;
}