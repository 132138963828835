import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_Language_List, APP_Timezone_List, validEmail, validLanguage, validTimezone } from '../../../domain/_defaults';
import { UserProfile } from '../../../domain/models';

export interface EditProfileCardProps {
    feedbackError?: string;
    disabled?: boolean;
    isSending?: boolean;
    currentFullName?: string;
    currentNickName?: string;
    currentPhone?: string;
    currentEmail?: string;
    currentLanguage?: string;
    currentTimezone?: string;
    onCancel: () => void;
    onSave: (newProfile: UserProfile) => void;
}

export const EditProfileCard: React.FunctionComponent<EditProfileCardProps> = ({
    feedbackError,
    disabled,
    isSending,
    currentFullName,
    currentNickName,
    currentPhone,
    currentEmail,
    currentLanguage,
    currentTimezone,
    onCancel,
    onSave
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        newFullName: currentFullName || '',
        invalidFullNameMessage: undefined as string | undefined,
        newNickName: currentNickName || '',
        invalidNickNameMessage: undefined as string | undefined,
        newPhone: currentPhone || '',
        invalidPhoneMessage: undefined as string | undefined,
        newEmail: currentEmail || '',
        invalidEmailMessage: undefined as string | undefined,
        newLanguage: currentLanguage || '',
        invalidLanguageMessage: undefined as string | undefined,
        newTimezone: currentTimezone || '',
        invalidTimezoneMessage: undefined as string | undefined
    });
    const {
        newFullName,
        invalidFullNameMessage,
        newNickName,
        invalidNickNameMessage,
        newPhone,
        invalidPhoneMessage,
        newEmail,
        invalidEmailMessage,
        newLanguage,
        invalidLanguageMessage,
        newTimezone,
        invalidTimezoneMessage
    } = state;

    function checkAndSend() {
        let a: string | undefined;
        let b: string | undefined;
        let c: string | undefined;
        let d: string | undefined;
        let e: string | undefined;
        let f: string | undefined;

        if (newFullName.length === 0) a = t('EditProfileCard.fieldRequired');

        if (newNickName.length === 0) b = t('EditProfileCard.fieldRequired');

        // (TODO) re-enable after endpoint fix
        // if (newPhone.length === 0) c = t('EditProfileCard.fieldRequired');
        // if (!validPhone(newPhone)) c = t('EditProfileCard.invalidPhoneFormat');

        if (newEmail.length === 0) d = t('EditProfileCard.fieldRequired');
        if (!validEmail(newEmail)) d = t('EditProfileCard.invalidEmailFormat');

        if (!newLanguage || newLanguage.length === 0) e = t('EditProfileCard.fieldRequired');
        if (!validLanguage(newLanguage)) e = t('EditProfileCard.invalidLanguage');

        if (!newTimezone || newTimezone.length === 0) f = t('EditProfileCard.fieldRequired');
        if (!validTimezone(newTimezone)) f = t('EditProfileCard.invalidTimezone');

        if (!a && !b && !c && !d && !e && !f) {
            onSave({
                name: newFullName,
                preferred_username: newNickName,
                email: newEmail,
                phone_number: newPhone ? newPhone : undefined,
                locale: newLanguage,
                zoneinfo: newTimezone
            });
        } else {
            setState({
                ...state,
                invalidFullNameMessage: a,
                invalidNickNameMessage: b,
                invalidPhoneMessage: c,
                invalidEmailMessage: d,
                invalidLanguageMessage: e,
                invalidTimezoneMessage: f
            });
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h1 className="h5">
                    <strong>
                        {t('EditProfileCard.myProfile')}
                        {' '}<span className="text-primary">{t('EditProfileCard.edit')}</span>
                    </strong>
                </h1>
            </div>

            <div className="card-body">
                <EditProfileForm
                    feedbackError={feedbackError}
                    disabled={isSending || disabled}
                    fullNameText={newFullName || ''}
                    invalidFullNameMessage={invalidFullNameMessage}
                    nickNameText={newNickName || ''}
                    invalidNickNameMessage={invalidNickNameMessage}
                    phoneText={newPhone || ''}
                    invalidPhoneMessage={invalidPhoneMessage}
                    emailText={newEmail || ''}
                    invalidEmailMessage={invalidEmailMessage}
                    languageValue={newLanguage || ''}
                    invalidLanguageMessage={invalidLanguageMessage}
                    timezoneValue={newTimezone || ''}
                    invalidTimezoneMessage={invalidTimezoneMessage}
                    onFullNameTextChange={text => setState({ ...state, newFullName: text, invalidFullNameMessage: undefined })}
                    onNickNameTextChange={text => setState({ ...state, newNickName: text, invalidNickNameMessage: undefined })}
                    onPhoneTextChange={text => setState({ ...state, newPhone: text, invalidPhoneMessage: undefined })}
                    onEmailTextChange={text => setState({ ...state, newEmail: text, invalidEmailMessage: undefined })}
                    onLanguageValueChange={value => setState({ ...state, newLanguage: value, invalidLanguageMessage: undefined })}
                    onTimezoneValueChange={value => setState({ ...state, newTimezone: value, invalidTimezoneMessage: undefined })}
                />
            </div>

            <div className="card-footer text-right">
                <FooterBtns
                    disabled={disabled || false}
                    isSending={isSending || false}
                    onCancelClick={() => onCancel()}
                    onSaveClick={() => checkAndSend()}
                />
            </div>
        </div>
    )
}

interface EditProfileFormProps {
    feedbackError?: string;
    disabled?: boolean;
    fullNameText: string;
    invalidFullNameMessage?: string;
    nickNameText: string;
    invalidNickNameMessage?: string;
    phoneText: string;
    invalidPhoneMessage?: string;
    emailText: string;
    invalidEmailMessage?: string;
    languageValue: string;
    invalidLanguageMessage?: string;
    timezoneValue: string;
    invalidTimezoneMessage?: string;
    onFullNameTextChange: (text: string) => void;
    onNickNameTextChange: (text: string) => void;
    onPhoneTextChange: (text: string) => void;
    onEmailTextChange: (text: string) => void;
    onLanguageValueChange: (value: string) => void;
    onTimezoneValueChange: (value: string) => void;
}

const EditProfileForm: React.FunctionComponent<EditProfileFormProps> = ({
    feedbackError,
    disabled,
    fullNameText,
    invalidFullNameMessage,
    nickNameText,
    invalidNickNameMessage,
    phoneText,
    invalidPhoneMessage,
    emailText,
    invalidEmailMessage,
    languageValue,
    invalidLanguageMessage,
    timezoneValue,
    invalidTimezoneMessage,
    onFullNameTextChange,
    onNickNameTextChange,
    onPhoneTextChange,
    onEmailTextChange,
    onLanguageValueChange,
    onTimezoneValueChange
}) => {
    const { t } = useTranslation();

    return (
        <form className="form-horizontal col-md-10 col-lg-8 col-xlg-6">
            {feedbackError && <div className="alert alert-danger">{feedbackError}</div>}

            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-fullname">{t('EditProfileCard.fullName')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidFullNameMessage ? 'is-invalid' : '')}
                        disabled={disabled}
                        id="profile-fullname"
                        type="text"
                        name="profile-fullname"
                        value={fullNameText}
                        placeholder={t('EditProfileCard.fullName')}
                        onChange={e => onFullNameTextChange(e.target.value)}
                    />
                    {invalidFullNameMessage && <div className="invalid-feedback">{invalidFullNameMessage}</div>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-nickname">{t('EditProfileCard.nickName')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidNickNameMessage ? 'is-invalid' : '')}
                        disabled={disabled}
                        id="profile-nickname"
                        type="text"
                        name="profile-nickname"
                        value={nickNameText}
                        placeholder={t('EditProfileCard.nickName')}
                        onChange={e => onNickNameTextChange(e.target.value)}
                    />
                    {invalidNickNameMessage && <div className="invalid-feedback">{invalidNickNameMessage}</div>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-phone">{t('EditProfileCard.phone')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidPhoneMessage ? 'is-invalid' : '')}
                        disabled={true}             // (TODO) re-enable after endpoint fix
                        id="profile-phone"
                        type="tel"
                        name="profile-phone"
                        value={phoneText}
                        placeholder="+001 011 00000-0000"
                        onChange={e => onPhoneTextChange(e.target.value)}
                    />
                    {invalidPhoneMessage && <div className="invalid-feedback">{invalidPhoneMessage}</div>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-email">{t('EditProfileCard.email')}</label>
                <div className="col-md-9">
                    <input
                        className={"form-control " + (invalidEmailMessage ? 'is-invalid' : '')}
                        disabled={true}             // (TODO) re-enable after endpoint fix
                        id="profile-email"
                        type="email"
                        name="profile-email"
                        value={emailText}
                        placeholder="email@email.com"
                        onChange={e => onEmailTextChange(e.target.value)}
                    />
                    {invalidEmailMessage && <div className="invalid-feedback">{invalidEmailMessage}</div>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-language">{t('EditProfileCard.language')}</label>
                <div className="col-md-9">
                    <LanguageSelectElement
                        selected={languageValue}
                        invalidLanguageMessage={invalidLanguageMessage}
                        disabled={disabled || false}
                        onChange={(val) => onLanguageValueChange(val)}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-3 col-form-label" htmlFor="profile-timezone">{t('EditProfileCard.timezone')}</label>
                <div className="col-md-9">
                    <TimezoneSelectElement
                        selected={timezoneValue}
                        invalidTimezoneMessage={invalidTimezoneMessage}
                        disabled={disabled || false}
                        onChange={(val) => onTimezoneValueChange(val)}
                    />
                </div>
            </div>
        </form>
    )
}

interface LanguageSelectElementProps {
    selected?: string;
    invalidLanguageMessage?: string;
    disabled: boolean;
    onChange: (selectedValue: string) => void;
}

const LanguageSelectElement: React.FunctionComponent<LanguageSelectElementProps> = ({
    selected,
    invalidLanguageMessage,
    disabled,
    onChange
}) => {
    const { t } = useTranslation();
    const languages = APP_Language_List;

    return (
        <>
            <select
                className={"form-control " + (invalidLanguageMessage ? 'is-invalid' : '')}
                disabled={disabled}
                id="profile-language"
                name="profile-language"
                value={selected ? selected : ''}
                onChange={e => onChange(e.target.value)}
            >
                <option value={''} disabled>{t('EditProfileCard.pleaseSelect')}</option>
                {languages.map((lng, i) =>
                    <option key={i} value={lng}>{t(`IANA.${lng}`)}</option>
                )}
            </select>
            {invalidLanguageMessage && <div className="invalid-feedback">{invalidLanguageMessage}</div>}
        </>
    )
}

interface TimezoneSelectElementProps {
    selected?: string;
    invalidTimezoneMessage?: string;
    disabled: boolean;
    onChange: (selectedValue: string) => void;
}

const TimezoneSelectElement: React.FunctionComponent<TimezoneSelectElementProps> = ({
    selected,
    invalidTimezoneMessage,
    disabled,
    onChange
}) => {
    const { t } = useTranslation();
    const timezones = APP_Timezone_List;

    return (
        <>
            <select
                className={"form-control " + (invalidTimezoneMessage ? 'is-invalid' : '')}
                disabled={disabled}
                id="profile-timezone"
                name="profile-timezone"
                value={selected ? selected : ''}
                onChange={e => onChange(e.target.value)}
            >
                <option value={''} disabled>{t('EditProfileCard.pleaseSelect')}</option>
                {timezones.map((tz, i) =>
                    <option key={i} value={tz}>{t(`IANA.${tz}`)}</option>
                )}
            </select>
            {invalidTimezoneMessage && <div className="invalid-feedback">{invalidTimezoneMessage}</div>}
        </>
    )
}

interface FooterBtnsProps {
    disabled: boolean;
    isSending: boolean;
    onCancelClick: () => void;
    onSaveClick: () => void;
}

const FooterBtns: React.FunctionComponent<FooterBtnsProps> = ({
    disabled,
    isSending,
    onCancelClick,
    onSaveClick
}) => {
    const { t } = useTranslation();

    return (
        isSending
            ? (
                <>
                    <button
                        type="button"
                        className="btn btn-light m-1"
                        disabled={true}
                    >
                        {t('EditProfileCard.cancelBtn')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-loader btn-primary m-1"
                        disabled={true}
                    >
                        <div className="sk-rotating-plane"></div>
                        <span className="label small">{t('EditProfileCard.saveBtn')}</span>
                    </button>
                </>
            )
            : (
                <>
                    <button
                        type="button"
                        className="btn btn-light m-1"
                        disabled={disabled}
                        onClick={() => onCancelClick()}
                    >
                        {t('EditProfileCard.cancelBtn')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary m-1"
                        disabled={disabled}
                        onClick={() => onSaveClick()}
                    >
                        {t('EditProfileCard.saveBtn')}
                    </button>
                </>
            )

    )
}
