import React from 'react';
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';

export interface UnassignCoachPopupProps {
    onClickUnAssign: () => void;
    onClose: () => void;
    isVisible: boolean;
}

export const UnassignCoachPopup: React.FunctionComponent<UnassignCoachPopupProps> = ({ isVisible, onClose, onClickUnAssign }) => {
    const { t } = useTranslation();

    return (
        <span >
            <Modal open={isVisible} onClose={() => onClose()}>
                <h6 className="h6">{t('RemoveCoachPopup.UnsignCoach')}</h6>
                <p>
                    {t('RemoveCoachPopup.UnassignMessage')}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onClose()}>{t('RemoveCoachPopup.Close')}</button>
                    <button type="button" className="btn btn-primary" onClick={() => onClickUnAssign()}>{t('RemoveCoachPopup.UnsignCoach')}</button>
                </div>
            </Modal>
        </span>
    );
}
