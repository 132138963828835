import * as React from "react";
import { Lesson, Task, Trainee, TraineeResult } from "../../../domain/models";
import { LessonCollapsePanel } from "./LessonCollapsePanel";
import { TraineeStatusCard } from "../CourseDashboardPage/components/tab1/TraineeStatusCard";
export interface TraineeLessonPanelProps {
  lessons: Lesson[];
  courseName: string;
  courseIcon: string | undefined;
  trainees: TraineeResult;
  selectedTaskId: number;
  traineeID?: string;
  onTaskClick: (task: Task, lessonId: number) => void;
  onChange: (selectedValue: string) => void;
}
export const TaskRightSidePanel: React.FunctionComponent<TraineeLessonPanelProps> = ({
  lessons,
  courseName,
  courseIcon,
  trainees,
  selectedTaskId,
  traineeID,
  onTaskClick,
  onChange
}) => {
  console.log("mmmm", trainees);
  return (
    <div className="col-md-4 col-lg-3 task-execution-sidebar">
      <div className="lessons-sidebar">
        <div className="lessons-sidebar-header">
          <div className="lessons-sidebar-header-img">
            <img src={courseIcon} alt="preview" />
          </div>
          <h2 className="lessons-sidebar-header-title">{courseName}</h2>
        </div>

        <div className="lessons-sidebar-info">
          <div className="form-group">
            <label htmlFor="ccyear" className="small">
              Trainee:
            </label>
            <select
              className="form-control"
              value={traineeID}
              onChange={e => onChange(e.target.value)}
            >
              {trainees.results.map((trainee, i) => (
                <option key={i} value={trainee.id}>
                  {trainee.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="lessons-sidebar-content">
          {lessons &&
            lessons.map(function(item, index: number) {
              return (
                <LessonCollapsePanel
                  key={index}
                  lesson={item}
                  index={index}
                  selectedTaskId={selectedTaskId}
                  onTaskClick={onTaskClick}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
