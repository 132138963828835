import * as React from 'react';
import classnames from 'classnames';

export interface ButtonBackProps {
    styleName?: string;
    text: string;
}

export const ButtonLoader: React.FunctionComponent<ButtonBackProps> = ({ styleName, text }) => {
    return (
        <button
            type="button"
            className={classnames(styleName)}
            disabled
        >
            <div className="sk-rotating-plane"></div>
            {' '} <span className="label small">{text}</span>
        </button>
    )
}