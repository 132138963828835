import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as services from '../../../../../domain/services';
import { Card } from '../../../../../components/Cards/Card';
import { CardViewType } from '../../../../../components/Cards/CardSection';
import * as Router from '../../../../../api/Router';
import { Popup } from '../../../CourseManagementPage/components/tab2/Popup';
import { DeleteConfirmPopup } from '../../../../../components/Popup/DeleteConfirmPopup';


export interface AdminCourseStatusCardProps {
    cardViewType?: CardViewType;
    name: string;
    totalTrainees?: number;
    totalCoaches?: number;
    publishDate?: string;
    courseStatus: string;
    onClick: () => void
    onClickDeleteCourse: () => void
    onClickPublish: () => void;
    groupId: number;
    courseId:number;
    courseType:string;
}

export const AdminCourseStatusCard: React.FunctionComponent<AdminCourseStatusCardProps> = ({
    cardViewType,
    name,
    totalTrainees,
    totalCoaches,
    publishDate,
    courseStatus,
    onClick,
    onClickDeleteCourse,
    onClickPublish,
    groupId,
    courseId,
    courseType
}) => {
    const { t } = useTranslation();

    const coursStatusTextStyle = courseStatus === 'Published' ? { color: 'green' } : { color: '#2e9fc9' }
    const [isdeletePopUpVisible, toggleDeletePopUp] = useState(false)
  
    return (
        <Card
            cardViewType={cardViewType}
            header={() => (
                <div>
                    <h2 className="card-title h6">
                        <a href="javascript:void(0)" onClick={() => onClick()} style={{ cursor: 'hand' }}>{name}</a>
                    </h2>
                    <p style={coursStatusTextStyle}>{courseStatus.toUpperCase()}</p>
                </div>
            )}
            body={() => (
                <>
                {courseStatus==='Published'  &&
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                        {(totalTrainees !== undefined) && (<div>
                                <small><strong className="text-muted">{t('AdminCourseStatusCard.totalTrainees')}:</strong> {totalTrainees}</small>
                            </div>
                        )}
                            {(totalCoaches !== undefined) && (courseType!=='SelfPaced') &&
                            (<div className="mt-1 mt-md-2">
                                <small><strong className="text-muted">{t('AdminCourseStatusCard.totalCoaches')}:</strong> {totalCoaches}</small>
                            </div>
                            )}
                        </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <small><strong className="text-muted">{t('AdminCourseStatusCard.published')}:</strong> {
                                    (_.isUndefined(publishDate) || (_.isNull(publishDate))) ? 'N/A' : t('dates.short', { valueObj: { type: 'withTZdatetime', data: publishDate } })}</small>
                            </div>
                    </div>
                }
                </>
            )}
            
            footer={() => (
                <div>
                    {(courseStatus === 'Published' ) &&
                        <button className="btn btn-light m-1" type="button" onClick={() => onClick()}>{t('AdminCourseStatusCard.viewCourseBtn')}</button>
                    }

                    {(courseStatus === 'Draft' || courseStatus==='Test') &&
                        <div className="btn-display-flex">
                          {courseStatus==='Test'&&
                            <button className="btn btn-primary m-1" type="button" onClick={onClickPublish}>{t('AdminCourseStatusCard.publishButton')}</button>
                          }
                            <button className="btn btn-light m-1" type="button" onClick={() => Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)}>{t('AdminCourseStatusCard.viewCourseBtn')}</button>
                            <button className="btn btn-light m-1" type="button" onClick={() => Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`)}>{t('AdminCourseStatusCard.viewButton')}</button>
                            <button className="btn text-danger" onClick={() => toggleDeletePopUp(true)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    }
                    <DeleteConfirmPopup onClose={() => { toggleDeletePopUp(false) }} message={t('AdminCourseStatusCard.deleteCourseMsg')} isVisible={isdeletePopUpVisible} onClickDelete={onClickDeleteCourse}></DeleteConfirmPopup>
                </div>
            )}
           
        />
    );
}
