import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionOption } from '../../domain/models';
import { DropdownI } from './DropdownI';

export interface DropdownBlockProps {
    active: boolean;
    answered: boolean;
    questionBody: string;
    options: QuestionOption[];
    expectedOptions?: number[];
    selected?: number;
    onChange: (newSelected: number) => void;
}

export const DropdownBlock: React.FunctionComponent<DropdownBlockProps> = ({ active, answered, questionBody, options, expectedOptions, selected, onChange }) => {
    const { t } = useTranslation();

    const oneIsSelected = function (): number | undefined {
        return (!!options) && (options.map(o => o.id).indexOf(selected || -999999) !== -1)
            ? selected
            : undefined;
    }

    const oneIsValid = function (oneIsSelected: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedOptions) {
            return false;
        } else {
            oneIsExpected = (expectedOptions.indexOf(selected || -999999) !== -1);
        }

        return answered && oneIsSelected && oneIsExpected;
    }

    const oneIsInvalid = function (oneIsSelected: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedOptions) {
            return false;
        } else {
            oneIsExpected = (expectedOptions.indexOf(selected || -999999) !== -1);
        }

        return answered && (oneIsSelected && !oneIsExpected || !oneIsSelected);
    }

    const getExpectedOption = function (): string | undefined {
        const option = (!!options) && options.find(o => o.id === (expectedOptions ? expectedOptions[0] : -999999));
        return option ? option.body : '';
    }

    const expectedLabel: string | undefined = getExpectedOption();
    const oneIsSltd: number | undefined = oneIsSelected();
    return (
        <div className="form-group">
            <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
            <DropdownI
                active={active}
                options={options.map(({ id, body }) => ({ value: id.toString(), label: body }))}
                selected={oneIsSltd ? oneIsSltd.toString() : undefined}
                isValid={oneIsValid(!!oneIsSltd)}
                isInvalid={oneIsInvalid(!!oneIsSltd)}
                invalidMessage={` ${t('DropdownBlock.wrongAnswerMsg')} '${expectedLabel}'`}
                onChange={(selectedValue: string) => onChange(+selectedValue)}
            />
        </div>
    );
}
