/**
 * Custom module for quilljs to allow user to resize <img> elements and to add links and caption
 * (Works on Chrome, Edge, Safari and replaces Firefox's native resize behavior)
 * @see https://quilljs.com/blog/building-a-custom-module/
 */

import { Quill } from 'react-quill'
import { StyleHTMLAttributes } from 'react';
type Options = {
    displaySize?: boolean;
    handleStyles?: StyleHTMLAttributes<any>;
    displayStyles?: StyleHTMLAttributes<any>
}
export class ImageResize {
    quill: Quill
    options: Options
    boxes: HTMLDivElement[]
    img?: HTMLImageElement
    dragBox?: HTMLDivElement
    dragStartX?: number
    preDragWidth?: number
    display?: HTMLDivElement
    buttonLink: HTMLButtonElement
    titleInput: HTMLInputElement
    captionInput: HTMLInputElement
    linkInput: HTMLInputElement
    isVisibleImageOptions: boolean
    divPopup: HTMLDivElement
    innerdivPopup: HTMLDivElement
    titleLabel: HTMLLabelElement
    captionLabel: HTMLLabelElement
    linkLabel: HTMLLabelElement
    btnContainer: HTMLDivElement
    btnCancel: HTMLButtonElement
    btnSave: HTMLButtonElement
    errormsg: HTMLLabelElement
    footerdivPopup: HTMLDivElement

    constructor(quill: Quill, options = {}) {
        // save the quill reference and options
        this.quill = quill;
        this.options = options;
        this.isVisibleImageOptions = false
        //button for adding link for image
        this.buttonLink = document.createElement('button')
        //popup components
        this.titleInput = document.createElement('input')
        this.captionInput = document.createElement('input')
        this.linkInput = document.createElement('input')
        this.divPopup = document.createElement('div');
        this.innerdivPopup = document.createElement('div');
        this.titleLabel = document.createElement('label')
        this.captionLabel = document.createElement('label')
        this.linkLabel = document.createElement('label')
        this.btnContainer = document.createElement('div')
        this.btnCancel = document.createElement('button')
        this.btnSave = document.createElement('button')
        this.errormsg = document.createElement('label')
        this.footerdivPopup = document.createElement('div');

        // bind handlers to this instance
        this.handleClick = this.handleClick.bind(this);
        this.handleMousedown = this.handleMousedown.bind(this);
        this.handleMouseup = this.handleMouseup.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.checkImage = this.checkImage.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.handleParentClick = this.handleParentClick.bind(this)
        // track resize handles
        this.boxes = [];
        // disable native image resizing on firefox
        document.execCommand('enableObjectResizing', false, 'false');
        // respond to clicks inside the editor
        this.quill.root.addEventListener('click', this.handleClick, false);
        this.buttonLink.addEventListener('click', this.handleButtonClick, false)
        document!.documentElement!.parentNode!.addEventListener('click', this.handleParentClick, false)
    }

    handleClick(evt: MouseEvent) {
        const target = evt.target as HTMLElement;

        if (target.tagName && target.tagName.toUpperCase() == 'IMG') {
            if (this.img === evt.target) {
                this.show(evt.target as HTMLImageElement);
                // we are already focused on this image
                return;
            }
            if (this.img) {
                // we were just focused on another image
                this.hide();
            }
            // clicked on an image inside the editor
            this.show(evt.target as HTMLImageElement);
        }
        else if (this.img) {
            // clicked on a non image
            this.hide();
        }
    }

    // Hide the resizer on clicking outside of the editor
    handleParentClick(evt: Event) {
        const target = evt.target as HTMLElement
        if (target.className && target.className != 'resizeBox' && target.tagName && target.tagName.toUpperCase() != 'IMG') if (this.img) this.hide()
    }

    show(img: HTMLImageElement) {
        // keep track of this img element
        this.img = img;
        this.showResizers();
        this.showSizeDisplay();
        // position the resize handles at the corners
        const rect = this.img!.getBoundingClientRect();
        this.positionBoxes(rect);
        this.positionSizeDisplay(rect);
    }

    hide() {
        if (!this.isVisibleImageOptions) {
            this.hideResizers();
            this.hideSizeDisplay();
            this.img = undefined;
        }
        else {
            this.hideResizers();
            this.hideSizeDisplay();
        }
    }

    showResizers() {
        // prevent spurious text selection
        this.setUserSelect('none');
        // add 4 resize handles
        this.addBox('nwse-resize'); // top left
        this.addBox('nesw-resize'); // top right
        this.addBox('nwse-resize'); // bottom right
        this.addBox('nesw-resize'); // bottom left
        this.addButton();//edit button for add link
        // listen for the image being deleted or moved
        document.addEventListener('keyup', this.checkImage, true);
        this.quill.root.addEventListener('input', this.checkImage, true);
    }

    hideResizers() {
        // stop listening for image deletion or movement
        document.removeEventListener('keyup', this.checkImage);
        this.quill.root.removeEventListener('input', this.checkImage);
        // reset user-select
        this.setUserSelect('');
        this.setCursor('');
        // remove boxes
        this.boxes.forEach((box: HTMLDivElement) => document.body.removeChild(box));
        // release memory
        this.dragBox = undefined;
        this.dragStartX = undefined;
        this.preDragWidth = undefined;
        this.boxes = [];
    }

    addBox(cursor: string) {
        // create div element for resize handle
        const box = document.createElement('div');
        box.setAttribute('class', 'resizeBox')
        // apply styles
        const styles = {
            position: 'absolute',
            height: '12px',
            width: '12px',
            backgroundColor: 'white',
            border: '1px solid #777',
            boxSizing: 'border-box',
            opacity: '0.80',
            cursor: cursor,
        };
        this.extend(box.style, styles, this.options.handleStyles || {});
        // listen for mousedown on each box
        box.addEventListener('mousedown', this.handleMousedown, false);
        // add drag handle to document
        document.body.appendChild(box);
        // keep track of drag handle
        this.boxes.push(box);
    }

    addButton() {

        this.buttonLink.innerHTML = 'Edit';
        this.buttonLink.setAttribute('class', 'buttonlink')
        // apply styles
        const styles = {
        };

        this.extend(this.buttonLink.style, styles, this.options.handleStyles || {});

        // add drag handle to document
        document.body.appendChild(this.buttonLink);
    }

    extend(destination: any, ...sources: any) {
        sources.forEach((source: any) => {
            for (let prop in source) {
                if (source.hasOwnProperty(prop)) {
                    destination[prop] = source[prop];
                }
            }
        });
        return destination;
    }

    positionBoxes(rect: ClientRect | DOMRect) {
        // set the top and left for each drag handle
        [
            { left: rect.left - 6, top: rect.top - 6 },               // top left
            { left: rect.left + rect.width - 6, top: rect.top - 6 },               // top right
            { left: rect.left + rect.width - 6, top: rect.top + rect.height - 6 }, // bottom right
            { left: rect.left - 6, top: rect.top + rect.height - 6 }, // bottom left
        ].forEach((pos, idx) => {
            this.extend(this.boxes[idx].style, {
                top: Math.round(pos.top + window.pageYOffset) + 'px',
                left: Math.round(pos.left + window.pageXOffset) + 'px',
            });
        });
    }

    handleMousedown(evt: MouseEvent) {
        // note which box
        const target = evt.target as HTMLDivElement
        this.dragBox = target;
        // note starting mousedown position
        this.dragStartX = evt.clientX;
        // store the width before the drag
        this.preDragWidth = this.img!.width || this.img!.naturalWidth;
        // set the proper cursor everywhere
        this.setCursor(this.dragBox!.style.cursor);
        // listen for movement and mouseup
        document.addEventListener('mousemove', this.handleDrag, false);
        document.addEventListener('mouseup', this.handleMouseup, false);
    }

    handleMouseup() {
        // reset cursor everywhere
        this.setCursor('');
        // stop listening for movement and mouseup
        document.removeEventListener('mousemove', this.handleDrag);
        document.removeEventListener('mouseup', this.handleMouseup);
    }

    handleDrag(evt: MouseEvent) {
        if (!this.img) {
            // image not set yet
            return;
        }
        // update image size
        if (this.dragBox == this.boxes[0] || this.dragBox == this.boxes[3]) {
            // left-side resize handler; draging right shrinks image
            this.img.width = Math.round(this.preDragWidth! - evt.clientX - this.dragStartX!);
        }
        else {
            // right-side resize handler; draging right enlarges image
            this.img.width = Math.round(this.preDragWidth! + evt.clientX - this.dragStartX!);
        }
        // reposition the drag handles around the image
        const rect = this.img.getBoundingClientRect();
        this.positionBoxes(rect);
        this.positionSizeDisplay(rect);
    }

    setUserSelect(value: string) {
        [
            'userSelect',
            'mozUserSelect',
            'webkitUserSelect',
            'msUserSelect'
        ].forEach((prop: any) => {
            // set on contenteditable element and <html>
            this.quill.root.style[prop] = value;
            document.documentElement.style[prop] = value;
        });
    }

    setCursor(value: any) {
        [
            document.body,
            this.img,
            this.quill.root
        ].forEach(el => el!.style.cursor = value);
    }

    checkImage() {
        if (this.img) {
            this.hide();
        }
    }

    showSizeDisplay() {
        if (!this.options.displaySize) {
            return;
        }
        if (!this.display) {
            this.display = document.createElement('div');
        }
        // apply styles
        const styles = {
            position: 'absolute',
            font: '12px/1.0 Arial, Helvetica, sans-serif',
            padding: '4px 8px',
            textAlign: 'center',
            backgroundColor: 'white',
            color: '#333',
            border: '1px solid #777',
            boxSizing: 'border-box',
            opacity: '0.80',
            cursor: 'default',
        };

        this.extend(this.display.style, styles, this.options.displayStyles || {});
        document.body.appendChild(this.display);

    }

    hideSizeDisplay() {
        if (this.display) {
            document.body.removeChild(this.display);
            this.display = undefined;
        }

        this.buttonLink.setAttribute('class', 'display-none');

    }

    positionSizeDisplay(rect: ClientRect | DOMRect) {
        if (!this.display || !this.img) {
            return;
        }
        const size = this.getCurrentSize();
        this.display.innerHTML = size.join(' &times; ');
        if (size[0] > 120 && size[1] > 30) {
            // position on top of image
            const dispRect = this.display.getBoundingClientRect();
            this.extend(this.display.style, {
                left: Math.round(rect.left + rect.width + window.pageXOffset - dispRect.width - 8) + 'px',
                top: Math.round(rect.top + rect.height + window.pageYOffset - dispRect.height - 8) + 'px',
            });
            this.extend(this.buttonLink.style, {
                left: Math.round(rect.left + (rect.width / 2) + window.pageXOffset - (dispRect.width / 2)) + 'px',
                top: Math.round(rect.top + (rect.height / 2) + window.pageYOffset - (dispRect.height / 2)) + 'px',
            });
        }
        else {
            // position off bottom right
            this.extend(this.display.style, {
                left: Math.round(rect.left + rect.width + window.pageXOffset + 8) + 'px',
                top: Math.round(rect.top + rect.height + window.pageYOffset + 8) + 'px',
            });
            this.extend(this.buttonLink.style, {
                left: Math.round(rect.left + (rect.width / 2) + window.pageXOffset - 8) + 'px',
                top: Math.round(rect.top + (rect.height / 2) + window.pageYOffset - 8) + 'px',
            });
        }
    }

    handleButtonClick(evt: MouseEvent) {
        const target = evt.target as HTMLElement;
        if (target && target.tagName && target.tagName.toUpperCase() == 'BUTTON') {
            this.isVisibleImageOptions = true
            this.divPopup.setAttribute('class', 'modal');
            document.body.appendChild(this.divPopup);

            this.innerdivPopup.setAttribute('class', 'modal-content');
            this.divPopup.appendChild(this.innerdivPopup);

            this.titleLabel.setAttribute('for', 'inputName')
            this.titleLabel.innerText = 'Title'
            this.innerdivPopup.appendChild(this.titleLabel)

            this.titleInput.setAttribute('type', 'text')
            this.titleInput.setAttribute('class', 'form-control')
            this.innerdivPopup.appendChild(this.titleInput)

            this.captionLabel.setAttribute('for', 'inputName')
            this.captionLabel.innerText = 'Caption'
            this.innerdivPopup.appendChild(this.captionLabel)

            this.captionInput.setAttribute('type', 'text')
            this.captionInput.setAttribute('class', 'form-control')
            this.innerdivPopup.appendChild(this.captionInput)

            this.linkLabel.setAttribute('for', 'inputName')
            this.linkLabel.innerText = 'Link'
            this.innerdivPopup.appendChild(this.linkLabel)

            this.linkInput.setAttribute('type', 'text')
            this.linkInput.setAttribute('class', 'form-control')
            this.innerdivPopup.appendChild(this.linkInput)

            this.errormsg.setAttribute('for', 'inputName')
            this.errormsg.setAttribute('class', 'display-none');
            this.errormsg.innerText = 'Enter valid url'
            this.innerdivPopup.appendChild(this.errormsg)

            this.btnCancel.setAttribute('type', 'button')
            this.btnCancel.setAttribute('class', 'btn btn-light m-1')
            this.btnCancel.innerText = 'Cancel'
            this.btnContainer.appendChild(this.btnCancel)

            this.btnSave.setAttribute('type', 'button')
            this.btnSave.setAttribute('class', 'btn btn-primary')
            this.btnSave.innerText = 'Save'
            this.btnContainer.appendChild(this.btnSave)

            this.btnContainer.setAttribute('class', 'editor-button-container')
            

            this.saveBtnHandler = this.saveBtnHandler.bind(this)
            this.btnSave.addEventListener("click", this.saveBtnHandler);

            this.btnCancel.setAttribute('type', 'button')
            this.btnCancel.setAttribute('class', 'btn btn-default')
            this.btnCancel.innerText = 'Cancel'
            this.innerdivPopup.appendChild(this.btnContainer)
            this.cancelBtnHandler = this.cancelBtnHandler.bind(this)
            this.btnCancel.addEventListener("click", this.cancelBtnHandler);
        }
    }

    cancelBtnHandler(event: MouseEvent) {
        event.preventDefault();
        this.divPopup.setAttribute('class', 'display-none');
        this.linkInput.value = "";
        this.captionInput.value = "";
        this.titleInput.value = "";
    }

    saveBtnHandler(event: MouseEvent) {
        event.preventDefault();
        if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(this.linkInput.value)) {
            this.errormsg.setAttribute('class', 'display-none')
            const newChild = document.createElement('p')
            newChild.innerHTML = '<a class="whitespace-rmv" target="_blank" href="' + this.linkInput.value + '"> <img src="' + this.img!.getAttribute('src') + '" alt="'+this.titleInput.value+'" title="'+this.titleInput.value+'" width="'+ this.img!.getAttribute('width') +'"><br/></a><figcaption>'+this.captionInput.value+'</figcaption>'
            this.img!.replaceWith(newChild)
            this.isVisibleImageOptions = false
            this.linkInput.value = "";
            this.captionInput.value = "";
            this.titleInput.value = "";
            this.divPopup.setAttribute('class', 'display-none');
        }
        else {
            this.errormsg.setAttribute('class', 'text-danger display-block')
        }
    }

    getCurrentSize() {
        return [
            this.img!.width,
            Math.round(this.img!.width / this.img!.naturalWidth * this.img!.naturalHeight),
        ];
    }

}
