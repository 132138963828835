import * as React from 'react';

import { FontAwesomeIcon, Fa } from '../Icons';

export interface ResultItemProps {
    active: boolean;
    inactivePlaceholder?: string;
    value: Array<any>;
    label?: string;
    description?: string;
    onClick: (itemValue: Array<any>) => void;
}

export const ResultItem: React.FunctionComponent<ResultItemProps> = ({ active, inactivePlaceholder, value, label, description, onClick }) => {
     return (
        <li className="list-group-item list-results-item">
            <div className="list-results-info">
                <p className="list-results-title">{label}</p>
                <span className="list-results-description">{description}</span>
            </div>
            <div className="list-results-action">
                {(active || !inactivePlaceholder) ?
                    <button
                        className="btn btn-primary"
                        onClick={() => onClick(value)}>
                        <FontAwesomeIcon icon={Fa.faPlus} />
                    </button>
                    : <span>{inactivePlaceholder}</span>
                }
            </div>
        </li>
    );
}