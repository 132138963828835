import * as React from 'react';
import { Task, Question, QuestionOption } from '../../../../../domain/models';
import { QuestionPanel } from '../../../../../components/Survey/QuestionPanel';
import _ from 'lodash';
import { QuizSingleLineText } from '../../../../../components/Quiz/QuizSingleLineText';
import { SurveyDropDown } from '../../../../../components/Survey/SurveyDropDown';
import { QuizRadioBox } from '../../../../../components/Quiz/QuizRadioBox';
import { QuizCheckBox } from '../../../../../components/Quiz/QuizCheckBox';
import { QuizMultipleChoice } from '../../../../../components/Quiz/QuizMultipleChoiceGrid';
import { QuizCheckBoxGrid } from '../../../../../components/Quiz/QuizCheckBoxGrid';
import { SurveyCheckBox } from '../../../../../components/Survey/SurveyCheckBox';
import { SurveyRadioBox } from '../../../../../components/Survey/SurveyRadioBox';
import { SurveyLinearForm } from '../../../../../components/Survey/SurveyLinearForm';
import { SurveyMultipleChoice } from '../../../../../components/Survey/SurveyMultipleChoiceGrid';
import { SurveyCheckBoxGrid } from '../../../../../components/Survey/SurveyCheckBoxGrid';
import { useTranslation } from 'react-i18next';
import { DateTimeI } from '../../../../../components/Forms/DateTimeI';

export interface ContentQuestionPanelProps {
    content: Task;
    isQuestionHeaderDisplayed: boolean;
    type: string;
    questionType: string;
    surveyQuestion?: string;
    invalidQuestionBodyMessage?: string;
    setQuestionType: React.Dispatch<React.SetStateAction<string>>;
    handleNewQuestionClick: () => void;
    showQuestionHeader: React.Dispatch<React.SetStateAction<boolean>>;
    setSurveyQuestion: React.Dispatch<any>;
    setSurveySubQuestions: React.Dispatch<any>;
    setMinValue: React.Dispatch<React.SetStateAction<number>>;
    setMaxValue: React.Dispatch<React.SetStateAction<number>>;
    setMinValueLabel: React.Dispatch<any>;
    setMaxValueLabel: React.Dispatch<any>;
    onChangeSurveyOption: (options: QuestionOption[]) => void;
    onChangeOptions: (options: QuestionOption[]) => void;
    saveForms: (questions: Question, formId: number) => void;
    cancelSurveyFormHandler: () => void;
    saveSurveyFormHandler: () => void;
}

export const ContentQuestionPanel: React.FunctionComponent<ContentQuestionPanelProps> = ({
    content,
    isQuestionHeaderDisplayed,
    type,
    questionType,
    surveyQuestion,
    handleNewQuestionClick,
    setQuestionType,
    invalidQuestionBodyMessage,
    showQuestionHeader,
    setSurveyQuestion,
    setSurveySubQuestions,
    setMinValue,
    setMaxValue,
    setMinValueLabel,
    setMaxValueLabel,
    onChangeSurveyOption,
    onChangeOptions,
    saveForms,
    cancelSurveyFormHandler,
    saveSurveyFormHandler }) => {

    const { t } = useTranslation();

    const [questionConfig, setquestion] = React.useState(surveyQuestion! || '')
    const [filteredRowOptions, setFilteredRowOptions] = React.useState()
    const [filteredColumnOptions, setFilteredColumnOptions] = React.useState()
    const [answerOptions, setAnswerOptions] = React.useState()
    const [isSaveDisabled, setIsSaveDisabled] = React.useState(false)

    React.useEffect(() => {
      setIsSaveDisabled(false)
      setAnswerOptions(undefined)
      setFilteredColumnOptions(undefined)
      setFilteredRowOptions(undefined)
      setquestion(surveyQuestion! || '')
    }, [questionType])

    const onCloseHandler = () => {
        showQuestionHeader(false)
        setquestion('')
        setSurveyQuestion('')
    }
    
    const isAnswersSelected = (options: QuestionOption[]) => {
      if(options) {      
        if(content.type.toLowerCase() === 'quiz' && questionType === '10') {
          let isAnswerSelected = true
            filteredRowOptions.forEach((rowOption : any) => {
              if(options.findIndex((option) => {return option.body === rowOption.body}) === -1)
              isAnswerSelected = false
            })
            if(isAnswerSelected) {
              return true
            } else {
              return false
            }
        } else if(content.type.toLowerCase() === 'quiz' && questionType === '9') {
              if(filteredRowOptions.length === options.length) {
                return true
              } else {
                return false
              }
        } else {
          if(options.length > 0) {
            return true
          } else {
            return false
          }
        }
    } else {
      return false
    }
    }

    const handleOnSetAnswer = (options: QuestionOption[]) => {
      if (isAnswersSelected(options)) {
        setIsSaveDisabled(false)
      } 
      setAnswerOptions(options)
      onChangeOptions(options)
    }

    const cancelTaskHandler = () => {
        showQuestionHeader(false)
        setquestion('')
        cancelSurveyFormHandler()
    }
    const handleRowOptionChange = (rowOptions: QuestionOption[]) => {
        let filteredRowOptions = rowOptions.filter((rowOption) => {
           return rowOption.body !== ''
        })
        setFilteredRowOptions([...filteredRowOptions])
        setSurveySubQuestions(filteredRowOptions)
      }

    const handleColumnOptionChange = (columnOptions: QuestionOption[]) => {
      setIsSaveDisabled(false)
      let filteredColumnOptions = columnOptions.filter((columnOption) => {
          return columnOption.body !== ''
      })
      setFilteredColumnOptions([...filteredColumnOptions])
      onChangeSurveyOption(filteredColumnOptions)
    }
    

    const onChangeHandler = (value: string) => {
      setquestion(value)
      setSurveyQuestion(value)
    }  
    const isRowAndColumnAdded = () => {
      if(questionType === '1' || questionType ==='2' || questionType === '0' || questionType === '9' || questionType === '10') {
        if(filteredColumnOptions)
        return true
      else
        return false
      } else {
        return true
      }
    }
    const saveClickHandler = () => {
      if(content.type.toLowerCase() === 'quiz') {
        if(isRowAndColumnAdded()) {
          answerOptions ? saveSurveyFormHandler() : setIsSaveDisabled(true)
        } else {
          setIsSaveDisabled(true)
        }
        
      } else if(content.type.toLowerCase() === 'survey' || content.type.toLowerCase() === 'editableform'){
        isRowAndColumnAdded() ? saveSurveyFormHandler() : setIsSaveDisabled(true)   
      } else {
        saveSurveyFormHandler()
      }
    }
    

    return (
        <div>
            <div className="builder-new-task-questions">
                {((content.type.toLowerCase() === 'survey') ||
                    (content.type.toLowerCase() === 'quiz') ||
                    (content.type.toLowerCase() === 'editableform')) &&
                    !isQuestionHeaderDisplayed &&
                    <div className="lessons-builder-task-content-add">
                        <button className="btn btn-outline-primary" onClick={() => handleNewQuestionClick()}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            {t('ContentPanel.new_Question')}
                        </button>
                    </div>

                }
                <div className="lessons-builder-task-questions-new">
                    {isQuestionHeaderDisplayed &&
                        <div className="lessons-builder-task-questions-new-question">
                            <QuestionPanel
                                errorMessage={invalidQuestionBodyMessage!}
                                taskType={type}
                                formId={content.id}
                                question={questionConfig}
                                onSave={(formId, newForm) => saveForms(newForm, content.id)}
                                onClose={onCloseHandler}
                                onSelectType={setQuestionType}
                                onChangeValue={onChangeHandler}
                            >
                            </QuestionPanel>
                        </div>
                    }
                    {/* SingleLineText */}
                    {isQuestionHeaderDisplayed &&
                        content.type.toLowerCase() === 'quiz' &&
                        (questionType === '3' || _.isUndefined(questionType)) &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <QuizSingleLineText onChangeOption={handleColumnOptionChange} onSetAnswer={handleOnSetAnswer} />
                        </div>
                    }
                    {/* DropDOwn */}
                    {isQuestionHeaderDisplayed &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform') &&
                        questionType === '1' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <SurveyDropDown onChangeOption={handleColumnOptionChange} />
                        </div>
                    }
                    {/* quiz DropDOwn */}
                    {isQuestionHeaderDisplayed &&
                        (content.type.toLowerCase() === 'quiz') &&
                        questionType === '1' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <QuizRadioBox
                                onSetAnswer={handleOnSetAnswer}
                                onChangeOption={handleColumnOptionChange} />
                        </div>
                    }
                    {/* CheckBox */}
                    {isQuestionHeaderDisplayed &&
                        content.type.toLowerCase() === 'quiz' &&
                        questionType === '2' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <QuizCheckBox
                                onSetAnswer={handleOnSetAnswer}
                                onChangeOption={handleColumnOptionChange} />
                        </div>
                    }
                    {/* RadioBox */}
                    {isQuestionHeaderDisplayed &&
                        content.type.toLowerCase() === 'quiz' &&
                        questionType === '0' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <QuizRadioBox
                                onSetAnswer={handleOnSetAnswer}
                                onChangeOption={handleColumnOptionChange} />
                        </div>
                    }
                    {/* RadioBoxGrid */}
                    {isQuestionHeaderDisplayed &&
                        content.type.toLowerCase() === 'quiz' &&
                        questionType === '9' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <QuizMultipleChoice
                                 onChangeRowOption={handleRowOptionChange}
                                 onChangeColumnOption={handleColumnOptionChange}
                                onSetAnswer={handleOnSetAnswer}
                            />
                        </div>
                    }
                    {/* CheckBoxGrid */}
                    {isQuestionHeaderDisplayed &&
                        content.type.toLowerCase() === 'quiz' &&
                        questionType === '10' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <QuizCheckBoxGrid
                                 onChangeRowOption={handleRowOptionChange}
                                 onChangeColumnOption={handleColumnOptionChange}
                                onSetAnswer={handleOnSetAnswer}
                            />
                        </div>

                    }
                    {/* RadioBox */}
                    {isQuestionHeaderDisplayed &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform') &&
                        questionType === '0' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <SurveyRadioBox onChangeOption={handleColumnOptionChange} />
                        </div>
                    }
                    {/* CheckBox */}
                    {isQuestionHeaderDisplayed &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform') &&
                        questionType === '2' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <SurveyCheckBox onChangeOption={handleColumnOptionChange} />
                        </div>
                    }
                    {/* LinearScale */}
                    {isQuestionHeaderDisplayed &&
                        questionType === '8' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <SurveyLinearForm
                                onChangeMinValue={setMinValue}
                                onChangeMaxValue={setMaxValue}
                                onChangeMinLabel={setMinValueLabel}
                                onChangeMaxLabel={setMaxValueLabel}
                            />
                        </div>
                    }
                    {/* RadioBoxGrid */}
                    {isQuestionHeaderDisplayed &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform') &&
                        questionType === '9' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <SurveyMultipleChoice
                                onChangeRowOption={handleRowOptionChange}
                                onChangeColumnOption={handleColumnOptionChange}
                            />
                        </div>
                    }
                    {/* CheckBoxGrid */}
                    {isQuestionHeaderDisplayed &&
                        (content.type.toLowerCase() === 'survey' ||
                            content.type.toLowerCase() === 'editableform') &&
                        questionType === '10' &&
                        <div className="lessons-builder-task-questions-new-answers">
                            <SurveyCheckBoxGrid
                                onChangeRowOption={handleRowOptionChange}
                                onChangeColumnOption={handleColumnOptionChange}
                            />
                        </div>
                    }
                    {isQuestionHeaderDisplayed &&

                        <div className="lessons-builder-task-questions-new-btns">
                            {isSaveDisabled && filteredColumnOptions  && !isAnswersSelected(answerOptions) &&
                                <div className="invalid-feedback d-flex">{questionType === '3' ? "Please add answer." : "Please select the answers."}</div>
                            } 
                            {isSaveDisabled && !filteredColumnOptions&&
                                <div className="invalid-feedback d-flex">{ "Please add the options."}</div>
                            }
                            <button
                                className="btn btn-light m-1"
                                type="submit"
                                onClick={cancelTaskHandler}
                            >
                                {t('miscelaneous.cancel')}
                            </button>
                            <button
                                className="btn btn-primary m-1"
                                type="submit"
                                onClick={saveClickHandler}
                                disabled={isSaveDisabled}
                            >
                                {t('miscelaneous.save')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}