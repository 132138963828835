import _ from 'lodash';
import { Auth } from '../../../domain/_shared';
import * as services from '../../../domain/services';

export const deleteLesson = async (auth: Auth, lessonId: number) => {
    try {
        return services.deleteLesson(auth,lessonId);
    }
    catch (e) {
        return;
    }
}

export const deleteContent = async (auth: Auth, contentId: number) => {
    try {
        return services.deleteContent(auth,contentId);
    }
    catch (e) {
        return;
    }
}

export const deleteContentForms = async (auth: Auth, contentId: number) => {
    try {
        return services.deleteContentForms(auth,contentId);
    }
    catch (e) {
        return;
    }
}

export const deleteMeeting = async (auth: Auth, meetingId: number) => {
    try {
        return services.deleteMeeting(auth,meetingId);
    }
    catch (e) {
        return;
    }
}