import { Quill } from 'react-quill';

let BlockEmbed = Quill.import('blots/block/embed');
export class VideoBlot extends BlockEmbed {
  static create(url: string) {
    let node = super.create();
    node.setAttribute('src', url);
    // Set non-format related attributes with static values
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);
    node.setAttribute('width', '100%')
    node.setAttribute('class', 'quill_responsive_video')

    return node;
  }

  static formats(node: any) {
    // We still need to report unregistered embed formats
    let format = {} as any;
    if (node.hasAttribute('height')) {
      format.height = node.getAttribute('height');
    }
    if (node.hasAttribute('width')) {
      format.width = '500px'
    }
    return format;
  }

  static value(node: any) {
    return node.getAttribute('src');
  }

  format(name: any, value: any) {
    // Handle unregistered embed formats
    if (name === 'height' || name === 'width') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name, value);
      }
    } else {
      super.format(name, value);
    }
  }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';

