import * as React from "react";
import * as Router from "../../../../api/Router";
import { useTranslation } from 'react-i18next';
import { Auth, isRequestError } from "../../../../domain/_shared";
import { P404 } from "../../../../components/P404";
import { ContentPage } from "./ContentPage";
import { MeetingPage } from "./MeetingPage";
import { EditableFormPage } from "./EditableFormPage";
import { QuizPage } from "./QuizPage";
import { SurveyPage } from "./SurveyPage";
import { TaskRightSidePanel } from "./TaskRightSidePanel";
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../../api/react-helper';
import * as services from '../../../../domain/services';
import { useReducer } from 'react';
import { LoadingPage } from "../../../../components/LoadingPage";
import { Footer } from "../../../../components/Footer";
import { Pagination } from "./Pagination";
import { LessonHeaderPanel } from "./LessonHeaderPanel";
import { TaskPagination } from "./TaskPagination";
import { ParticipantCourse, Lesson } from "../../../../domain/models";
import Avatar from 'react-avatar';
import { UserContext } from '../../../../domain/contexts/UserContext';
const brand = require("../../../../assets/img/logo.png");

export interface TaskPageRouteParameters {
    groupId: number;
    courseId: number;
    traineeId: number;
    lessonId: number;
    taskId: number;
    taskType: string;
}

type State = {
    apiError?: any | undefined;
    course?: ParticipantCourse;
    lessons?: Lesson[];
    lesson?: Lesson;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: ParticipantCourse): State;
    LoadLessonsList(lessons: Lesson[]): State;
    LoadLessonbyId(lesson: Lesson): State;
}


const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadLessonsList: lessons => ({ ...state, lessons }),
    LoadLessonbyId: lesson => ({ ...state, lesson }),
})

export interface TaskIndexPageProps extends TaskPageProps {
    taskType: string;
}

export interface TaskPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export const TaskPage: React.FunctionComponent<TaskIndexPageProps> = ({
    taskType,
    ...taskProps
}) => {
    const [{ apiError, course, lessons, lesson }, actions] = wrapperReducer(useReducer(reducer, {}));
    const { t } = useTranslation();
    useLoadLessons(taskProps.auth, taskProps.courseId, actions);
    useLoadCourse(taskProps.auth, taskProps.courseId, actions);
    useLoadLessonbyId(taskProps.auth, taskProps.lessonId, actions)

    const nextTaskClick = (nextlessonid: number, nexttaskId: number, nexttaskType: string) => {
        Router.push(`/groups/${taskProps.groupId}/admin/courses/${taskProps.courseId}/preview/trainee/lesson/${nextlessonid}/task/${nexttaskId}/${nexttaskType.toLowerCase()}`)
    }

    const prevTaskClick = (prevlessonid: number, prevtaskId: number, prevtaskType: string) => {
        Router.push(`/groups/${taskProps.groupId}/admin/courses/${taskProps.courseId}/preview/trainee/lesson/${prevlessonid}/task/${prevtaskId}/${prevtaskType.toLowerCase()}`)
    }

    const nextLessonClick = (nextlessonid: number, nexttaskId: number, nexttaskType: string) => {
        Router.push(`/groups/${taskProps.groupId}/admin/courses/${taskProps.courseId}/preview/trainee/lesson/${nextlessonid}/task/${nexttaskId}/${nexttaskType.toLowerCase()}`)
    }

    const prevLessonClick = (prevlessonid: number, prevtaskId: number, prevtaskType: string) => {
        Router.push(`/groups/${taskProps.groupId}/admin/courses/${taskProps.courseId}/preview/trainee/lesson/${prevlessonid}/task/${prevtaskId}/${prevtaskType.toLowerCase()}`)
    }

    const [showSidebar, setShowSidebar] = React.useState(true);

    const [isOpen, toggleProfile] = React.useState(false);
    const { state: { presets } } = React.useContext(UserContext);

    if (!course || !lessons || !lesson) return <LoadingPage />

    return (
        <>
            <div className={`task-execution ${showSidebar ? "showSidebar" : ""}`}>
                <header className="app-header navbar justify-content-start align-content-center task-execution-header">
                    <button className="navbar-back" type="button"
                        onClick={() => Router.push(`/groups/${taskProps.groupId}/admin/courses/${taskProps.courseId}/1`)}>

                        <span className="icon fa fa-arrow-left"></span>
                    </button>
                    
                    <a
                        className="navbar-brand"
                        href="/"
                        style={{ backgroundImage: `url(${brand})` }}
                        onClick={() => { }}
                    ></a>
                    <h1
                        className="text-white mb-0 navbar-title"
                        style={{ fontSize: "14px" }}
                    >
                        {course.name}
                    </h1>

                    <span className="hide" style={{ float: 'right' }}>
                        <a className="sub-profile  text-white" onClick={() => toggleProfile(!isOpen)} style={{ marginTop: ' -15px' }}>
                            <div className="d-flex align-items-center">
                                <span className="profile-name text-right mr-2 text-muted" style={{ fontSize: '.75rem' }}>
                                    <span className="lead">{t('Header.greeting')}, {presets ? presets.nickName : '(unknown)'}</span>
                                </span>
                                <Avatar name={presets ? presets.nickName : '(unknown)'} round={true} size="30px" />
                                <div className="ml-1">
                                    <span className="fa fa-angle-down"></span>
                                </div>
                            </div>
                        </a>
                    </span>
                    {isOpen &&
                        <div className="dpdown-menu">
                            <div className="dropdown-header">
                                <span style={{ cursor: 'pointer' }} onClick={() => Router.push('/profile')}>Profile</span>
                            </div>
                            <div className="divider"></div>
                            <a className="dropdown-item" href="javascript:void(0)" onClick={() => taskProps.onLogout()} >
                                <i className="icon-logout text-muted"></i> Logout
                            </a>
                        </div>
                    }
                    <button
                        className={`navbar-toggle-sidebar ${showSidebar ? "active" : ""}`}
                        type="button"
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        Lectures{" "}
                        <span
                            className={`icon fa ${showSidebar ? "fa-close" : "fa-list-ul"}`}
                        ></span>
                    </button>
                </header>
                <main className="app-body d-flex">
                    <div className="row marginTop" style={{ width: "100%" }}>
                        <div
                            className={`bg-white task-execution-body ${
                                showSidebar ? "col-md-8 col-lg-9" : "col-12"
                                }`}
                        >
                            <button
                                className="task-execution-toggle-sidebar"
                                onClick={() => setShowSidebar(!showSidebar)}
                            >
                                <i
                                    className={`${
                                        showSidebar
                                            ? "fa fa-angle-double-right"
                                            : "fa fa-angle-double-left"
                                        }`}
                                ></i>
                            </button>
                            <div className="task-wrapper">
                                <div className="px-4 pt-3 task-execution-content">
                                    <div className="task-execution-content-header">
                                        <LessonHeaderPanel lessons={lessons} lesson={lesson} />
                                        {!showSidebar && (
                                            <TaskPagination
                                                lessons={lessons}
                                                lesson={lesson}
                                                taskId={taskProps.taskId}
                                                onNavigateToNextTaskClick={nextTaskClick}
                                                onNavigateToPrevTaskClick={prevTaskClick}
                                            />
                                        )}
                                    </div>
                                    {getTaskByType(taskType.toLowerCase(), taskProps)}
                                </div>
                            </div>
                            <Pagination
                                lessons={lessons}
                                lesson={lesson}
                                onNavigateToNextLessonsClick={nextLessonClick}
                                onNavigateToPrevLessonsClick={prevLessonClick}
                            />
                        </div>
                        {showSidebar && lessons && <TaskRightSidePanel
                            lessons={lessons}
                            courseName={course.name}
                            courseIcon={course.imageUrl}
                            selectedTaskId={taskProps.taskId}
                            onTaskClick={(task, lessonId) => {
                                Router.push(`/groups/${taskProps.groupId}/admin/courses/${taskProps.courseId}/preview/trainee/lesson/${lessonId}/task/${task.id}/${task.type.toLowerCase()}`)
                            }}
                        />}
                    </div>
                </main>
                <Footer
                    onTermsClick={() => Router.push('/terms')}
                    onPrivacyClick={() => Router.push('/privacy')}
                ></Footer>
            </div>
        </>
    );
};

function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getParticipantCourse(auth, courseId);
            if (isRequestError(course)) {
                console.log(course);
            }
            else {
                actions.send(a => a.LoadCourse(course));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadLessons(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const lessons = await services.getParticipantCourseLessons(auth, courseId);
            if (isRequestError(lessons)) {
                console.log(lessons);
            }
            else {
                actions.send(a => a.LoadLessonsList(lessons));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

const getTaskByType: (t: string, p: TaskPageProps) => JSX.Element = (
    taskType: string,
    taskProps: TaskPageProps
) => {
    switch (taskType) {
        case "content":
            return <ContentPage {...taskProps}></ContentPage>;
        case "meeting":
            return <MeetingPage {...taskProps}></MeetingPage>;
        case "editableform":
            return <EditableFormPage {...taskProps}></EditableFormPage>;
        case "quiz":
            return <QuizPage {...taskProps}></QuizPage>;
        case "survey":
            return <SurveyPage {...taskProps}></SurveyPage>;
        default:
            return <P404 onGoBack={() => Router.push("/")} />;
    }
};

function useLoadLessonbyId(auth: Auth, lessonId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const lesson = await services.getLessonbyId(auth, lessonId);
            if (isRequestError(lesson)) {
                console.log(lesson);
            }
            else {
                actions.send(a => a.LoadLessonbyId(lesson!));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lessonId]);
}

