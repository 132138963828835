import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Coach } from '../../../../../domain/models';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';

export interface FilterDropdownProps {
    label: string;
    styleName?: string;
    icon?: string,
    hasSearch?: boolean,
    hasDeleteBtn?: boolean,
    coachList?: Coach[],
}

export const FilterDropdown: React.FunctionComponent<FilterDropdownProps> = ({
    label,
    styleName,
    icon,
    hasDeleteBtn,
}) => {
    const { state: { selectedTraineeFilters, coachStatusFilter }, actions } = useContext(ParticipantsMgmtContext);
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);
    const { t } = useTranslation();
    
    const closeDropdown = () => {
        if (openDropdownMenu) toggleDropdownMenu(false);
    }

    const handleStatusCheckBox = (value: React.MouseEvent<HTMLInputElement>) => {
        const checkbox = value.target as HTMLInputElement
        let updatedStatusList = !selectedTraineeFilters ? [] : [...selectedTraineeFilters]
        checkbox.checked ? updatedStatusList.push(checkbox.value) : updatedStatusList.splice(updatedStatusList.indexOf(checkbox.value), 1)
        actions.send(a => a.updateTraineeFilters(updatedStatusList))
    }

    const handleCoachStatusCheckBox = (value: React.MouseEvent<HTMLInputElement>) => {
        const checkbox = value.target as HTMLInputElement
        if (checkbox.value === 'pending') {
            checkbox.checked ? actions.send(a => a.updateCoachFilters(true)) : actions.send(a => a.updateCoachFilters(false))
        }
    }

    const isFiltersChecked = (filter: string) => {
        if (!selectedTraineeFilters) return false
        return (selectedTraineeFilters.includes(filter))
    }

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(closeDropdown, 500);
    }

    return (

        <div className="btn-group" onBlur={handleOnBlur}>
            <div className="dropdown">
                <button className={`btn dropdown-toggle ${styleName ? styleName : 'btn-light'} ${openDropdownMenu ? 'active' : ''}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
                    {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
                    <span className="btn-label">{label}</span>
                </button>
                <div className={classnames('dropdown-menu dropdown-menu-left', { 'show': openDropdownMenu })}>
                    <div>
                        <h6 className="dropdown-header">{t('CourseManagementParticipantsPage.CoachStatusLbl')}</h6>
                        <li className="dropdown-item small">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="option1" value="pending" checked={coachStatusFilter} onClick={handleCoachStatusCheckBox} onChange={() => { }} />
                                <label className="form-check-label text-danger" htmlFor="option1"><span className="small">{t('CourseManagementParticipantsPage.PendingLbl')}</span></label>
                                {hasDeleteBtn && <button style={{ position: 'absolute', right: '0' }}><i className="fa fa-trash" style={{ color: '#000', margin: '0' }}></i></button>}
                            </div>
                        </li>
                        <li className="dropdown-item small">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="option2" value="2" />
                                <label className="form-check-label" htmlFor="option2"><span className="small">{t('CourseManagementParticipantsPage.N/ALbl')}</span></label>
                                {hasDeleteBtn && <button style={{ position: 'absolute', right: '0' }}><i className="fa fa-trash" style={{ color: '#000', margin: '0' }}></i></button>}
                            </div>
                        </li>
                        <h6 className="dropdown-header">{t('CourseManagementParticipantsPage.TraineeStatusLbl')}</h6>
                        <li className="dropdown-item small">
                            <div className="form-check">

                                <input className="form-check-input" type="checkbox" id="option3" value="notStarted" onClick={handleStatusCheckBox} onChange={() => { }} checked={isFiltersChecked('notStarted')} />
                                <label className="form-check-label font-italic" htmlFor="option3"><span className="small">{t('CourseManagementParticipantsPage.NotStartedLbl')}</span></label>

                                {hasDeleteBtn && <button style={{ position: 'absolute', right: '0' }}><i className="fa fa-trash" style={{ color: '#000', margin: '0' }}></i></button>}
                            </div>
                        </li>
                        <li className="dropdown-item small">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="option4" value="inProgress" onClick={handleStatusCheckBox} onChange={() => { }} checked={isFiltersChecked('inProgress')} />
                                <label className="form-check-label text-info font-italic" htmlFor="option4"><span className="small">{t('CourseManagementParticipantsPage.InProgressLbl')}</span></label>
                                {hasDeleteBtn && <button style={{ position: 'absolute', right: '0' }}><i className="fa fa-trash" style={{ color: '#000', margin: '0' }}></i></button>}
                            </div>
                        </li>
                        <li className="dropdown-item small">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="option5" value="finished" onClick={handleStatusCheckBox} onChange={() => { }} checked={isFiltersChecked('finished')} />
                                <label className="form-check-label text-success font-italic" htmlFor="option5"><span className="small">{t('CourseManagementParticipantsPage.CompletedLbl')}</span></label>
                                {hasDeleteBtn && <button style={{ position: 'absolute', right: '0' }}><i className="fa fa-trash" style={{ color: '#000', margin: '0' }}></i></button>}
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>

    )
}
