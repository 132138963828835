export enum CourseStatus  {
  Test = "test",
  Draft = "draft",
  Published = "published"
}

export enum LessonStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Finished   = "Finished"
}