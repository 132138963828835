import * as React from 'react';
import { useContext, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as Router from '../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../api/react-helper';
import { Auth, isAuthError } from '../../domain/_shared';
import * as services from '../../domain/services';

import { GlobalContext } from '../../domain/contexts/GlobalContext';
import { AuthContext } from '../../domain/contexts/AuthContext';

import { P50x } from '../../components/P50x';
import { Login } from './Login';

export interface LoginPageRouteParameters {
}

export interface LoginPageProps {
}

type State = {
    apiError?: any | undefined;
    input?: InputType;
    lock?: boolean;
    errorMessage?: string;
    submitted?: boolean;
    inboundAuth?: Auth;
}

type Action = {
    Set50xError(apiError: any): State;
    SendLogin(user: string, password: string): State;
    UnlockLogin(): State;
    SetErrorMessage(error: string): State;
    ClearErrorMessage(): State;
    PostStatus200(inboundAuth: Auth): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    SendLogin: (user, password) => ({ ...state, input: { user, password }, lock: true }),
    UnlockLogin: () => ({ ...state, lock: false }),
    SetErrorMessage: error => ({ ...state, errorMessage: error }),
    ClearErrorMessage: () => ({ ...state, errorMessage: undefined }),
    PostStatus200: inboundAuth => ({ ...state, lock: false, submitted: true, inboundAuth }),
})

export const LoginPage: React.FunctionComponent<LoginPageProps> = ({ }) => {
    const { actions: globalActions } = useContext(GlobalContext);
    const { actions: authActions } = useContext(AuthContext);
    const { t } = useTranslation();
    const [{
        apiError,
        input,
        lock,
        errorMessage,
        submitted,
        inboundAuth
    }, actions] = wrapperReducer(useReducer(reducer, {
        input: {},
        lock: false,
        errorMessage: undefined,
        submitted: false
    }));

    const goToRoot = () => Router.push('/d/1');     // NOTE: workaround for error 500 in '/'
    const goToForgotPassword = () => Router.push('/forgotpassword');

    useHandleLogin(lock || false, submitted || false, input || {}, actions, t);

    useEffect(() => {
        if (submitted) {
            authActions.send(a => a.SetAuth(inboundAuth!));
            Router.replaceWithClearPath(Router.url());
        }
    }, [submitted]);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <Login
            errorMessage={errorMessage}
            disabled={lock || submitted}
            isSending={lock || submitted}
            onChangeLanguage={(lng) => globalActions.send(a => a.SetLanguage(lng))}
            onLoginClick={(u, p) => actions.send(a => a.SendLogin(u, p))}
            onForgotClick={goToForgotPassword}
        />
    );
}

const useHandleLogin = function (
    lock: boolean,
    submitted: boolean,
    input: InputType | undefined,
    actions: ActionDispatcher<Action, State>,
    t: i18next.TFunction
) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                actions.send(a => a.ClearErrorMessage());

                if (!input || !input.user || !input.password) {
                    actions.send(a => a.SetErrorMessage(t('LoginPage.errorNoEntry')));
                    actions.send(a => a.UnlockLogin());
                } else {
                    const auth = await services.signIn(input.user, input.password);
                    if (isAuthError(auth)) {
                        const err: string | undefined = auth.message || auth.error_description;
                        const end = err ? err.indexOf('.') : 0;
                        actions.send(a => a.SetErrorMessage(err ? t(`LoginPage.${err.substring(0, end === -1 ? undefined : end)}`) : t('LoginPage.errorGeneric')));
                        actions.send(a => a.UnlockLogin());
                    } else {
                        actions.send(a => a.PostStatus200(auth));
                    }
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}

interface InputType {
    user?: string;
    password?: string
}
