import * as React from 'react';
import classnames from 'classnames';
import { useState } from 'react';

export interface ButtonDropdownProps {
    label: string;
    styleName?: string;
    icon?:string;
    onClick: (selectedItem: string) => void;
}

export const ButtonDropdown: React.FunctionComponent<ButtonDropdownProps> = ({ label, styleName, icon, onClick }) => {
  const [openDropdownMenu, toggleDropdownMenu] = useState(false);

    return (
      <div className="btn-group">
        <div className="dropdown">
          <button className={`btn dropdown-toggle ${styleName ? styleName : 'btn-light'}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
            {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
            {label}
          </button>
          <div className={classnames('dropdown-menu dropdown-menu-right', { 'show': openDropdownMenu })}>
            <button className="dropdown-item" onClick={() => onClick('trainee')}>Trainee</button>
            <button className="dropdown-item" onClick={() => onClick('coach')}>Coach</button>
          </div>
        </div>
      </div>
    )
}