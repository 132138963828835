import * as React from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { SingleLineIcon } from '../../components/Icons';
import * as Sli from '../../components/Icons';

import { GlobalContext } from '../../domain/contexts/GlobalContext';

import { LanguageSelector } from '../../components/i18n/LanguageSelector';

const logo = require('../../assets/img/logo.png');

export interface LoginProps {
    errorMessage?: string;
    disabled?: boolean;
    isSending?: boolean;
    onChangeLanguage: (lng: string) => void;
    onLoginClick: (user: string, password: string) => void;
    onForgotClick?: () => void;
}

export const Login: React.FunctionComponent<LoginProps> = ({
    errorMessage,
    disabled,
    isSending,
    onChangeLanguage,
    onLoginClick,
    onForgotClick
}) => {
    const { state: { currentLanguage } } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [{ userText, passwordText }, setState] = useState({ userText: '', passwordText: '' });

    function handleFormLoginClick() {
        onLoginClick(userText, passwordText);
    }

    return (
        <div className="login-page bg-gradient">
            <header className="app-header text-right">
                <div className="container-fluid pt-3 mb-xs-3">
                    <LanguageSelector
                        styleName="bg-primary text-white border-primary"
                        contentPosition="dropdown-menu-right"
                        currentLanguage={currentLanguage}
                        disabled={!!disabled}
                        onFlagClick={onChangeLanguage}
                    />
                </div>
            </header>

            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center py-4">

                        <div className="col-12 col-md-6 col-lg-7 info-box d-md-flex align-items-center">
                            <div className="mb-3 mb-md-0">

                                <img alt="Galilee" className="brand-logo mb-md-3" width="200" src={logo} />

                                <blockquote className="blockquote text-white d-none d-md-block">
                                    <p className="mb-0 display-4">{t('LoginComponent.quote')}</p>
                                    <footer className="blockquote-footer mt-2">
                                        <cite className="text-white">{t('LoginComponent.CSLewis')}</cite>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-5">
                            <div className="card-group">
                                <div className="card p-2 p-md-3">
                                    <div className="card-body">
                                        <h1 className="h3">{t('LoginComponent.title')}</h1>
                                        <p className="">{t('LoginComponent.instruction')}</p>
                                        <LoginForm
                                            userText={userText}
                                            passwordText={passwordText}
                                            errorMessage={errorMessage}
                                            disabled={disabled}
                                            isSending={isSending}
                                            onUserTextChange={text => setState({ userText: text, passwordText })}
                                            onPasswordTextChange={text => setState({ userText, passwordText: text })}
                                            onLoginClick={handleFormLoginClick}
                                            onForgotClick={onForgotClick || (() => { })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

interface LoginFormProps {
    userText: string;
    passwordText: string;
    errorMessage?: string;
    disabled?: boolean;
    isSending?: boolean;
    onUserTextChange: (text: string) => void;
    onPasswordTextChange: (text: string) => void;
    onLoginClick: () => void;
    onForgotClick: () => void;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
    userText,
    passwordText,
    errorMessage,
    disabled,
    isSending,
    onUserTextChange,
    onPasswordTextChange,
    onLoginClick,
    onForgotClick,
}) => {
    const { t } = useTranslation();
    const userNameEl = useRef(null);

    useEffect(() => {
        if (userNameEl && userNameEl.current) {
            (userNameEl.current! as HTMLInputElement).focus();
        }
    }, [errorMessage]);

    function handleKeyPress(e: React.KeyboardEvent) {
        if (e.keyCode === 13) {
            e.preventDefault();    // don't trigger onChange
            e.stopPropagation();
            onLoginClick();
        }
    }

    return (
        <form>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <SingleLineIcon icon={Sli.user} />
                    </span>
                </div>
                <input
                    ref={userNameEl}
                    className={classnames('form-control', { 'is-invalid': errorMessage })}
                    placeholder={t('LoginComponent.userPlaceholder')}
                    type="text"
                    value={userText}
                    disabled={disabled}
                    onChange={e => onUserTextChange(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <SingleLineIcon icon={Sli.lock} />
                    </span>
                </div>
                <input
                    className={classnames('form-control', { 'is-invalid': errorMessage })}
                    placeholder={t('LoginComponent.passwordPlaceholder')}
                    type="password"
                    value={passwordText}
                    disabled={disabled}
                    onChange={e => onPasswordTextChange(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div className="row">
                <div className="col-12">
                    {isSending
                        ? (
                            <button
                                type="button"
                                className="btn btn-loader btn-primary"
                                disabled={true}
                                style={{ width: '100%' }}
                            >
                                <div className="sk-rotating-plane"></div>
                                <span className="label small">{t('LoginComponent.submitLabel')}</span>
                            </button>
                        )
                        : (
                            <button
                                type="button"
                                className="btn btn-block btn-primary text-center"
                                disabled={disabled}
                                onClick={() => onLoginClick()}
                            >
                                {t('LoginComponent.submitLabel')}
                            </button>
                        )
                    }
                </div>
                <div className="col-12 text-center text-sm-left mt-3 mt-md-2 mb-1">
                    <button
                        disabled={disabled}
                        type="button"
                        className="btn btn-link p-0 small text-muted"
                        onClick={() => onForgotClick()}
                    >
                        {t('LoginComponent.forgotPasswordBtn')}
                    </button>
                </div>
            </div>
        </form>
    );
};
