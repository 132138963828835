
import * as React from 'react';
import { Lesson } from '../../../../domain/models';

export interface TaskPaginationProps {
    lessons: Lesson[];
    lesson: Lesson;
    taskId: number;
    onNavigateToNextTaskClick: (nextlessonId: number, nexttaskId: number, nexttaskType: string) => void
    onNavigateToPrevTaskClick: (prevlessonId: number, prevtaskId: number, prevtaskType: string) => void
}
export const TaskPagination: React.FunctionComponent<TaskPaginationProps> = ({
    lessons,
    lesson,
    taskId,
    onNavigateToNextTaskClick,
    onNavigateToPrevTaskClick
}) => {


    let currentLesson = lessons.find((item) => item.id === lesson.id)!
    let currentLessonIndex = lessons.findIndex(item => item == currentLesson)
    let currentTask = currentLesson.tasks.find(item => item.id == taskId)
    let currentTaskIndex = currentLesson.tasks.findIndex(item => currentTask == item)

    let previousLesson = currentLessonIndex > 0 ? lessons[currentLessonIndex - 1] : undefined
    let prevousTask = currentTaskIndex > 0 ? currentLesson.tasks[currentTaskIndex - 1] : (previousLesson ?  previousLesson.tasks[previousLesson.tasks.length - 1] : undefined)
    previousLesson = currentTaskIndex > 0 ? currentLesson : previousLesson

    let nextLesson = currentLessonIndex < lessons.length - 1  ? lessons[currentLessonIndex + 1] : undefined
    let nextTask = currentTaskIndex < currentLesson.tasks.length - 1 ? currentLesson.tasks[currentTaskIndex + 1] : (nextLesson ?  nextLesson.tasks[0] : undefined)
    nextLesson = currentTaskIndex < currentLesson.tasks.length - 1 ? currentLesson : nextLesson


    return (
        <ul className="task-pagination">
            <li className="task-pagination-item">
                {prevousTask &&
                    <button
                        className="btn btn-light task-pagination-prev"
                        onClick={() => onNavigateToPrevTaskClick(previousLesson!.id, prevousTask!.id, prevousTask!.type)}
                    >
                        <i className="task-pagination-icon fa fa-angle-left"></i>
                        <span className="task-pagination-label"> Prev</span>
                    </button>
                }
            </li>
            <li className="task-pagination-item">
                {nextTask &&
                    <button
                        className="btn btn-light task-pagination-next"
                       
                        onClick={() => onNavigateToNextTaskClick(nextLesson!.id, nextTask!.id, nextTask!.type)}
                    >
                        <span className="task-pagination-label">Next </span>
                        <i className="task-pagination-icon fa fa-angle-right"></i>
                    </button>
                }
            </li>
        </ul>
    );
}