import * as React from 'react';
import * as services from '../../../../../domain/services';
import _ from 'lodash';
import { ActionDispatcher, ActionMatcher, useAsyncEffect, wrapperReducer } from '../../../../../api/react-helper';
import { AddButton } from '../../../../../components/Lessons/AddButton';
import { Auth, isRequestError } from '../../../../../domain/_shared';
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { ContentArea } from '../../../../../components/ContentArea';
import { ContentOptions } from '../../../../../components/Lessons/ContentOptions';
import { DeleteConfirmPopup } from '../../../../../components/Popup/DeleteConfirmPopup';
import DragList from '../../../../../domain/utils/DragList';
import { Form, Lesson, Question, QuestionOption, Task  } from '../../../../../domain/models';
import { LoadingPage } from '../../../../../components/LoadingPage';
import { NewContent } from '../../../../../components/Lessons/NewContent';
import { SurveyCheckBox } from '../../../../../components/Survey/SurveyCheckBox';
import { SurveyDropDown } from '../../../../../components/Survey/SurveyDropDown';
import { SurveyLinearForm } from '../../../../../components/Survey/SurveyLinearForm';
import { SurveyRadioBox } from '../../../../../components/Survey/SurveyRadioBox';
import { QuizMultipleChoice } from '../../../../../components/Quiz/QuizMultipleChoiceGrid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditQuestion } from './EditQuestion';
import { ContentQuestionPanel } from './ContentQuestionPanel';
import { QuestionOptionsPreview } from './QuestionOptionsPreview';
import { Assignee } from './Assignee';
import { QuizCheckBox } from '../../../../../components/Quiz/QuizCheckBox';
import { QuizCheckBoxGrid } from '../../../../../components/Quiz/QuizCheckBoxGrid';
import { QuizSingleLineField } from '../../../../../components/Quiz/QuizSingleLineField';
import { QuizRadioBox } from '../../../../../components/Quiz/QuizRadioBox';
import { SurveyMultipleChoice } from '../../../../../components/Survey/SurveyMultipleChoiceGrid';
import { SurveyCheckBoxGrid } from '../../../../../components/Survey/SurveyCheckBoxGrid';

export interface ContentPanelProps {
    lessonId: number;
    index: number;
    courseType: string;
    content: Task;
    type: string;
    groupId: number;
    courseId: number;
    isVisibleAddNewContent?: number;
    onClickDelete: (type: string) => void;
    onClickEditButon: (contentId: number, type: string) => void;
    onContentUpdate: (content: Form) => void;
    onClickAdd: () => void;
    onOptionsClick: (value: string) => void;
    onCreateContent: (content: Task, lessonId: number) => void;
    onSaveForms: (question: Question, options: QuestionOption[], subQuestions: QuestionOption[], formId: number, quizOptions: QuestionOption[], type: string, questionType: string) => void;
    onCancelForms: () => void;
    updateQuestion: (question: Question) => void;
    onCompleteEdit: (item: QuestionOption) => void;
    setContent: (content: Form) => void;
    errorHandler: (error: any) => void;
    setAddNewContent: React.Dispatch<React.SetStateAction<any>>
    
}

type State = {
    formApi: boolean | false;
    contentApi: boolean | false;
    meetingApi: boolean | false;
    lessons?: Lesson;
    forms?: Form;
    apiError?: any | undefined;
}

type Action = {
    getFormApi(formApi: boolean | false): State;
    getContentApi(contentApi: boolean | false): State;
    getMeetingApi(meetingApi: boolean | false): State;
    loadContentDetail(lessons: Lesson): State;
    loadFormContentDetails(forms: Form): State;
    loadMeetingDetails(forms: Form): State;
    Set50xError(apiError: any): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    getFormApi: formApi => ({ ...state, formApi }),
    getContentApi: contentApi => ({ ...state, contentApi }),
    getMeetingApi: meetingApi => ({ ...state, meetingApi }),
    loadContentDetail: lessons => ({ ...state, lessons }),
    loadFormContentDetails: forms => ({ ...state, forms }),
    loadMeetingDetails: forms => ({ ...state, forms }),
    Set50xError: apiError => ({ ...state, apiError })
})

export const ContentPanel: React.FunctionComponent<ContentPanelProps> = (props) => {
    const { t } = useTranslation();
    const [{
        forms,
        formApi,
        contentApi,
        meetingApi
    }, actions] = wrapperReducer(React.useReducer(
        reducer, {
        formApi: false,
        contentApi: false,
        meetingApi: false
    }));
    const { lessonId,
        index,
        courseType,
        content,
        type,
        groupId,
        courseId,
        onClickDelete,
        onClickEditButon,
        onContentUpdate,
        onClickAdd,
        onOptionsClick,
        onCreateContent,
        onSaveForms,
        onCancelForms,
        setContent,
        errorHandler,
        setAddNewContent,
        isVisibleAddNewContent
    } = props
    const [isdeletePopUpVisible, toggleDeletePopUp] = useState(false)
    const [isvisibleContentOptions, setContentOptions] = React.useState(false)
    const [isQuestionHeaderDisplayed, showQuestionHeader] = React.useState(false)
    const [contentOption, getContentOptions] = React.useState()
    const [questionType, setQuestionType] = React.useState('3')
    const [surveyQuestion, setSurveyQuestion] = React.useState()
    const [surveyOptions, setSurveyOptions] = React.useState()
    const [surveySubQuestions, setSurveySubQuestions] = React.useState()
    const [deleteQuestionId, setDeleteQuestionId] = useState()
    const [showQuestion, visibleQuestions] = useState(false);
    const [minValue, setMinValue] = useState(1)
    const [maxValue, setMaxValue] = useState(2)
    const [minValueLabel, setMinValueLabel] = useState()
    const [maxValueLabel, setMaxValueLabel] = useState()
    const [showOptions, visibleOptions] = useState();
    const [quizOptions, setQuizOptions] = React.useState()
    const [editQuestion, setEditQuestion] = React.useState()
    const [editMode, toggleEditMode] = React.useState(false)
    const { state: { auth } } = React.useContext(AuthContext);

    const [state, setState] = useState({
        clearText: '',
        invalidQuestionBodyMessage: undefined as string | undefined,
    });

    const { invalidQuestionBodyMessage } = state;

    let contentOptionsNode: any = React.useRef(null)

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClick, false)
        return () => {
            document.removeEventListener('mousedown', handleClick, false)
        }
    }, [])

    const handleClick = (event: MouseEvent) => {
        if (!_.isNull(contentOptionsNode.current) && event.target instanceof HTMLElement && contentOptionsNode.current.contains(event.target)) {
            // clicked inside the options
        }
        else {
            // cliked outside the options
            onCloseContentOptions()
        }
    }

    const saveForms = (
        questions: Question,
        formId: number
    ) => {
        onSaveForms(questions, surveyOptions, surveySubQuestions, formId, quizOptions, type, questionType ? questionType : '3')
    }

    const saveSurveyFormHandler = () => {
        let surveyQuestionExist: string | undefined;
        if (surveyQuestion === undefined || surveyQuestion === "<p><br></p>") surveyQuestionExist = t('ContentPanel.question_Required');
        if (!surveyQuestionExist) {
            const questions: Question = {
                id: 0,
                name: surveyQuestion,
                type: questionType || '3',
                body: surveyQuestion,
                isRequired: false,
                weight: 1,
                order: forms!.questions.length + 1,
                minValue: minValue,
                maxValue: maxValue,
                minValueLabel: minValueLabel,
                maxValueLabel: maxValueLabel
            }
            showQuestionHeader(false)
            saveForms(questions, content.id)
        }

        else {
            setState({
                ...state,
                invalidQuestionBodyMessage: surveyQuestionExist
            });

        }
    }

    const cancelForms = () => {
        onCancelForms();
    }

    const cancelSurveyFormHandler = () => {
        showQuestionHeader(false)
        setState({
            ...state,
            invalidQuestionBodyMessage: undefined,
        });
        cancelForms();
    }


    const onClickAddContent = () => {
        setContentOptions(true)
        setAddNewContent(undefined)
    }

    const onCloseContentOptions = () => {
        setContentOptions(false)
    }

    const onSelectContentType = (value: string) => {
        getContentOptions(value)
        setAddNewContent(content.id)
    }

    const renderConfirmMessage = (type: string) => {
        if (type.toLowerCase() === t('miscelaneous.survey')) {
            return t('ContentPanel.delete_Confirm_Message_survey')
        }
        else if (type.toLowerCase() === t('ContentPanel.editableform')) {
            return t('ContentPanel.delete_Confirm_Message_editableform')
        }
        else if (type.toLowerCase() === t('miscelaneous.quiz')) {
            return t('ContentPanel.delete_Confirm_Message_quiz')
        }
        else if (type.toLowerCase() === t('miscelaneous.content')) {
            return t('ContentPanel.delete_Confirm_Message')
        }
        else if (type.toLowerCase() === "meeting") {
            return t('ContentPanel.delete_Confirm_Message_meeting')
        }
        return undefined
    }

    const deleteQuestionHandler = () => {
        services.deleteQuestions(auth!, deleteQuestionId)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onCreateContentHandler = (content: Task) => {
        content.order = index
        onCreateContent(content, lessonId)
    }

    const handleNewQuestionClick = () => {
        setQuestionType('3')
        showQuestionHeader(true)
    }

    const handleQuestionEditClick = (questionId: number) => {
        setEditQuestion(questionId)
        toggleEditMode(true)
    }

    const handleToggleEditClick = () => {
        setEditQuestion('')
        toggleEditMode(false)
    }

    const onChangeSurveyOption = (options: QuestionOption[]) => {
        setSurveyOptions(options)
    }

    const onChangeOptions = (options: QuestionOption[]) => {
        setQuizOptions(options)
    }

    const handleQuestionCollapse = (index: number) => () => {
        const value = index === showOptions ? undefined : index
        visibleOptions(value)
        setEditQuestion('')
        toggleEditMode(false)
    }

    //=============================================================================
    // API Requests
    //=============================================================================

    const updateQuestion = (question: Question) => {
        services.updateQuestion(auth!, question.id, question)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onCompleteExpectedAnswerEdit = (item: Question) => {
        services.updateExpectedAnswer(auth!, item.id, item.expectedAnswer!.toString())
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onCompleteOptionEdit = (item: QuestionOption) => {
        services.updateQuestionOptions(auth!, item.id, item)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onChangeAnswer = (questionId: number) => (item: QuestionOption) => {
        services.setExpectedOptions(auth!, questionId, item.id)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onCompleteSubQuestionEdit = (item: QuestionOption) => {
        services.updateSubQuestionOptions(auth!, item.id, item)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onAddOption = (questionId: number) => (item: QuestionOption) => {
        services.createQuestionOptions(auth!, questionId, item)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onDeleteOption = (optionId: number) => {
        services.deleteOption(auth!, optionId)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onAddSubQuestion = (questionId: number) => (item: QuestionOption) => {
        services.createSubQuestions(auth!, questionId, item)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const onDeleteSubQuestion = (optionId: number) => {
        services.deleteSubQuestion(auth!, optionId)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const updateRadioGridExpectedAnswer = (subQuestionId: number, expectedAnswerId: number) => {
        services.setExpectedSubQuestionAnswer(auth!, subQuestionId, expectedAnswerId)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const deleteCheckBoxGridExpectedAnswer = (subQuestionId: number, expectedAnswerId: number) => {
        services.deleteSubQuestionExpectedAnswer(auth!, subQuestionId, expectedAnswerId)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const deleteQuestionExpectedAnswer = (expectedAnswerId: number) => {
        services.deleteQuestionExpectedAnswer(auth!, expectedAnswerId)
            .then(async (_) => {
                const forms = await services.getFormContent(auth!, content.id);
                setContent(forms)
                actions.send(a => a.loadFormContentDetails(forms));
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const dragProps = {
        onDragEnd(fromIndex: number, toIndex: number) {
            let updatedQuestion = forms!.questions[fromIndex]
            updatedQuestion.order = toIndex + 1
            services.updateQuestion(auth!, updatedQuestion.id, updatedQuestion)
                .then(async (_) => {
                    const forms = await services.getFormContent(auth!, content.id);
                    actions.send(a => a.loadFormContentDetails(forms));
                })
        },
        nodeSelector: 'dt',
        handleSelector: 'sup',
    };

    useLoadContentDetail(auth!, content.id, type, setContent, actions)
    useLoadFormContentDetail(auth!, content.id, type, setContent, actions)
    useLoadMeetingDetail(auth!, content.id, type, setContent, actions)

    if (formApi && contentApi && meetingApi) return <LoadingPage />;

    return (

        <div className="lessons-builder-task">
            <div className="lessons-builder-task-add">
                <AddButton
                    onAdd={onClickAddContent}
                    label={t('miscelaneous.task')}
                    active={true}
                />
            </div>
            {isvisibleContentOptions &&
                <div className="lessons-builder-task-options">
                    <div ref={contentOptionsNode}>
                        <ContentOptions
                            isVisible={isvisibleContentOptions}
                            onClick={(value) => onSelectContentType(value)}
                            onClose={onCloseContentOptions}
                            couseType={courseType}
                        />
                    </div>
                </div>
            }
            {isVisibleAddNewContent && (isVisibleAddNewContent==content.id) &&
                <div className="lessons-builder-task-new">
                    <NewContent
                        courseType={courseType}
                        onClose={() => setAddNewContent(undefined)}
                        onSave={onCreateContentHandler}
                        type={contentOption}
                        lessonId={lessonId}
                    />
                </div>
            }
            <div className="lessons-builder-task-container" style={{ cursor: 'default' }}>
                {props.children &&
                    props.children
                }
                {!props.children &&
                    <div>
                        <div className="lessons-builder-task-header">
                            <dfn className="drag-drop-button mr-3">
                                <span className="drag-drop-button-icon" />
                                <span className="drag-drop-button-icon" />
                            </dfn>
                            <div className="lessons-builder-task-header-info">
                                <div className="lessons-builder-task-header-type">
                                    <strong>{content.type.toLowerCase() === 'editableform' ? 'Editable Form' : content.type} </strong>
                                </div>
                            </div>
                            <div className="lessons-builder-task-header-title">
                                <h2 className="h6" style={{ fontSize: '1rem' }}>{content.name}</h2>
                            </div>
                            <div className="lessons-builder-task-header-actions">
                                <div className="lessons-builder-task-header-options">
                                    <div>{t('ContentPanel.sequence_Level')} {content.level}</div>
                                    {content.isRequired &&
                                        <div>{content.isRequired === true ? t('miscelaneous.required') : ''}</div>
                                    }
                                    <div>{t('NewContent.assign_to')}
                                        {content.type.toLowerCase() !== "meeting" &&
                                            <Assignee assignedTo={content.assignedTo} />
                                        }
                                        {content.type.toLowerCase() === "meeting" &&
                                        <>{'Both'} </>
                                        }
                                    </div>
                                </div>
                                <div className="lessons-builder-task-header-btns">
                                    <button className="btn text-dark" onClick={() => { visibleQuestions(!showQuestion); onClickEditButon(content.id, content.type) }}>
                                        <i className="fa fa-pencil"></i>
                                    </button>

                                    <button className="btn text-dark" onClick={() => toggleDeletePopUp(true)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                                {(content.type.toLowerCase() === 'survey' ||
                                    content.type.toLowerCase() === 'editableform' ||
                                    content.type.toLowerCase() === 'quiz') &&
                                    forms! &&
                                    forms!.questions.length > 0 &&
                                    <button className="btn lessons-builder-task-toogle-btn" onClick={() => visibleQuestions(!showQuestion)}>
                                        <i className={showQuestion ? "icon fa fa-chevron-up" : "icon fa fa-chevron-down"}></i>
                                    </button>
                                }
                                <DeleteConfirmPopup
                                    onClose={() => { toggleDeletePopUp(false) }}
                                    message={renderConfirmMessage(content.type)}
                                    isVisible={isdeletePopUpVisible}
                                    onClickDelete={() => onClickDelete(content.type)}>
                                </DeleteConfirmPopup>
                            </div>
                        </div>
                        <DragList {...dragProps}>
                            <ul className="lessons-builder-task-questions">
                                {showQuestion &&
                                    (content.type.toLowerCase() === 'survey' ||
                                        content.type.toLowerCase() === 'editableform' ||
                                        (content.type.toLowerCase() === 'quiz')) &&
                                    forms! &&
                                    forms!.questions.map((question, index) => (
                                        <dt key={index} className="lessons-builder-task-questions-item">
                                            <div className="row">
                                                {/* the '<sup>' tag is used for the drag handler, just to make it unique from its parent's drag handlers */}
                                                <sup className="drag-drop-button">
                                                    <span className="drag-drop-button-icon" />
                                                    <span className="drag-drop-button-icon" />
                                                </sup>
                                                <div className="col-md-1">
                                                    <p className="small">
                                                        <strong>{index + 1} </strong>
                                                    </p>
                                                </div>

                                                {/* Editing section :: TODO: need to create a new component*/}

                                                {editQuestion !== question.id &&
                                                    <>
                                                        <div className="col-md-7">
                                                            <p className="small">{' '}  <ContentArea rawHTML={question.body || ''} /> </p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="small"><strong>{' '}{question.type}</strong></p>
                                                        </div>
                                                        <div className="lessons-builder-task-questions-btns">
                                                            <button className="btn" onClick={() => setDeleteQuestionId(question.id)}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                            <button className="btn" onClick={() => { visibleOptions(!showOptions); handleQuestionEditClick(question.id) }}>
                                                                <i className="fa fa-pencil"></i>
                                                            </button>
                                                            {(question.type.toLowerCase() === 'dropdown' ||
                                                                question.type.toLowerCase() === 'checkbox' ||
                                                                question.type.toLowerCase() === 'radiobox' ||
                                                                question.type.toLowerCase() === 'radioboxgrid' ||
                                                                question.type.toLowerCase() === 'checkboxgrid' ||
                                                                question.type.toLowerCase() === 'linearscale' ||
                                                                (question.type.toLowerCase() === 'singlelinetext' &&
                                                                    content.type.toLowerCase() === 'quiz')) &&
                                                                <i className={showOptions === index ? "icon fa fa-angle-up" : "icon fa fa-angle-down"}
                                                                    onClick={handleQuestionCollapse(index)}>

                                                                </i>
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {editQuestion === question.id &&
                                                    editMode &&
                                                    (question.type.toLowerCase() === 'singlelinetext' ||
                                                        question.type.toLowerCase() === 'multilinetext' ||
                                                        question.type.toLowerCase() === 'date' ||
                                                        question.type.toLowerCase() === 'time' ||
                                                        question.type.toLowerCase() === 'datetime') &&
                                                    (content.type.toLowerCase() === 'survey' ||
                                                        content.type.toLowerCase() === 'editableform') &&
                                                    <div className="col-md-9">
                                                        <EditQuestion
                                                            question={question}
                                                            updateQuestion={updateQuestion}
                                                            order={index}>
                                                        </EditQuestion>
                                                    </div>
                                                }

                                                {editQuestion === question.id &&
                                                    editMode &&
                                                    (question.type.toLowerCase() === 'singlelinetext') &&
                                                    (content.type.toLowerCase() === 'quiz') &&
                                                    <div className="col-md-9">
                                                        <QuizSingleLineField
                                                            question={question}
                                                            updateQuestion={updateQuestion}
                                                            order={index}
                                                            currentOptions={question!.expectedAnswer!.toString()}
                                                            onCompleteEdit={onCompleteExpectedAnswerEdit}
                                                        />

                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'dropdown' &&
                                                    (content.type.toLowerCase() !== 'quiz') &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <SurveyDropDown
                                                            onChangeOption={() => { }}
                                                            question={question}
                                                            updateQuestion={updateQuestion}
                                                            currentOptions={question.options}
                                                            onCompleteEdit={onCompleteOptionEdit}
                                                            order={index}
                                                            onAddOption={onAddOption(question.id)}
                                                            onDeleteOption={onDeleteOption}
                                                        />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'dropdown' &&
                                                    (content.type.toLowerCase() === 'quiz') &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <QuizRadioBox
                                                            onChangeOption={() => { }}
                                                            onSetAnswer={onChangeOptions}
                                                            updateQuestion={updateQuestion}
                                                            question={question}
                                                            currentOptions={question.options}
                                                            onCompleteEdit={onCompleteOptionEdit}
                                                            order={index}
                                                            expectedAnswer={question.expectedOptions}
                                                            onChangeAnswer={onChangeAnswer(question.id)}
                                                            onAddOption={onAddOption(question.id)}
                                                            onDeleteOption={onDeleteOption}
                                                        />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'checkbox' &&
                                                    content.type.toLowerCase() === 'quiz' &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <QuizCheckBox
                                                            onChangeOption={() => { }}
                                                            onSetAnswer={() => { }}
                                                            updateQuestion={updateQuestion}
                                                            question={question}
                                                            currentOptions={question.options}
                                                            onCompleteEdit={onCompleteOptionEdit}
                                                            order={index}
                                                            expectedAnswer={question.expectedOptions}
                                                            onChangeAnswer={onChangeAnswer(question.id)}
                                                            onAddOption={onAddOption(question.id)}
                                                            onDeleteOption={onDeleteOption}
                                                            onDeleteExpectedAnswer={deleteQuestionExpectedAnswer}
                                                        />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'checkbox' &&
                                                    content.type.toLowerCase() !== 'quiz' &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <SurveyCheckBox
                                                            onChangeOption={() => { }}
                                                            question={question}
                                                            updateQuestion={updateQuestion}
                                                            currentOptions={question.options}
                                                            onCompleteEdit={onCompleteOptionEdit}
                                                            onAddOption={onAddOption(question.id)}
                                                            onDeleteOption={onDeleteOption}
                                                            order={index} />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'radiobox' &&
                                                    content.type.toLowerCase() !== 'quiz' &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <SurveyRadioBox
                                                            onChangeOption={() => { }}
                                                            question={question}
                                                            updateQuestion={updateQuestion}
                                                            currentOptions={question.options}
                                                            onCompleteEdit={onCompleteOptionEdit}
                                                            onAddOption={onAddOption(question.id)}
                                                            onDeleteOption={onDeleteOption}
                                                            order={index} />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'radiobox' &&
                                                    content.type.toLowerCase() === 'quiz' &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <QuizRadioBox
                                                            onChangeOption={() => { }}
                                                            onSetAnswer={onChangeOptions}
                                                            updateQuestion={updateQuestion}
                                                            question={question}
                                                            currentOptions={question.options}
                                                            onCompleteEdit={onCompleteOptionEdit}
                                                            order={index}
                                                            expectedAnswer={question.expectedOptions}
                                                            onChangeAnswer={onChangeAnswer(question.id)} onAddOption={onAddOption(question.id)}
                                                            onDeleteOption={onDeleteOption}
                                                        />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === 'linearscale' &&
                                                    editMode &&
                                                    <div className="col-md-9">
                                                        <SurveyLinearForm
                                                            onChangeMinValue={setMinValue}
                                                            onChangeMaxValue={setMaxValue}
                                                            onChangeMinLabel={setMinValueLabel}
                                                            onChangeMaxLabel={setMaxValueLabel}
                                                            question={question}
                                                            updateQuestion={updateQuestion}
                                                            currentOptions={question.options}
                                                            order={index}
                                                        />
                                                    </div>
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === "radioboxgrid" &&
                                                    content.type.toLowerCase() !== 'quiz' &&
                                                    editMode &&
                                                    <SurveyMultipleChoice
                                                        onChangeRowOption={() => { }}
                                                        currentOptions={question.options}
                                                        currentSubQuestions={question.subQuestions}
                                                        onCompleteOptionEdit={onCompleteOptionEdit}
                                                        onCompleteSubQuestionEdit={onCompleteSubQuestionEdit}
                                                        onChangeColumnOption={() => { }}
                                                        question={question}
                                                        updateQuestion={updateQuestion}
                                                        order={index}
                                                        onAddOption={onAddOption(question.id)}
                                                        onAddSubQuestion={onAddSubQuestion(question.id)}
                                                        onDeleteSubQuestion={onDeleteSubQuestion}
                                                        onDeleteOption={onDeleteOption}
                                                    />
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === "radioboxgrid" &&
                                                    content.type.toLowerCase() === 'quiz' &&
                                                    editMode &&
                                                    <QuizMultipleChoice
                                                        onChangeRowOption={() => { }}
                                                        currentOptions={question.options}
                                                        currentSubQuestions={question.subQuestions}
                                                        onCompleteOptionEdit={onCompleteOptionEdit}
                                                        onCompleteSubQuestionEdit={onCompleteSubQuestionEdit}
                                                        onChangeColumnOption={() => { }}
                                                        onSetAnswer={() => { }}
                                                        question={question}
                                                        updateQuestion={updateQuestion}
                                                        order={index}
                                                        onAddOption={onAddOption(question.id)}
                                                        onDeleteOption={onDeleteOption}
                                                        onDeleteSubQuestion={onDeleteSubQuestion}
                                                        onAddSubQuestion={onAddSubQuestion(question.id)}
                                                        updateExpectedAnswer={updateRadioGridExpectedAnswer}
                                                    />
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === "checkboxgrid" &&
                                                    content.type.toLowerCase() !== 'quiz' &&
                                                    editMode &&
                                                    <SurveyCheckBoxGrid
                                                        onChangeRowOption={() => { }}
                                                        currentOptions={question.options}
                                                        currentSubQuestions={question.subQuestions}
                                                        onCompleteOptionEdit={onCompleteOptionEdit}
                                                        onCompleteSubQuestionEdit={onCompleteSubQuestionEdit}
                                                        onChangeColumnOption={() => { }}
                                                        question={question}
                                                        updateQuestion={updateQuestion}
                                                        order={index}
                                                        onAddOption={onAddOption(question.id)}
                                                        onDeleteOption={onDeleteOption}
                                                        onAddSubQuestion={onAddSubQuestion(question.id)}
                                                        onDeleteSubQuestion={onDeleteSubQuestion}
                                                    />
                                                }
                                                {editQuestion === question.id &&
                                                    question.type.toLowerCase() === "checkboxgrid" &&
                                                    content.type.toLowerCase() === 'quiz' &&
                                                    editMode &&
                                                    <QuizCheckBoxGrid
                                                        onChangeRowOption={() => { }}
                                                        currentOptions={question.options}
                                                        currentSubQuestions={question.subQuestions}
                                                        onCompleteOptionEdit={onCompleteOptionEdit}
                                                        onCompleteSubQuestionEdit={onCompleteSubQuestionEdit}
                                                        onChangeColumnOption={() => { }}
                                                        onSetAnswer={() => { }}
                                                        question={question}
                                                        updateQuestion={updateQuestion}
                                                        order={index}
                                                        onAddOption={onAddOption(question.id)}
                                                        onDeleteOption={onDeleteOption}
                                                        onAddSubQuestion={onAddSubQuestion(question.id)}
                                                        onDeleteSubQuestion={onDeleteSubQuestion}
                                                        updateExpectedAnswer={updateRadioGridExpectedAnswer}
                                                        deleteExpectedAnswer={deleteCheckBoxGridExpectedAnswer}
                                                    />
                                                }

                                                {editQuestion === question.id && editMode &&
                                                    <div className="col-md-1" style={{ cursor: "pointer" }}>
                                                        <i className="fa fa-times" aria-hidden="true" onClick={handleToggleEditClick}></i>
                                                    </div>
                                                }

                                                {/* editing section end*/}
                                            </div>
                                            {/* Preview the question content */}
                                            {showQuestion &&
                                                <QuestionOptionsPreview
                                                    index={index}
                                                    showOptions={showOptions}
                                                    question={question}
                                                    content={content}
                                                    type={type}
                                                />
                                            }
                                        </dt>
                                    ))
                                }
                            </ul>
                        </DragList>
                        <ContentQuestionPanel
                            content={content}
                            isQuestionHeaderDisplayed={isQuestionHeaderDisplayed}
                            type={type}
                            questionType={questionType}
                            surveyQuestion={surveyQuestion}
                            handleNewQuestionClick={handleNewQuestionClick}
                            setQuestionType={setQuestionType}
                            invalidQuestionBodyMessage={invalidQuestionBodyMessage}
                            showQuestionHeader={showQuestionHeader}
                            setSurveyQuestion={setSurveyQuestion}
                            setSurveySubQuestions={setSurveySubQuestions}
                            setMinValue={setMinValue}
                            setMaxValue={setMaxValue}
                            setMinValueLabel={setMinValueLabel}
                            setMaxValueLabel={setMaxValueLabel}
                            onChangeSurveyOption={onChangeSurveyOption}
                            onChangeOptions={onChangeOptions}
                            saveForms={saveForms}
                            cancelSurveyFormHandler={cancelSurveyFormHandler}
                            saveSurveyFormHandler={saveSurveyFormHandler}
                        />
                    </div>
                }
            </div >
            <DeleteConfirmPopup
                onClose={() => { setDeleteQuestionId(undefined) }}
                message={t('ContentPanel.remove_Confirm_Message_question')}
                isVisible={deleteQuestionId}
                onClickDelete={deleteQuestionHandler}
            />
        </div >
    )
}

function useLoadContentDetail(auth: Auth, contentId: number, type: string, setContent: (content: Form) => void, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (type.toLowerCase() === 'content') {
                let forms = await services.getContent(auth, contentId);
                if (isRequestError(forms)) {
                    actions.send(a => a.getContentApi(true));
                }
                else {
                    setContent(forms)
                    actions.send(a => a.loadContentDetail(forms));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadFormContentDetail(auth: Auth, contentId: number, type: string, setContent: (content: Form) => void, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (type.toLowerCase() === 'survey' || type.toLowerCase() === 'quiz' || type.toLowerCase() === 'editableform') {
                const forms = await services.getFormContent(auth, contentId);
                if (isRequestError(forms)) {
                    actions.send(a => a.getFormApi(true));
                }
                else {
                    setContent(forms)
                    actions.send(a => a.loadFormContentDetails(forms));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadMeetingDetail(auth: Auth, contentId: number, type: string, setContent: (content: Form) => void, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (type.toLowerCase() === 'meeting') {
                const forms = await services.getMeetingContent(auth, contentId);
                if (isRequestError(forms)) {
                    actions.send(a => a.getMeetingApi(true));
                }
                else {
                    setContent(forms)
                    actions.send(a => a.loadMeetingDetails(forms));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}