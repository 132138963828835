import * as React from 'react';

export interface AddButtonProps {
  active: boolean,
  label: string,
  onAdd: () => void,
}

export const AddButton: React.FunctionComponent<AddButtonProps> = ({ active, label, onAdd }) => {
  return (
    <div className="float-button" onClick={onAdd} >
      <div className="float-button-icon">
        <i className="icon fa fa-plus"></i>
      </div>
      <div className="float-button-text">
        {label}
      </div>      
    </div>
  )
}

