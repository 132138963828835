import * as React from 'react';
import { DropdownI } from '../Forms/DropdownI';
import { TextI } from '../Forms/TextI';
import { Question, QuestionOption } from '../../domain/models';
import { EditQuestion } from '../../pages/Admin/CourseManagementPage/components/tab1/EditQuestion';
import { EditOption } from '../../pages/Admin/CourseManagementPage/components/tab1/EditOption';

export interface SurveyLinearProps {
    onChangeMinValue: (value: number) => void;
    onChangeMaxValue: (value: number) => void;
    onChangeMinLabel: (value: string) => void;
    onChangeMaxLabel: (value: string) => void;
    question?: Question;
    currentOptions?: QuestionOption[];
    updateQuestion?: (question: Question) => void;
    onCompleteEdit?: (item: QuestionOption) => void
    order?: number;
}

export const SurveyLinearForm: React.FunctionComponent<SurveyLinearProps> = ({
    onChangeMinValue,
    onChangeMaxValue,
    onChangeMinLabel,
    onChangeMaxLabel,
    question,
    currentOptions,
    updateQuestion,
    onCompleteEdit,
    order }) => {


    const [selectedMinValue, setSelectedMinValue] = React.useState('1');
    const [selectedMaxValue, setSelectedMaxValue] = React.useState('2');
    const [minValuLabel, setMinValueLabel] = React.useState('')
    const [maxValuLabel, setMaxValueLabel] = React.useState('')
    const [isEditMode, setEditMode] = React.useState(false)

    const [selectedMinValueEdit, setSelectedMinValueEdit] = React.useState('1');
    const [selectedMaxValueEdit, setSelectedMaxValueEdit] = React.useState('2');
    const [minValuLabelEdit, setMinValueLabelEdit] = React.useState('')
    const [maxValuLabelEdit, setMaxValueLabelEdit] = React.useState('')

    React.useEffect(() => {
        if (question) {
          setEditMode(true)
          let currentMinValue = question.minValue ? question.minValue.toString(): '1'
          setSelectedMinValueEdit(currentMinValue)
          let currentMaxValue = question.maxValue ? question.maxValue.toString(): '2'
          setSelectedMaxValueEdit(currentMaxValue)
          let currentMinValuLabel = question.minValueLabel ? question.minValueLabel.toString(): ''
          setMinValueLabelEdit(currentMinValuLabel)
          let currentMaxValuLabel = question.maxValueLabel ? question.maxValueLabel.toString(): ''
          setMaxValueLabelEdit(currentMaxValuLabel)
        }
    }, [])

    React.useEffect(() => {
        if(isEditMode)
      updateLinearScale()
    }, [selectedMinValueEdit, selectedMaxValueEdit, minValuLabelEdit, maxValuLabelEdit])

    const getMaxValueItems = (maxValue: number) => {
        let valueList = [];
        for (let i = 1; i <= maxValue; i++) {
            valueList.push({ value: i.toString(), label: i.toString() })
        }
        return valueList
    }

    const handleMinValueDropDown = (selectedValue: string) => {
        setSelectedMinValue(selectedValue)

        onChangeMinValue(Number(selectedValue))
    }
    const handleMaxValueDropDown = (selectedValue: string) => {
        setSelectedMaxValue(selectedValue)
        onChangeMaxValue(Number(selectedValue))
    }
    const handleMaxValueLabel = (value: string) => {
        setMaxValueLabel(value)
        onChangeMaxLabel(value)
    }
    const handleMinValueLabel = (value: string) => {
        setMinValueLabel(value)
        onChangeMinLabel(value)
    }

const updateLinearScale = () => {
  const questions: Question = {
    id: question!.id,
    name: question!.body,
    type:  'LinearScale',
    body: question!.body,
    isRequired: false,
    weight: 0,
    order: order! + 1,
    minValue: Number(selectedMinValueEdit),
    maxValue: Number(selectedMaxValueEdit),
    minValueLabel: minValuLabelEdit,
    maxValueLabel: maxValuLabelEdit
  }
  updateQuestion!(questions)
}
    const handleMinValueDropDownEdit = (selectedValue: string) => {
      setSelectedMinValueEdit(selectedValue)
     
      // onChangeMinValue(Number(selectedValue))
  }
  const handleMaxValueDropDownEdit = (selectedValue: string) => {
      setSelectedMaxValueEdit(selectedValue)
     
      // onChangeMaxValue(Number(selectedValue))
  }
  const handleMaxValueLabelEdit = (value: string) => {
      setMaxValueLabelEdit(value)
     
      // onChangeMaxLabel(value)
  }
  const handleMinValueLabelEdit = (value: string) => {
      setMinValueLabelEdit(value)
     
      // onChangeMinLabel(value)
  }

    return (
        <div>
            <EditQuestion question={question} updateQuestion={updateQuestion} order={order} />
            <>
                {!isEditMode &&
                    <div>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex w-25 p-3 justify-content-between align-items-center">
                                <DropdownI
                                    active={true}
                                    selected={selectedMinValue}
                                    options={[
                                        { value: '0', label: '0' },
                                        { value: '1', label: '1' },
                                    ]}
                                    isValid={false}
                                    isInvalid={false}
                                    invalidMessage={undefined}
                                    onChange={handleMinValueDropDown}
                                />
                                {'  to  '}
                                <DropdownI
                                    active={true}
                                    selected={selectedMaxValue}
                                    options={getMaxValueItems(10)}
                                    isValid={false}
                                    isInvalid={false}
                                    invalidMessage={undefined}
                                    onChange={handleMaxValueDropDown}
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="px-3" style={{width:"50px"}}>
                                {selectedMinValue}
                            </div>
                            <TextI
                                active={true}
                                placeholder={'Enter the label'}
                                value={minValuLabel}
                                isValid={false}
                                isInvalid={false}
                                onChange={handleMinValueLabel}
                            />
                        </div>
                        <div className="d-flex">
                            <div className="px-3" style={{width:"50px"}}>
                                {selectedMaxValue}
                            </div>
                            <TextI
                                active={true}
                                placeholder={'Enter the label'}
                                value={maxValuLabel}
                                isValid={false}
                                isInvalid={false}
                                onChange={handleMaxValueLabel}
                            />
                        </div>
                    </div>
                }
                {isEditMode &&
                     <div>
                     <div className="d-flex justify-content-center">
                         <div className="d-flex w-25 p-3 justify-content-between align-items-center">
                             <DropdownI
                                 active={true}
                                 selected={selectedMinValueEdit}
                                 options={[
                                     { value: '0', label: '0' },
                                     { value: '1', label: '1' },
                                 ]}
                                 isValid={false}
                                 isInvalid={false}
                                 invalidMessage={undefined}
                                 onChange={handleMinValueDropDownEdit}
                             />
                             {'  to  '}
                             <DropdownI
                                 active={true}
                                 selected={selectedMaxValueEdit}
                                 options={getMaxValueItems(10)}
                                 isValid={false}
                                 isInvalid={false}
                                 invalidMessage={undefined}
                                 onChange={handleMaxValueDropDownEdit}
                             />
                         </div>
                     </div>
                     <div className="d-flex">
                         <div className="px-3">
                             {question!.minValue!}
                         </div>
                         <TextI
                             active={true}
                             placeholder={'Enter the label'}
                             value={minValuLabelEdit}
                             isValid={false}
                             isInvalid={false}
                             onChange={handleMinValueLabelEdit}
                         />
                     </div>
                     <div className="d-flex">
                         <div className="px-3">
                             {question!.maxValue!}
                         </div>
                         <TextI
                             active={true}
                             placeholder={'Enter the label'}
                             value={maxValuLabelEdit}
                             isValid={false}
                             isInvalid={false}
                             onChange={handleMaxValueLabelEdit}
                         />
                     </div>
                 </div>
                }
            </>
        </div>
    );
}