import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Lesson, Task } from '../../../../../domain/models';
import { showLessonPanel, highlightTask, getTaskTitle } from '../../../../../components/LessonPanel/synchronous-helpers';
import { FormatLessonStatus, FormatTaskNotes } from '../../../../../components/LessonPanel/component-helpers';

export interface TraineeLessonPanelProps {
    index: number;
    lesson: Lesson;
    onTaskClick?: (task: Task) => void;
}

export const TraineeLessonPanel: React.FunctionComponent<TraineeLessonPanelProps> = ({ index, lesson, onTaskClick }) => {
    const { t } = useTranslation();
    const [show, toggle] = useState(showLessonPanel(lesson));

    return (
        <div className="list-group list-lessons mb-4">
            <a className="list-group-item list-group-item-action list-lessons-item" href="javascript:void(0)" onClick={() => toggle(!show)}>
                <div className="list-lessons-info">
                    <div className="list-lessons-category">
                        <p className="mb-3 small text-muted">{t('LessonPanel.lesson', { index: index })}</p>
                    </div>
                    <div className="list-lessons-status">
                        <p className="mb-0 small"><strong><FormatLessonStatus status={lesson.status} /></strong></p>
                    </div>
                    <div className="list-lessons-actions">
                        <p className="mb-0 small text-muted"><strong>{
                            (lesson.tasks || []).filter(lesson => (lesson.status == 'Finished' || lesson.status == 'Passed')).length
                        }/{(lesson.tasks || []).length}</strong></p>
                        <i className={show ? "icon fa fa-angle-up" : "icon fa fa-angle-down"}></i>
                    </div>
                </div>
                <div className="list-lessons-title">
                    <h3 className="h6">{lesson.name}</h3>
                </div>
            </a>

            {show && <div className="list-group list-tasks">
                {(lesson.tasks || []).map((task, i) => (
                    (task.isEnabled)
                        ? (<a
                            key={i}
                            className={classnames('list-group-item list-group-item-action list-tasks-item', {
                                'active': highlightTask(task.isRequired, task.isEnabled, task.status, task.assignedTo == 'Coach', false)
                            })}
                            href="javascript:void(0)"
                            onClick={() => onTaskClick ? onTaskClick(task) : null}
                        >
                            {getTaskTitle(task.type, task.assignedTo == 'Coach', task.name)}
                            <FormatTaskNotes status={task.status} score={task.score} />
                            {(!task.isRequired) && <span className="list-tasks-info"><span className="badge badge-warning">{t('LessonPanel.optional')}</span></span>}
                        </a>)
                        : (<span key={i} className="list-group-item list-group-item-action list-tasks-item disabled">
                            {getTaskTitle(task.type, task.assignedTo == 'Coach', task.name)}
                            <FormatTaskNotes status={task.status} score={task.score} />
                            {(!task.isRequired) && <span className="list-tasks-info"><span className="badge badge-warning">{t('LessonPanel.optional')}</span></span>}
                        </span>)
                ))}
            </div>}
        </div>
    )
}
