import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../../api/Router';

import { scrollToPosition } from '../../../../api/utils';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../../api/react-helper';
import { Auth, isRequestError } from '../../../../domain/_shared';
import * as services from '../../../../domain/services';
import { Meeting, Form } from '../../../../domain/models';

import { P50x } from '../../../../components/P50x';
import { BaseAppLayout } from '../../../../layouts/shared/BaseAppLayout';
import { ButtonBack } from '../../../../components/Buttons/ButtonBack';
import { FeedbackBox } from '../../../../components/Task/FeedbackBox';
import { CoachMeetingFeedback } from '../../../../components/Task/CoachMeetingFeedback';
import { CountdownTrigger } from '../../../../components/Task/CountdownTrigger';
import { CardSection, CardSectionLoading, CardViewType } from '../../../../components/Cards/CardSection';
import { ContentArea } from '../../../../components/ContentArea';
import { MeetingBody } from '../../../../components/Task/MeetingBody';

export interface MeetingPageRouteParameters {
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export interface MeetingPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

type State = {
    apiError?: any | undefined;
    meeting?: Form;
    lock?: boolean;
    submitted?: boolean;
    newStatus?: string;
    showFeedback?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadMeeting(meeting?: Form): State;
    SendAcknowledge(): State;
    UnlockSend(): State;
    PostStatus200(newStatus: string): State;
    ToggleFeedback(showFeedback: boolean): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadMeeting: meeting => ({ ...state, meeting }),
    SendAcknowledge: () => ({ ...state, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    PostStatus200: newStatus => ({ ...state, lock: false, submitted: true, newStatus }),
    ToggleFeedback: showFeedback => ({ ...state, showFeedback }),
})

export const MeetingPage: React.FunctionComponent<MeetingPageProps> = ({
    auth,
    onLogout,
    groupId,
    courseId,
    lessonId,
    taskId
}) => {
    const { t } = useTranslation();
    const [{
        apiError,
        meeting,
        lock,
        submitted,
        newStatus,
        showFeedback
    }, actions] = wrapperReducer(useReducer(reducer, {
        lock: false,
        submitted: false,
        showFeedback: false
    }));

    const goToRoot = () => Router.push('/');
    const goToCourse = () => Router.push(`/groups/${groupId}/coach/courses/${courseId}`);
    const goToLessons = () => Router.push(`/groups/${groupId}/admin/courses/${courseId}/1`);

    useLoadMeeting(auth,  taskId, actions);
    useSendAcknowledge(lock || false, submitted || false, auth,  taskId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <div>

            {(meeting)
                ? (
                    <>
                        <div className="card bg-white px-4 pt-3">
                            <CardSection
                                title={meeting.name}
                                showGridListToggle={false}
                                initialCardViewType={CardViewType.List}
                            >
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />
                                    <MeetingBody
                                        meeting={meeting}
                                    />
                                    <hr />
                                    <div className="col-12 text-right">
                                        <ButtonBack text={t('TaskPage.goBack')} btnDefault={true} onClick={goToLessons} />
                                    </div>
                                </div>
                            </CardSection>
                        </div>
                    </>
                )
                : <CardSectionLoading description={true} showGridListToggle={true} initialCardViewType={CardViewType.List} />}

        </div>
        //</BaseAppLayout>
    );
}

const useLoadMeeting = function (auth: Auth,  meetingId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            actions.send(a => a.LoadMeeting(undefined));
            const meeting = await services.getMeetingContent(auth,  meetingId);
            if (isRequestError(meeting)) {
                // TODO: log the error
                // TODO: Diogo - create request error message
                // TODO: use error boundary
                console.log(meeting);
            }
            else {
                actions.send(a => a.LoadMeeting(meeting));
                scrollToPosition(0, 0);

                //if (meeting.status !== 'NotScheduled') {
                actions.send(a => a.ToggleFeedback(true));
                //} (TODO) fix this (when to show pending approval?)
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [meetingId]);
}

const useSendAcknowledge = function (lock: boolean, submitted: boolean, auth: Auth, meetingId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                // const status = await services.postCoachMeetingApproval(auth, traineeId, meetingId);
                // if (isRequestError(status)) {
                //     // TODO: log the error
                //     // TODO: Diogo - create request error message
                //     // TODO: use error boundary
                //     console.log(status);

                //     // (TODO) unlock resend in any error case?
                //     actions.send(a => a.UnlockSend());
                // }
                // else {
                //     actions.send(a => a.PostStatus200(status));
                //     actions.send(a => a.ToggleFeedback(true));
                // }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}
