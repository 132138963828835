import * as React from 'react';
import { useContext } from 'react';
import Pagination from 'react-js-pagination';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';

export interface ParticipantPaginationProps {
    resultsCount: number
}

export const ParticipantPaginationCustom: React.FunctionComponent<ParticipantPaginationProps> = ({ resultsCount }) => {
    const { state: { page, resultsPerPage }, actions } = useContext(ParticipantsMgmtContext)
    const handlePageChange = (pageNumber: number) => {
        actions.send(a => a.changePage(pageNumber - 1))
    }

    return (
        <div>
            <Pagination
                activePage={page + 1}
                itemsCountPerPage={resultsPerPage}
                totalItemsCount={resultsCount}
                pageRangeDisplayed={10}
                onChange={e => handlePageChange(e)}
                firstPageText={<i className='fa fa-angle-double-left'></i>}
                prevPageText={<i className='fa fa-angle-left'></i>}
                lastPageText={<i className='fa fa-angle-double-right'></i>}
                nextPageText={<i className='fa fa-angle-right'></i>}
            />
        </div>
    );
}
