export const __SERVER_API__: string = process.env.REACT_APP_WEBAPI_URL || '';
export const __AUTH_API__: string = process.env.REACT_APP_AUTHAPI_URL || '';

// export * from './as-unlogged';
export * from './auth';
export * from './as-user';
export * from './as-member';
export * from './as-participant';
export * from './as-trainee';
export * from './as-coach';
export * from './as-admin';
// export * from './as-sys-admin';
