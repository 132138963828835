import * as React from 'react';
import { useReducer, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Router from '../../../api/Router';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { } from '../../../domain/models';
import { NewCourse } from '../CreateNewCoursePage/components/NewCourse';
import { GlobalContext } from '../../../domain/contexts/GlobalContext';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';

export interface CreateNewCoursePageRouteParameters {
    groupId: number;
    tabId:number;
}

export interface CreateNewCoursePageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    tabId:number;
}

type State = {
    apiError?: any | undefined;
}

type Action = {
    Set50xError(apiError: any): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
})

export const CreateNewCoursePage: React.FunctionComponent<CreateNewCoursePageProps> = ({ auth, onLogout, groupId,tabId }) => {
    const { actions } = React.useContext(GlobalContext); // (TODO) remove showcase example
    const { t } = useTranslation();

    return (
        <BaseAppLayout
            breadcrumbs={[]}
            onLogout={onLogout}
        >

          
            <NewCourse groupId={groupId} tabId={tabId}></NewCourse>
            
    </BaseAppLayout>
    );
}
