import * as React from 'react';
import * as services from '../../../../../domain/services';
import { AddButton } from '../../../../../components/Lessons/AddButton';
import { Auth } from '../../../../../domain/_shared';
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { ContentConfigs,Form, Lesson, LessonConfigs, Question, QuestionOption, Task} from '../../../../../domain/models';
import { ContentOptions } from '../../../../../components/Lessons/ContentOptions';
import { ContentPanel } from './ContentPanel'; import { DeleteConfirmPopup } from '../../../../../components/Popup/DeleteConfirmPopup';
import DragList from '../../../../../domain/utils/DragList';
import { NewContent } from '../../../../../components/Lessons/NewContent';
import { NewLesson } from './NewLesson';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface LessonsPanelProps {
    courseId: number;
    groupId: number;
    courseType: string;
    index: number;
    lesson: Lesson;
    isVisibleAddNewContent?: number;
    isContentDisplayed:boolean;
    editingContent?:number;
    onClickDeleteLesson: () => void;
    onClickEditButon: (lessonId: number) => void;
    onClickContentDelete: (id: number, lessonId: number, option: string) => void;
    onContentUpdate: (content: Form, lessonId?: number, fromIndex?: number, toIndex?: number) => void;
    onAddNewContent: (content: ContentConfigs, lessonId: number) => void,
    onAddNewForms: (question: Question, options: QuestionOption[], subQuestions: QuestionOption[], formId: number, expectedAnswer: QuestionOption[], type: string, questionType: string) => void
    onClick: () => void;
    onAddNewLesson: (auth: Auth, lesson: LessonConfigs) => void;
    onCancelForms: () => void;
    updateQuestion: (question: Question) => void;
    onCompleteEdit: (item: QuestionOption) => void;
    errorHandler: (error: any) => void;
    setAddNewContent: React.Dispatch<React.SetStateAction<any>>
    showcontent: React.Dispatch<React.SetStateAction<boolean>>
    setContentEditing: React.Dispatch<React.SetStateAction<any>>
}

export const LessonsPanel: React.FunctionComponent<LessonsPanelProps> = (props) => {
    const { t } = useTranslation();
    const [show, toggle] = useState(true);
    const [isdeletePopUpVisible, toggleDeletePopUp] = useState(false)
    const [isOptionsDisplayed, showOptions] = React.useState(false)
    const [contentOption, setContentOptions] = React.useState('')
    const [traineeBodyContent, setTraineeContent] = React.useState()
    const [coachBodyContent, setCoachBodyContent] = React.useState()
    const [isRequiredContent, setIsRequiredContent] = React.useState(true)
    const [isCoachValidationRequired, setCoachValidationRequired] = React.useState(true)
    const { state: { auth } } = React.useContext(AuthContext);
    const [visibleContentid, showAddContent] = React.useState()
    const [isVisibleAddLesson, setIsVisibleAddLesson] = React.useState(false)
    const [minPassScore, setMinPassingScore] = React.useState()
    const [isPrivate, setPrivate] = React.useState(false)
    const [duration, setDuration] = React.useState()

    const [contents, setContents] = React.useState([] as Form[])

    const handleOptionsClick = (value: string) => {
        setContentOptions(value)
        showOptions(false)
        setAddNewContent(undefined)
        showcontent(true)
        setContentEditing(undefined); 
    }

    const handleOptions = () => {
        showOptions(false);
        showAddContent(undefined)
    }

    const addnewContentHandler = (id: any) => {
        setContentEditing(undefined); 
        showcontent(false);
        setAddNewContent(id)
    }

    const handleContents = () => {
        setAddNewContent(undefined);
       showcontent(false)
    }

    const handleNewContent = (content: Task) => {
        onAddNewContent(content, lesson.id)
    }

    const handleBottomNewContent = (content: Task) => {
        content.order = lesson.tasks.length + 1
        handleNewContent(content)
    }

    const onHandleContentPanel = (contentID?: number) => {
        showAddContent(contentID)
        if (!contentID) {
            showOptions(true)
            setAddNewContent(undefined)
            showcontent(false)
        }
    }

   

    const onClickContentEdit = (contentId: number, type: string) => {
        if (type.toLowerCase() === "content") {
            services.getContent(auth!, contentId)
                .then((response) => {
                    setTraineeContent(response.traineeBody)
                    setCoachBodyContent(response.coachBody)
                    setIsRequiredContent(response.isRequired)
                    setCoachValidationRequired(response.coachValidationRequired)
                    setPrivate(response.isPrivate)
                    showcontent(false)
                    setAddNewContent(undefined)
                    setContentEditing(contentId)

                })
                .catch((error) => {
                  errorHandler(error)
                })
        }
        else if (type.toLowerCase() === "meeting") {
            services.getMeetingContent(auth!, contentId)
                .then((response) => {
                    setTraineeContent(response.traineeBody)
                    setCoachBodyContent(response.coachBody)
                    setIsRequiredContent(response.isRequired!)
                    setDuration(response.durationMinutes!)
                    showcontent(false)
                    setAddNewContent(undefined)
                    setContentEditing(contentId)

                })
                .catch((error) => {
                  errorHandler(error)
                })
        }
        else {
            services.getFormContent(auth!, contentId)
                .then((response) => {
                    setTraineeContent(response.traineeBody)
                    setCoachBodyContent(response.coachBody)
                    setIsRequiredContent(response.isRequired!)
                    if (type.toLowerCase() === "quiz") {
                        setMinPassingScore(response.minPassingScore!)
                    }
                    setPrivate(response.isPrivate!)
                    showcontent(false)
                    setAddNewContent(undefined)
                    setContentEditing(contentId)

                })
                .catch((error) => {
                  errorHandler(error)
                })
        }
    }

    const renderSwitch = (value: string) => {
        switch (value) {
            case 'Trainee':
                return '0';
            case 'Coach':
                return '1';
            case '0':
                return '0';
            case '1':
                return '1';
            default:
                return '0';
        }
    }

    const setContentHandler = (content: Form) => {
        let index = contents.findIndex((val: Form) => { return val.id === content.id })
        if (index !== -1) {
            let contentsArray = contents.slice()
            contentsArray[index] = content
            setContents([...contentsArray])
        } else {
            contents.push(content)
            setContents([...contents])
        }
    }

    const {
        courseId,
        groupId,
        courseType,
        index,
        lesson,
        onClickDeleteLesson,
        onClickEditButon,
        onClickContentDelete,
        onContentUpdate,
        onAddNewContent,
        onAddNewForms,
        onClick,
        onAddNewLesson,
        onCancelForms,
        updateQuestion,
        onCompleteEdit,
        errorHandler,
        setAddNewContent,
        isVisibleAddNewContent,
        isContentDisplayed,
        showcontent,
        editingContent,
        setContentEditing
    } = props

    const updateContent = (
        content: Form,
        contentId?: number,
        lessonId?: number) => {
        content.id = contentId!
        onContentUpdate(content, lessonId)
    }

    const createLessonHandler = (auth: Auth, lesson: LessonConfigs) => {
        setIsVisibleAddLesson(false)
        onAddNewLesson(auth, lesson)
    }

    const dragProps = {
        onDragEnd(fromIndex: number, toIndex: number) {
            let data = [...lesson.tasks]
            let item = data[fromIndex]
            let content = contents.find((val: Form) => { return val.id === item.id })
            onContentUpdate(content!, lesson.id, fromIndex, toIndex)
        },
        nodeSelector: 'dl',
        handleSelector: 'dfn',
    };

    React.useEffect(() => {
        if (isContentDisplayed) {
            setContentEditing(undefined)
            setAddNewContent(undefined)
        }
    }, [isContentDisplayed]);

    return (
        <div className="lessons-builder-lesson">
            <div className="lessons-builder-lesson-add">
                <AddButton active={true} label={'Lesson'} onAdd={() => setIsVisibleAddLesson(true)} />
            </div>
            {isVisibleAddLesson &&
                <div className="lessons-builder-lesson-new">
                    <NewLesson
                        onClose={() => { setIsVisibleAddLesson(false) }}
                        onSave={createLessonHandler}
                        courseId={courseId}
                        groupId={groupId}
                        index={index}
                    />
                </div>
            }

            <div className="lessons-builder-lesson-container">

                {props.children &&
                    props.children
                }
                {!props.children &&
                    <div>
                        <div className="lessons-builder-lesson-header" >
                            <dd className="drag-drop-button">
                                <span className="drag-drop-button-icon" />
                                <span className="drag-drop-button-icon" />
                            </dd>
                            <div className="lessons-builder-lesson-header-info">
                                <div className="lessons-builder-lesson-header-index">
                                    {t('LessonPanel.lesson', { index: index })}
                                </div>
                                <div className="lessons-builder-lesson-header-title">
                                    <h2 className="h6">{lesson.name}</h2>
                                </div>
                            </div>
                            <div className="lessons-builder-lesson-header-actions">
                                <div className="lessons-builder-lesson-header-options">
                                    <div>{t('LessonPanel.SequenceLevel')}{lesson.level}</div>
                                    <div>{lesson.isRequired ? t('miscelaneous.required') : ''}</div>
                                </div>
                                <div className="lessons-builder-lesson-header-btns">
                                    <button className="btn text-dark"
                                        onClick={() => onClickEditButon(lesson.id)}>
                                        <i className="fa fa-pencil"></i>
                                    </button>
                                    <button className="btn text-dark"
                                        onClick={() => toggleDeletePopUp(true)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <DeleteConfirmPopup
                                onClose={() => { toggleDeletePopUp(false) }}
                                message={t('LessonPanel.deleteLessonMsg')}
                                isVisible={isdeletePopUpVisible}
                                onClickDelete={onClickDeleteLesson}>
                            </DeleteConfirmPopup>
                        </div>

                    </div>
                }

                <DragList {...dragProps}>
                    <ul className="lessons-builder-tasks-list">
                        {lesson.tasks && lesson.tasks.map((content, index) => (
                            <dl key={index} className="lessons-builder-tasks-list-item">
                                <ContentPanel
                                    lessonId={lesson.id}
                                    key={index}
                                    courseType={courseType}
                                    index={index + 1}
                                    content={content!}
                                    onClickDelete={(value) => onClickContentDelete(content.id, lesson.id, value)}
                                    onClickEditButon={onClickContentEdit}
                                    onContentUpdate={onContentUpdate}
                                    onSaveForms={onAddNewForms}
                                    onClickAdd={() => onHandleContentPanel(content.id)}
                                    onOptionsClick={(value: string) => handleOptionsClick(value)}
                                    onCreateContent={handleNewContent}
                                    onCancelForms={onCancelForms}
                                    type={content.type}
                                    groupId={groupId}
                                    courseId={courseId}
                                    updateQuestion={updateQuestion}
                                    onCompleteEdit={onCompleteEdit}
                                    setContent={setContentHandler}
                                    errorHandler={errorHandler}
                                    isVisibleAddNewContent={isVisibleAddNewContent}
                                    setAddNewContent={(id) => addnewContentHandler(id)}
                                >
                                    {editingContent && (editingContent == content.id) &&
                                        <div className="lessons-builder-task-new" style={{ marginTop: "10px" }}>
                                            <NewContent
                                                lessonId={lesson.id}
                                                name={content.name}
                                                courseType={courseType}
                                                coachValidationRequired={isCoachValidationRequired}
                                                privateAssign={isPrivate}
                                                sequenceLevel={content.level}
                                                required={content.isRequired}
                                                assigndTo={renderSwitch(content.assignedTo)}
                                                contentId={content.id}
                                                traineeContent={traineeBodyContent}
                                                coachContent={coachBodyContent}
                                                onClose={() => setContentEditing(false)}
                                                onSave={(newContent, lessonId) => updateContent(newContent, content.id, lessonId)}
                                                type={editingContent ? content.type : contentOption}
                                                nameLength={content.name.length}
                                                minPassingScore={minPassScore}
                                                duration={duration}
                                            />
                                        </div>
                                    }
                                </ContentPanel>
                            </dl>
                        ))}
                    </ul>
                </DragList>
                <div className="lessons-builder-tasks-list-new">
                    <div className="lessons-builder-task-add">
                        <AddButton
                            onAdd={() => onHandleContentPanel()}
                            label={t('miscelaneous.task')}
                            active={true} />
                    </div>
                </div>
                {isOptionsDisplayed &&
                    <div style={{ marginLeft: "10px", marginTop: "70px" }}>
                        <ContentOptions
                            isVisible={true}
                            onClick={(value: string) => handleOptionsClick(value)}
                            onClose={handleOptions}
                            couseType={courseType}>
                        </ContentOptions>
                    </div>
                }
                {isContentDisplayed && 
                    <div style={{ marginTop: "63px", paddingLeft: "14.5px" }}>
                        <NewContent
                            onClose={handleContents}
                            onSave={handleBottomNewContent}
                            type={contentOption}
                            lessonId={lesson.id}
                            courseType={courseType}
                        >
                        </NewContent>
                    </div>
                }
            </div>
        </div >
    )
}
