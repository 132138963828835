import * as React from 'react';
import { QuestionOption } from '../../../../../domain/models';
import { QuizOptionField } from '../../../../../components/Quiz/QuizOptionField';
import { CheckboxI } from '../../../../../components/Forms/CheckboxI';
import _ from 'lodash';

export interface EditCheckboxProps {
    currentOptions: QuestionOption[];
    onCompleteEdit: (item: QuestionOption) => void
    expectedAnswer?: number[]
    onChangeAnswer?: (options: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
    onDeleteExpectedAnswer?: (id: number) => void;
}

export const EditCheckbox: React.FunctionComponent<EditCheckboxProps> = ({
    currentOptions,
    onCompleteEdit,
    expectedAnswer,
    onChangeAnswer,
    onAddOption,
    onDeleteOption,
    onDeleteExpectedAnswer
}) => {


    const [editedOptionValue, setEditedOptionValue] = React.useState()
    const [editedOptionIndex, setEditedOptionIndex] = React.useState()
    const [optionsList, setOptionsList] = React.useState(currentOptions)
    const [validationOn, toggleValidation] = React.useState(false)
    const [selectedItems, setSelectedItems] = React.useState(expectedAnswer!)
    const [showSpinner, setShowSpinner] = React.useState()
    
    React.useEffect(() => {
        setOptionsList(currentOptions)
    }, [currentOptions])

    React.useEffect(() => {
      setSelectedItems(expectedAnswer!)
      setShowSpinner(undefined)
    }, [expectedAnswer])


    const onAddNewOptionHandler = (index: number) => {
        if (optionsList.length === currentOptions.length) {
            toggleValidation(false)
            const updatedOptions = [...optionsList]
            updatedOptions.splice(index + 1, 0, { id: 0, body: '' })
            setOptionsList([...updatedOptions])
        }
    }


    const onRemoveOptionHandler = (index: number) => {

        let item = optionsList[index]
        let isAnswerItem = expectedAnswer!.filter((answer) => { return answer === item.id })
        if (isAnswerItem.length) {
            toggleValidation(true)
            return
        }
        if (item.id !== 0) {
            toggleValidation(false)
        onDeleteOption!(item.id)
        }
        else {
            const updatedOptions = [...optionsList]
            updatedOptions.splice(index, 1)
            setOptionsList([...updatedOptions])
        }
    }

    const editOptionHandler = (val: string, index: number) => {
        setEditedOptionValue(val)
        setEditedOptionIndex(index)
    }

    const editCompleteHandler = (item: QuestionOption,index:number ) => () => {
      if(!_.isEmpty(editedOptionValue) && !_.isUndefined(editedOptionValue) && !_.isNull(editedOptionValue)) {
        setShowSpinner(editedOptionIndex)
        let updatedOption: QuestionOption = {
            id: item.id,
            body: editedOptionValue,
            order: index + 1,
            expectedAnswer: item.expectedAnswer
        }
        if (editedOptionValue)
            item.id === 0 ? onAddOption!(updatedOption) : onCompleteEdit(updatedOption)

        currentOptions.splice(index, 1, updatedOption)
      }
      setEditedOptionValue(undefined)
      setEditedOptionIndex(undefined)
    }

    const handleAnswerGridEdit = (item: QuestionOption) => (checkedValue: string) => {

        let selectedItemtIndex = selectedItems.findIndex((val) => val === Number(checkedValue))
        if (selectedItemtIndex !== -1) {
            if (selectedItems.length <= 1) return
            let updatedItems = [...selectedItems]
            updatedItems.splice(selectedItemtIndex, 1)
            setSelectedItems([...updatedItems])
            onDeleteExpectedAnswer!(Number(checkedValue))
        } else {
            setSelectedItems([...selectedItems, Number(checkedValue)])
            let answer = {
                id: item.id,
                body: item.body
            }
            toggleValidation(false)
            onChangeAnswer!(answer);
        }
    }

    return (
        <>
            {optionsList!.map((item, index) => (
              <div className=""> 
                <div className="d-flex">      
                    <CheckboxI
                        active={true}
                        id={index.toString()}
                        value={item.id.toString()}
                        label={''}
                        isChecked={selectedItems.filter((val) => { return val === item.id }).length > 0}
                        isValid={false}
                        isInvalid={false}
                        onChange={handleAnswerGridEdit(item)}
                    />

                    <QuizOptionField
                        index={index}
                        active={true}
                        placeholder={'Enter an answer choice'}
                        value={index === editedOptionIndex  ?  (editedOptionValue || '') : item.body}
                        isValid={false}
                        isInvalid={false}
                        onChange={editOptionHandler}
                        onAddOption={onAddNewOptionHandler}
                        onRemoveOption={onRemoveOptionHandler}
                        isEditing={index === editedOptionIndex}
                        showSpinner={index === showSpinner}
                        onCompleteEdit={editCompleteHandler(item, index)}
                    />
                </div>
                
                </div>
            ))
            }
            {validationOn &&
                <div className="invalid-feedback d-flex">You cant delete answer key. Please change answer key to delete.</div>
            }
        </>
    )

}