import * as React from 'react';

import { Auth } from '../../domain/_shared';

import { CourseParticipantPage, CourseParticipantPageRouteParameters } from './CourseParticipantPage';

export const participantRoutes = (auth: Auth, handleLogout: () => void): [string[], Function][] => {
    return [
        [['groups', ':groupId', 'courses', ':courseId'],    // OBS.: non-Admin route
        (params: CourseParticipantPageRouteParameters) => (
            <CourseParticipantPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
            />
        )],
    ]
}