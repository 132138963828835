import * as React from 'react';

export declare type ActionMatcher<A, S> = {
    match: (act: A) => S;
};

export declare type ActionDispatcher<A, S> = {
    send: (sender: (act: A) => S) => void;
}; 

export function wrapperReducer<A, S>(tpl: [S, React.Dispatch<ActionMatcher<A, S>>]) {
    return [
        tpl[0],
        {
            send: (sender: (act: A) => S) => {
                tpl[1]({
                    match: function (act: A): S {
                        return sender(act);
                    }
                });
            }
        }
    ] as [S, ActionDispatcher<A, S>];
}

export function useAsyncEffect(asyncFn: () => Promise<any>, deps?: ReadonlyArray<any> | undefined) {
    React.useEffect(() => { asyncFn() }, deps);
}

export function style(stl: React.CSSProperties): React.CSSProperties {
    return stl;
}
