import { __SERVER_API__ } from '.';
import { Auth, get, post, remove } from '../_shared';
import {
    ParticipantCourse,
    Lesson
} from '../models';

export async function getParticipantCourse(auth: Auth, courseId: number): Promise<ParticipantCourse> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}`, auth);
}

export async function getParticipantCourseLessons(auth: Auth, courseId: number): Promise<Lesson[]> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}/lessons`, auth);
}
