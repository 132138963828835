import spinnerBackground from '../../assets/img/input-spinner.gif'
import * as React from 'react';


export interface TextIProps {
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    showSpinner?: boolean;
    onChange: (typedValue: string) => void;
}

export const TextI: React.FunctionComponent<TextIProps> = ({ active, placeholder, value, isValid, isInvalid, onChange, showSpinner }) => {
  const inputStyle = showSpinner ? {backgroundImage: `url(${spinnerBackground})`} : {}
    return (
      <>
        <input
            className={"form-control col-md-12 form-spinner-background" + (isValid ? 'is-valid' : '') + (isInvalid ? 'is-invalid' : '')}
            type="text"
            disabled={!active}
            placeholder={placeholder}
            value={value}
            maxLength={4000}
            onChange={e => onChange(e.target.value)}
            style={inputStyle}
        />
        </>
    );
}