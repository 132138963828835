import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Card } from '../../../../../components/Cards/Card';
import { CardViewType } from '../../../../../components/Cards/CardSection';

export interface TraineeCourseCardProps {
    cardViewType?: CardViewType;

    courseStatus: string;
    courseType: string;
    name: string;
    courseImgUrl?: string;
    groupName?: string;
    description: string;

    coachName?: string;
    coachEmail?: string;
    startDate?: string;
    traineeLastAccessDate?: string;
    progress?: number;
    lastLessonName?: string;
    lastTaskAssignedTo?: string;
    lastTaskName?: string;
    lastTaskStatus?: string;

    onCourseClick?: () => void;
}

export const TraineeCourseCard: React.FunctionComponent<TraineeCourseCardProps> = ({
    cardViewType,

    courseStatus,
    courseType,
    name,
    courseImgUrl,
    groupName,
    description,

    coachName,
    coachEmail,
    startDate,
    traineeLastAccessDate,
    progress,
    lastLessonName,
    lastTaskAssignedTo,
    lastTaskName,
    lastTaskStatus,

    onCourseClick,
}) => {
    const { t } = useTranslation();

    const [openLastActivities, toggleLastActivities] = useState(false);

    if (['Draft', 'Test', 'Published'].indexOf(courseStatus) === -1
        || ['SelfPaced', 'CoachAssisted'].indexOf(courseType) === -1) {
        return (
            <Card
                cardViewType={cardViewType}
                body={() => (
                    <>
                        <h2 className="card-title h6">
                            <a href="javascript:void(0)" onClick={() => onCourseClick!()}>{name}</a>
                        </h2>
                        <div style={{ color: 'red' }} className="mt-1 mt-md-2"><em>(invalid course)</em></div>
                    </>
                )}
            />
        );
    }

    return (
        <Card
            cardViewType={cardViewType}
            header={() => (
                <>
                  <div className="header-wrapper">
                    {(courseImgUrl) && <img className="card-img-top rounded mb-2" src={courseImgUrl} alt="(some-URL)"></img>}
                    <div className="header-content">                    
                      <h2 className="card-title h6">
                          <a href="javascript:void(0)" onClick={() => onCourseClick!()}>{name}</a>
                      </h2>
                      <div className="mt-1 mt-md-2">
                          <strong>{t('CourseCard.group')}:</strong>
                          {' '} {groupName}
                      </div>
                      {(courseType === 'CoachAssisted') && <div className="mt-1 mt-md-2">
                          <strong>{t('CourseCard.coach')}:</strong> {coachName}
                          {' '} <a data-placement="top" href={`mailto:${coachEmail}`}>
                              {' '} <i className="fa fa-envelope"></i>
                          </a>
                      </div>}
                      {(courseType === 'SelfPaced') && <div className="mt-1 mt-md-2">
                          <em>{t('CourseCard.selfPaced')}</em>
                      </div>}
                      {(courseStatus === 'Draft') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}> <strong className="badge badge-pill badge-info">{t('CourseCard.draftLbl')}</strong> </p>
                      </div>}
                      {(courseStatus === 'Test') && <div className="mt-1 mt-md-2">
                          <p style={{ color: '#2e9fc9' }}> <strong className="badge badge-pill badge-light">{t('CourseCard.testLbl')}</strong></p>
                      </div>}
                      <p className="card-text text-muted mt-1 mt-md-2 mb-1 d-none d-sm-block">{description}</p>
                    </div>
                  </div>
                </>
            )}
            body={() => (
                <>
                    {startDate && <div className="">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.startDate')}:</span> {startDate ? t('dates.short', { valueObj: { type: 'withTZdatetime', data: startDate } }) : ''}</small>
                    </div>}
                    {traineeLastAccessDate && <div className="">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.lastEntry')}:</span> {traineeLastAccessDate ? t('dates.long', { valueObj: { type: 'withTZdatetime', data: traineeLastAccessDate } }) : ''}</small>
                    </div>}
                    <div className="">
                        <small><span style={{ fontWeight: 500 }} className="text-muted">{t('CourseCard.progress')}:</span> {progress == null ? 0 : (progress * 100).toFixed(1)} %</small>
                    </div>

                    {lastTaskName && <div className="mb-md-1 mt-3 mt-md-3">
                        <small>
                          <strong className="text-dark" style={{ cursor: 'pointer' }} onClick={() => toggleLastActivities(!openLastActivities)}>{t('CourseCard.lastActivity')}</strong>
                          {' '}<span className={classnames('fa strong', { 'fa-angle-up': openLastActivities, 'fa-angle-down': !openLastActivities })}></span>
                        </small> <br />
                        <div className={classnames('small my-1 lastActivities', { 'show': openLastActivities })}>
                            <strong className="text-muted">{t('CourseCard.lastLesson')}:</strong> {' '}{lastLessonName ? lastLessonName : ''} <br />
                            <strong className="text-muted">{t('CourseCard.lastTask')}:</strong> {lastTaskName} <br />
                            <strong className="text-muted">{t('CourseCard.lastTaskStatus')}:</strong> <FormatTaskStatus status={lastTaskStatus} /><br />
                            <strong className="text-muted">{t('CourseCard.lastTaskAssignedTo')}:</strong> <FormatAssignedTo role={lastTaskAssignedTo} />
                        </div>
                    </div>}
                </>
            )}
            footer={() => (
                <button className="btn btn-primary" type="button" onClick={() => onCourseClick!()}>{t('CourseCard.viewCourseBtn')}</button>
            )}
        />
    )
}

const FormatTaskStatus: React.FunctionComponent<{
    status?: string;
}> = ({ status }) => {
    const { t, i18n } = useTranslation();

    if (status && i18n.exists && i18n.exists(`fromAPI.taskStatuses.${status}`)) {
        return <>{t(`fromAPI.taskStatuses.${status}`)}</>
    }

    return <></>
}

const FormatAssignedTo: React.FunctionComponent<{
    role?: string;
}> = ({ role }) => {
    const { t } = useTranslation();

    if (role) {
        switch (role.toLowerCase()) {
            case 'trainee':
                return (<span className={'mb-0'}>{' '}<strong>{t(`fromAPI.userRoles.Trainee`)}</strong></span>);
            case 'coach':
                return (<span className={'mb-0 text-info'}>{' '}<strong>{t(`fromAPI.userRoles.Coach`)}</strong></span>);
            case 'both':
                return (<span className={'mb-0'}>{' '}<strong>{t(`fromAPI.userRoles.Both`)}</strong></span>);
            default:
                return (<>(INVALID)</>);
        }
    }

    return <></>
}
