import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import _ from 'lodash';

import { ParticipantsMgmtContext } from './ParticipantsMgmtContext';

export interface SelfPacedSorterDropDownProps {
    label: string;
    styleName?: string;
    icon?: string,
}

export const SelfPacedSorterDropDown: React.FunctionComponent<SelfPacedSorterDropDownProps> = ({ label, styleName, icon }) => {
    const { state: { }, actions } = useContext(ParticipantsMgmtContext);
    const { t } = useTranslation();
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);

    const closeDropdown = () => {
        if (openDropdownMenu) toggleDropdownMenu(false);
    }

    const handleSortSelection = (sorter: string) => (value: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        actions.send(a => a.updateSorter(sorter))
    }

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(closeDropdown, 500);
    }

    return (
        <div className="btn-group" onBlur={handleOnBlur}>
            <div className="dropdown">
                <button className={`btn dropdown-toggle ${styleName ? styleName : 'btn-light'}`} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)}>
                    {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
                    <span className="btn-label">{label}</span>
                </button>
                <div className={classnames('dropdown-menu dropdown-menu-left', { 'show': openDropdownMenu })}>
                    <a className="dropdown-item" href="#" onClick={handleSortSelection('enrollmentDate')}><span className="small">{t('CourseManagementParticipantsPage.EnrollDateLbl')}</span></a>
                    <a className="dropdown-item" href="#" onClick={handleSortSelection('name')}><span className="small">{t('CourseManagementParticipantsPage.NameAlphabeticalOrderLbl')}</span></a>
                </div>
            </div>
        </div>
    )
}
