import * as React from 'react';
import { QuestionOption } from '../../../../../domain/models';
import { QuizOptionField } from '../../../../../components/Quiz/QuizOptionField';
import { RadioboxI } from '../../../../../components/Forms/RadioboxI';
import _ from 'lodash';

export interface EditRadioboxProps {
    currentOptions: QuestionOption[];
    onCompleteEdit: (item: QuestionOption) => void
    expectedAnswer?: number[]
    onChangeAnswer?: (options: QuestionOption) => void;
    onAddOption?: (item: QuestionOption) => void;
    onDeleteOption?: (id: number) => void;
}

export const EditRadiobox: React.FunctionComponent<EditRadioboxProps> = ({
    currentOptions,
    onCompleteEdit,
    expectedAnswer,
    onChangeAnswer,
    onAddOption,
    onDeleteOption
}) => {

    const [editedOptionValue, setEditedOptionValue] = React.useState()
    const [editedOptionIndex, setEditedOptionIndex] = React.useState()
    const [optionsList, setOptionsList] = React.useState(currentOptions)
    const [validationOn, toggleValidation] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState(expectedAnswer![0])
    const [showSpinner, setShowSpinner] = React.useState()

    React.useEffect(() => {
        setOptionsList(currentOptions)
        setShowSpinner(undefined)
    }, [currentOptions])

     React.useEffect(() => {
      setSelectedItem(expectedAnswer![0])
    }, [expectedAnswer])

    const editOptionHandler = (val: string, index: number) => {
        setEditedOptionValue(val)
        setEditedOptionIndex(index)
    }

    const onAddNewOptionHandler = (index: number) => {
        if (optionsList.length === currentOptions.length) {
            toggleValidation(false)
            const updatedOptions = [...optionsList]
            updatedOptions.splice(index + 1, 0, { id: 0, body: '' })
            setOptionsList([...updatedOptions])
        }
    }

    const onRemoveOptionHandler = (index: number) => {
        let item = optionsList[index]
        let isAnswerItem = expectedAnswer!.filter((answer) => { return answer === item.id })
        if (isAnswerItem.length) {
            toggleValidation(true)
            return
        }
        if (item.id !== 0) {
            toggleValidation(false)
            onDeleteOption!(item.id)}
        else {
            const updatedOptions = [...optionsList]
            updatedOptions.splice(index, 1)
            setOptionsList([...updatedOptions])
        }
    }

    const editCompleteHandler = (item: QuestionOption, index: number) => () => {
      if(!_.isEmpty(editedOptionValue) && !_.isUndefined(editedOptionValue) && !_.isNull(editedOptionValue)) {
      setShowSpinner(editedOptionIndex)
        let updatedOption: QuestionOption = {
            id: item.id,
            body: editedOptionValue,
            order: index + 1,
            expectedAnswer: item.expectedAnswer
        }
        if (editedOptionValue)
            item.id === 0 ? onAddOption!(updatedOption) : onCompleteEdit(updatedOption)
        currentOptions.splice(index, 1, updatedOption)
      }
        setEditedOptionValue(undefined)
        setEditedOptionIndex(undefined)
    }

    const onOptionChangeHandler = (item: QuestionOption, index: number) => {        
        setEditedOptionIndex(undefined)
        let answer = {
            id: item.id,
            body: item.body
        }
        toggleValidation(false)
        setSelectedItem(answer.id)
        onChangeAnswer!(answer);
    }

    const [selectedIndex, setSelectedIndex] = React.useState(currentOptions.findIndex(currentOptions => currentOptions.id === expectedAnswer![0]))



    return (
        <>
            {optionsList!.map((item, index) => (
                <div className="d-flex">
                    <RadioboxI
                        active={true}
                        id={index.toString()}
                        value={item.id.toString()}
                        label={''}
                        isSelected={selectedItem === item.id}
                        isValid={false}
                        isInvalid={false}
                        onChange={() => onOptionChangeHandler(item, index)}
                    />

                    <QuizOptionField
                        index={index}
                        active={true}
                        placeholder={'Enter an answer choice'}
                        value={index === editedOptionIndex  ?  (editedOptionValue || '') : item.body}
                        isValid={false}
                        isInvalid={false}
                        onChange={editOptionHandler}
                        onAddOption={onAddNewOptionHandler}
                        onRemoveOption={onRemoveOptionHandler}
                        isEditing={index === editedOptionIndex}
                        showSpinner={index === showSpinner}
                        onCompleteEdit={editCompleteHandler(item, index)}
                    />
                    
                </div>
            ))
            }
            {validationOn &&
                        <div className="invalid-feedback d-flex">You can't delete answer key. Please change answer key to delete.</div>
            }
        </>
    )

}