import * as React from 'react';
import moment from 'moment';

import { DateTimeI } from './DateTimeI';

export interface DateTimeBlockProps {
    active: boolean;
    answered: boolean;
    questionBody: string;
    expectedAnswer?: string;        // 'YYYY-MM-DDTHH:mm:ss.sss' (ISO) format
    value?: string;                 // 'yyyy-MM-ddThh:mm' format
    min?: string;                   // 'yyyy-MM-ddThh:mm' format
    max?: string;                   // 'yyyy-MM-ddThh:mm' format
    onChange: (newDateTime: string) => void;
}

export const DateTimeBlock: React.FunctionComponent<DateTimeBlockProps> = ({ active, answered, questionBody, expectedAnswer, value, min, max, onChange }) => {

    const isValid = function (localDate: string | undefined): boolean {
        let valueIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            const formattedDT = moment.utc(expectedAnswer).format('YYYY-MM-DDTHH:mm');
            valueIsExpected = moment(formattedDT).isSame(localDate);
        }

        return answered && valueIsExpected;
    }

    const isInvalid = function (localDate: string | undefined): boolean {
        let valueIsExpected: boolean;
        if (!expectedAnswer) {
            return false;
        } else {
            valueIsExpected = (moment.utc(expectedAnswer).format('YYYY-MM-DDTHH:mm') === localDate);
        }

        return answered && !valueIsExpected;
    }

    return (
        <>
            <div className="form-group">
                <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
                <DateTimeI
                    active={active}
                    value={value}
                    min={min}
                    max={max}
                    isValid={isValid(value)}
                    isInvalid={isInvalid(value)}
                    onChange={(typedValue: string) => onChange(typedValue)}
                />
            </div>
        </>
    );
}
