import * as React from 'react';

import classnames from 'classnames';

export interface PageNavProps {
    isVertical?: boolean;
}

export const PageNav: React.FunctionComponent<PageNavProps> = ({ isVertical, children }) => {
    return (
        <nav className="page-nav">
            <ul className={classnames('nav', { 'flex-column': isVertical })}>
                {children}
            </ul>
        </nav>
    )
}