import * as React from 'react';

import { QuestionOption } from '../../domain/models';
import { CheckboxI } from './CheckboxI';

export interface CheckboxBlockProps {
    active: boolean;
    answered: boolean;
    questionBody: string;
    options: QuestionOption[];
    hideOptionLabels: boolean;
    expectedOptions?: number[];
    checked?: number[];
    onChange: (toggleChecked: number) => void;
}

let uniqueID: number = 0;

export const CheckboxBlock: React.FunctionComponent<CheckboxBlockProps> = ({ active, answered, questionBody, options, hideOptionLabels, expectedOptions, checked, onChange }) => {
    const checkboxBlockId = ++uniqueID;

    const oneIsChecked = function (optionId: number): boolean {
        return (!!checked) && (checked.indexOf(optionId) !== -1);
    }

    const oneIsValid = function (value: number, oneIsChecked: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedOptions) {
            return false;
        } else {
            if (expectedOptions.length === 0) return answered && !oneIsChecked;

            oneIsExpected = (expectedOptions.indexOf(value) !== -1);
        }

        return answered && oneIsChecked && oneIsExpected;
    }

    const oneIsInvalid = function (value: number, oneIsChecked: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedOptions) {
            return false;
        } else {
            if (expectedOptions.length === 0) return answered && oneIsChecked;

            oneIsExpected = (expectedOptions.indexOf(value) !== -1);
        }

        return answered && (oneIsChecked && !oneIsExpected || !oneIsChecked && oneIsExpected);
    }

    return (
        <div className="form-group">
            <div className="form-group-title">
                <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
            </div>
            {options && options.map((option, i) => {
                const oneIsCkd: boolean = oneIsChecked(option.id);
                return (
                    <CheckboxI
                        key={i}
                        active={active}
                        id={`check${checkboxBlockId}${i}`}
                        value={option.id.toString()}
                        label={hideOptionLabels ? '' : option.body}
                        isChecked={oneIsCkd}
                        isValid={oneIsValid(option.id, oneIsCkd)}
                        isInvalid={oneIsInvalid(option.id, oneIsCkd)}
                        onChange={(checkedValue: string) => onChange(+checkedValue)}
                    />
                )
            })}
        </div>
    );
}
