import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import _ from 'lodash';

export interface ApprovePendingParticipantsPopupProps {
    message?: string;
    courseId: any;
    isVisible: boolean;
    onClose: () => void;
    onApprove: () => void;
}

type State = {
    open: any
}

export const ApprovePendingParticipantsPopup: React.FunctionComponent<ApprovePendingParticipantsPopupProps> = ({ isVisible, message, onClose, onApprove }) => {
    const { t } = useTranslation();
    return (
        <span >
               <Modal open={isVisible} onClose={() => onClose()}>
               <br/>
                <p>
                    {message}
                </p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onClose()}>{t('miscelaneous.cancel')}</button>
                    <button type="button" className="btn btn-primary"  onClick={() => onApprove()}>{t('miscelaneous.yes')}</button>

                </div>
            </Modal>
        </span>
    );
}
