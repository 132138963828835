import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { ParticipantCourse, Lesson } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { ParticipantCourseHeader } from './components/main/ParticipantCourseHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { CardSection } from '../../../components/Cards/CardSection';
import { EffectContentArea } from '../../../components/EffectContentArea';
import { ParticipantLessonPanel } from './components/tab1/ParticipantLessonPanel';

export interface CourseParticipantPageRouteParameters {
    groupId: number;
    courseId: number;
}

export interface CourseParticipantPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
}

type State = {
    apiError?: any | undefined;
    course?: ParticipantCourse;
    lessons?: Lesson[];
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: ParticipantCourse): State;
    LoadLessons(lessons: Lesson[]): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadLessons: lessons => ({ ...state, lessons }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const CourseParticipantPage: React.FunctionComponent<CourseParticipantPageProps> = ({ auth, onLogout, groupId, courseId }) => {
    const { t } = useTranslation();
    const [{ apiError, course, lessons, tabIndex }, actions] = wrapperReducer(useReducer(reducer, { tabIndex: 0 }));

    const goToRoot = () => Router.push('/');
    const goToMyGroups = () => Router.push('/mygroups');
    const goToGroup = () => Router.push(`/groups/${groupId}`);

    useLoadCourse(auth, courseId, actions);
    useLoadLessons(auth, courseId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!course || !lessons) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myGroups'), onClick: goToMyGroups },
                { text: `${course.groupName}`, onClick: goToGroup },
                { text: `${course.name}`, active: true }
            ]}
            onLogout={onLogout}
        >

            <ParticipantCourseHeader
                courseName={course.name}
                groupName={course.groupName}

                description={course.description!}
            />

            <PageNav>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 0 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(0))}>
                        {t('CourseParticipantPage.tab0')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 1 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(1))}>
                        {t('CourseParticipantPage.tab1')}
                    </a>
                </li>
            </PageNav>

            {tabIndex === 0 && (
                <div className="main mt-3 mt-md-4">
                    <div className="container-fluid">
                        <div className="card bg-white px-4 pt-3">
                            <CardSection title={t('CourseParticipantPage.tab0_title')}>
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />
                                    <EffectContentArea rawHTML={course.body || ''} onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))} />
                                </div>
                            </CardSection>
                        </div>
                    </div>
                </div>
            )}

            {tabIndex === 1 && (
                <div className="mt-3 mt-md-4">
                    <div className="container-fluid">
                        {lessons.map((lesson, i) => (
                            <ParticipantLessonPanel index={i + 1} lesson={lesson}></ParticipantLessonPanel>
                        ))}
                    </div>
                </div>
            )}

        </BaseAppLayout>
    );
}

function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getParticipantCourse(auth, courseId);
            if (isRequestError(course)) {
            }
            else {
                actions.send(a => a.LoadCourse(course));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadLessons(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const lessons = await services.getParticipantCourseLessons(auth, courseId);
            if (isRequestError(lessons)) {
            }
            else {
                actions.send(a => a.LoadLessons(lessons));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
