import * as React from 'react';
import classnames from 'classnames';

/**
 * SUBMIT ANYTHING, instead of just forms
 */

export interface SubmitFormProps {
    active: boolean,
    secondaryColor?: boolean,
    label: string,
    loading: boolean,
    onSubmit: () => void,
}

export const SubmitForm: React.FunctionComponent<SubmitFormProps> = ({ active, secondaryColor, label, loading, onSubmit }) => {
    return (
        <button
            disabled={!active}
            onClick={onSubmit}
            className={classnames('btn m-1',
                { 'btn-loader': loading, 'btn-primary': !secondaryColor, 'btn-secondary': secondaryColor }
            )}
            type="button"
        >
            {(loading) && <div className="sk-rotating-plane"></div>}
            {' '}{label}
        </button>
    )
}