import * as React from 'react';
import Pagination from 'react-js-pagination';


export interface TraineePaginationProps {
    resultsCount: number;
    activePage: number;
    resultsPerPage: number;
    changePage: (page: number) => void
}

export const TraineePagination: React.FunctionComponent<TraineePaginationProps> = ({ resultsCount, activePage, resultsPerPage, changePage }) => {
    const handlePageChange = (pageNumber: number) => {
      changePage(pageNumber)
    }

    return (
        <div>
            <Pagination
                activePage={ activePage }
                itemsCountPerPage={ resultsPerPage }
                totalItemsCount={resultsCount}
                pageRangeDisplayed={5}
                onChange={e => handlePageChange(e)}
                firstPageText={<i className='fa fa-angle-double-left'></i>}
                prevPageText={<i className='fa fa-angle-left'></i>}
                lastPageText={<i className='fa fa-angle-double-right'></i>}
                nextPageText={<i className='fa fa-angle-right'></i>}
            />
        </div>
    );
}
