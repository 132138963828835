import * as React from 'react';

import { QuestionOption } from '../../domain/models';
import { RadioboxI } from './RadioboxI';

export interface RadioboxBlockProps {
    active: boolean;
    answered: boolean;
    questionBody: string;
    options: QuestionOption[];
    hideOptionLabels: boolean;
    expectedOptions?: number[];
    selected?: number;
    style?: string;
    onChange: (newSelected: number) => void;
}

let uniqueID: number = 0;

export const RadioboxBlock: React.FunctionComponent<RadioboxBlockProps> = ({ active, answered, questionBody, options, hideOptionLabels, expectedOptions, selected, onChange, style }) => {
    const radioboxBlockId = ++uniqueID;

    const oneIsSelected = function (optionId: number): boolean {
        return (selected === optionId);
    }

    const oneIsValid = function (value: number, oneIsSelected: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedOptions) {
            return false;
        } else {
            oneIsExpected = (expectedOptions.indexOf(value) !== -1);
        }

        return answered && oneIsSelected && oneIsExpected;
    }

    const oneIsInvalid = function (value: number, oneIsSelected: boolean): boolean {
        let oneIsExpected: boolean;
        if (!expectedOptions) {
            return false;
        } else {
            oneIsExpected = (expectedOptions.indexOf(value) !== -1);
        }

        return answered && (oneIsSelected && !oneIsExpected || !oneIsSelected && oneIsExpected);
    }

    return (
        <div className={`form-group ${style}`}>
            <div className="form-group-title">
                <h3 className="h6 mb-3 mb-md-4"><div dangerouslySetInnerHTML={{ __html: questionBody }} /></h3>
            </div>
            {options && options.map((option, i) => {
                const oneIsSltd: boolean = oneIsSelected(option.id);
                return (
                    <RadioboxI
                        key={i}
                        active={active}
                        id={`radio${radioboxBlockId}${i}`}
                        value={option.id.toString()}
                        label={hideOptionLabels ? '' : option.body}
                        isSelected={oneIsSltd}
                        isValid={oneIsValid(option.id, oneIsSltd)}
                        isInvalid={oneIsInvalid(option.id, oneIsSltd)}
                        onChange={(selectedValue: string) => onChange(+selectedValue)}
                    />
                )
            })}
        </div>
    );
}
