
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import Modal from 'react-responsive-modal';

interface ImageLoadingProps {
    isVisible: boolean,
}

export const ImageLoading: React.FunctionComponent<ImageLoadingProps> = ({isVisible}) => {
 
    

    return (
        <span>
            <Modal
             onClose={() =>{}}
             open={isVisible}
             showCloseIcon={false}
                center>
                <div>
                        <a href="">
                            <div className='loading-spinner'></div>
                        </a>
                </div>
            </Modal>

        </span>


    )



}


